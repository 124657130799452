import React, { useMemo } from 'react';
import { FaRegEdit } from 'react-icons/fa';

import Avatar from 'src/assets/images/Avatar.png';
import { Typography } from 'src/components/Typography';
import { Button } from 'src/components/ui/button';
import { Skeleton } from 'src/components/ui/skeleton';
import { HEADER_TEXT, USER_ROLE } from 'src/constants/common';
import { PROFILE } from 'src/constants/profile';
import useAuthContext from 'src/hooks/useAuth';

interface ProfileImageProps {
  toggleEditImage: () => void;
}

const ProfileImage: React.FC<ProfileImageProps> = ({ toggleEditImage }) => {
  const { auth } = useAuthContext();
  const { user } = auth ?? {};
  const userName = useMemo(() => {
    if (user?.role === USER_ROLE.ADMIN && !user?.firstName) {
      return HEADER_TEXT.ADMIN;
    }
    return `${user?.firstName} ${user?.lastName}`;
  }, [user?.role, user?.firstName, user?.lastName]);
  return (
    <div className='bg-nightSky w-11/12 mx-auto p-3 md:p-6 m-4 rounded-2xl'>
      <div className='flex justify-between'>
        <Typography variant='subheading' className='text-lg md:text-2xl'>
          {PROFILE.profilePicture}
        </Typography>
        <Button
          variant={'primary'}
          className='rounded-full p-3 md:p-5 font-medium text-sm md:text-base'
          onClick={toggleEditImage}
        >
          <FaRegEdit className='mr-2 sm:mr-4' size={19} />
          {PROFILE.EDIT}
        </Button>
      </div>
      <div className='flex items-center gap-2 my-2'>
        <img src={user?.avatar || Avatar} className='size-16 rounded-full' />
        <div className='text-white text-left ml-1 md:ml-2 flex flex-col gap-1'>
          <Typography className='text-base md:text-lg'>
            {!userName ? (
              <Skeleton className='w-[90%] h-5 bg-borderGrayColor rounded-[20px]' />
            ) : (
              userName
            )}
          </Typography>
          {!user?.role ? (
            <Skeleton className='w-32 h-5 bg-borderGrayColor rounded-[20px]' />
          ) : (
            <Typography className='text-sm capitalize'>{user?.role}</Typography>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfileImage;
