export const PROFILE_COMPLETION_INPUT_ERRORS = {
  FIRST_NAME: {
    required: '*First name is required.',
  },
  NAME_TYPE: {
    acceptFormat: '*Invalid name type. Only alphabets are allowed.',
  },
  ZIP_CODE_TYPE: {
    acceptFormat: '*Invalid zip code type. Only number are allowed.',
  },
  MIDDLE_NAME: {
    required: '*Middle name is required.',
  },
  LAST_NAME: {
    required: '*Last name is required.',
  },
  STREET_ADDRESS: {
    required: '*Street address is required.',
  },
  COUNTRTY: {
    required: '*Country name is required.',
  },
  STATES: {
    required: '*State name is required.',
  },
  ZIP_CODE: {
    required: '*Zip code is required.',
  },
  NAME_OF_EMPLOYER: {
    required: '*Name of employer is required.',
  },
  OCCUPATION_OR_JOB: {
    required: '*Occupation or job is required.',
  },
  CITY: {
    required: '*City name is required.',
  },
  NAME_MAX_LENGTH: '*Maximum length is 25 characters',
  FULL_NAME_MAX_LENGTH: '*Maximum length is 50 characters',
  STREET_MAX_LENGTH: '*Maximum length is 255 characters',
  ZIPCODE_MAX_LENGTH: '*Maximum length is 15 characters',
};
