import React, { useState } from 'react';

import withProtectedRoute from 'src/components/ProtectedRoute';
import { Typography } from 'src/components/Typography';
import { PROFILE } from 'src/constants/profile';
import useAuthContext from 'src/hooks/useAuth';

import EditProfileImage from './EditProfile/EditeProfileImage';
import EditPersonalInfo from './EditProfile/EditPersonalInfo';
import LinkedCard from './LinkedCard';
import Payment from './Payment';
import PaymentHistory from './PaymentHistory';
import PersonalInfo from './PersonalInfo';
import ProfileImage from './ProfileImage';

const Profile = () => {
  const [isEditInfo, setIsEditInfo] = useState(false);
  const [isEditImage, setIsEditImage] = useState(false);
  const toggleEdit = () => setIsEditInfo((prev) => !prev);
  const toggleEditImage = () => setIsEditImage((prev) => !prev);
  const { auth } = useAuthContext();

  return (
    <>
      <div className='flex justify-center my-5'>
        <div className='bg-skyCaptain px-3 py-1 rounded-full border border-white'>
          <Typography
            textGradient
            className='text-lg md:text-xl font-medium md:tracking-[0.15rem]'
          >
            {PROFILE.profile}
          </Typography>
        </div>
      </div>

      <div className='bg-navyBlue rounded-[24px] mb-48 md:px-12 py-6'>
        {isEditImage ? (
          <EditProfileImage toggleEditImage={toggleEditImage} />
        ) : (
          <ProfileImage toggleEditImage={toggleEditImage} />
        )}
        {isEditInfo ? (
          <EditPersonalInfo handleUpdateInfo={toggleEdit} />
        ) : (
          <PersonalInfo handleEditInfo={toggleEdit} />
        )}
        {auth?.user.subscriptionType || auth?.user.planId ? (
          <>
            <Payment />
            <LinkedCard />
            <PaymentHistory />
          </>
        ) : null}
      </div>
    </>
  );
};

export default withProtectedRoute(Profile);
