import React, { FC } from 'react';

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'src/components/ui/select';
import { SORTING_ITEMS } from 'src/constants/dashboard';

interface ISortRow {
  handleSelect: (value: string) => void;
  value?: string;
}

const SortRow: FC<ISortRow> = ({ handleSelect }) => {
  return (
    <Select onValueChange={handleSelect}>
      <SelectTrigger className='w-32 rounded-2xl border-none focus:ring-0 focus:ring-offset-0 bg-darkGray text-white focus:bg-darkGray'>
        <SelectValue placeholder='All' />
      </SelectTrigger>
      <SelectContent className='bg-darkGray rounded-2xl'>
        <SelectGroup>
          {SORTING_ITEMS.map((item: { label: string; value: string }) => (
            <SelectItem
              className='rounded-lg px-2 hover:text-darkGray text-white hover:!font-medium'
              key={item.value}
              value={item.value}
            >
              {item.label}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};

export default SortRow;
