import React, { Suspense, useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Loader } from 'lucide-react';

import circle from '@/assets/svgs/circle.svg';
import stars from '@/assets/svgs/stars.svg';

import Footer from 'src/components/Footer';
import Header from 'src/components/Header';
import { TooltipProvider } from 'src/components/ui/tooltip';
import { ROUTES } from 'src/constants/common';
import { COMMON_ERROR } from 'src/constants/feedback';
import useAuthContext from 'src/hooks/useAuth';
import useDashboardContext from 'src/hooks/useDashboardData';
import { UserType } from 'src/providers/Auth/types';
import { getPersonalInfo } from 'src/services/profile';
import { getAuthToken, setUserToLS } from 'src/utils/common';

import ForgotPassword from '../Auth/ForgotPassword';
import Login from '../Auth/Login';
import OTP from '../Auth/OTP';
import SetNewPassword from '../Auth/SetNewPassword';
import SignUp from '../Auth/SignUp';
import Contact from '../Contact';
import Dashboard from '../Dashboard';
import FAQ from '../FAQ';
import OptionsOptimizer from '../OptionsOptimizer';
import OptionsProfitCalculator from '../OptionsProfitCalculator';
import Profile from '../Profile';
import ProfileCompletion from '../ProfileCompletion';
import WriteAReview from '../Review/writeAReview';
import Subscription from '../Subscription';
import Tutorials from '../Tutorials';

import 'src/assets/styles/App.css';

const App = () => {
  const { setUser, setAuth } = useAuthContext();
  const { getCardData } = useDashboardContext();
  const token = getAuthToken();
  const { pathname } = useLocation();

  const getUserData = async () => {
    try {
      const response = await getPersonalInfo();
      if (response?.status) {
        if (token) {
          let newUserData;
          if (
            response?.data?.isAgreement &&
            response?.data?.isProfileCompleted &&
            response?.data?.subscription
          ) {
            newUserData = { ...response.data };
          } else if (!response?.data?.isProfileCompleted) {
            newUserData = { ...response.data };
            if ('isProfileCompleted' in newUserData) {
              delete (newUserData as UserType).isProfileCompleted;
            }
            if ('isAgreement' in newUserData) {
              delete (newUserData as UserType).isAgreement;
            }
            if ('subscription' in newUserData) {
              delete (newUserData as UserType).subscription;
            }
          } else if (!response?.data?.isAgreement) {
            newUserData = { ...response.data };
            if ('isAgreement' in newUserData) {
              delete (newUserData as UserType).isAgreement;
            }
            if ('subscription' in newUserData) {
              delete (newUserData as UserType).subscription;
            }
          } else if (!response?.data?.subscription) {
            newUserData = { ...response.data };
            if ('subscription' in newUserData) {
              delete (newUserData as UserType).subscription;
            }
          }
          setUserToLS(newUserData as UserType);
          setAuth({
            token: String(token),
            user: newUserData as UserType,
          });
        }
      }
    } catch (error) {
      toast.error(COMMON_ERROR);
    }
  };
  useEffect(() => {
    setUser();
    getCardData();
    getUserData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Suspense fallback={<Loader />}>
      <TooltipProvider>
        <div className='bg-blackGray py-0.5 min-h-screen flex flex-col justify-between'>
          <img
            src={stars}
            className='absolute left-0 z-0 opacity-40 max-h-screen'
            width={'100%'}
          />
          <img src={circle} className='absolute right-[88px] top-[100px] z-0' />
          <img
            src={circle}
            className='absolute left-0 top-[288px] z-0'
            width={200}
          />
          <div className='absolute bottom-0 opacity-30 -left-40 top-[-10%] h-[50vw] w-[50vw] rounded-full bg-deep-sea-gradient-radial' />
          <div className='absolute right-0 opacity-15 h-[50vw] w-[50vw] rounded-full bg-teal-green-gradient-radial' />
          <div className='z-10'>
            <Header />
            <div className='z-10'>
              <Routes>
                <Route path={ROUTES.HOME}>
                  <Route
                    index
                    element={<Navigate to={ROUTES.LOGIN} replace />}
                  />
                  <Route path={ROUTES.LOGIN} element={<Login />} />
                  <Route path={ROUTES.SIGNUP} element={<SignUp />} />
                  <Route path={ROUTES.OTP} element={<OTP />} />
                  <Route
                    path={ROUTES.FORGOT_PASSWORD}
                    element={<ForgotPassword />}
                  />
                  <Route
                    path={ROUTES.SET_NEW_PASSWORD}
                    element={<SetNewPassword />}
                  />
                  <Route
                    path={`${ROUTES.PROFILE_COMPLETION}/:step?`}
                    element={<ProfileCompletion />}
                  />
                  <Route
                    path={ROUTES.WRITE_A_REVIEW}
                    element={<WriteAReview />}
                  />
                  <Route path={ROUTES.CONTACT} element={<Contact />} />
                  <Route path={ROUTES.DASHBOARD} element={<Dashboard />} />
                  <Route path={ROUTES.PROFILE} element={<Profile />} />
                  <Route
                    path={ROUTES.SUBSCRIPTION}
                    element={<Subscription />}
                  />
                  <Route path={ROUTES.FAQ} element={<FAQ />} />
                  <Route path={ROUTES.TUTORIALS} element={<Tutorials />} />
                  <Route
                    path={ROUTES.OPTIONS_PROFIT_CALCULATOR}
                    element={<OptionsProfitCalculator />}
                  />
                  <Route
                    path={ROUTES.OPTIONS_OPTIMIZER}
                    element={<OptionsOptimizer />}
                  />
                </Route>
              </Routes>
            </div>
          </div>
          <Footer />
        </div>
        <Toaster />
      </TooltipProvider>
    </Suspense>
  );
};

export default App;
