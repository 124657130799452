/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo, useState } from 'react';
import { FaHistory } from 'react-icons/fa';
import { TbPin, TbPinFilled } from 'react-icons/tb';
import moment from 'moment';

import { ColumnDef } from '@tanstack/react-table';

import {
  PinnedAndUnPinnedApiResponseType,
  SendMessage,
  TradeStockAPIResponseType,
} from 'src/@types/dashboardTypes';
import { TABLE_HEADING_TABS } from 'src/constants/dashboard';
import useDashboardContext from 'src/hooks/useDashboardData';
import { pinStock, tradeStock, unpinStock } from 'src/services/Dashboard';

import { Checkbox, History, StockName } from './CellComponents';
import { IActualStocks, IStockRecord } from './makeData';

interface LiveFlowProps {
  sendJsonMessage: (message: SendMessage) => void;
  selectedTableTab: string;
  sortValue: string;
  lastJsonMessage: any;
}

const LiveFlow = ({
  sendJsonMessage,
  selectedTableTab,
  sortValue,
  lastJsonMessage,
}: LiveFlowProps) => {
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const { screener } = useDashboardContext();
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (lastJsonMessage !== null) {
      setLimit(lastJsonMessage?.limit);
      setCurrentPage(lastJsonMessage?.page);
    }
  }, [lastJsonMessage]);

  const handleTradeStock = async (id: number, quantity: number) => {
    setIsLoading(true);
    const res = (await tradeStock(id, quantity)) as TradeStockAPIResponseType;
    if (res) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
      if (selectedTableTab === TABLE_HEADING_TABS.LIVE_FLOW.key) {
        sendJsonMessage({
          action: 'request_paginated_data',
          userId: res?.data?.user_id,
          sort: sortValue,
          limit: Number(limit),
          page: Number(currentPage),
          filters: screener,
        });
      }
    }
  };

  const toggleCheckbox = async (row: IActualStocks) => {
    await handleTradeStock(row.sec_contract_id, row.value);
    setSelectedRows((prevSelectedRows) => {
      if (!prevSelectedRows.includes(row.sec_contract_id)) {
        return [...prevSelectedRows, row.sec_contract_id];
      }
      return prevSelectedRows;
    });
  };

  const columnsData: ColumnDef<IActualStocks>[] = useMemo<
    ColumnDef<IActualStocks>[]
  >(() => {
    const unPinnedStock = async (id: number) => {
      setIsLoading(true);
      const res = (await unpinStock(id)) as PinnedAndUnPinnedApiResponseType;
      if (res) {
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
        if (selectedTableTab === TABLE_HEADING_TABS.HISTORICAL_FLOW.key) {
          sendJsonMessage({
            action: 'requested_historical_data',
            userId: res?.data?.user_id,
            sort: sortValue,
            limit: Number(limit),
            page: Number(currentPage),
            filters: screener,
          });
        } else {
          sendJsonMessage({
            action: 'request_paginated_data',
            userId: res?.data?.user_id,
            sort: sortValue,
            limit: Number(limit),
            page: Number(currentPage),
            filters: screener,
          });
        }
      }
    };

    const pinnedStock = async (id: number) => {
      setIsLoading(true);
      const res = (await pinStock(id)) as PinnedAndUnPinnedApiResponseType;
      if (res) {
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
        if (selectedTableTab === TABLE_HEADING_TABS.HISTORICAL_FLOW.key) {
          sendJsonMessage({
            action: 'requested_historical_data',
            userId: res?.data?.user_id,
            sort: sortValue,
            limit: Number(limit),
            page: Number(currentPage),
            filters: screener,
          });
        } else {
          sendJsonMessage({
            action: 'request_paginated_data',
            userId: res?.data?.user_id,
            sort: sortValue,
            limit: Number(limit),
            page: Number(currentPage),
            filters: screener,
          });
        }
      }
    };

    const toPercentage = (num: number) => {
      return num ? `${(num * 100).toFixed(2)} %` : '-';
    };

    return [
      {
        id: 'is_pinned',
        header: '',
        cell: ({ row }) => {
          return row.original.is_pinned ? (
            <button
              disabled={isLoading}
              onClick={() => unPinnedStock(+row?.original?.user_pin_id)}
            >
              <TbPinFilled
                className='ml-2 text-goldenYellow -rotate-90'
                size={18}
              />
            </button>
          ) : (
            <button
              disabled={isLoading}
              onClick={() => pinnedStock(row.original.sec_contract_id)}
            >
              <TbPin className='ml-2 text-goldenYellow -rotate-90' size={18} />
            </button>
          );
        },
        size: 10,
      },
      {
        id: 'select',
        header: '',
        cell: ({ row }) => {
          if (row.original.value) {
            return (
              <Checkbox
                {...{
                  checked: selectedRows.includes(row.original.sec_contract_id),
                  disabled: selectedRows.includes(row.original.sec_contract_id),
                  setChecked: () => toggleCheckbox(row.original),
                }}
                disabled={
                  isLoading ||
                  selectedRows.includes(row.original.sec_contract_id)
                }
                contract={row.original.value}
              />
            );
          }
          return null;
        },
        size: 10,
      },
      {
        accessorKey: 'symbol',
        header: 'Stock',
        cell: ({ row, getValue }) => (
          <StockName
            rowValues={row?.original as unknown as IStockRecord}
            getValue={getValue}
          />
        ),
      },
      {
        accessorKey: 'expiration_date',
        header: 'Expiration',
        cell: ({ row }) => (
          <div className='flex items-center'>
            {row.original.expiration_date ?? '-'}
          </div>
        ),
      },
      {
        accessorKey: 'strike_price',
        header: 'Strike',
        cell: ({ row }) => (
          <div className='flex items-center'>
            {row.original.strike_price ?? '-'}
          </div>
        ),
      },
      {
        accessorKey: 'premium',
        header: 'Premium',
        cell: ({ row }) => (
          <div className='flex items-center'>
            {row?.original?.premium?.toFixed(3) ?? '-'}
          </div>
        ),
      },
      {
        accessorKey: 'stock_breakeven',
        header: 'Breakeven',
        cell: ({ row }) => (
          <div className='flex items-center'>
            {row.original.stock_breakeven.toFixed(3) ?? '-'}
          </div>
        ),
      },
      {
        accessorKey: 'below_strike',
        header: 'Belowstrike',
        size: 100,
        cell: ({ row }) => (
          <div className='flex items-center'>
            {row.original.below_strike ?? '-'}
          </div>
        ),
      },
      {
        accessorKey: 'pnl',
        header: 'PnL',
        cell: ({ row }) => (
          <div className='flex items-center'>
            {row.original.pnl.toFixed(3) ?? '-'}
          </div>
        ),
      },
      {
        accessorKey: 'week',
        header: 'Week',
        cell: ({ row }) => (
          <div className='flex items-center w-[130px] justify-center'>
            {row.original.week ? row.original.week : '-'}
          </div>
        ),
      },
      {
        accessorKey: 'days_to_expiration',
        header: 'Days Remaining',
        size: 140,
        cell: ({ row }) => (
          <div className='flex items-center'>
            {row.original.days_to_expiration ?? '-'}
          </div>
        ),
      },
      {
        accessorKey:
          screener?.filter.riskYield?.value === 'yieldByContract'
            ? 'yield_data'
            : 'yield_by_day',
        header: 'Yield',
        cell: ({ row }) => (
          <div className='flex items-center'>
            {screener?.filter.riskYield?.value === 'yieldByContract'
              ? row.original.yield_data
              : row.original.yield_data ?? '-'}
          </div>
        ),
      },
      {
        accessorKey: 'updated_at',
        header: 'Updated',
        cell: ({ row }) => (
          <div className='flex items-center'>
            {row.original.updated_at
              ? moment(row.original.updated_at).format('YYYY-MM-DD')
              : '-'}
          </div>
        ),
      },
      {
        accessorKey: 'firstpubprice',
        header: 'First Published Premium',
        size: 120,
        cell: ({ row }) => (
          <div className='flex items-center'>
            {row.original?.firstPubPrice ??
              row?.original?.firstpubprice?.toFixed(3) ??
              '-'}
          </div>
        ),
      },
      {
        accessorKey: 'firstpubat',
        header: 'First Published At',
        size: 120,
        cell: ({ row }) => (
          <div className='flex items-center'>
            {moment
              .utc(row.original?.firstPubAt)
              .local()
              .format('YYYY-MM-DD hh:mm A') ??
              moment
                .utc(row.original?.firstpubat)
                .local()
                .format('YYYY-MM-DD hh:mm A') ??
              '-'}
          </div>
        ),
      },
      {
        accessorKey: 'chance_of_profit_short',
        header: 'Profit Chance (Short)%',
        size: 200,
        cell: ({ row }) => (
          <div className='flex items-center'>
            {row?.original?.chance_of_profit_short
              ? toPercentage(row?.original?.chance_of_profit_short)
              : '-'}
          </div>
        ),
      },
      {
        accessorKey:
          screener?.filter?.riskYield?.value === 'yieldByContract'
            ? 'return_probability_contract'
            : 'return_probability',
        header: 'Return Probability',
        size: 200,
        cell: ({ row }) => (
          <div className='flex items-center'>
            {screener?.filter?.riskYield?.value === 'yieldByContract'
              ? row?.original?.return_probability?.toFixed(3)
              : row?.original?.return_probability
              ? row?.original?.return_probability?.toFixed(3)
              : '-'}
          </div>
        ),
      },
      {
        accessorKey: 'history',
        header: 'History',
        cell: ({ row }) =>
          row.original.sec_trades.length > 0 ? (
            <History data={row.original.sec_trades ?? []} />
          ) : (
            <span className='flex justify-center'>
              <FaHistory color='grey' />
            </span>
          ),
      },
    ];
  }, [
    selectedRows,
    selectedTableTab,
    lastJsonMessage,
    sortValue,
    screener,
    limit,
    currentPage,
    isLoading,
    setIsLoading,
  ]);

  return { columnsData };
};

export default LiveFlow;
