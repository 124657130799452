import React, { useEffect, useState } from 'react';
import { RiVipCrown2Fill } from 'react-icons/ri';

import BasicCardIcon from 'src/assets/svgs/BasicCardIcon.svg';
import PricingCard from 'src/components/PricingCard';
import { Typography } from 'src/components/Typography';
import { Tabs, TabsList, TabsTrigger } from 'src/components/ui/tabs';
import { USER_PLAN_TYPE } from 'src/constants/common';
import {
  PROFILE_COMPLETION,
  TRIAL_SECTION,
} from 'src/constants/profileCompletion';
import useAuthContext from 'src/hooks/useAuth';
import { getSubscriptionData } from 'src/services/auth';
import { getCurrentSubscription } from 'src/services/profile';
import { CurrentSubscription, dataType } from 'src/services/types';

const StepThree = () => {
  const [activeCard, setActiveCard] = useState(PROFILE_COMPLETION.BASIC);
  const [data, setData] = useState<dataType[]>([]);
  const [subscriptionInfo, setSubscriptionInfo] =
    useState<CurrentSubscription | null>(null);
  const [activeTab, setActiveTab] = useState('monthly');
  const { auth } = useAuthContext();
  const { user } = auth ?? {};
  const handleActiveCard = (activeTab: string) => {
    setActiveCard(activeTab);
  };

  const getCardData = async () => {
    const response = await getCurrentSubscription();
    setSubscriptionInfo(response?.data as CurrentSubscription);
  };

  const getProfile = async () => {
    const response = await getSubscriptionData();
    if (response) {
      setData(response.data as unknown as dataType[]);
    }
  };
  useEffect(() => {
    getProfile();
    getCardData();
  }, []);
  return (
    <div>
      <div className='mx-auto flex w-11/12'>
        <div className='mx-auto my-4 rounded-full border border-white bg-darkGray px-0.5 py-1.5'>
          <Tabs
            defaultValue={activeTab}
            className='md:full z-10 min-w-fit rounded-full'
            onValueChange={(value) => setActiveTab(value)}
          >
            <TabsList className='h-12 rounded-full bg-darkGray'>
              <TabsTrigger
                className='rounded-full px-6 py-3 text-lg font-normal text-lightOrange data-[state=active]:bg-gradient-to-r data-[state=active]:from-lightBlue data-[state=active]:to-lightPurple data-[state=active]:text-white md:px-10'
                value='monthly'
                aria-label='trial monthly'
                aria-expanded={`${activeTab === 'monthly'}`}
              >
                {TRIAL_SECTION.monthly}
              </TabsTrigger>
              <TabsTrigger
                className='rounded-full px-6 py-3 text-lg font-normal text-lightOrange data-[state=active]:bg-gradient-to-r data-[state=active]:from-lightBlue data-[state=active]:to-lightPurple data-[state=active]:text-white md:px-10'
                value='yearly'
                aria-label='trial annual'
                aria-expanded={`${activeTab === 'yearly'}`}
              >
                {TRIAL_SECTION.annual}
              </TabsTrigger>
            </TabsList>
          </Tabs>
        </div>
      </div>
      <div className='pt-5 w-full sm:w-2/3 px-4 mx-auto'>
        <div className='space-y-8 md:grid md:grid-cols-2 sm:gap-6 lg:gap-16 md:space-y-0'>
          <PricingCard
            cardImage={BasicCardIcon}
            activeCard={activeCard}
            handleActiveCard={handleActiveCard}
            cardTitle={data[1]?.name || ''}
            price={
              (activeTab === 'monthly'
                ? data[1]?.price[1]?.unit_amount_decimal
                : data[1]?.price[0]?.unit_amount_decimal) || 0
            }
            cardList={PROFILE_COMPLETION.BASIC_CARD}
            cardDesc={''}
            activeTab={activeTab}
            priceValue={
              activeTab === 'monthly'
                ? data[1]?.price[1]?.id
                : data[1]?.price[0]?.id
            }
            isLoading={data.length === 0}
            totalSubscription={user?.totalSubscriptionsPurchased}
            isPay={subscriptionInfo?.subscriptionType === USER_PLAN_TYPE.BASIC}
          />
          <PricingCard
            cardImage={<RiVipCrown2Fill size={20} />}
            activeTab={activeTab}
            activeCard={activeCard}
            cardTitle={data[0]?.name || ''}
            handleActiveCard={handleActiveCard}
            price={
              (activeTab === 'yearly'
                ? data[0]?.price[0]?.unit_amount_decimal
                : data[0]?.price[1]?.unit_amount_decimal) || 0
            }
            cardList={PROFILE_COMPLETION.PREMIUM_CARD}
            cardDesc={''}
            priceValue={
              activeTab === 'yearly'
                ? data[0]?.price[0]?.id
                : data[0]?.price[1]?.id
            }
            isLoading={data.length === 0}
            totalSubscription={user?.totalSubscriptionsPurchased}
            isPay={
              subscriptionInfo?.subscriptionType === USER_PLAN_TYPE.PREMIUM
            }
          />
        </div>
        <Typography className='text-base mb-2 mt-5 text-center w-full'>
          {PROFILE_COMPLETION.STEP_THREE_TEXT}
        </Typography>
      </div>
    </div>
  );
};

export default StepThree;
