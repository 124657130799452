export const PROFILE = {
  PAYMENT_HISTORY: ' Payment History',
  DOWNLOAD: 'Download',
  NO_DATA_FOUND: ' No record found',
  TABLE_HEADING: [
    { title: 'Payment Method', key: 'paymentMethod' },
    { title: 'Amount', key: 'amount' },
    { title: 'Date', key: 'date' },
    { title: 'Status', key: 'status' },
    { title: 'Invoice', key: 'invoice' },
  ],
  EDIT: 'Edit',
  UPDATE: 'Update',
  CANCEL: 'Cancel',
  CANCEL_PAYMENT: 'Cancel Subscription',
  UPGRADE_PLAN: 'Upgrade Plan',
  SELECT_PLAN: 'Select Plan',
  DOWNGRADE_PLAN: 'Downgrade Plan',
  profile: 'Profile',
  profilePicture: 'Profile Picture',
  personalInformation: 'Personal Information',
  FIELDS: {
    FIRST_NAME: 'First Name',
    MIDDLE_NAME: 'Middle Name',
    LAST_NAME: 'Last Name',
    EMAIL: 'Email Address',
    ROLE: 'Role',
    PHONE: 'Phone',
    ADDRESS: 'Address',
    CITY: 'City',
    STATE: 'State',
    ZIP_CODE: 'Zip Code',
    COUNTRY: 'Country',
    NAME_OF_EMPLOYER: 'Name of Employer',
    OCCUPATION_OR_JOB: 'Occupation or Job Title',
  },
  payment: 'Payment',
  country: 'USD',
  perMonth: 'Per Month',
  perYear: 'Per Year',
  paymentTextOne:
    'Total amount varies according to the modules selected and the number of users.',
  nextPaymentDate: 'Next Payment Date:',
  subscriptionType: 'Subscription Type',
  paymentMethod: 'Payment Method:',
  linkedCard: 'Linked Card',
  linkedCardText:
    'Schedule payment will be automatically deducted from this card.',
  defaultPaymentMethod: 'Default Payment Method',
  noPlan: 'Uh-ho... You have not selected any plan yet.',
  selectPlan: 'Select your plan now!',
};
export enum PAYMENT_STATUS {
  PAID = 'paid',
  DRAFT = 'draft',
  OPEN = 'open',
}
export enum CARD_TYPE {
  VISA = 'visa',
  MASTER = 'master',
}
export const CANCEL_PLAN_MODAL = {
  title: 'Cancel Plan',
  description: [
    {
      text: 'Are you sure you want to cancel this plan ?',
      type: 'heading',
    },
  ],
  btnText: 'Confirm',
};
