import React, { useEffect, useState } from 'react';
import { MdPayment } from 'react-icons/md';
import { RiVipCrown2Fill } from 'react-icons/ri';
import { Link, useNavigate } from 'react-router-dom';

import BasicCardIcon from 'src/assets/svgs/BasicCardIcon.svg';
import Upload from 'src/assets/svgs/upload.svg';
import Modal from 'src/components/Modal';
import { Typography } from 'src/components/Typography';
import { Button } from 'src/components/ui/button';
import { ROUTES, USER_PLAN_TYPE } from 'src/constants/common';
import { CANCEL_PLAN_MODAL, PROFILE } from 'src/constants/profile';
import useAuthContext from 'src/hooks/useAuth';
import { UserType } from 'src/providers/Auth/types';
import {
  cancelSubscription,
  getCurrentSubscription,
} from 'src/services/profile';
import { CurrentSubscription } from 'src/services/types';
import { getFormattedDate } from 'src/utils/common';

const Payment = () => {
  const [subscriptionInfo, setSubscriptionInfo] =
    useState<CurrentSubscription | null>(null);
  const [confirmCancel, setConfirmCancel] = useState(false);
  const { auth, setAuth } = useAuthContext();
  const { user } = auth ?? {};
  const navigate = useNavigate();

  useEffect(() => {
    getCardData();
  }, []);

  const getCardData = async () => {
    const response = await getCurrentSubscription();
    setSubscriptionInfo(response?.data as CurrentSubscription);
  };

  const cancelHandler = async () => {
    const response = await cancelSubscription(user?.subscription as string);
    if (response) {
      getCardData();
      setAuth({
        token: String(auth?.token),
        user: {
          ...(user as UserType),
          isSubscriptionCancel: true,
        },
      });
      if (
        user?.totalSubscriptionsPurchased &&
        user?.totalSubscriptionsPurchased >= 2
      ) {
        navigate(`${ROUTES.PROFILE_COMPLETION}/?step=3`);
        setAuth({
          token: String(auth?.token),
          user: {
            ...(user as UserType),
            subscription: '',
            planId: '',
            totalSubscriptionsPurchased: user?.totalSubscriptionsPurchased + 1,
          },
        });
      }
    }
  };

  const renderSubscriptionType = () => {
    if (subscriptionInfo?.subscriptionType === USER_PLAN_TYPE.BASIC) {
      return <img src={BasicCardIcon} alt='basic' />;
    }
    return <RiVipCrown2Fill className='size-[16px]' />;
  };
  const handleOpenConfirmCancelDialog = () => {
    setConfirmCancel(true);
  };

  return (
    <div className='bg-nightSky w-11/12 mx-auto p-3 md:p-6 m-4 rounded-2xl'>
      <Modal
        setOpen={setConfirmCancel}
        open={confirmCancel}
        title={CANCEL_PLAN_MODAL.title}
        description={CANCEL_PLAN_MODAL.description}
        handleBtn={cancelHandler}
        btnText={CANCEL_PLAN_MODAL.btnText}
      />
      <div className='flex justify-between'>
        <Typography variant='subheading' className='text-lg md:text-2xl'>
          {PROFILE.payment}
        </Typography>
        {subscriptionInfo && !user?.isSubscriptionCancel ? (
          <div className='flex gap-2'>
            <Link to={ROUTES.SUBSCRIPTION}>
              <Button
                variant={'primary'}
                className='rounded-full p-3 sm:p-5 font-medium text-sm md:text-base bg-nightSky'
              >
                <img src={Upload} className='mr-2 sm:mr-4' />
                {subscriptionInfo?.subscriptionType === USER_PLAN_TYPE.BASIC
                  ? PROFILE.UPGRADE_PLAN
                  : PROFILE.DOWNGRADE_PLAN}
              </Button>
            </Link>
            <Button
              variant={'primary'}
              className='rounded-full p-3 sm:p-5 font-medium text-sm md:text-base'
              onClick={handleOpenConfirmCancelDialog}
            >
              <MdPayment className='mr-2 sm:mr-4' size={19} />
              {PROFILE.CANCEL_PAYMENT}
            </Button>
          </div>
        ) : null}
      </div>
      {subscriptionInfo && !user?.isSubscriptionCancel ? (
        <>
          <Typography
            variant='subheading'
            className='text-base md:text-lg font-medium mb-5 md:mb-10 mt-2 md:mt-4'
          >
            {`${PROFILE.country} $${subscriptionInfo?.amount} ${
              ['298.08', '571.32'].includes(subscriptionInfo?.amount)
                ? `${PROFILE.perYear}`
                : `${PROFILE.perMonth}`
            }`}
          </Typography>
          <div className='md:w-1/2 text-white'>
            <div className='flex'>
              <div className='w-7/12 pr-8'>
                <Typography className='text-sm'>
                  {PROFILE.paymentTextOne}
                </Typography>
              </div>
              <div className='w-5/12'>
                <Typography className='text-sm md:text-base'>
                  ${subscriptionInfo?.amount}
                </Typography>
              </div>
            </div>
            <div className='flex mt-5 mb-7'>
              <div className='w-7/12'>
                <Typography className='text-sm'>
                  {PROFILE.nextPaymentDate}
                </Typography>
              </div>
              <div className='w-5/12'>
                <Typography className='text-sm md:text-base'>
                  {getFormattedDate(
                    subscriptionInfo?.nextPaymentDate as string,
                  )}
                </Typography>
              </div>
            </div>
            <div className='flex mt-5 mb-7'>
              <div className='w-7/12'>
                <Typography className='text-sm'>
                  {PROFILE.subscriptionType}
                </Typography>
              </div>
              <div className='w-5/12'>
                <div className='flex items-center'>
                  {renderSubscriptionType()}
                  <Typography className='text-sm md:text-base'>
                    {subscriptionInfo?.subscriptionType}
                  </Typography>
                </div>
              </div>
            </div>
            <div className='flex'>
              <div className='w-7/12'>
                <Typography className='text-sm'>
                  {PROFILE.paymentMethod}
                </Typography>
              </div>
              <div className='w-5/12'>
                <Typography className='text-sm capitalize md:text-base'>
                  {subscriptionInfo?.paymentMethod}
                </Typography>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className='py-4 md:py-12 flex flex-col justify-center text-center'>
          <Typography>{PROFILE.noPlan}</Typography>
          <Typography>{PROFILE.selectPlan}</Typography>
          <div className='m-4'>
            <Link to={ROUTES.SUBSCRIPTION}>
              <Button variant='primary'>{PROFILE.SELECT_PLAN}</Button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default Payment;
