import React, { useEffect, useState } from 'react';
import { LuChevronDown } from 'react-icons/lu';
import Select, { components, MenuListProps } from 'react-select';

import { OptionType } from 'src/@types/dashboardTypes';
import {
  DASHBOARD_TEXT,
  FiltersKeyName,
  LOADING,
} from 'src/constants/dashboard';
import useDashboardContext from 'src/hooks/useDashboardData';
import { cn } from 'src/lib/utils';
import { Option } from 'src/pages/Dashboard/components/MultiSelectInput';
import { getAllLatestSymbols } from 'src/services/Dashboard';
import { Tickers } from 'src/services/types';

import LabelText from '../LabelText';
import { CustomStyles, customStyles } from '../MultiSelectInput/customStyle';

export interface ITickerSelectInput {
  value?: OptionType;
  onChange: (
    name: string,
    value: string | number | OptionType | Date | OptionType[],
  ) => void;
  infoText: string;
}

interface CustomMenuListProps extends MenuListProps {
  isLoading: boolean;
}

const MenuList = (props: CustomMenuListProps) => {
  return (
    <components.MenuList {...props}>
      {props.children}
      {props.isLoading ? (
        <div className='text-center w-full py-2 opacity-70'>{LOADING}</div>
      ) : null}
    </components.MenuList>
  );
};

const TickerSelectInput: React.FC<ITickerSelectInput> = ({
  onChange,
  value,
  infoText = '',
}) => {
  const [state, setState] = useState<{
    optionSelected: Array<OptionType> | null | any;
  }>({
    optionSelected: null,
  });
  const { keyInScreener } = useDashboardContext();
  const keyName = FiltersKeyName.TICKER_FIELD;
  const [tickers, setTickers] = useState<Tickers[]>([]);
  const [query, setQuery] = useState('');
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);

  const DEBOUNCE_DELAY = 500; // Delay in ms

  // fetch data
  const fetchData = async () => {
    await getAllLatestSymbols({
      page,
      query,
    })
      .then((res) => {
        setTickers((prevTickers) =>
          page === 1
            ? res?.tickers || []
            : [...prevTickers, ...(res?.tickers || [])],
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    const handler = setTimeout(() => {
      fetchData();
    }, DEBOUNCE_DELAY);
    return () => {
      clearTimeout(handler);
    };
  }, [page, query]);

  useEffect(() => {
    setState({ optionSelected: value ? value : null });
  }, [value]);

  const handleChange = (selected: Array<OptionType>) => {
    setState({ optionSelected: selected });
    onChange(keyName, selected);
  };

  const handleInputChange = (inputValue: string) => {
    setQuery(inputValue ? inputValue : '');
  };

  const loadMore = () => setPage((prev) => prev + 1);

  return (
    <div>
      <LabelText infoText={infoText} label={DASHBOARD_TEXT.TICKER_FIELD} />
      <div className='relative'>
        <Select
          defaultValue={null}
          onInputChange={handleInputChange}
          getOptionLabel={(option: OptionType) => option?.name || ''}
          getOptionValue={(option: OptionType) => option.value}
          isMulti
          placeholder='Select'
          styles={customStyles as CustomStyles | any}
          name={keyName}
          options={tickers}
          onChange={handleChange}
          value={state.optionSelected}
          components={{
            Option,
            MenuList: (props) => <MenuList {...props} isLoading={loading} />,
          }}
          className={cn('basic-multi-select w-full mt-4', {
            'ring-[2px] ring-lightBlue rounded-xl':
              state.optionSelected?.length > 0 ? keyInScreener(keyName) : false,
          })}
          classNamePrefix='select'
          onMenuScrollToBottom={loadMore}
        />
        <LuChevronDown className='h-4 w-4 absolute top-3 right-4' />
      </div>
    </div>
  );
};

export default TickerSelectInput;
