import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { DropDownListType, OptionType } from 'src/@types/dashboardTypes';
import Divider from 'src/components/Divider';
import SelectDropdown from 'src/components/SelectDropdown';
import { Typography } from 'src/components/Typography';
import {
  ALL_FIELDS_REQUIRED,
  DASHBOARD_INFO_TEXT,
  DASHBOARD_TEXT,
  FiltersKeyName,
  NO_SCREENERS,
  SCREENER_ITEMS,
} from 'src/constants/dashboard';
import useDashboardContext from 'src/hooks/useDashboardData';

import AssetPrice from '../assetPrice';
import CalendarSelect from '../CalendarSelect';
import CallToPutRatio from '../CallPutRatio';
import DaysRemaining from '../DaysRemaining';
import FilterTextInput from '../FilterTextInput';
import MultiSelectInput from '../MultiSelectInput';
import PriceToEarningsRatio from '../PriceToEarningsRatio';
import ProfitProbabilityLongSlider from '../ProfitProbabilityLong';
import RiskYieldComponent from '../RiskYieldComponent';
import ScreenerTopbar from '../ScreenerTopbar';
import TickerSelectInput from '../TickerField';
interface IScreener {
  handleOpen: () => void;
}

const Screener: React.FC<IScreener> = ({ handleOpen }) => {
  const [selectedTheme, setSelectedTheme] = useState(SCREENER_ITEMS[0]?.value);
  const [screenerNameList, setScreenerNameList] =
    useState<{ value: string | number; name: string }[]>();
  const {
    fetchFiltersData,
    countryList,
    sectorList,
    industoryList,
    marketCapList,
    screenerList,
    screener,
    handleFilterChange,
    getCardData,
    fetchAllFiltersDataByUser,
    areCalculatorFieldsValid,
    isPremiumOrAdmin,
  } = useDashboardContext();

  const [openSave, setOpenSave] = useState(false);

  const handleSelectedValue = (item: string) => {
    setSelectedTheme(item);
  };
  const onChangeValues = (
    name: string,
    value: string | number | DropDownListType | Date,
  ) => {
    handleFilterChange(name, value);
  };
  const handleOpenSaveFilterDialog = () => {
    if (screener) {
      if (!areCalculatorFieldsValid(screener.calculator)) {
        toast.error(ALL_FIELDS_REQUIRED);
        return;
      }
    }
    setOpenSave(true);
  };

  useEffect(() => {
    getCardData();
    fetchFiltersData();
    fetchAllFiltersDataByUser();
  }, []);

  useEffect(() => {
    if (screenerList?.length) {
      const screenerNameList = screenerList?.map((item) => ({
        value: item._id,
        name: item.name,
      }));
      setScreenerNameList(screenerNameList);
    } else {
      setScreenerNameList([NO_SCREENERS]);
    }
  }, [screenerList]);

  return (
    <div className='pb-2'>
      <ScreenerTopbar
        handleOpenSaveFilterDialog={handleOpenSaveFilterDialog}
        openSave={openSave}
        setOpenSave={setOpenSave}
      />
      <Divider className='border-b-2' />
      <div className='pt-5 pb-1 grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-x-3 gap-y-4 lg:gap-x-7'>
        <SelectDropdown
          items={screenerNameList}
          inputClassName='w-full'
          handleSelectedValue={handleSelectedValue}
          label={DASHBOARD_TEXT.SCREENER}
          isScreener={true}
        />
        <DaysRemaining
          label={DASHBOARD_TEXT.DAYS_REMAINEG}
          keyName={FiltersKeyName.DAYS_REMAINING}
          onChange={handleFilterChange}
          value={screener?.filter?.daysRemaining}
          infoText={DASHBOARD_INFO_TEXT.DAYS_REMAINEG}
        />
        <RiskYieldComponent
          label={DASHBOARD_TEXT.RISKYIELD}
          keyName={FiltersKeyName.RISKYIELD}
          optionOne={{ title: DASHBOARD_TEXT.YIELD_BY_DAY, value: '' }}
          optionTwo={{ title: DASHBOARD_TEXT.YIELD_BY_CONTRACT, value: '' }}
          onChange={handleFilterChange}
          screener={screener}
          infoText={DASHBOARD_INFO_TEXT.RISKYIELD}
        />
        <TickerSelectInput
          onChange={handleFilterChange}
          value={screener?.filter?.tickerFiled}
          infoText={DASHBOARD_INFO_TEXT.TICKER_FIELD}
        />
        <AssetPrice
          value={screener?.filter?.assetPrice}
          label={DASHBOARD_TEXT.ASSET_PRICE}
          keyName={FiltersKeyName.ASSET_PRICE}
          onChange={handleFilterChange}
          infoText={DASHBOARD_INFO_TEXT.ASSET_PRICE}
        />
        <CalendarSelect
          placeholder={DASHBOARD_TEXT.SELECT}
          value={screener?.filter?.expiratonDate}
          onChange={onChangeValues}
          keyName={FiltersKeyName.EXPIRATION_DATE}
          label={DASHBOARD_TEXT.EXPIRATION_DATE}
          handleOpen={handleOpen}
          infoText={DASHBOARD_INFO_TEXT.EXPIRATION_DATE}
        />
        <FilterTextInput
          placeholder={DASHBOARD_TEXT.TYPE_HERE}
          label={DASHBOARD_TEXT.EXCLUDE_INSIDER_SELL_FOR}
          isPremium={!isPremiumOrAdmin}
          isDisabled={
            !isPremiumOrAdmin || !!screener?.filter?.includeInsiderPurchFor
          }
          handleOpen={handleOpen}
          keyName={FiltersKeyName.EXCLUDE_INSIDER_SELL_FOR}
          onChange={onChangeValues}
          value={screener?.filter?.excludeInsiderSellFor}
          type='number'
          minValue={1}
          maxValue={120}
          infoText={DASHBOARD_INFO_TEXT.EXCLUDE_INSIDER_SELL_FOR}
        />
        <FilterTextInput
          placeholder={DASHBOARD_TEXT.TYPE_HERE}
          label={DASHBOARD_TEXT.INCL_INSIDER_PURCH_FOR}
          isPremium={!isPremiumOrAdmin}
          isDisabled={
            !isPremiumOrAdmin || !!screener?.filter?.excludeInsiderSellFor
          }
          handleOpen={handleOpen}
          keyName={FiltersKeyName.INCLUDE_INSIDER_PURCH_FOR}
          onChange={onChangeValues}
          value={screener?.filter?.includeInsiderPurchFor}
          type='number'
          infoText={DASHBOARD_INFO_TEXT.INCL_INSIDER_PURCH_FOR}
          minValue={1}
          maxValue={120}
        />
        <MultiSelectInput
          label={DASHBOARD_TEXT.SECTOR}
          options={sectorList as OptionType[]}
          keyName={FiltersKeyName.SECTOR}
          onChange={handleFilterChange}
          handleOpen={handleOpen}
          value={screener?.filter?.sector}
          isPremium={!isPremiumOrAdmin}
          isDisabled={!isPremiumOrAdmin}
          infoText={DASHBOARD_INFO_TEXT.SECTOR}
        />
        <MultiSelectInput
          label={DASHBOARD_TEXT.INDUSTRY}
          options={industoryList as OptionType[]}
          keyName={FiltersKeyName.INDUSTRY}
          onChange={handleFilterChange}
          handleOpen={handleOpen}
          value={screener?.filter?.industry}
          isPremium={!isPremiumOrAdmin}
          isDisabled={!isPremiumOrAdmin}
          infoText={DASHBOARD_INFO_TEXT.INDUSTRY}
        />
        <MultiSelectInput
          label={DASHBOARD_TEXT.MARKET_CAP}
          options={marketCapList as OptionType[]}
          keyName={FiltersKeyName.MARKET_CAP}
          onChange={handleFilterChange}
          handleOpen={handleOpen}
          value={screener?.filter?.marketCap}
          isPremium={!isPremiumOrAdmin}
          isDisabled={!isPremiumOrAdmin}
          infoText={DASHBOARD_INFO_TEXT.MARKET_CAP}
        />
        <MultiSelectInput
          label={DASHBOARD_TEXT.COUNTRY}
          options={countryList as OptionType[]}
          keyName={FiltersKeyName.COUNTRY}
          onChange={handleFilterChange}
          handleOpen={handleOpen}
          value={screener?.filter?.country}
          isPremium={!isPremiumOrAdmin}
          isDisabled={!isPremiumOrAdmin}
          infoText={DASHBOARD_INFO_TEXT.COUNTRY}
        />
        <PriceToEarningsRatio
          label={DASHBOARD_TEXT.PE}
          isPremium={!isPremiumOrAdmin}
          isDisabled={!isPremiumOrAdmin}
          handleOpen={handleOpen}
          onChange={handleFilterChange}
          keyName={FiltersKeyName.PRICE_TO_EARNINGS_RATIO}
          value={screener?.filter?.priceToEarningsRatio}
          infoText={DASHBOARD_INFO_TEXT.PE}
        />
        <CallToPutRatio
          label={DASHBOARD_TEXT.CALL_PUT_RATIO}
          handleOpen={handleOpen}
          onChange={handleFilterChange}
          value={screener?.filter?.callPutRatio}
          keyName={FiltersKeyName.CALL_PUT_RATIO}
          isPremium={!isPremiumOrAdmin}
          isDisabled={!isPremiumOrAdmin}
          infoText={DASHBOARD_INFO_TEXT.CALL_PUT_RATIO}
        />
        <ProfitProbabilityLongSlider
          label={DASHBOARD_TEXT.PROFILT_PROBABILITY_SHORT}
          keyName={FiltersKeyName.PROFIT_PROBABILITY_SHORT}
          onChange={handleFilterChange}
          value={screener?.filter?.profitProbabilityShort}
          isPremium={!isPremiumOrAdmin}
          isDisabled={!isPremiumOrAdmin}
          infoText={DASHBOARD_INFO_TEXT.PROFILT_PROBABILITY_LONG}
        />
        <Typography className='hidden'>{selectedTheme}</Typography>
      </div>
    </div>
  );
};

export default Screener;
