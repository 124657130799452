import React from 'react';
import {
  TiArrowSortedDown,
  TiArrowSortedUp,
  TiArrowUnsorted,
} from 'react-icons/ti';

import { flexRender, HeaderGroup, Table } from '@tanstack/react-table';

import { YIELD_TEXT } from 'src/constants/common';
import { TABLE_COLUMN_ENUM } from 'src/constants/dashboard';
import { cn } from 'src/lib/utils';

import { IActualStocks } from './makeData';

const TableHead = ({
  table,
  loading = false,
}: {
  loading?: boolean;
  table: Table<IActualStocks>;
}) => {
  const getWidth = (header: any) => {
    if (
      header.column.columnDef.header === TABLE_COLUMN_ENUM.YIELD ||
      header.column.columnDef.header === 'First Published At'
    ) {
      return '155px';
    } else if (header.column.columnDef.header === 'First Published Premium') {
      return '180px';
    }
    return `${header.getSize()}px`;
  };

  return (
    <thead>
      {table
        .getHeaderGroups()
        .map((headerGroup: HeaderGroup<IActualStocks>) => (
          <tr key={headerGroup.id} className='bg-wintersDay rounded-xl m-3'>
            {headerGroup.headers.map((header, index: number, array) => (
              <th
                key={header.id}
                className={cn('px-4 pr-2 md:py-3 font-bold', {
                  'rounded-r-2xl': index === array.length - 1,
                  'rounded-l-2xl': index === 0,
                  'md:!py-1':
                    header.column.columnDef.header === TABLE_COLUMN_ENUM.YIELD,
                  'flex flex-col items-center':
                    header.column.columnDef.header ===
                      TABLE_COLUMN_ENUM.YIELD ||
                    header.column.columnDef.header ===
                      TABLE_COLUMN_ENUM.RETURN_PROBABILITY,
                })}
              >
                {!header.isPlaceholder ? (
                  <>
                    <div
                      className={cn({
                        'cursor-pointer select-none flex items-center justify-center':
                          header.column.getCanSort(),
                        'opacity-70 cursor-not-allowed': loading,
                      })}
                      onClick={
                        loading
                          ? undefined
                          : header.column.getToggleSortingHandler()
                      }
                      style={{ width: getWidth(header) }}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                      <span>
                        {(header.column.columnDef.header &&
                          {
                            asc: <TiArrowSortedUp />,
                            desc: <TiArrowSortedDown />,
                            default: <TiArrowUnsorted />,
                          }[header.column.getIsSorted() as string]) ?? (
                          <TiArrowUnsorted />
                        )}
                      </span>
                    </div>
                    {header.column.columnDef.header ===
                      TABLE_COLUMN_ENUM.YIELD ||
                    header.column.columnDef.header ===
                      TABLE_COLUMN_ENUM.RETURN_PROBABILITY ? (
                      <p className='text-[10px] block'>({YIELD_TEXT})</p>
                    ) : null}
                  </>
                ) : null}
              </th>
            ))}
          </tr>
        ))}
    </thead>
  );
};

export default TableHead;
