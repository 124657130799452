import React from 'react';
import { FaCheck, FaHistory } from 'react-icons/fa';
import { GoDot } from 'react-icons/go';

import { Getter } from '@tanstack/react-table';

import { Typography } from 'src/components/Typography';
import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
} from 'src/components/ui/tooltip';
import { TABLE_HISTORTY_TOOLTIP } from 'src/constants/common';
import { cn } from 'src/lib/utils';

import { IStockRecord } from './makeData';

export const StockName = ({
  rowValues,
  getValue,
}: {
  rowValues: IStockRecord;
  getValue: Getter<string>;
}) => {
  const { delta, gamma, theta, vega, rho } = rowValues ?? {};
  const graphValues = { delta, gamma, theta, vega, rho };
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <div>
          <Typography className='cursor-pointer'>
            {getValue() as string}
          </Typography>
        </div>
      </TooltipTrigger>
      <TooltipContent className='bg-wintersDay'>
        <TooltipArrow className='fill-wintersDay w-3 h-2' />
        <div className='flex flex-col justify-center'>
          <img src={rowValues?.image_url} alt='stock' />
          <Typography className='text-black font-semibold mt-1'>
            {'Greeks:'}
          </Typography>
          <div className='flex mb-1'>
            {Object.keys(graphValues).map((key: string) => (
              <Typography
                key={key}
                className='text-black text-sm font-semibold capitalize'
              >
                {`${key}: ${rowValues[key as keyof IStockRecord]}`}
              </Typography>
            ))}
          </div>
        </div>
      </TooltipContent>
    </Tooltip>
  );
};

export const Checkbox = ({
  className = '',
  checked,
  setChecked,
  contract = 0,
}: {
  checked: boolean;
  setChecked: (value: boolean) => void;
  contract?: number;
} & React.HTMLProps<HTMLDivElement>) => {
  const handleClick = () => {
    setChecked(!checked);
  };
  return (
    <div
      onClick={handleClick}
      className={cn(className, 'cursor-pointer flex items-center')}
    >
      <span className='w-4'>{checked ? <FaCheck size={12} /> : <GoDot />}</span>
      <Typography className='p-0 text-sm m-0'>
        {contract < 10 ? `0${contract}` : contract}
      </Typography>
    </div>
  );
};

export const History = ({
  data,
}: {
  data: {
    date: string;
    id: number;
    quantity: number;
  }[];
}) => {
  const groupedData = data.reduce(
    (acc: { date: string; quantity: number }[], entry) => {
      const existingEntry = acc.find(
        (item: { date: string }) => item.date === entry.date,
      );
      if (existingEntry) {
        existingEntry.quantity += entry.quantity;
      } else {
        acc.push({ date: entry.date, quantity: entry.quantity });
      }
      return acc;
    },
    [],
  );
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <span className='flex justify-center cursor-pointer'>
          <FaHistory />
        </span>
      </TooltipTrigger>
      <TooltipContent className='bg-wintersDay'>
        <TooltipArrow className='fill-wintersDay w-3 h-2' />
        <div className='grid grid-cols-3 gap-3'>
          <Typography className='text-skyCaptain font-medium text-base col-span-2'>
            {TABLE_HISTORTY_TOOLTIP.Date}
          </Typography>
          <Typography className='text-skyCaptain font-medium text-base'>
            {TABLE_HISTORTY_TOOLTIP.Qty}
          </Typography>
        </div>
        {groupedData.length === 0 ? (
          <div className='flex justify-center items-center'>
            <Typography className='text-black text-sm'>
              {TABLE_HISTORTY_TOOLTIP.noData}
            </Typography>
          </div>
        ) : (
          groupedData.map(
            (entry: { date: string; quantity: number }, index) => (
              <div key={index} className='grid grid-cols-3 gap-3 text-center'>
                <Typography className='text-black text-sm col-span-2'>
                  {entry.date}
                </Typography>
                <div className='flex'>
                  <Typography className='text-black text-sm'>
                    {entry.quantity}
                  </Typography>
                  {/* <span className='flex items-center'>
                    {entry.date === new Date().toISOString().slice(0, 10) ? (
                      <FaRegTimesCircle
                        color='red'
                        className='ml-1 cursor-pointer'
                      />
                    ) : null}
                  </span> */}
                </div>
              </div>
            ),
          )
        )}
      </TooltipContent>
    </Tooltip>
  );
};
