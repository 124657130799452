export const COMMON_ERROR = 'Something went wrong!';
export const COMMON_SUCCESS = 'Operation completed successfully!';

export const REVIEW = {
  TITLE: 'Write a Review',
  BUTTON_TEXT: 'Send your review',
  SUB_HEADING: 'Rating',
  CLICK_TO_RATE: 'Click To Rate',
  YOUR_REVIEW: 'Your Review',
  PLACEHOLDER: 'Enter your review',
};
export const CONTACT = {
  TITLE: 'Contact',
  BUTTON_TEXT: 'Send',
  MESSAGE: 'Message',
  PLACEHOLDER: 'Enter your message',
  TITLE_LABLE: 'Title',
  TITLE_PLACEHOLDER: 'Enter title',
  SUBMITTING: 'Submitting...',
};
export const INPUT_ERRORS = {
  EMAIL: {
    required: '*Email is required.',
    pattern: '*Enter a valid email.',
  },
  PASSWORD: {
    required: '*Password is required.',
  },
  CONFIRM_PASSWORD: {
    required: '*Confirm Password is required.',
    match: '*Passwords do not match',
  },
  REVIEW: {
    required: '*Review is required.',
  },
  MESSAGE: {
    required: '*Message is required.',
    maxLength: '*Maximum length is 150 characters',
  },
  TITLE: {
    required: '*Title is required.',
  },
};

export enum TOAST_MESSAGE_ENUM {
  SUCCESS = 'success',
  DESTRUCTIVE = 'destructive',
  DEFAULT = 'default',
}

export enum TOAST_TITLE {
  SUCCESS = 'Success',
  ERROR = 'Error',
}
