import React, { useEffect, useState } from 'react';

import {
  Tooltip,
  TooltipProvider,
  TooltipTrigger,
} from '@radix-ui/react-tooltip';

import { DropDownListType } from 'src/@types/dashboardTypes';
import SelectDropdown from 'src/components/SelectDropdown';
import { Input } from 'src/components/ui/input';
import { TooltipArrow, TooltipContent } from 'src/components/ui/tooltip';
import { PREMIUM_TOOLTIP_TEXT } from 'src/constants/common';
import { CALL_PUT_RATIO_VALUES } from 'src/constants/dashboard';
import { cn } from 'src/lib/utils';

import LabelText from '../LabelText';
export interface Operator {
  value: string;
  name: string;
}
interface Props {
  label: string;
  isDisabled?: boolean;
  keyName: string;
  handleOpen?: () => void;
  isPremium?: boolean;
  onChange:
    | ((
        key: string,
        value: { operator: string | Operator; callPutRatioValue: number },
      ) => void)
    | any;
  value?: {
    operator: Operator;
    callPutRatioValue: number;
  };
  infoText?: string;
}

const CallToPutRatio: React.FC<Props> = ({
  label,
  keyName,
  value,
  isDisabled,
  isPremium,
  handleOpen,
  onChange,
  infoText = '',
}) => {
  const [operator, setOperator] = useState<
    Operator | string | DropDownListType | Date | number
  >('');
  const [priceValue, setValues] = useState(0);

  useEffect(() => {
    setOperator(value?.operator ?? '');
    setValues(value?.callPutRatioValue ?? 0);
  }, [value]);

  const handleOpChange = (
    keyName: string,
    secondArg: string | number | Date | Operator | undefined | DropDownListType,
  ) => {
    setOperator(secondArg as Operator);
    onChange(keyName, {
      operator: secondArg as Operator,
      callPutRatioValue: priceValue,
    });
  };

  const handleCallRatioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let valueToSet = parseFloat(e.target.value);
    if (valueToSet < 0.0) valueToSet = 0.0;
    if (e.target.value.length > 5) return;
    setValues(valueToSet);
    const changeObject: { callPutRatioValue: number; operator?: Operator } = {
      callPutRatioValue: valueToSet,
    };
    if (typeof operator === 'object' && 'value' in operator && operator.value) {
      changeObject.operator = operator as Operator;
    }
    onChange(keyName, changeObject);
  };
  const handleClickPremiumFields = () => {
    if (isPremium && isDisabled && handleOpen) {
      handleOpen();
    }
  };
  return (
    <div onClick={handleClickPremiumFields}>
      <LabelText
        label={label}
        isDisabled={isDisabled}
        isPremium={isPremium}
        infoText={infoText}
      />
      <TooltipProvider>
        <Tooltip>
          <div className='flex flex-row gap-[9px] mt-3'>
            <div className='w-3/5'>
              <SelectDropdown
                items={CALL_PUT_RATIO_VALUES}
                listItemContainerClassName='w-full md:w-40'
                listItemClassName='mx-2'
                handleOpen={handleOpen}
                isDisabled={isDisabled}
                inputClassName='w-full'
                onChange={handleOpChange}
                placeholder='Operator'
                value={operator as Operator}
                keyName={keyName}
                hasOperator
              />
            </div>
            <TooltipTrigger asChild>
              <div className='relative w-2/5'>
                <div
                  className={cn('w-auto h-11 z-50 left-0 absolute right-0', {
                    'hidden ': !isDisabled,
                  })}
                  onClick={handleClickPremiumFields}
                />
                <Input
                  name={'call-put-ratio'}
                  maxLength={5}
                  placeholder={'0.1'}
                  value={priceValue}
                  disabled={isDisabled}
                  containerStyles='h-11 w-full'
                  onChange={handleCallRatioChange}
                  type={'number'}
                  min={0.0}
                />
              </div>
            </TooltipTrigger>
          </div>
          {isDisabled ? (
            <TooltipContent
              side='top'
              align='center'
              className='max-w-[200px] text-center data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade select-none rounded-[14px] bg-navyBlue px-[15px] py-[10px] text-[13px] text-white leading-[17px]'
            >
              {isPremium ? PREMIUM_TOOLTIP_TEXT : ''}
              <TooltipArrow className='fill-navyBlue w-3 h-2' />
            </TooltipContent>
          ) : null}
        </Tooltip>
      </TooltipProvider>
    </div>
  );
};

export default CallToPutRatio;
