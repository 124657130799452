import React from 'react'; // Import ReactNode type

import { loggedInUserType } from './types';

export const AuthContext = React.createContext<{
  auth: loggedInUserType | null;
  setAuth: React.Dispatch<React.SetStateAction<loggedInUserType | null>>;
  emailForOtp: string | null;
  setEmailForOtp: React.Dispatch<React.SetStateAction<string | null>>;
  setUser: () => void;
}>({
  auth: null,
  setAuth: () => {},
  emailForOtp: null,
  setEmailForOtp: () => {},
  setUser: () => {},
});
