import React, { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';

import FormField from 'src/components/FormField';
import Loader from 'src/components/Loader';
import withPublicRoute from 'src/components/PublicRoute';
import { Typography } from 'src/components/Typography';
import { Button } from 'src/components/ui/button';
import { FORGOT_PASSWORD, LOGIN } from 'src/constants/auth';
import { ROUTES } from 'src/constants/common';
import { INPUT_ERRORS } from 'src/constants/feedback';
import useAuthContext from 'src/hooks/useAuth';
import { forgotPassword } from 'src/services/auth';
import { EmailRegex } from 'src/utils/common';

interface IForgotPasswordField {
  email: string;
}

const ForgotPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { setEmailForOtp } = useAuthContext();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IForgotPasswordField>({
    mode: 'all',
    defaultValues: {
      email: '',
    },
  });

  const handleForgotPassword: SubmitHandler<IForgotPasswordField> = async (
    data,
  ) => {
    setIsLoading(true);
    const response = await forgotPassword(data);
    setIsLoading(false);
    if (!response) return;
    setEmailForOtp(data.email);
    navigate(ROUTES.OTP, { state: { isForgot: true } });
  };

  return (
    <div className='bg-nightSky md:px-10 md:py-6 mt-20 mb-40 lg:px-20 p-6 lg:py-8 flex flex-col mx-auto w-11/12 md:w-7/12 lg:w-5/12 rounded-xl'>
      <Typography
        variant='subheading'
        className='mx-auto my-4 text-center capitalize'
      >
        {FORGOT_PASSWORD.TITLE}
      </Typography>
      <Typography
        variant='p'
        className='mx-auto capitalize text-base text-center'
      >
        {FORGOT_PASSWORD.DESC}
      </Typography>
      <Controller
        control={control}
        name='email'
        rules={{
          required: INPUT_ERRORS.EMAIL.required,
          pattern: {
            value: EmailRegex,
            message: INPUT_ERRORS.EMAIL.pattern,
          },
        }}
        render={({ field: { onChange, value, name } }) => (
          <FormField
            title={LOGIN.EMAIL.title}
            placeholder={LOGIN.EMAIL.placeholder}
            name={name}
            value={value}
            errors={errors}
            onChange={(e) => onChange(e.target.value?.toLocaleLowerCase())}
          />
        )}
      />
      <Button
        variant='gradient'
        className='my-4 text-lg'
        onClick={handleSubmit(handleForgotPassword)}
      >
        {isLoading ? <Loader loading={isLoading} /> : FORGOT_PASSWORD.SUBMIT}
      </Button>
      <Typography variant='p' className='mx-auto capitalize'>
        {FORGOT_PASSWORD.BACK_TO}{' '}
        <Link to={ROUTES.HOME}>
          <span className='text-skyBlue underline'>
            {FORGOT_PASSWORD.LOGIN}
          </span>
        </Link>
      </Typography>
    </div>
  );
};

export default withPublicRoute(ForgotPassword);
