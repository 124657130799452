import * as React from 'react';
import { SVGProps } from 'react';

const Logo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={126}
    height={56}
    fill='none'
    {...props}
  >
    <rect width={126} height={56} fill='#051C31' rx={28} />
    <path
      fill='url(#a)'
      d='M56.747 32.996a.324.324 0 0 1-.25-.096.342.342 0 0 1-.089-.244v-9.65a.37.37 0 0 1 .088-.26.326.326 0 0 1 .25-.096h4.05c.74 0 1.342.12 1.811.362.467.241.813.569 1.035.982.222.415.333.877.333 1.39 0 .404-.066.752-.199 1.042a2.184 2.184 0 0 1-.496.71 2.766 2.766 0 0 1-.575.42c.384.188.726.494 1.026.916.301.424.452.937.452 1.538 0 .542-.124 1.039-.37 1.493a2.72 2.72 0 0 1-1.102 1.086c-.487.271-1.091.407-1.81.407h-4.154Zm1.108-5.972h2.719c.63 0 1.108-.145 1.433-.436.325-.29.488-.687.488-1.19 0-.502-.163-.886-.488-1.152-.325-.265-.803-.4-1.433-.4h-2.72v3.178Zm0 4.774h2.853c.64 0 1.132-.172 1.478-.517.345-.344.517-.768.517-1.271 0-.503-.172-.954-.517-1.294-.346-.34-.837-.509-1.478-.509h-2.853v3.591Z'
    />
    <path
      fill='url(#b)'
      d='M69.235 33.144c-.749 0-1.375-.142-1.876-.428a2.885 2.885 0 0 1-1.153-1.19c-.265-.507-.413-1.086-.443-1.736-.01-.168-.014-.382-.014-.643 0-.262.005-.47.014-.629.03-.66.18-1.24.451-1.743.271-.503.657-.897 1.16-1.183.503-.286 1.123-.428 1.863-.428s1.36.142 1.862.428c.503.286.889.68 1.16 1.183.27.502.421 1.084.451 1.743.01.157.015.367.015.629 0 .261-.005.475-.015.643-.03.65-.177 1.229-.443 1.737s-.65.903-1.153 1.19c-.502.285-1.128.427-1.877.427h-.002Zm0-1.138c.611 0 1.099-.195 1.463-.583.365-.39.563-.958.591-1.707.01-.148.015-.335.015-.563 0-.227-.006-.413-.015-.562-.03-.749-.226-1.318-.59-1.707-.365-.388-.853-.583-1.464-.583-.61 0-1.101.195-1.47.584-.37.388-.564.957-.585 1.706-.01.148-.015.335-.015.563 0 .227.006.414.015.562.02.749.214 1.318.584 1.707.37.388.86.583 1.471.583Z'
    />
    <path
      fill='url(#c)'
      d='M74.792 23.877a.33.33 0 0 1-.34-.34v-.798a.33.33 0 0 1 .34-.34h.93a.33.33 0 0 1 .34.34v.798a.33.33 0 0 1-.34.34h-.93Z'
    />
    <path
      fill='url(#d)'
      d='M78.502 32.995a.33.33 0 0 1-.34-.34v-9.813a.33.33 0 0 1 .34-.34h.695c.109 0 .193.032.25.096.06.064.089.145.089.244v9.813c0 .099-.03.18-.088.244a.325.325 0 0 1-.25.096h-.696Z'
    />
    <path
      fill='url(#e)'
      d='M81.96 23.877a.33.33 0 0 1-.34-.34v-.798a.33.33 0 0 1 .34-.34h.93a.33.33 0 0 1 .34.34v.798a.33.33 0 0 1-.34.34h-.93Zm.117 9.118a.33.33 0 0 1-.34-.34V25.65a.33.33 0 0 1 .34-.34h.71a.31.31 0 0 1 .237.097c.06.063.088.145.088.243v7.005c0 .1-.03.18-.088.244a.31.31 0 0 1-.237.096h-.71Z'
    />
    <path
      fill='url(#f)'
      d='M85.655 32.995a.33.33 0 0 1-.34-.34V25.65a.33.33 0 0 1 .34-.34h.695a.33.33 0 0 1 .34.34v.65c.256-.334.578-.608.968-.819.39-.211.889-.318 1.5-.318.61 0 1.185.14 1.634.421.448.28.785.67 1.012 1.168.226.497.34 1.077.34 1.736v4.169c0 .099-.03.18-.088.244a.31.31 0 0 1-.237.096h-.74a.331.331 0 0 1-.244-.096.331.331 0 0 1-.096-.244v-4.094c0-.69-.168-1.229-.503-1.619-.334-.389-.827-.584-1.478-.584-.61 0-1.101.195-1.47.584-.37.389-.555.93-.555 1.619v4.094a.33.33 0 0 1-.34.34h-.74l.002-.002Z'
    />
    <path
      fill='url(#g)'
      d='M97.419 36.248c-.67 0-1.22-.088-1.648-.266-.428-.177-.764-.391-1.005-.643a2.497 2.497 0 0 1-.517-.753c-.103-.251-.16-.451-.17-.6a.285.285 0 0 1 .097-.25.351.351 0 0 1 .243-.103h.71c.09 0 .163.02.222.06.06.04.109.123.148.25.06.148.148.314.266.496.117.182.3.34.547.473.246.133.59.2 1.035.2.444 0 .852-.063 1.168-.185.315-.123.554-.34.716-.65.163-.31.244-.737.244-1.279v-1.005c-.237.306-.547.56-.93.761-.385.202-.868.304-1.449.304-.58 0-1.025-.097-1.418-.289a2.664 2.664 0 0 1-.969-.783 3.446 3.446 0 0 1-.562-1.131 5.566 5.566 0 0 1-.214-1.33c-.01-.277-.01-.548 0-.813.02-.463.09-.906.214-1.33.123-.424.31-.804.562-1.138a2.63 2.63 0 0 1 .969-.791c.394-.193.867-.289 1.418-.289.59 0 1.081.111 1.471.333.39.223.708.49.954.806v-.635c0-.099.032-.181.096-.25a.317.317 0 0 1 .244-.104h.68c.099 0 .182.036.251.103.069.07.103.153.103.251v7.168c0 .66-.111 1.246-.332 1.758a2.565 2.565 0 0 1-1.094 1.212c-.507.296-1.189.443-2.046.443l-.004-.001Zm-.03-4.374c.493 0 .889-.114 1.19-.34.3-.227.52-.512.664-.858.142-.345.22-.695.23-1.05.009-.138.014-.307.014-.509s-.005-.371-.015-.51a2.945 2.945 0 0 0-.229-1.05 2.036 2.036 0 0 0-.665-.857c-.3-.226-.698-.34-1.19-.34-.49 0-.885.111-1.182.332a1.828 1.828 0 0 0-.635.872c-.129.36-.202.747-.222 1.16-.01.256-.01.518 0 .783.019.413.093.8.222 1.16.127.36.34.65.635.872.295.222.69.332 1.183.332v.003Z'
    />
    <path
      fill='url(#h)'
      d='M59.08 44.6c-.836 0-1.556-.144-2.157-.436a3.061 3.061 0 0 1-1.39-1.345c-.325-.605-.508-1.367-.547-2.284a57.144 57.144 0 0 1 0-2.498c.04-.906.229-1.662.569-2.269.34-.605.813-1.06 1.418-1.36.606-.3 1.309-.45 2.107-.45.797 0 1.514.15 2.12.45.605.3 1.08.753 1.426 1.36.345.606.532 1.363.562 2.27a26.686 26.686 0 0 1 0 2.498c-.03.915-.21 1.676-.539 2.283a3.102 3.102 0 0 1-1.397 1.345c-.601.29-1.324.436-2.172.436Zm0-1.255c.76 0 1.377-.227 1.855-.68.479-.453.737-1.187.777-2.203.019-.443.03-.84.03-1.19 0-.349-.01-.74-.03-1.174-.02-.68-.145-1.231-.377-1.655-.232-.424-.54-.734-.924-.93-.385-.197-.828-.296-1.33-.296a2.76 2.76 0 0 0-1.3.295c-.385.198-.693.508-.924.93-.232.425-.362.976-.392 1.656-.01.434-.015.825-.015 1.175 0 .35.006.746.015 1.19.04 1.014.3 1.748.783 2.202.482.453 1.093.68 1.833.68Z'
    />
    <path
      fill='url(#i)'
      d='M65.538 47.26a.33.33 0 0 1-.34-.34v-9.812a.33.33 0 0 1 .34-.34h.68c.1 0 .18.032.244.096a.331.331 0 0 1 .097.244v.65a2.84 2.84 0 0 1 .945-.813c.394-.217.886-.325 1.478-.325.551 0 1.021.094 1.412.28.388.187.71.443.96.769.252.325.442.694.569 1.108.127.414.196.857.207 1.33a8.343 8.343 0 0 1 0 1.005 4.81 4.81 0 0 1-.207 1.322 3.394 3.394 0 0 1-.569 1.108c-.25.32-.572.576-.96.768-.39.193-.86.289-1.412.289-.551 0-1.052-.106-1.441-.318a3.006 3.006 0 0 1-.954-.792v3.429c0 .099-.03.18-.088.244a.326.326 0 0 1-.25.096h-.71v.003Zm3.134-3.857c.512 0 .914-.111 1.204-.332.291-.222.5-.512.629-.873.127-.359.202-.746.222-1.16.01-.285.01-.57 0-.857-.02-.414-.094-.8-.222-1.16a1.858 1.858 0 0 0-.629-.872c-.291-.222-.692-.331-1.204-.331-.483 0-.874.113-1.175.34-.3.226-.523.514-.665.864-.142.35-.223.707-.244 1.071a10.202 10.202 0 0 0 0 1.138c.01.346.091.686.244 1.02.153.335.382.611.687.828.305.217.69.325 1.153.325v-.001Z'
    />
    <path
      fill='url(#j)'
      d='M76.905 44.453c-.551 0-1-.106-1.345-.319a1.824 1.824 0 0 1-.753-.902c-.159-.389-.237-.849-.237-1.381v-3.902h-1.153a.33.33 0 0 1-.34-.34v-.502a.33.33 0 0 1 .34-.34h1.153v-11.33a.33.33 0 0 1 .34-.34h.695a.33.33 0 0 1 .34.34v11.33h1.833a.33.33 0 0 1 .34.34v.502a.33.33 0 0 1-.34.34h-1.833v3.799c0 .463.078.827.237 1.093.157.265.439.4.843.4h.902c.099 0 .18.032.244.096a.331.331 0 0 1 .096.243v.533c0 .099-.033.18-.096.244a.331.331 0 0 1-.244.096h-1.022Z'
    />
    <path
      fill='url(#k)'
      d='M79.904 35.333a.33.33 0 0 1-.34-.34v-.797a.33.33 0 0 1 .34-.34h.931a.33.33 0 0 1 .34.34v.797a.33.33 0 0 1-.34.34h-.93Zm.118 9.119a.33.33 0 0 1-.34-.34v-7.005a.33.33 0 0 1 .34-.34h.71a.31.31 0 0 1 .237.096c.06.063.088.145.088.244v7.005c0 .098-.03.18-.088.243a.31.31 0 0 1-.237.097h-.71Z'
    />
    <path
      fill='url(#l)'
      d='M86.407 44.6c-.75 0-1.375-.142-1.876-.428a2.885 2.885 0 0 1-1.153-1.19c-.266-.507-.413-1.086-.443-1.736-.01-.168-.015-.382-.015-.643 0-.262.005-.47.015-.629.03-.66.18-1.24.45-1.743.272-.503.658-.897 1.16-1.183.503-.286 1.123-.428 1.863-.428s1.36.142 1.863.428c.502.286.888.68 1.16 1.183.27.502.42 1.083.45 1.743.01.157.015.367.015.629 0 .261-.005.475-.015.643-.03.65-.177 1.229-.443 1.737-.265.508-.65.903-1.152 1.19-.503.285-1.129.427-1.878.427h-.001Zm0-1.138c.61 0 1.098-.195 1.463-.584.364-.388.562-.957.59-1.706.01-.148.015-.335.015-.563 0-.227-.005-.413-.015-.562-.03-.749-.226-1.318-.59-1.707-.365-.388-.852-.584-1.463-.584-.611 0-1.102.196-1.471.584-.37.39-.564.958-.584 1.707-.01.148-.015.335-.015.562 0 .228.005.415.015.563.019.749.214 1.318.584 1.706.37.39.86.584 1.47.584Z'
    />
    <path
      fill='url(#m)'
      d='M92.081 44.453a.33.33 0 0 1-.34-.34v-7.005a.33.33 0 0 1 .34-.34h.695a.33.33 0 0 1 .34.34v.65c.256-.335.579-.608.969-.82.389-.211.889-.318 1.5-.318.61 0 1.185.14 1.633.421.448.28.786.67 1.012 1.168.226.497.34 1.077.34 1.737v4.168c0 .099-.03.18-.088.244a.31.31 0 0 1-.237.096h-.74a.33.33 0 0 1-.34-.34V40.02c0-.69-.168-1.229-.502-1.619-.335-.388-.828-.584-1.478-.584-.611 0-1.102.196-1.471.584-.37.39-.554.93-.554 1.62v4.093a.33.33 0 0 1-.34.34h-.74l.001-.002Z'
    />
    <path
      fill='url(#n)'
      d='M103.357 44.6c-.551 0-1.025-.069-1.418-.207-.394-.138-.714-.305-.96-.503a2.417 2.417 0 0 1-.554-.59c-.124-.197-.19-.355-.2-.473-.009-.108.025-.192.105-.252a.386.386 0 0 1 .237-.088h.65c.06 0 .106.007.141.022a.496.496 0 0 1 .141.126c.127.138.271.276.428.414.157.138.352.25.584.34.231.09.52.133.864.133.503 0 .916-.096 1.241-.289a.929.929 0 0 0 .488-.849c0-.247-.067-.443-.2-.59-.132-.148-.369-.281-.709-.4-.34-.118-.805-.241-1.397-.37-.591-.138-1.059-.308-1.403-.51-.346-.201-.591-.442-.739-.724a2.025 2.025 0 0 1-.222-.954c0-.355.108-.716.325-1.056.217-.34.534-.618.954-.835.418-.217.943-.325 1.574-.325.512 0 .951.064 1.315.192.365.129.665.289.901.481.237.193.415.382.533.57.117.186.181.345.192.472a.281.281 0 0 1-.088.244.34.34 0 0 1-.237.096h-.621a.292.292 0 0 1-.169-.045.895.895 0 0 1-.126-.103 3.68 3.68 0 0 0-.347-.384 1.58 1.58 0 0 0-.517-.319c-.212-.084-.491-.126-.835-.126-.493 0-.863.103-1.108.31a.983.983 0 0 0-.37.783c0 .187.055.355.163.503.108.148.316.28.62.4.305.117.759.246 1.36.384.651.13 1.163.301 1.538.518.374.217.641.469.798.753.157.286.237.616.237.99 0 .413-.121.794-.362 1.138-.241.346-.596.62-1.065.82-.467.202-1.047.303-1.736.303h-.006Z'
    />
    <path
      fill='url(#o)'
      d='M49.288 41.497H38.424a.309.309 0 0 0-.309.309v2.782c0 .17.138.31.309.31h10.864c.17 0 .309-.14.309-.31v-2.782a.309.309 0 0 0-.309-.309Z'
    />
    <path
      fill='url(#p)'
      d='M29.802 42.06H25.73a.309.309 0 0 0-.309.31v5.574c0 .17.138.309.309.309h4.07c.171 0 .31-.139.31-.31V42.37a.309.309 0 0 0-.31-.309Z'
    />
    <path
      fill='url(#q)'
      d='M36.657 29.113V6.309A.309.309 0 0 0 36.35 6h-4.07a.309.309 0 0 0-.309.309v27.937c0 .292.37.422.552.192l4.07-5.133a.307.307 0 0 0 .066-.192Z'
    />
    <path
      fill='url(#r)'
      d='M36.657 46.617V36.111c0-.293-.37-.423-.55-.192l-4.07 5.132a.307.307 0 0 0-.066.193v8.774c0 .17.138.309.308.309h37.936c.17 0 .308-.139.308-.31v-2.78a.309.309 0 0 0-.308-.31H36.968a.309.309 0 0 1-.31-.308v-.002Z'
    />
    <path
      fill='url(#s)'
      d='m25.63 33.296.865-1.024 3.085 3.166a.31.31 0 0 0 .53-.216V10.886a.309.309 0 0 0-.308-.309h-4.07a.309.309 0 0 0-.308.31v22.656l.207-.246-.002-.001Z'
    />
    <path
      fill='#7C774E'
      d='m54.837 17.48-6.5 1.793a.622.622 0 0 0-.236 1.076l1.798 1.514a.31.31 0 0 1 .048.424l-6.695 8.835a.31.31 0 0 1-.455.042l-3.742-3.392a.309.309 0 0 0-.45.037l-7.538 9.51a.31.31 0 0 1-.465.024l-3.869-3.97a.309.309 0 0 0-.457.016l-.645.764c-2.06 2.438-5.515 6.406-6.519 7.556a.455.455 0 0 0 .023.623l1.153 1.146c.19.188.499.175.67-.029l5.446-6.442a.309.309 0 0 1 .458-.017l3.929 4.03a.31.31 0 0 0 .464-.024l7.643-9.64a.308.308 0 0 1 .45-.036l3.782 3.43a.31.31 0 0 0 .455-.042l8.207-10.825a.31.31 0 0 1 .445-.05l1.702 1.433a.622.622 0 0 0 1.02-.414l.663-6.71a.623.623 0 0 0-.785-.661v-.001Z'
    />
    <defs>
      <linearGradient
        id='a'
        x1={102.187}
        x2={43.631}
        y1={4.503}
        y2={36.346}
        gradientUnits='userSpaceOnUse'
      >
        <stop offset={0.38} stopColor='#6C98E7' />
        <stop offset={0.43} stopColor='#7396E8' />
        <stop offset={0.67} stopColor='#9690ED' />
        <stop offset={0.87} stopColor='#AC8DF0' />
        <stop offset={1} stopColor='#B48CF2' />
      </linearGradient>
      <linearGradient
        id='b'
        x1={105.014}
        x2={46.458}
        y1={9.702}
        y2={41.543}
        gradientUnits='userSpaceOnUse'
      >
        <stop offset={0.38} stopColor='#6C98E7' />
        <stop offset={0.43} stopColor='#7396E8' />
        <stop offset={0.67} stopColor='#9690ED' />
        <stop offset={0.87} stopColor='#AC8DF0' />
        <stop offset={1} stopColor='#B48CF2' />
      </linearGradient>
      <linearGradient
        id='c'
        x1={103.863}
        x2={45.307}
        y1={7.584}
        y2={39.426}
        gradientUnits='userSpaceOnUse'
      >
        <stop offset={0.38} stopColor='#6C98E7' />
        <stop offset={0.43} stopColor='#7396E8' />
        <stop offset={0.67} stopColor='#9690ED' />
        <stop offset={0.87} stopColor='#AC8DF0' />
        <stop offset={1} stopColor='#B48CF2' />
      </linearGradient>
      <linearGradient
        id='d'
        x1={106.618}
        x2={48.062}
        y1={12.651}
        y2={44.493}
        gradientUnits='userSpaceOnUse'
      >
        <stop offset={0.38} stopColor='#6C98E7' />
        <stop offset={0.43} stopColor='#7396E8' />
        <stop offset={0.67} stopColor='#9690ED' />
        <stop offset={0.87} stopColor='#AC8DF0' />
        <stop offset={1} stopColor='#B48CF2' />
      </linearGradient>
      <linearGradient
        id='e'
        x1={107.4}
        x2={48.844}
        y1={14.089}
        y2={45.931}
        gradientUnits='userSpaceOnUse'
      >
        <stop offset={0.38} stopColor='#6C98E7' />
        <stop offset={0.43} stopColor='#7396E8' />
        <stop offset={0.67} stopColor='#9690ED' />
        <stop offset={0.87} stopColor='#AC8DF0' />
        <stop offset={1} stopColor='#B48CF2' />
      </linearGradient>
      <linearGradient
        id='f'
        x1={109.463}
        x2={50.907}
        y1={17.882}
        y2={49.724}
        gradientUnits='userSpaceOnUse'
      >
        <stop offset={0.38} stopColor='#6C98E7' />
        <stop offset={0.43} stopColor='#7396E8' />
        <stop offset={0.67} stopColor='#9690ED' />
        <stop offset={0.87} stopColor='#AC8DF0' />
        <stop offset={1} stopColor='#B48CF2' />
      </linearGradient>
      <linearGradient
        id='g'
        x1={112.097}
        x2={53.542}
        y1={22.727}
        y2={54.569}
        gradientUnits='userSpaceOnUse'
      >
        <stop offset={0.38} stopColor='#6C98E7' />
        <stop offset={0.43} stopColor='#7396E8' />
        <stop offset={0.67} stopColor='#9690ED' />
        <stop offset={0.87} stopColor='#AC8DF0' />
        <stop offset={1} stopColor='#B48CF2' />
      </linearGradient>
      <linearGradient
        id='h'
        x1={106.955}
        x2={48.4}
        y1={13.273}
        y2={45.114}
        gradientUnits='userSpaceOnUse'
      >
        <stop offset={0.38} stopColor='#6C98E7' />
        <stop offset={0.43} stopColor='#7396E8' />
        <stop offset={0.67} stopColor='#9690ED' />
        <stop offset={0.87} stopColor='#AC8DF0' />
        <stop offset={1} stopColor='#B48CF2' />
      </linearGradient>
      <linearGradient
        id='i'
        x1={109.648}
        x2={51.093}
        y1={18.224}
        y2={50.065}
        gradientUnits='userSpaceOnUse'
      >
        <stop offset={0.38} stopColor='#6C98E7' />
        <stop offset={0.43} stopColor='#7396E8' />
        <stop offset={0.67} stopColor='#9690ED' />
        <stop offset={0.87} stopColor='#AC8DF0' />
        <stop offset={1} stopColor='#B48CF2' />
      </linearGradient>
      <linearGradient
        id='j'
        x1={109.01}
        x2={50.455}
        y1={17.051}
        y2={48.893}
        gradientUnits='userSpaceOnUse'
      >
        <stop offset={0.38} stopColor='#6C98E7' />
        <stop offset={0.43} stopColor='#7396E8' />
        <stop offset={0.67} stopColor='#9690ED' />
        <stop offset={0.87} stopColor='#AC8DF0' />
        <stop offset={1} stopColor='#B48CF2' />
      </linearGradient>
      <linearGradient
        id='k'
        x1={111.739}
        x2={53.183}
        y1={22.067}
        y2={53.91}
        gradientUnits='userSpaceOnUse'
      >
        <stop offset={0.38} stopColor='#6C98E7' />
        <stop offset={0.43} stopColor='#7396E8' />
        <stop offset={0.67} stopColor='#9690ED' />
        <stop offset={0.87} stopColor='#AC8DF0' />
        <stop offset={1} stopColor='#B48CF2' />
      </linearGradient>
      <linearGradient
        id='l'
        x1={113.741}
        x2={55.185}
        y1={25.75}
        y2={57.591}
        gradientUnits='userSpaceOnUse'
      >
        <stop offset={0.38} stopColor='#6C98E7' />
        <stop offset={0.43} stopColor='#7396E8' />
        <stop offset={0.67} stopColor='#9690ED' />
        <stop offset={0.87} stopColor='#AC8DF0' />
        <stop offset={1} stopColor='#B48CF2' />
      </linearGradient>
      <linearGradient
        id='m'
        x1={115.737}
        x2={57.182}
        y1={29.421}
        y2={61.262}
        gradientUnits='userSpaceOnUse'
      >
        <stop offset={0.38} stopColor='#6C98E7' />
        <stop offset={0.43} stopColor='#7396E8' />
        <stop offset={0.67} stopColor='#9690ED' />
        <stop offset={0.87} stopColor='#AC8DF0' />
        <stop offset={1} stopColor='#B48CF2' />
      </linearGradient>
      <linearGradient
        id='n'
        x1={117.626}
        x2={59.07}
        y1={32.893}
        y2={64.736}
        gradientUnits='userSpaceOnUse'
      >
        <stop offset={0.38} stopColor='#6C98E7' />
        <stop offset={0.43} stopColor='#7396E8' />
        <stop offset={0.67} stopColor='#9690ED' />
        <stop offset={0.87} stopColor='#AC8DF0' />
        <stop offset={1} stopColor='#B48CF2' />
      </linearGradient>
      <linearGradient
        id='o'
        x1={9.422}
        x2={67.985}
        y1={7.057}
        y2={68.523}
        gradientUnits='userSpaceOnUse'
      >
        <stop offset={0.38} stopColor='#6C98E7' />
        <stop offset={0.43} stopColor='#7396E8' />
        <stop offset={0.67} stopColor='#9690ED' />
        <stop offset={0.87} stopColor='#AC8DF0' />
        <stop offset={1} stopColor='#B48CF2' />
      </linearGradient>
      <linearGradient
        id='p'
        x1={2.811}
        x2={54.57}
        y1={18.962}
        y2={73.288}
        gradientUnits='userSpaceOnUse'
      >
        <stop offset={0.38} stopColor='#6C98E7' />
        <stop offset={0.43} stopColor='#7396E8' />
        <stop offset={0.67} stopColor='#9690ED' />
        <stop offset={0.87} stopColor='#AC8DF0' />
        <stop offset={1} stopColor='#B48CF2' />
      </linearGradient>
      <linearGradient
        id='q'
        x1={20.371}
        x2={67.625}
        y1={5.644}
        y2={55.241}
        gradientUnits='userSpaceOnUse'
      >
        <stop offset={0.14} stopColor='#B48CF2' />
        <stop offset={0.2} stopColor='#6C98E7' />
        <stop offset={0.21} stopColor='#5D9AE4' />
        <stop offset={0.23} stopColor='#479EE2' />
        <stop offset={0.25} stopColor='#40A0E1' />
        <stop offset={0.3} stopColor='#4999C9' />
        <stop offset={0.4} stopColor='#588EA3' />
        <stop offset={0.5} stopColor='#658684' />
        <stop offset={0.61} stopColor='#6F7F6C' />
        <stop offset={0.73} stopColor='#767A5B' />
        <stop offset={0.85} stopColor='#7A7751' />
        <stop offset={1} stopColor='#7C774E' />
      </linearGradient>
      <linearGradient
        id='r'
        x1={2.703}
        x2={78.015}
        y1={-2.323}
        y2={76.723}
        gradientUnits='userSpaceOnUse'
      >
        <stop offset={0.14} stopColor='#B48CF2' />
        <stop offset={0.2} stopColor='#6C98E7' />
        <stop offset={0.21} stopColor='#5D9AE4' />
        <stop offset={0.23} stopColor='#479EE2' />
        <stop offset={0.25} stopColor='#40A0E1' />
        <stop offset={0.3} stopColor='#4999C9' />
        <stop offset={0.4} stopColor='#588EA3' />
        <stop offset={0.5} stopColor='#658684' />
        <stop offset={0.592} stopColor='#6F7F6C' />
        <stop offset={0.73} stopColor='#767A5B' />
        <stop offset={0.85} stopColor='#7A7751' />
        <stop offset={1} stopColor='#7C774E' />
      </linearGradient>
      <linearGradient
        id='s'
        x1={15.88}
        x2={62.644}
        y1={9.675}
        y2={58.758}
        gradientUnits='userSpaceOnUse'
      >
        <stop offset={0.14} stopColor='#B48CF2' />
        <stop offset={0.2} stopColor='#6C98E7' />
        <stop offset={0.21} stopColor='#5D9AE4' />
        <stop offset={0.23} stopColor='#479EE2' />
        <stop offset={0.25} stopColor='#40A0E1' />
        <stop offset={0.3} stopColor='#4999C9' />
        <stop offset={0.4} stopColor='#588EA3' />
        <stop offset={0.5} stopColor='#658684' />
        <stop offset={0.61} stopColor='#6F7F6C' />
        <stop offset={0.73} stopColor='#767A5B' />
        <stop offset={0.85} stopColor='#7A7751' />
        <stop offset={1} stopColor='#7C774E' />
      </linearGradient>
    </defs>
  </svg>
);
export default Logo;
