import React, { useEffect } from 'react';
import { GetCity, GetState } from 'react-country-state-city';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { FaRegEdit } from 'react-icons/fa';

import FormField from 'src/components/FormField';
import { Typography } from 'src/components/Typography';
import { Button } from 'src/components/ui/button';
import { PROFILE_COMPLETION_INPUT_ERRORS } from 'src/constants/aterlogin';
import { PROFILE } from 'src/constants/profile';
import { PROFILE_COMPLETION } from 'src/constants/profileCompletion';
import useAuthContext from 'src/hooks/useAuth';
import { useGetLocation } from 'src/hooks/useLocation';
import CitySelect from 'src/pages/ProfileCompletion/(Components)/CitySelect';
import CountrySelect from 'src/pages/ProfileCompletion/(Components)/CountrySelect';
import StateSelect from 'src/pages/ProfileCompletion/(Components)/StateSelect';
import EmployedFields from 'src/pages/ProfileCompletion/EmployedFields';
import { UserType } from 'src/providers/Auth/types';
import { updateProfile } from 'src/services/profile';
import { isAlpha, isNumeric } from 'src/utils/common';

export interface IEditPersonalInfo {
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  streetAddress: string;
  country: string;
  state: string;
  city: string;
  zipCode: string;
  currentlyEmployed: boolean;
  nameOfEmployer?: string;
  employerJobTitle?: string;
}

interface EditPersonalInfoProps {
  handleUpdateInfo: () => void;
}

const EditPersonalInfo: React.FC<EditPersonalInfoProps> = ({
  handleUpdateInfo,
}) => {
  const { location, setLocation } = useGetLocation();

  const { auth, setAuth } = useAuthContext();
  const { user } = auth ?? {};
  const countryById = location.countryList.find(
    (country) => country.name === user?.country,
  );
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IEditPersonalInfo>({
    mode: 'all',
    defaultValues: {
      firstName: user?.firstName,
      middleName: user?.middleName,
      lastName: user?.lastName,
      email: user?.email,
      streetAddress: user?.streetAddress,
      country: user?.country,
      state: user?.state,
      city: user?.city,
      zipCode: user?.zipCode,
      currentlyEmployed: false,
      nameOfEmployer: user?.nameOfEmployer,
      employerJobTitle: user?.employerJobTitle,
    },
  });
  const fetchStateAndCity = async () => {
    const states = await GetState(countryById?.id);
    const stateIndex = states.findIndex(
      (state: any) => state.name === user?.state,
    );
    const cities = await GetCity(countryById?.id, states[stateIndex]?.id);
    const cityIndex = cities.findIndex(
      (state: any) => state.name === user?.city,
    );
    setLocation((prevLocation) => ({
      ...prevLocation,
      stateList: states,
      stateId: stateIndex,
      cityList: cities,
      cityId: cityIndex,
    }));
    setValue('state', String(stateIndex));
    setValue('city', String(cityIndex));
  };
  useEffect(() => {
    if (countryById) {
      fetchStateAndCity();
    }
  }, [countryById]);

  useEffect(() => {
    if (countryById) {
      setValue('country', String(countryById.id - 1));
    } else {
      setValue('country', '0');
    }
  }, [countryById, setValue]);

  const updateProfileHandler: SubmitHandler<IEditPersonalInfo> = async (
    data,
  ) => {
    const country = location.countryList[parseInt(data.country)];
    const states = await GetState(country?.id);
    const state = states[parseInt(data.state)];
    const cities = await GetCity(country?.id, state?.id);
    const city = cities[parseInt(data.city)];
    const paylaod = {
      ...data,
      country: country?.name || '',
      state: state?.name || '',
      city: city?.name || '',
    };
    const response = await updateProfile(paylaod);
    if (response.status) {
      setAuth({
        token: String(auth?.token),
        user: response.data?.data as UserType,
      });
      handleUpdateInfo();
    }
  };
  return (
    <div className='bg-nightSky w-11/12 mx-auto p-3 md:p-6 m-4 rounded-2xl'>
      <div className='flex justify-between'>
        <Typography variant='subheading' className='text-lg md:text-2xl'>
          {PROFILE.personalInformation}
        </Typography>
        <div className='flex gap-4'>
          <Button
            variant={'primary'}
            className='rounded-full p-3 sm:p-5 font-medium text-sm md:text-base'
            onClick={handleUpdateInfo}
          >
            <FaRegEdit className='mr-2 sm:mr-4' size={19} />
            {PROFILE.CANCEL}
          </Button>
          <Button
            variant={'primary'}
            className='rounded-full p-3 sm:p-5 font-medium text-sm md:text-base'
            onClick={handleSubmit(updateProfileHandler)}
          >
            <FaRegEdit className='mr-2 sm:mr-4' size={19} />
            {PROFILE.UPDATE}
          </Button>
        </div>
      </div>
      <div className='md:grid md:grid-cols-3 md:w-10/12 gap-x-8 mt-5 md:mt-0 space-y-5 md:space-y-0'>
        <div className='md:h-28'>
          <Controller
            control={control}
            name='firstName'
            rules={{
              required: PROFILE_COMPLETION_INPUT_ERRORS.FIRST_NAME.required,
              validate: {
                isAlpha: (value) =>
                  isAlpha(value) ||
                  PROFILE_COMPLETION_INPUT_ERRORS.NAME_TYPE.acceptFormat,
              },
            }}
            render={({ field: { onChange, value, name } }) => (
              <FormField
                type='text'
                labelCalssName='text-base'
                title={PROFILE_COMPLETION.FIRST_NAME.title}
                placeholder={PROFILE_COMPLETION.FIRST_NAME.placeholder}
                name={name}
                value={value}
                onChange={onChange}
                errors={errors}
                isRequired
              />
            )}
          />
        </div>
        <div className='md:h-28'>
          <Controller
            control={control}
            name='middleName'
            rules={{
              validate: {
                isAlphaIfNotEmpty: (value) => {
                  if (value.trim() === '') {
                    return true;
                  }
                  return (
                    isAlpha(value) ||
                    PROFILE_COMPLETION_INPUT_ERRORS.NAME_TYPE.acceptFormat
                  );
                },
              },
            }}
            render={({ field: { onChange, value, name } }) => (
              <FormField
                type='text'
                labelCalssName='text-base'
                title={PROFILE_COMPLETION.MIDDLE_NAME.title}
                placeholder={PROFILE_COMPLETION.MIDDLE_NAME.placeholder}
                name={name}
                value={value}
                onChange={onChange}
                errors={errors}
              />
            )}
          />
        </div>
        <div className='md:h-28'>
          <Controller
            control={control}
            name='lastName'
            rules={{
              required: PROFILE_COMPLETION_INPUT_ERRORS.LAST_NAME.required,
              validate: {
                isAlphaIfNotEmpty: (value) => {
                  if (value.trim() === '') {
                    return true;
                  }
                  return (
                    isAlpha(value) ||
                    PROFILE_COMPLETION_INPUT_ERRORS.NAME_TYPE.acceptFormat
                  );
                },
              },
            }}
            render={({ field: { onChange, value, name } }) => (
              <FormField
                type='text'
                labelCalssName='text-base'
                title={PROFILE_COMPLETION.LAST_NAME.title}
                placeholder={PROFILE_COMPLETION.LAST_NAME.placeholder}
                name={name}
                value={value}
                onChange={onChange}
                errors={errors}
                isRequired
              />
            )}
          />
        </div>
        <div className='md:h-28'>
          <Controller
            control={control}
            name='email'
            render={({ field: { onChange, value, name } }) => (
              <FormField
                type='text'
                labelCalssName='text-base'
                title={PROFILE_COMPLETION.EMAIL.title}
                placeholder={PROFILE_COMPLETION.EMAIL.placeholder}
                name={name}
                value={value}
                onChange={onChange}
                errors={errors}
                disabled
              />
            )}
          />
        </div>
        <div className='md:h-28 col-span-2'>
          <Controller
            control={control}
            name='streetAddress'
            rules={{
              required: PROFILE_COMPLETION_INPUT_ERRORS.STREET_ADDRESS.required,
            }}
            render={({ field }) => (
              <FormField
                type='text'
                labelCalssName='text-base'
                title={PROFILE_COMPLETION.STREET_ADDRESS.title}
                placeholder={PROFILE_COMPLETION.STREET_ADDRESS.placeholder}
                {...field}
                errors={errors}
                isRequired
              />
            )}
          />
        </div>
        <div className='md:h-28'>
          <Controller
            control={control}
            name='country'
            render={({ field: { onChange, value } }) => (
              <CountrySelect
                isRequired
                errors={errors}
                value={value}
                onChange={onChange}
                countryId={location.countryId}
                setCountryId={(newCountryId) =>
                  setLocation((prevLocation) => ({
                    ...prevLocation,
                    countryId: newCountryId,
                    stateId: 0,
                  }))
                }
                countryList={location.countryList}
                setStateList={(newStateList) =>
                  setLocation((prevLocation) => ({
                    ...prevLocation,
                    stateList: newStateList,
                  }))
                }
              />
            )}
          />
        </div>
        <div className='md:h-28'>
          <Controller
            control={control}
            name='state'
            render={({ field: { onChange, value } }) => (
              <StateSelect
                value={value}
                onChange={onChange}
                errors={errors}
                countryId={Number(countryById?.id)}
                stateList={location.stateList}
                setCityList={(newCityList) => {
                  setLocation((prevLocation) => ({
                    ...prevLocation,
                    cityList: newCityList,
                  }));
                }}
                setStateId={(newStateId) => {
                  setLocation((prevLocation) => ({
                    ...prevLocation,
                    stateId: newStateId,
                  }));
                }}
                stateId={location.stateId}
              />
            )}
          />
        </div>
        <div className='md:h-28'>
          <Controller
            control={control}
            name='city'
            render={({ field: { onChange, value } }) => (
              <CitySelect
                cityList={location?.cityList}
                onChange={onChange}
                value={value}
                errors={errors}
                cityId={location.cityId}
                setCityId={(newCityId) =>
                  setLocation((prevLocation) => ({
                    ...prevLocation,
                    cityId: newCityId,
                  }))
                }
              />
            )}
          />
        </div>
        <div className='md:h-28'>
          <Controller
            control={control}
            name='zipCode'
            rules={{
              required: PROFILE_COMPLETION_INPUT_ERRORS.ZIP_CODE.required,
              validate: {
                isNumeric: (value) =>
                  isNumeric(value) ||
                  PROFILE_COMPLETION_INPUT_ERRORS.ZIP_CODE_TYPE.acceptFormat,
              },
            }}
            render={({ field: { onChange, value, name } }) => (
              <FormField
                type='text'
                labelCalssName='text-base'
                title={PROFILE_COMPLETION.ZIP_CODE.title}
                placeholder={PROFILE_COMPLETION.ZIP_CODE.placeholder}
                name={name}
                value={value}
                onChange={onChange}
                isRequired
                errors={errors}
              />
            )}
          />
        </div>
      </div>
      <div className='md:w-10/12'>
        <EmployedFields
          errors={errors}
          control={control}
          setValue={setValue}
          empName={user?.nameOfEmployer}
          empJobTitle={user?.employerJobTitle}
          status={user?.currentlyEmployed}
        />
      </div>
    </div>
  );
};

export default EditPersonalInfo;
