import React, { useState } from 'react';

import { flexRender, Table } from '@tanstack/react-table';

import { cn } from 'src/lib/utils';

import { Checkbox } from './CellComponents';
import { IActualStocks } from './makeData';

const TableBody = ({
  table,
  loading = false,
}: {
  loading?: boolean;
  table: Table<IActualStocks>;
}) => {
  const [checkedRows, setCheckedRows] = useState<Record<string, boolean>>({});

  if (loading) {
    return (
      <tbody className='dashboard-table'>
        {Array.from({ length: 5 })?.map((_, idx) => (
          <tr
            key={idx}
            className='text-white border-transparentGreen bg-transparentGreen/5 border-2 animate-pulse'
          >
            {Array.from({ length: 15 })?.map((_, idx) => (
              <td
                key={idx}
                className={cn('py-2 my-2', {
                  'rounded-r-2xl': idx === 14,
                  'rounded-l-2xl': idx === 0,
                })}
              >
                <div className='h-2 bg-slate-700 rounded col-span-1 my-2' />
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    );
  }

  return (
    <tbody className='dashboard-table'>
      {table.getRowModel().rows.map((row) => {
        if (row.getIsPinned()) return null;
        return (
          <tr
            key={row.id}
            className='text-white border-transparentGreen bg-transparentGreen/5 border-2'
          >
            {row.getVisibleCells().map((cell, index: number, array) => (
              <td
                key={cell.id}
                className={cn('py-2 my-2', {
                  'rounded-r-2xl': index === array.length - 1,
                  'rounded-l-2xl': index === 0,
                })}
              >
                {cell.column.id === 'checkbox' ? (
                  <Checkbox
                    checked={checkedRows[row.id] || false}
                    setChecked={(checked) =>
                      setCheckedRows({
                        ...checkedRows,
                        [row.id]: checked,
                      })
                    }
                  />
                ) : (
                  <div className='flex justify-center'>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </div>
                )}
              </td>
            ))}
          </tr>
        );
      })}
    </tbody>
  );
};

export default TableBody;
