import { useCallback, useEffect, useMemo, useState } from 'react';
import { GetCity, GetState } from 'react-country-state-city';
import {
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormSetValue,
} from 'react-hook-form';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from 'src/constants/common';
import { COMMON_ERROR } from 'src/constants/feedback';
import { UserType } from 'src/providers/Auth/types';
import {
  createProfile,
  getAgreements,
  updateAggremtnt,
} from 'src/services/auth';
import { getPersonalInfo } from 'src/services/profile';
import {
  BASIC_INFO,
  CREATE_PROFILE_PAYLOAD,
  IAgreement,
  USER_TYPE,
} from 'src/services/types';
import { setUserToLS } from 'src/utils/common';

import useAuthContext from './useAuth';
import { useGetLocation } from './useLocation';

const useSubmitProfile = (
  setValue: UseFormSetValue<BASIC_INFO>,
  reset: () => void,
  incrementStep: () => void,
  step: number,
  handleSubmit: UseFormHandleSubmit<BASIC_INFO, undefined>,
) => {
  const navigate = useNavigate();
  const { location, setLocation } = useGetLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [agreementList, setAgreementList] = useState<IAgreement[]>([]);
  const [currentUseData, setCurrentUseData] = useState<UserType>();
  const { auth, setAuth } = useAuthContext();

  const onSubmit: SubmitHandler<BASIC_INFO> = useCallback(
    async (data: CREATE_PROFILE_PAYLOAD) => {
      const country = location.countryList[parseInt(data.country)];
      const states = await GetState(country?.id);
      const state = states[parseInt(data.state)];
      const cities = await GetCity(country?.id, state?.id);
      const city = cities[parseInt(data.city)];
      const payload = {
        country: country?.name || '',
        state: state?.name || '',
        city: city?.name || '',
        avatar: data.avatar,
        firstName: data.firstName,
        lastName: data.lastName,
        middleName: data.middleName,
        userType: data.userType,
        zipCode: data.zipCode,
        streetAddress: data.streetAddress,
        currentlyEmployed: data.currentlyEmployed,
        nameOfEmployer: data.nameOfEmployer,
        employerJobTitle: data.employerJobTitle,
      };
      const response = await createProfile(payload);
      if (response) {
        setAuth((prevAuth) => {
          if (!prevAuth) return prevAuth;
          return {
            ...prevAuth,
            user: {
              ...prevAuth.user,
              isProfileCompleted: true,
            },
            token: String(prevAuth.token),
          };
        });
        const updatedUser: UserType = {
          ...(auth?.user as UserType),
          isProfileCompleted: true,
        };
        setUserToLS(updatedUser);
        reset();
        navigate(`${ROUTES.PROFILE_COMPLETION}?step=${step + 1}`);
        incrementStep();
      }
    },
    [location, reset, navigate, incrementStep, step],
  );

  const fetchAgreements = async () => {
    try {
      setIsLoading(true);
      const response = await getAgreements();
      if (response) {
        setAgreementList(response?.data);
      }
      setIsLoading(false);
    } catch (error) {
      toast.error(COMMON_ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  const getProfile = async () => {
    try {
      const response = await getPersonalInfo();
      if (response) {
        setCurrentUseData(response.data);
      }
    } catch (error) {
      toast.error(COMMON_ERROR);
    }
  };
  useEffect(() => {
    if (auth?.token) {
      getProfile();
    }
  }, [auth, step]);

  useEffect(() => {
    if (currentUseData) {
      setValue('avatar', currentUseData?.avatar || null);
      setValue('firstName', currentUseData?.firstName || '');
      setValue('middleName', currentUseData?.middleName || '');
      setValue('lastName', currentUseData?.lastName || '');
      setValue('streetAddress', currentUseData?.streetAddress || '');
      setValue('currentlyEmployed', currentUseData?.currentlyEmployed || false);
      setValue('zipCode', currentUseData?.zipCode || '');
      setValue('nameOfEmployer', currentUseData?.nameOfEmployer || '');
      setValue('employerJobTitle', currentUseData?.employerJobTitle || '');
      setValue('userType', currentUseData?.userType || USER_TYPE.INDVIDUAL);
    }
  }, [currentUseData]);

  const countryById = location.countryList.find(
    (country: { name: string }) => country.name === currentUseData?.country,
  );

  const fetchStateAndCity = async () => {
    const states = await GetState(countryById?.id);
    const stateIndex = states.findIndex(
      (state: { name: string }) => state.name === currentUseData?.state,
    );
    const cities = await GetCity(countryById?.id, states[stateIndex]?.id);
    const cityIndex = cities.findIndex(
      (city: { name: string }) => city.name === currentUseData?.city,
    );
    setLocation((prevLocation) => ({
      ...prevLocation,
      stateList: states,
      stateId: stateIndex,
      cityList: cities,
      cityId: cityIndex,
    }));
    setValue('state', String(stateIndex));
    setValue('city', String(cityIndex));
  };

  const fullLocation = useMemo(() => {
    const {
      streetAddress = '',
      city = '',
      state = '',
      country = '',
    } = currentUseData || {};
    const locationParts = [streetAddress, city, state, country].filter(Boolean);
    return locationParts.join(', ');
  }, [currentUseData]);

  useEffect(() => {
    if (countryById) {
      setValue('country', String(countryById.id - 1));
      fetchStateAndCity();
    }
  }, [countryById, step, setValue]);

  const handleNext = async () => {
    setButtonLoading(true);
    switch (step) {
      case 1:
        handleSubmit(onSubmit)();
        setButtonLoading(false);
        break;
      case 2:
        {
          const now = new Date().toString();
          const agreementListData = agreementList.map((item, index) => {
            return {
              name: `agreement-${index}`,
              value: item.description
                .replace(
                  /\/\*name\*\//g,
                  `${currentUseData?.firstName} ${currentUseData?.lastName}`,
                )
                .replace(/\/\*location\*\//g, fullLocation),
            };
          });
          const response = await updateAggremtnt({
            agreementDate: now,
            agreementList: agreementListData,
          });
          if (response) {
            reset();
            navigate(`${ROUTES.PROFILE_COMPLETION}?step=${step + 1}`);
            setAuth((prevAuth) => {
              if (!prevAuth) return prevAuth;
              return {
                ...prevAuth,
                user: {
                  ...prevAuth.user,
                  isAgreement: true,
                },
                token: String(prevAuth.token),
              };
            });
            setButtonLoading(false);
            const updatedUser: UserType = {
              ...(auth?.user as UserType),
              isAgreement: true,
            };
            setUserToLS(updatedUser);
            incrementStep();
          }
        }
        break;
      case 3:
        incrementStep();
        navigate(`${ROUTES.PROFILE_COMPLETION}?step=${step + 1}`);
        setButtonLoading(false);
        break;
      default:
        break;
    }
  };

  return {
    onSubmit,
    fetchAgreements,
    isLoading,
    agreementList,
    getProfile,
    currentUseData,
    handleNext,
    location,
    setLocation,
    fullLocation,
    buttonLoading,
  };
};

export default useSubmitProfile;
