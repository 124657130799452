export const DASHBOARD_TEXT = {
  SCREENER: 'My Presets',
  TITLE: 'Screener',
  SELECT: 'Select',
  HISTORICAL_FILTER: 'Historical Filters',
  CALCULATOR: 'Optimal Allocation Wiz',
  REQUIRED: 'This field is required',
  DAYS_REMAINEG: 'Days Remaining',
  RISKYIELD: 'Risk Yield Factor',
  TICKER_FIELD: 'Ticker',
  ASSET_PRICE: 'Strike',
  EXPIRATION_DATE: 'Expiration Date',
  EXPW: 'Belowstrike',
  PROFILT_PROBABILITY_SHORT: 'Profit Probability Short',
  PROFILT_PROBABILITY_LONG: 'Profit Probability Long',
  CALL_PUT_RATIO: 'Call Put Ratio',
  PNL: 'PnL',
  WEEK: 'Week',
  FIRST_PUB_PRICE: 'First Published Premium',
  FIRST_PUB_AT: 'First Published At',
  EXCLUDE_INSIDER_SELL_FOR: 'Exclude Insider Sell for X # of days',
  INCL_INSIDER_PURCH_FOR: 'Incl. Insider Purch. for X # of days',
  SECTOR: 'Sector',
  INDUSTRY: 'Industry',
  MARKET_CAP: 'Market Cap',
  SELECT_PLAN: 'Select Plan',
  PLAN_MODAL_TEXT: 'You must have to select premium plan to avail this feature',
  PE: 'P/E',
  COUNTRY: 'Country',
  FIELD: 'Field',
  AMOUNT: 'Amount',
  MIN_SECURITY: 'Min # of Security(ties)',
  CONTRACT_PER_SECURITIES: 'Min # of Contracts per Security(ties)',
  MAX_WEIGHTAGE_PER_SECURITY: 'Max Wgt % per Security(ties)',
  CONTRACT_TO_PICK: 'Contracts to Pick',
  RESET_FILTER: 'Reset Filters',
  APPLY_FILTER: 'Apply Filters',
  TYPE_HERE: 'Type here',
  YIELD_BY_DAY: 'Yield by Day',
  YIELD_BY_CONTRACT: 'Yield by Contract',
  SAVE_FILTER: 'Save Filter',
  PROFIT_PROBABILITY: 'Profit Probability',
  BELOWSTRIKE: 'Belowstrike',
  BELOWSTRIKE_YES: 'Belowstrike (Yes)',
  BELOWSTRIKE_NO: 'Belowstrike (No)',
  GRAND_TOTAL: 'Grand Total',
  YES: 'Yes',
  NO: 'No',
  VALUE_SUM_OF: 'PnL',
  LAST_BATCH: 'Last Batch',
  CHART_SECTION_TEXT:
    '* Assuming one contract per security published simultaneously, profitability is based on the initial premium. Contracts are held until expiration, with shares sold at the closing price if assigned',
};
export const SCREENER_ITEMS = [
  { value: 'screenerOne', name: 'Screener One' },
  { value: 'screenerTwo', name: 'Screener Two' },
  { value: 'screenerThree', name: 'Screener Three' },
];
export const DELETE_FILTER_MODAL = {
  title: 'Delete Filter',
  description: [
    {
      text: 'Are you sure you want to delete this filter ?',
      type: 'heading',
    },
  ],
  btnText: 'Confirm',
};
export const SAVE_UPDATE_FILTER_MODAL = {
  save: {
    title: 'Save Filter',
  },
  update: {
    title: 'Update Filter',
  },
  label: 'Filter Name',
  placeholder: 'Type Here',
  secondLabel: 'Selected Filter',
  thirdLabel: 'No Filters Selected',
};
export const CONTRACT_OPTION_DUMMY_TEXT = [
  { value: 10, name: '10' },
  { value: 20, name: '20' },
  { value: 30, name: '30' },
];

export const PNL_OPTION_VALUES = [
  { value: 1, name: 'Above Zero' },
  { value: 0, name: 'Equal to Zero' },
  { value: 0.5, name: 'Less than Zero' },
];
export const BELOWSTRIKE_OPTION_VALUES = [
  { value: 'yes', name: 'Yes' },
  { value: 'no', name: 'No' },
  { value: 'notExpired', name: 'Not Expired' },
];
export const CALL_PUT_RATIO_VALUES = [
  { value: '<', name: 'Less than' },
  { value: '<=', name: 'Less than or equal to' },
  { value: '>', name: 'Greater than' },
  { value: '>=', name: 'Greater than or equal to' },
  { value: '=', name: 'Equal to' },
];

export enum FIELDS_NAME {
  COUNTRY = 'Country',
  SECTOR = 'Sector',
  INDUSTRY = 'Industry',
  MARKET_CAP = 'Market Capitalization',
}

export const TABLE_NOTE = {
  title: '• Note:',
  desc: 'The data below may be delayed',
};
export const EXPORT_BTN = 'Export';
export const ROOWS = 'Rows per page:';

export const SORTING_ITEMS = [
  { value: 'all', label: 'All' },
  { value: 'first', label: 'Pin First' },
  { value: 'last', label: 'Pin Last' },
];
export const PAGE_SIZE_OPTION = [
  { value: 10, label: '10' },
  { value: 30, label: '30' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
];
export enum FiltersKeyName {
  DAYS_REMAINING = 'daysRemaining',
  RISKYIELD = 'riskYield',
  TICKER_FIELD = 'tickerFiled',
  ASSET_PRICE = 'assetPrice',
  EXPIRATION_DATE = 'expiratonDate',
  EXCLUDE_INSIDER_SELL_FOR = 'excludeInsiderSellFor',
  INCLUDE_INSIDER_PURCH_FOR = 'includeInsiderPurchFor',
  SECTOR = 'sector',
  INDUSTRY = 'industry',
  MARKET_CAP = 'marketCap',
  PRICE_TO_EARNINGS_RATIO = 'priceToEarningsRatio',
  COUNTRY = 'country',
  PROFIT_PROBABILITY_SHORT = 'profitProbabilityShort',
  PROFIT_PROBABILITY_LONG = 'profitProbabilityLong',
  CALL_PUT_RATIO = 'callPutRatio',
  EXPW = 'expw',
  PNL = 'pnl',
  WEEK = 'week',
  FIRST_PUB_PRICE = 'firstPubPrice',
  FIRST_PUB_AT = 'firstPubAt',
  AMOUNT = 'amount',
  MIN_SECURITY = 'minOfSecurity',
  CONTRACT_PER_SECURITY = 'contractPerSecurity',
  MAX_WEIGHTAGE_PER_SECURITY = 'maxWeightagePerSecurity',
  CONTRACT_TO_PICK = 'contractsToPick',
}
export enum RISKYIELD_OPTIONS {
  YIELD_BY_DAY = 'yieldbyDay',
  YIELD_BY_CONTRACT = 'yieldByContract',
}
export enum FILTER_LABEL_ENUM {
  tickerFiled = 'Ticker Field',
  amount = 'Amount',
  country = 'Country',
  screener = 'Screener',
  daysRemaining = 'Days Remaining',
  riskYield = 'RiskYield Factor',
  assetPrice = 'Asset price',
  expiratonDate = 'Expiration Date',
  expw = 'ExpW/OExer',
  pnl = 'PnL',
  week = 'Week',
  firstPubPrice = 'FirstPubPrice',
  firstPubAt = 'FirstPubAt',
  excludeInsiderSellFor = 'Exclude Insider Sell for X # of days',
  includeInsiderPurchFor = 'Incl. Insider Purch. for X # of days',
  sector = 'Sector',
  industry = 'Industry',
  marketCap = 'Market Cap',
  priceToEarningsRatio = 'P/E',
  minSecurity = 'Min # of Securities',
  contractPerSecurities = 'Min # of contracts per securities',
  maxWeightagePerSecurity = 'Max Weightage % per Security',
  contractToPick = 'Contracts to Pick',
  callPutRatio = 'Call Put ratio',
  profitProbabilityLong = 'Profit Probability Long',
  profitProbabilityShort = 'Profit Probability Short ',
}

export enum TableFilterType {
  liveFlow = 'liveFlow',
  historicalFlow = 'historicalFlow',
}
export enum TABLE_COLUMN_ENUM {
  YIELD = 'Yield',
  RETURN_PROBABILITY = 'Return Probability',
}
export const TABLE_HEADING_TABS = {
  LIVE_FLOW: {
    title: 'Live Flow',
    key: TableFilterType.liveFlow,
  },
  HISTORICAL_FLOW: {
    title: 'Historical Flow',
    key: TableFilterType.historicalFlow,
  },
};

export const NO_SCREENERS = { name: 'No Screeners Available', value: 'noData' };
export const ALL_FIELDS_REQUIRED =
  'All calculator fields must be filled or all must be empty.';

export const DASHBOARD_INFO_TEXT = {
  SCREENER: 'My Presets',
  SELECT: 'Select',
  HISTORICAL_FILTER: 'Historical Filters',
  CALCULATOR: 'Calculator',
  REQUIRED: 'This field is required',
  DAYS_REMAINEG:
    "This field indicates the number of days left before the current contract expires. It is calculated from today's date to the contract's end date, providing a countdown to the expiration.",
  RISKYIELD:
    'Yield by Days: Multiply the yield by the probability of profit and then divide by the days remaining.',
  TICKER_FIELD: 'This is the Stock symbbol of underlying security',
  ASSET_PRICE: 'This is the stike of option',
  EXPIRATION_DATE: 'This is the expiration date of option',
  EXPW: "This field indicates whether the underlying stock's closing price was below the strike price of the contract.",
  PROFILT_PROBABILITY_LONG: '',
  CALL_PUT_RATIO: '',
  PNL: 'If the stock closes above the strike price: You retain the entire premium as profit. If the stock closes below the strike price: The profit or loss is calculated as follows: (Closing price of the stock + Premium) - Strike price. This assumes that any stocks assigned due to the stock price being below the strike price are sold at the closing price.',
  WEEK: 'This field indicates the week when the contract appears on your live flow based on your screener. It helps track the timing of when specific contracts become relevant and actionable in your trading or monitoring activities.',
  FIRST_PUB_PRICE:
    'This field indicates the premuim when the contract first appeared on your live flow or during the specified week',
  FIRST_PUB_AT:
    'This field indicates the time and date when the contract first appeared on your live flow or during the specified week',
  EXCLUDE_INSIDER_SELL_FOR:
    'This field indicates the number of days in the past that need to be considered when analyzing insider selling activities',
  INCL_INSIDER_PURCH_FOR:
    'This field indicates the number of days in the past that need to be considered when analyzing insider buying activities',
  SECTOR: 'The sector that the stocks belong',
  INDUSTRY: 'The industry that the stocks belong',
  MARKET_CAP: 'The market capitaliztion of the stocks',
  PE: 'Price to Earning Ratio',
  COUNTRY: "Counrty to which the stock's company belong",
  AMOUNT:
    'The amount that needs to spread among the securities in an opmpital portfolio.',
  MIN_SECURITY:
    'This is the minimum number of stocks that needs to be considered to create the optimal porfolio',
  CONTRACT_PER_SECURITIES:
    'This is the minimum number of contracts for each stock that needs to be considered to create the optimal porfolio',
  MAX_WEIGHTAGE_PER_SECURITY:
    'Per Security: This field represents the maximum weightage percentage that each stock (comprising all of its contracts) can have in the optimal portfolio.',
  CONTRACT_TO_PICK:
    'This is the number of stocks from the top to bottom of the list, to be considered for the construction of the portfolio.',
  YIELD_BY_DAY:
    'Multiply the yield by the probability of profit and then divide by the days remaining.',
  YIELD_BY_CONTRACT:
    'Multiply the yield of the entire contract by the probability of profit.',
  FILL_IN_ALL_CALCULATOR_FIELDS: 'Fill in all previous fields first.',
};
export const LOADING = 'Loading...';
