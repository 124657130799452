import React, { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { ErrorMessage as HookFormErrorMessage } from '@hookform/error-message';

import FormField from 'src/components/FormField';
import withProtectedRoute from 'src/components/ProtectedRoute';
import { Typography } from 'src/components/Typography';
import { Button } from 'src/components/ui/button';
import { Textarea } from 'src/components/ui/textArea';
import { PROFILE_COMPLETION_INPUT_ERRORS } from 'src/constants/aterlogin';
import { CONTACT, INPUT_ERRORS } from 'src/constants/feedback';
import { createContact } from 'src/services/contact';
import { ContactFieldsType } from 'src/services/types';

const Contact = () => {
  const [loading, setLoading] = useState(false);

  const {
    control,
    reset,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<ContactFieldsType>({
    mode: 'all',
    defaultValues: {
      title: '',
      message: '',
    },
  });

  const handleContact: SubmitHandler<ContactFieldsType> = async (data) => {
    setLoading(true);
    const payload = {
      title: data.title,
      message: data.message,
    };
    try {
      const response = await createContact(payload);
      if (response) {
        reset();
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='bg-nightSky md:px-10 md:py-6 mt-20 mb-40 lg:px-20 p-6 lg:py-8 flex flex-col mx-auto w-11/12 md:w-7/12 lg:w-5/12 rounded-[18px]'>
      <Typography variant='subheading' className='self-center'>
        {CONTACT.TITLE}
      </Typography>
      <Controller
        control={control}
        name='title'
        rules={{
          required: INPUT_ERRORS.TITLE.required,
          maxLength: {
            value: 49,
            message: PROFILE_COMPLETION_INPUT_ERRORS.FULL_NAME_MAX_LENGTH,
          },
        }}
        render={({ field: { onChange, value, name } }) => (
          <FormField
            title={CONTACT.TITLE_LABLE}
            labelCalssName='text-base'
            placeholder={CONTACT.TITLE_PLACEHOLDER}
            name={name}
            value={value}
            onChange={onChange}
            errors={errors}
            maxLength={50}
          />
        )}
      />
      <Typography className='text-base mt-3 mb-2'>{CONTACT.MESSAGE}</Typography>
      <div className='relative'>
        <Controller
          control={control}
          name='message'
          rules={{
            required: INPUT_ERRORS.MESSAGE.required,
            maxLength: {
              value: 149,
              message: INPUT_ERRORS.MESSAGE.maxLength,
            },
          }}
          render={({ field: { onChange, value } }) => (
            <Textarea
              placeholder={CONTACT.PLACEHOLDER}
              className='resize-none text-white focus:outline-none focus:ring-2 focus:ring-white'
              maxLength={150}
              value={value}
              onChange={(e) => {
                onChange(e);
              }}
            />
          )}
        />
        <div className='h-8'>
          <span className='absolute bottom-0 right-0 mt-2 text-sm text-white'>
            {watch('message').length ?? 0}/150
          </span>
          <div className='flex justify-between'>
            <HookFormErrorMessage
              errors={errors}
              name='message'
              render={({ message }) => (
                <p className='mt-1 text-red_color md:mt-2'>{message}</p>
              )}
            />
          </div>
        </div>
      </div>
      <Button
        variant='gradient'
        onClick={handleSubmit(handleContact)}
        className='my-4 text-lg'
        disabled={loading}
      >
        {loading ? CONTACT.SUBMITTING : CONTACT.BUTTON_TEXT}
      </Button>
    </div>
  );
};

export default withProtectedRoute(Contact);
