import toast from 'react-hot-toast';

import { API } from 'src/api/ApiInstance';
import { COMMON_ERROR, COMMON_SUCCESS } from 'src/constants/feedback';
import { REST_SUB_URL } from 'src/constants/url';
import { UserType } from 'src/providers/Auth/types';

import {
  CardInfo,
  CurrentSubscription,
  PaymentHistory,
  ResponseType,
  UpdateProfileRequest,
  uploadImageRequest,
} from './types';

export const getPersonalInfo = async () => {
  const response = await API.Get<ResponseType<UserType>>(
    REST_SUB_URL.GET_PROFILE,
  );
  return response?.data;
};

export const getCardInfo = async () => {
  const response = await API.Get<ResponseType<CardInfo>>(
    REST_SUB_URL.GET_CARD_INFO,
  );
  return response?.data;
};

export const getCurrentSubscription = async () => {
  const response = await API.Get<ResponseType<CurrentSubscription>>(
    REST_SUB_URL.GET_CURRENT_SUBSCRIPTION,
  );
  return response?.data;
};

export const getPaymentHistory = async () => {
  const response = await API.Get<ResponseType<PaymentHistory>>(
    REST_SUB_URL.GET_PAYMENT_HISTORY,
  );
  return response?.data;
};

export const updateProfile = async (payload: UpdateProfileRequest) => {
  const formData = new FormData();
  const data: { [key: string]: any } = payload;
  Object.keys(payload).forEach((key) => {
    formData.append(key, data[key]);
  });
  const response = await API.Put<ResponseType<UserType>, FormData>(
    REST_SUB_URL.UPDATE_PROFILE,
    formData,
    true,
  );
  if (response.status) {
    toast.success(response?.data?.message || COMMON_SUCCESS);
    return response;
  }
  toast.error(response?.message || COMMON_ERROR);
  return response;
};

export const cancelSubscription = async (subscriptionID: string) => {
  const response = await API.Delete<any>(
    `${REST_SUB_URL.CANCEL_SUBSCRIPTION}/${subscriptionID}`,
  );
  if (response.status) {
    toast.success(response?.data?.message || COMMON_SUCCESS);
    return true;
  }
  toast.error(response?.message || COMMON_ERROR);
  return false;
};

export const updateProfilePicture = async (payload: uploadImageRequest) => {
  const formData = new FormData();
  formData.append('avatar', payload.image);
  const response = await API.Put<ResponseType<UserType>, FormData>(
    REST_SUB_URL.UPLOAD_PROFILE_PICTURE,
    formData,
    true,
  );
  if (response.status) {
    toast.success(response?.data?.message || COMMON_SUCCESS);
    return response;
  }
  toast.error(response?.data?.message || COMMON_ERROR);
  return response;
};
