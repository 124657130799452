/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import moment from 'moment-timezone';

import {
  ColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  RowPinningState,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';

import { Alert, SendMessage, SUB_LEVELS } from 'src/@types/dashboardTypes';
import BannerAlert from 'src/components/BannerAlert';
import Loader from 'src/components/Loader';
import { Typography } from 'src/components/Typography';
import { Button } from 'src/components/ui/button';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'src/components/ui/select';
import { NO_DATA_FOUND } from 'src/constants/common';
import {
  EXPORT_BTN,
  PAGE_SIZE_OPTION,
  ROOWS,
  TABLE_HEADING_TABS,
  TableFilterType,
} from 'src/constants/dashboard';
import { COMMON_ERROR } from 'src/constants/feedback';
import useAuthContext from 'src/hooks/useAuth';
import useDashboardContext from 'src/hooks/useDashboardData';
import { exportData } from 'src/services/Dashboard';

import LiveFlow from './Columns';
import { IActualStocks } from './makeData';
import Pagination from './Pagination';
import SortRow from './SortRow';
import TableBody from './TableBody';
import TableHead from './TableHead';

const StocksTable = ({
  selectedTableTab,
  data,
  currentPage,
  setCurrentPage,
  totalPages,
  handleChangePage,
  handleSelectChange,
  sendJsonMessage,
  isLoading,
  pageSize,
  setPageSize,
  sort,
  alert,
  lastJsonMessage,
}: {
  selectedTableTab: string;
  data: IActualStocks[];
  currentPage?: number;
  setCurrentPage?: React.Dispatch<React.SetStateAction<number>>;
  totalPages?: number | undefined;
  handleChangePage: (page: number, pageSize: number) => void;
  handleSelectChange: (value: string) => void;
  sendJsonMessage: (message: SendMessage) => void;
  isLoading: boolean;
  pageSize: number;
  setPageSize: (pageSize: number) => void;
  sort: string;
  lastUpdateTime: Date;
  alert: Alert | null;
  lastJsonMessage: any;
}) => {
  const { auth } = useAuthContext();
  const { user } = auth ?? {};
  const { screener, setScreener } = useDashboardContext();
  const { isPremiumOrAdmin } = useDashboardContext();
  const { columnsData } = LiveFlow({
    sendJsonMessage,
    selectedTableTab,
    sortValue: sort,
    lastJsonMessage,
  });
  const [rowPinning, setRowPinning] = useState<RowPinningState>({
    top: [],
  });
  const [loading, setLoading] = useState(false);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [sorterloading, setSorterLoading] = useState(false);

  const columnVisibility = useMemo(
    () => ({
      pnl: selectedTableTab !== TableFilterType.liveFlow,
      week: selectedTableTab !== TableFilterType.liveFlow,
      below_strike: selectedTableTab !== TableFilterType.liveFlow,
      firstP: selectedTableTab !== TableFilterType.liveFlow,
      days_to_expiration: selectedTableTab !== TableFilterType.historicalFlow,
    }),
    [selectedTableTab],
  );

  const table = useReactTable<IActualStocks>({
    columns: columnsData as ColumnDef<IActualStocks>[],
    data,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      rowPinning,
      sorting,
    },
    initialState: {
      pagination: {
        pageSize: 10,
      },
      columnVisibility,
    },
    manualSorting: true,
    onSortingChange: setSorting,
    onRowPinningChange: setRowPinning,
    defaultColumn: {
      size: 80,
      minSize: 10,
      maxSize: 300,
    },
  });

  useEffect(() => {
    if (table.setColumnVisibility) {
      table.setColumnVisibility(columnVisibility);
    }
  }, [columnVisibility, table]);

  const handleSelect = (value: string) => {
    handleSelectChange(value);
  };
  const handlePageSize = (value: string) => {
    setPageSize(Number(value));
    table.setPageSize(Number(value));
    if (selectedTableTab === TABLE_HEADING_TABS.HISTORICAL_FLOW.key) {
      sendJsonMessage({
        action: 'requested_historical_data',
        userId: user?._id,
        page: 1,
        sort: 'all',
        filters: screener,
        limit: Number(value),
      });
    } else {
      sendJsonMessage({
        action: 'request_paginated_data',
        userId: user?._id,
        page: 1,
        sort: 'all',
        filters: screener,
        limit: Number(value),
      });
    }
  };

  const exportButtonClick = async () => {
    try {
      setLoading(true);
      await exportData(
        screener ?? { filter: {}, calculator: {}, sorter: {} },
        sort,
        !(selectedTableTab === TABLE_HEADING_TABS.HISTORICAL_FLOW.key),
      );
      setLoading(false);
    } catch (error) {
      toast.error(COMMON_ERROR);
    }
  };

  useEffect(() => {
    const type =
      selectedTableTab === TABLE_HEADING_TABS.HISTORICAL_FLOW.key
        ? 'requested_historical_data'
        : 'request_paginated_data';

    let screenerWithSorter = { sorter: {} };

    if (sorting?.length) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line no-unsafe-optional-chaining
      const { id, desc } = sorting?.[0];
      const order = desc ? 'desc' : 'asc';

      screenerWithSorter = {
        ...screener,
        sorter: { column: id, order },
      };
    } else {
      screenerWithSorter = {
        ...screener,
        sorter: {},
      };
    }

    if (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      screenerWithSorter?.sorter?.column === screener?.sorter?.column &&
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      screenerWithSorter?.sorter?.order === screener?.sorter?.order
    ) {
      return; // no need to sort again
    }
    setSorterLoading(true);
    setScreener(screenerWithSorter);

    sendJsonMessage({
      action: type,
      userId: user?._id,
      page: 1,
      sort: 'all',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      filters: screenerWithSorter,
      limit: Number(pageSize),
    });

    setTimeout(() => {
      setSorterLoading(false);
    }, 2000);
  }, [sorting]);

  const updatedAt = useMemo(() => {
    return moment.utc(alert?.updatedAt).local().format("MMM D, 'YY h:mm A");
  }, [alert?.updatedAt]);

  const now = moment().tz('America/New_York'); // Current time in EST

  // Define the cutoff times for today
  const todayStart = now
    .clone()
    .startOf('day')
    .set({ hour: 6, minute: 0, second: 0 }); // 6:00 AM EST today
  const todayEnd = now.clone().set({ hour: 16, minute: 45, second: 0 }); // 4:45 PM EST today
  const nextDayStart = now
    .clone()
    .add(1, 'days')
    .startOf('day')
    .set({ hour: 6, minute: 15, second: 0 }); // 6:15 AM EST tomorrow

  let refreshDate;

  // Check if it's a weekend (Saturday or Sunday)
  if (now.day() === 6) {
    // Saturday: Add 2 days to get to Monday
    refreshDate = now
      .clone()
      .add(2, 'days')
      .startOf('day')
      .set({ hour: 6, minute: 15, second: 0 })
      .format('MM/DD/YYYY');
  } else if (now.day() === 0) {
    // Sunday: Add 1 day to get to Monday, regardless of current time
    refreshDate = now
      .clone()
      .add(1, 'days')
      .startOf('day')
      .set({ hour: 6, minute: 15, second: 0 })
      .format('MM/DD/YYYY');
  } else {
    // It's a weekday, follow the normal logic
    if (now.isBefore(todayStart)) {
      // If the current time is before 6:00 AM, show today's date
      refreshDate = todayStart.format('MM/DD/YYYY');
    } else if (now.isAfter(todayEnd)) {
      // If the current time is after 4:45 PM, show the next day's date
      refreshDate = nextDayStart.format('MM/DD/YYYY');
    } else {
      // Between 6:00 AM and 4:45 PM, show today's date
      refreshDate = todayStart.format('MM/DD/YYYY');
    }
  }

  // Determine the message to show based on the current time and day
  let message;
  if (now.day() === 6 || now.day() === 0) {
    // If it's Saturday or Sunday, show the weekend message
    message = `The Options Market is currently closed. Data will be refreshed after 6:00 AM EST on ${refreshDate} - Last Refreshed At: ${updatedAt}`;
  } else if (now.isBetween(todayStart, todayEnd, null, '[]')) {
    // Between 6:00 AM and 4:45 PM on weekdays
    message = `${alert?.message} - Last Refreshed At: ${updatedAt}`;
  } else {
    // Between 4:45 PM and 6:00 AM the next day on weekdays
    message = `The Options Market is currently closed. Data will be refreshed after 6:00 AM EST on ${refreshDate} - Last Refreshed At: ${updatedAt}`;
  }

  return (
    <div>
      <div className='px-4 flex items-center flex-col md:flex-row justify-between'>
        <div className='flex justify-start w-full'>
          <BannerAlert
            loading={isLoading}
            alertType={alert?.subLevels || SUB_LEVELS.NORMAL}
            text={message}
          />
        </div>
        <div className='flex w-full justify-end items-center gap-5 mt-3 md:mt-0'>
          {isPremiumOrAdmin ? (
            <Button
              onClick={exportButtonClick}
              disabled={!data.length || loading}
              className='text-base cursor-pointer text-white p-0 bg-transparent hover:bg-transparent'
            >
              {loading ? <Loader /> : EXPORT_BTN}
            </Button>
          ) : null}

          <SortRow value={sort} handleSelect={handleSelect} />
        </div>
      </div>
      <div className='px-4 block max-w-full noScrollBar overflow-x-scroll overflow-y-hidden'>
        <table className='w-full border-separate border-spacing-y-3'>
          <TableHead table={table} loading={sorterloading} />
          <TableBody table={table} loading={isLoading} />
        </table>
      </div>
      {data?.length > 0 && !isLoading ? (
        <div className='flex flex-col md:flex-row mt-3 md:mt-0 md:justify-between '>
          <div className='flex mx-4 items-center gap-2 mb-3 md:mb-0'>
            <Typography className='text-base'>{ROOWS}</Typography>
            <Select value={pageSize.toString()} onValueChange={handlePageSize}>
              <SelectTrigger className='w-24 rounded-2xl border-none focus:ring-0 focus:ring-offset-0 bg-darkGray text-white focus:bg-darkGray'>
                <SelectValue placeholder='10' />
              </SelectTrigger>
              <SelectContent className='bg-darkGray rounded-2xl'>
                <SelectGroup>
                  {PAGE_SIZE_OPTION.map(
                    (item: { label: string; value: number }) => (
                      <SelectItem
                        className='rounded-lg px-2 hover:text-darkGray text-white hover:!font-medium'
                        key={item.value}
                        value={String(item.value)}
                      >
                        {item.label}
                      </SelectItem>
                    ),
                  )}
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
          <Pagination
            table={table}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage ?? 1}
            totalPages={totalPages ?? 1}
            handleChangePage={handleChangePage}
            pageSize={pageSize}
          />
        </div>
      ) : !isLoading ? (
        <div className='text-center'>
          <Typography className='text-md my-2'>{NO_DATA_FOUND}</Typography>
        </div>
      ) : null}
    </div>
  );
};

export default StocksTable;
