import React from 'react';
import { FieldErrors } from 'react-hook-form';
import { RiArrowDownSLine } from 'react-icons/ri';

import { ErrorMessage as HookFormErrorMessage } from '@hookform/error-message';

import { Typography } from 'src/components/Typography';
import { cn } from 'src/lib/utils';

export type CountryType = {
  id: number;
  sortname: string;
  name: string;
  phonecode: number;
};
export type StatesType = {
  id: number;
  name: string;
  country_id: number;
};
export type CityType = {
  id: number;
  name: string;
  state_id: number;
};
interface FormFieldProps {
  title?: string;
  placeholder?: string;
  labelCalssName?: string;
  name?: string;
  value?: string | number;
  onChange?: any;
  errors?: FieldErrors;
  dataList?: CountryType[] | StatesType[] | CityType[];
  isDisabled?: boolean;
  isRequired?: boolean;
}

const FormFieldSelect: React.FC<FormFieldProps> = ({
  title,
  placeholder,
  name,
  value,
  labelCalssName,
  onChange,
  errors,
  dataList,
  isDisabled,
  isRequired,
}) => {
  return (
    <>
      <label className='flex flex-col my-2 relative'>
        <Typography className={cn('my-2 text-base', labelCalssName)}>
          {title}
          {isRequired ? (
            <span className='text-red_color text-xl ml-1'>*</span>
          ) : null}
        </Typography>
        <div className='relative'>
          <select
            disabled={isDisabled}
            placeholder={placeholder}
            className='flex h-10 w-full text-white rounded-xl border-none border-slate-200 bg-darkGray px-3 py-2 text-md ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-lightGray focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:!cursor-pointer disabled:opacity-50 dark:border-slate-800 dark:bg-darkGray dark:ring-offset-slate-950 dark:placeholder:text-lightGray dark:focus-visible:ring-slate-300 '
            value={value}
            onChange={onChange}
          >
            {dataList?.length &&
              dataList.map((item: { name: string }, index: number) => (
                <option key={index} value={index}>
                  {item.name}
                </option>
              ))}
          </select>
          <RiArrowDownSLine
            size={20}
            className='absolute bottom-2.5 right-2.5'
            color='white'
          />
        </div>
        <HookFormErrorMessage
          errors={errors}
          name={String(name)}
          render={({ message }) => (
            <p className='text-red_color mt-1 md:mt-2'>{message}</p>
          )}
        />
      </label>
    </>
  );
};

export default FormFieldSelect;
