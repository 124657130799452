import React, { useEffect, useState } from 'react';

import { DropDownListType, ICalculator } from 'src/@types/dashboardTypes';
import Divider from 'src/components/Divider';
import SelectDropdown from 'src/components/SelectDropdown';
import { Typography } from 'src/components/Typography';
import {
  CONTRACT_OPTION_DUMMY_TEXT,
  DASHBOARD_INFO_TEXT,
  DASHBOARD_TEXT,
  FiltersKeyName,
} from 'src/constants/dashboard';
import useDashboardContext from 'src/hooks/useDashboardData';

import FiltersHeading from '../FiltersHeading';
import FilterTextInput from '../FilterTextInput';
interface ICalculatorFilter {
  handleOpen: () => void;
}
const Calculator: React.FC<ICalculatorFilter> = ({ handleOpen }) => {
  const { handleFilterChange, screener, isPremiumOrAdmin, calculatorError } =
    useDashboardContext();
  const onChangeValues = (
    name: string,
    value: string | number | DropDownListType | Date,
  ) => {
    handleFilterChange(name, value);
    if (name === FiltersKeyName.CONTRACT_PER_SECURITY && value === '1') {
      handleFilterChange(FiltersKeyName.MAX_WEIGHTAGE_PER_SECURITY, 100);
    }
  };
  const [validationMessages, setValidationMessages] = useState<
    Record<string, string>
  >({
    amount: '',
    minOfSecurity: '',
    contractPerSecurity: '',
    maxWeightagePerSecurity: '',
    contractToPick: '',
  });

  useEffect(() => {
    const calculator: ICalculator = screener?.calculator || {};
    if (!calculator || Object.keys(calculator).length === 0) {
      setValidationMessages({
        amount: '',
        minOfSecurity: '',
        contractPerSecurity: '',
        maxWeightagePerSecurity: '',
        contractToPick: '',
      });
      return;
    }
    const fields: Array<keyof ICalculator> = [
      'amount',
      'minOfSecurity',
      'contractPerSecurity',
      'maxWeightagePerSecurity',
      'contractsToPick',
    ];
    const newMessages: Record<string, string> = {};
    for (const field of fields) {
      newMessages[field] =
        calculator[field] !== undefined ? '' : DASHBOARD_TEXT.REQUIRED;
    }
    setValidationMessages(newMessages);
  }, [screener]);

  const isFieldDisabled = (fields: Array<keyof ICalculator>) => {
    return fields.some((field) => !screener?.calculator?.[field]);
  };

  /**
   * 1. Pre-requisite fields is entered then next field will be enabled.
   * 2. Min # of Security(ties) : Range (1 to <Contracts to pick>)
   * 3. Min # of Contracts : Range (1 to n)
   * 4. Max. Wgh % per Security(ties) : If <Min # of Security(ties)> is 1 then this should be 100% else < 100%
   */
  return (
    <div>
      <div className='py-5'>
        <FiltersHeading text={DASHBOARD_TEXT.CALCULATOR} />
      </div>
      <Divider className='border-b-2' />
      <div className='pt-5 pb-1 grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-x-3 gap-y-3 lg:gap-x-7'>
        <FilterTextInput
          label={DASHBOARD_TEXT.AMOUNT}
          placeholder={DASHBOARD_TEXT.TYPE_HERE}
          infoText={DASHBOARD_INFO_TEXT.AMOUNT}
          inputClassName='mt-2'
          handleOpen={handleOpen}
          keyName={FiltersKeyName.AMOUNT}
          onChange={onChangeValues}
          value={screener?.calculator?.amount}
          type='number'
          errorMessage={validationMessages[FiltersKeyName.AMOUNT]}
          labelClassName='h-8 flex items-start'
          isDisabled={!isPremiumOrAdmin}
          isPremium={!isPremiumOrAdmin}
        />
        <div className='flex flex-col gap-y-1 md:mt-2 !mt-0'>
          <SelectDropdown
            labelClassName='h-8 flex items-start mb-3'
            items={CONTRACT_OPTION_DUMMY_TEXT}
            inputClassName='w-full mt-2'
            handleOpen={handleOpen}
            label={DASHBOARD_TEXT.CONTRACT_TO_PICK}
            infoText={DASHBOARD_INFO_TEXT.CONTRACT_TO_PICK}
            hasInputFields
            minValue={1}
            keyName={FiltersKeyName.CONTRACT_TO_PICK}
            onChange={onChangeValues}
            value={screener?.calculator?.contractsToPick}
            isDisabled={
              isFieldDisabled([FiltersKeyName.AMOUNT]) || !isPremiumOrAdmin
            }
            isPremium={!isPremiumOrAdmin}
            calculatorToolTipText={
              isPremiumOrAdmin && !screener?.calculator.amount
                ? DASHBOARD_INFO_TEXT.FILL_IN_ALL_CALCULATOR_FIELDS
                : ''
            }
          />
          {validationMessages[FiltersKeyName.CONTRACT_TO_PICK] ? (
            <span className='text-red_color'>
              {validationMessages[FiltersKeyName.CONTRACT_TO_PICK]}
            </span>
          ) : null}
        </div>
        <FilterTextInput
          label={DASHBOARD_TEXT.MIN_SECURITY}
          maxValue={
            typeof screener?.calculator.contractsToPick === 'object'
              ? screener?.calculator.contractsToPick?.value
              : screener?.calculator.contractsToPick
          }
          minValue={1}
          handleOpen={handleOpen}
          labelClassName='h-8 flex items-start'
          placeholder={DASHBOARD_TEXT.TYPE_HERE}
          infoText={DASHBOARD_INFO_TEXT.MIN_SECURITY}
          inputClassName='mt-2'
          keyName={FiltersKeyName.MIN_SECURITY}
          onChange={onChangeValues}
          value={screener?.calculator?.minOfSecurity}
          type='number'
          calculatorToolTipText={
            isPremiumOrAdmin &&
            (!screener?.calculator.amount ||
              !screener.calculator.contractsToPick)
              ? DASHBOARD_INFO_TEXT.FILL_IN_ALL_CALCULATOR_FIELDS
              : ''
          }
          isDisabled={
            isFieldDisabled([
              FiltersKeyName.AMOUNT,
              FiltersKeyName.CONTRACT_TO_PICK,
            ]) || !isPremiumOrAdmin
          }
          isPremium={!isPremiumOrAdmin}
          errorMessage={
            isFieldDisabled([
              FiltersKeyName.AMOUNT,
              FiltersKeyName.CONTRACT_TO_PICK,
            ])
              ? ''
              : validationMessages[FiltersKeyName.MIN_SECURITY]
          }
        />
        <FilterTextInput
          label={DASHBOARD_TEXT.CONTRACT_PER_SECURITIES}
          labelClassName='h-8 flex items-start'
          minValue={1}
          handleOpen={handleOpen}
          placeholder={DASHBOARD_TEXT.TYPE_HERE}
          infoText={DASHBOARD_INFO_TEXT.CONTRACT_PER_SECURITIES}
          inputClassName='mt-2'
          keyName={FiltersKeyName.CONTRACT_PER_SECURITY}
          onChange={onChangeValues}
          value={screener?.calculator?.contractPerSecurity}
          type='number'
          errorMessage={
            isFieldDisabled([
              FiltersKeyName.AMOUNT,
              FiltersKeyName.CONTRACT_TO_PICK,
              FiltersKeyName.MIN_SECURITY,
            ])
              ? ''
              : validationMessages[FiltersKeyName.CONTRACT_PER_SECURITY]
          }
          isDisabled={
            isFieldDisabled([
              FiltersKeyName.AMOUNT,
              FiltersKeyName.CONTRACT_TO_PICK,
              FiltersKeyName.MIN_SECURITY,
            ]) || !isPremiumOrAdmin
          }
          isPremium={!isPremiumOrAdmin}
          calculatorToolTipText={
            isPremiumOrAdmin &&
            (!screener?.calculator.amount ||
              !screener.calculator.contractsToPick ||
              !screener.calculator.minOfSecurity)
              ? DASHBOARD_INFO_TEXT.FILL_IN_ALL_CALCULATOR_FIELDS
              : ''
          }
        />
        {calculatorError ? (
          <Typography className='text-red-600 justify-end items-end w-full flex lg:hidden'>
            Status: {calculatorError} (please adjust your contracts/security)
          </Typography>
        ) : (
          ''
        )}
        <FilterTextInput
          handleOpen={handleOpen}
          label={DASHBOARD_TEXT.MAX_WEIGHTAGE_PER_SECURITY}
          placeholder={DASHBOARD_TEXT.TYPE_HERE}
          infoText={DASHBOARD_INFO_TEXT.MAX_WEIGHTAGE_PER_SECURITY}
          labelClassName='h-8 flex items-start'
          inputClassName='mt-2'
          onChange={onChangeValues}
          value={screener?.calculator?.maxWeightagePerSecurity}
          keyName={FiltersKeyName.MAX_WEIGHTAGE_PER_SECURITY}
          type='number'
          minValue={1}
          maxValue={
            String(screener?.calculator?.minOfSecurity) === '1' ? 100 : 99
          }
          isPremium={!isPremiumOrAdmin}
          isDisabled={
            isFieldDisabled([
              FiltersKeyName.AMOUNT,
              FiltersKeyName.CONTRACT_TO_PICK,
              FiltersKeyName.MIN_SECURITY,
              FiltersKeyName.CONTRACT_PER_SECURITY,
            ]) || !isPremiumOrAdmin
          }
          calculatorToolTipText={
            isPremiumOrAdmin &&
            (!screener?.calculator.amount ||
              !screener.calculator.contractsToPick ||
              !screener.calculator.minOfSecurity ||
              !screener?.calculator.contractPerSecurity)
              ? DASHBOARD_INFO_TEXT.FILL_IN_ALL_CALCULATOR_FIELDS
              : ''
          }
          errorMessage={
            isFieldDisabled([
              FiltersKeyName.AMOUNT,
              FiltersKeyName.CONTRACT_TO_PICK,
              FiltersKeyName.CONTRACT_PER_SECURITY,
              FiltersKeyName.MIN_SECURITY,
            ])
              ? ''
              : validationMessages[FiltersKeyName.MAX_WEIGHTAGE_PER_SECURITY]
          }
        />
        {calculatorError ? (
          <Typography className='text-red-600 justify-end items-end w-full flex lg:hidden'>
            Status: {calculatorError} (please adjust your wgt/security values)
          </Typography>
        ) : (
          ''
        )}
      </div>
      {calculatorError ? (
        <Typography className='text-red-600 justify-end items-end w-full hidden lg:flex'>
          Status: {calculatorError} (please adjust your contracts/security and
          wgt/security values)
        </Typography>
      ) : (
        ''
      )}
    </div>
  );
};

export default Calculator;
