export const LOGIN = {
  TITLE: 'log in to boiling options',
  NEW_TO_BO: 'New to boiling options?',
  SIGN_UP: 'Sign up',
  EMAIL: { title: 'Email', placeholder: 'Enter email address' },
  PASSWORD: { title: 'Password', placeholder: 'Enter password' },
  REMEMBER_ME: 'Remember me',
  FORGOT_PASSWORD: 'Forgot password?',
  LOG_IN: 'Log In',
  SEND_EMAIL_TEXT: 'For queries and support contact us at ',
};
export const SIGNUP = {
  TITLE: 'Sign Up to boiling options',
  ALREADY_HAVE_AN_ACCOUNT: 'Already have an account?',
  LOGIN: 'Log in',
  EMAIL: { title: 'Email', placeholder: 'Enter email address' },
  PASSWORD: { title: 'Password', placeholder: 'Enter password' },
  CONFIRM_REMEMBER_ME: {
    title: 'Confirm Password',
    placeholder: 'Enter Confirm password',
  },
  SIGNUP: 'Sign up',
};
export const VERIFICATION = {
  TITLE: 'OTP Verification',
  ENTER_OTP: 'Enter the OTP sent to ',
  VERIFY: 'Verify',
  RESEND_OTP: 'Resend OTP',
  BACK_TO: 'Back to',
  LOGIN: 'Log In',
};
export const FORGOT_PASSWORD = {
  TITLE: 'Forgot Password',
  DESC: "Enter your account's email address and we will send you a link to reset your password.",
  EMAIL: { title: 'Email', placeholder: 'Enter email address' },
  SUBMIT: 'Submit',
  BACK_TO: 'Back to',
  LOGIN: 'Log In',
};
export const SET_NEW_PASSWORD = {
  TITLE: 'Set New Password',
  NEW_TO_BO: 'New to boiling options?',
  PASSWORD: { title: 'Password', placeholder: 'Enter password' },
  CONFIRM_REMEMBER_ME: {
    title: 'Confirm Password',
    placeholder: 'Enter Confirm password',
  },
  SIGNUP: 'Sign up',
  SUBMIT: 'Submit',
};

export enum OTP_TYPE {
  EMAIL_VERIFICATION = 'EMAIL_VERIFICATION',
  RESET_PASSWORD = 'RESET_PASSWORD',
}
