import Axios, { AxiosError, AxiosResponse } from 'axios';

import { APIResponse } from 'src/@types';
import { COMMON_ERROR } from 'src/constants/feedback';
import { REST_URL } from 'src/constants/url';
import { getAuthToken, removeAuthToken } from 'src/utils/common';

const axios = Axios.create({
  baseURL: REST_URL,
});

export class API {
  static SerializeResponse<T>(
    response: AxiosResponse<T> | null,
    message: string,
  ): APIResponse<T> {
    const data = response as any;
    if (data.error) {
      throw new Error(message);
    }
    return data;
  }

  static async Post<RQ, RES>(
    url: string,
    payload: RQ,
    isImage?: boolean,
  ): Promise<APIResponse<RES>> {
    try {
      const headers = await this.getHeaders();
      if (isImage) {
        headers['Content-Type'] = 'multipart/form-data';
      }
      const res = await axios.post<RES>(url, payload, {
        headers,
      });
      const response = this.SerializeResponse<RES>(res, '');
      return {
        data: response?.data,
        status: true,
        message: '',
      };
    } catch (error: any) {
      if ((error as AxiosError)?.response?.status === 401) {
        removeAuthToken();
        window.location.href = '/login';
        return {
          data: null,
          status: false,
          message:
            (error as { response?: { data?: { message?: string } } }).response
              ?.data?.message || '',
        };
      }
      return {
        data: null,
        status: false,
        message: error.response?.data?.message || COMMON_ERROR,
      };
    }
  }

  static async Get<RES>(url: string) {
    try {
      const headers = await this.getHeaders();
      const res = await axios.get<RES>(url, {
        headers,
      });
      const response = this.SerializeResponse<RES>(res, '');
      return {
        data: response?.data,
        status: true,
        message: '',
      };
    } catch (error: any) {
      if ((error as AxiosError)?.response?.status === 401) {
        removeAuthToken();
        window.location.href = '/login';
        return {
          data: null,
          status: false,
          message:
            (error as { response?: { data?: { message?: string } } }).response
              ?.data?.message || '',
        };
      }
      return {
        data: null,
        status: false,
        message: error.response?.data?.message || COMMON_ERROR,
      };
    }
  }

  static async Put<RES, REQ = undefined>(
    url: string,
    payload?: REQ,
    isImage?: boolean,
  ) {
    try {
      const headers = await this.getHeaders();
      if (isImage) {
        headers['Content-Type'] = 'multipart/form-data';
      }
      const res = await axios.put<RES>(url, payload, {
        headers,
      });
      const response = this.SerializeResponse<RES>(res, '');
      return {
        data: response?.data,
        status: true,
        message: '',
      };
    } catch (error: any) {
      if ((error as AxiosError)?.response?.status === 401) {
        removeAuthToken();
        window.location.href = '/login';
        return {
          data: null,
          status: false,
          message:
            (error as { response?: { data?: { message?: string } } }).response
              ?.data?.message || '',
        };
      }
      return {
        data: null,
        status: false,
        message: error.response?.data?.message || COMMON_ERROR,
      };
    }
  }

  static async Patch<RES, REQ = undefined>(url: string, payload?: REQ) {
    try {
      const headers = await this.getHeaders();
      const res = await axios.patch<RES>(url, payload, {
        headers,
      });
      const response = this.SerializeResponse<RES>(res, '');
      return {
        data: response?.data,
        status: true,
        message: '',
      };
    } catch (error: any) {
      if ((error as AxiosError)?.response?.status === 401) {
        removeAuthToken();
        window.location.href = '/login';
        return {
          data: null,
          status: false,
          message:
            (error as { response?: { data?: { message?: string } } }).response
              ?.data?.message || '',
        };
      }
      return {
        data: null,
        status: false,
        message: error.response?.data?.message || COMMON_ERROR,
      };
    }
  }

  static async Delete<RES>(url: string) {
    try {
      const headers = await this.getHeaders();
      const res = await axios.delete<RES>(url, {
        headers,
      });
      const response = this.SerializeResponse<RES>(res, '');
      return {
        data: response?.data,
        status: true,
        message: '',
      };
    } catch (error: any) {
      if ((error as AxiosError)?.response?.status === 401) {
        removeAuthToken();
        window.location.href = '/login';
        return {
          data: null,
          status: false,
          message:
            (error as { response?: { data?: { message?: string } } }).response
              ?.data?.message || '',
        };
      }
      return {
        data: null,
        status: false,
        message: error.response?.data?.message || COMMON_ERROR,
      };
    }
  }

  static async getHeaders(): Promise<Record<string, string>> {
    const authToken = await this.getAuthToken();
    return {
      'Content-Type': 'application/json',
      'Authorization': authToken,
      'ngrok-skip-browser-warning': 'any',
    };
  }

  static async getAuthToken(): Promise<string> {
    const token = await getAuthToken();
    return `Bearer ${token}`;
  }
}
