import toast from 'react-hot-toast';

import { API } from 'src/api/ApiInstance';
import { COMMON_ERROR, COMMON_SUCCESS } from 'src/constants/feedback';
import { REST_SUB_URL } from 'src/constants/url';
import { UserType } from 'src/providers/Auth/types';
import {
  setEmailLS,
  setRememberMeToLS,
  setTokenToLS,
  setUserToLS,
} from 'src/utils/common';

import {
  AggrementResponse,
  AggrementType,
  CREATE_PROFILE_PAYLOAD,
  CREATE_PROFILE_PAYLOAD_RESPONSE,
  createSubscriptionType,
  createSubscriptionTypeResponse,
  forgotPasswordRequest,
  forgotPasswordResponse,
  IAgreement,
  LoginRequest,
  LoginResponse,
  OtpRequest,
  OtpResponse,
  ResendOtpRequest,
  resetPasswordRequest,
  resetPasswordResponse,
  ResponseType,
  SignUpRequest,
  SignupResponse,
  subscriptionDataType,
} from './types';

export const login = async (payload: LoginRequest, isRemember: boolean) => {
  const response = await API.Post<LoginRequest, LoginResponse>(
    REST_SUB_URL.LOGIN,
    {
      ...payload,
    },
  );
  if (response.status) {
    if (response?.data?.token) {
      let newUserData;
      if (
        response?.data?.data.isAgreement &&
        response?.data?.data.isProfileCompleted &&
        response?.data?.data.subscription
      ) {
        newUserData = { ...response.data.data };
      } else if (!response?.data?.data.isProfileCompleted) {
        newUserData = { ...response.data.data };
        if ('isProfileCompleted' in newUserData) {
          delete (newUserData as UserType).isProfileCompleted;
        }
        if ('isAgreement' in newUserData) {
          delete (newUserData as UserType).isAgreement;
        }
        if ('subscription' in newUserData) {
          delete (newUserData as UserType).subscription;
        }
      } else if (!response?.data?.data.isAgreement) {
        newUserData = { ...response.data.data };
        if ('isAgreement' in newUserData) {
          delete (newUserData as UserType).isAgreement;
        }
        if ('subscription' in newUserData) {
          delete (newUserData as UserType).subscription;
        }
      } else if (!response?.data?.data.subscription) {
        newUserData = { ...response.data.data };
        if ('subscription' in newUserData) {
          delete (newUserData as UserType).subscription;
        }
      }
      if (isRemember) {
        setTokenToLS(response?.data?.token);
        setUserToLS(newUserData as UserType);
        setRememberMeToLS(isRemember);
        setEmailLS(newUserData?.email ?? '');
      } else {
        setTokenToLS(response?.data?.token);
        setUserToLS(newUserData as UserType);
        setRememberMeToLS(false);
      }
    }
    toast.success(response?.data?.message || COMMON_SUCCESS);
    return response.data?.data;
  }
  toast.error(response?.message || COMMON_ERROR);
  return false;
};

export const signup = async (payload: SignUpRequest) => {
  const response = await API.Post<SignUpRequest, SignupResponse>(
    REST_SUB_URL.SIGNUP,
    {
      ...payload,
    },
  );
  if (response.status) {
    toast.success(response?.data?.message || COMMON_SUCCESS);
    return true;
  }
  toast.error(response?.message || COMMON_ERROR);
  return false;
};

export const otp = async (payload: OtpRequest) => {
  const response = await API.Post<OtpRequest, OtpResponse>(REST_SUB_URL.OTP, {
    ...payload,
  });
  if (response.status) {
    toast.success(response?.data?.message || COMMON_SUCCESS);
    return true;
  }
  toast.error(response?.message || COMMON_ERROR);
  return false;
};

export const resentOTP = async (payload: ResendOtpRequest) => {
  const response = await API.Post<ResendOtpRequest, OtpResponse>(
    REST_SUB_URL.RESEND_OTP,
    {
      ...payload,
    },
  );
  if (response.status) {
    toast.success(response?.data?.message || COMMON_SUCCESS);
    return true;
  }
  toast.error(response?.message || COMMON_ERROR);
  return false;
};

export const resetPassword = async (payload: resetPasswordRequest) => {
  const response = await API.Post<resetPasswordRequest, resetPasswordResponse>(
    REST_SUB_URL.RESET_PASSWORD,
    {
      ...payload,
    },
  );
  if (response.status) {
    toast.success(response?.data?.message || COMMON_SUCCESS);
    return true;
  }
  toast.error(response?.message || COMMON_ERROR);
  return false;
};

export const forgotPassword = async (payload: forgotPasswordRequest) => {
  const response = await API.Post<
    forgotPasswordRequest,
    forgotPasswordResponse
  >(REST_SUB_URL.FORGOT_PASSWORD, {
    ...payload,
  });
  if (response.status) {
    toast.success(response?.data?.message || COMMON_SUCCESS);
    return true;
  }
  toast.error(response?.message || COMMON_ERROR);
  return false;
};
export const createProfile = async (payload: CREATE_PROFILE_PAYLOAD) => {
  const formData = new FormData();
  formData.append('avatar', payload.avatar as File);
  formData.append('firstName', payload.firstName);
  formData.append('lastName', payload.lastName);
  formData.append('middleName', payload.middleName);
  formData.append('zipCode', payload.zipCode);
  formData.append('streetAddress', payload.streetAddress);
  formData.append('currentlyEmployed', payload.currentlyEmployed.toString());
  formData.append('nameOfEmployer', payload.nameOfEmployer);
  formData.append('employerJobTitle', payload.employerJobTitle);
  formData.append('country', payload.country);
  formData.append('state', payload.state);
  formData.append('city', payload.city);
  formData.append('userType', payload.userType);
  const response = await API.Post<
    CREATE_PROFILE_PAYLOAD,
    CREATE_PROFILE_PAYLOAD_RESPONSE
  >(REST_SUB_URL.CREATE_PROFILE, formData as any, true);
  if (response.status) {
    toast.success(response?.data?.message || COMMON_SUCCESS);
    return true;
  }
  toast.error(response?.message || COMMON_ERROR);
  return false;
};

export const updateAggremtnt = async (payload: AggrementType) => {
  const response = await API.Put<AggrementResponse, AggrementType>(
    REST_SUB_URL.UPDATE_AGGREMENT,
    payload,
  );
  if (response.status) {
    toast.success(response?.data?.message || COMMON_SUCCESS);
    return response;
  }
  toast.error(response?.data?.message || COMMON_ERROR);
  return response;
};
export const getSubscriptionData = async () => {
  const response = await API.Get<subscriptionDataType>(
    REST_SUB_URL.SUBSCRIPTION,
  );
  return response?.data;
};
export const createSubscription = async (payload: createSubscriptionType) => {
  const response = await API.Post<
    createSubscriptionType,
    createSubscriptionTypeResponse
  >(REST_SUB_URL.SUBSCRIPTION, {
    ...payload,
  });

  return response;
};
export const getAgreements = async () => {
  const response = await API.Get<ResponseType<IAgreement[]>>(
    `${REST_SUB_URL.GET_AGREEMENTS}`,
  );
  if (response.status) {
    return response.data;
  }
  toast.error(response?.message || COMMON_ERROR);
  return false;
};
