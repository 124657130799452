'use client';

import * as React from 'react';
import { SelectSingleEventHandler } from 'react-day-picker';
import { FaChevronDown } from 'react-icons/fa';
import dayjs from 'dayjs';

import { Tooltip } from '@radix-ui/react-tooltip';

import { DropDownListType } from 'src/@types/dashboardTypes';
import { Button } from 'src/components/ui/button';
import { Calendar } from 'src/components/ui/calendar';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from 'src/components/ui/popover';
import {
  TooltipArrow,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'src/components/ui/tooltip';
import { PREMIUM_TOOLTIP_TEXT } from 'src/constants/common';
import useDashboardContext from 'src/hooks/useDashboardData';
import { cn } from 'src/lib/utils';

import LabelText from '../LabelText';
interface IDateInputItem {
  placeholder?: string;
  inputClassName?: string;
  label?: string;
  isPremium?: boolean;
  isDisabled?: boolean;
  handleOpen?: () => void;
  value: Date | undefined;
  keyName: string;
  infoText?: string;
  onChange: (
    name: string,
    value: string | number | DropDownListType | Date,
  ) => void;
}
const CalendarSelect: React.FC<IDateInputItem> = ({
  placeholder,
  label = '',
  value,
  keyName,
  onChange,
  isDisabled = false,
  isPremium = false,
  infoText = '',
  handleOpen,
}) => {
  const { keyInScreener } = useDashboardContext();
  const [isCalendarOpen, setIsCalendarOpen] = React.useState(false);

  const handleSelect: SelectSingleEventHandler = (newDate) => {
    onChange?.(keyName, dayjs(newDate).format('YYYY-MM-DD'));
    setIsCalendarOpen(false);
  };
  const handleClickPremiumFields = () => {
    if (isPremium && isDisabled && handleOpen) {
      handleOpen();
    }
  };
  return (
    <div onClick={handleClickPremiumFields}>
      <LabelText
        label={label}
        isDisabled={isDisabled}
        isPremium={isPremium}
        infoText={infoText}
      />
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <div className='relative'>
              <div
                className={cn('w-auto h-11 z-50 left-0 absolute right-0', {
                  'hidden ': !isDisabled,
                })}
                onClick={handleClickPremiumFields}
              />
              <Popover open={isCalendarOpen} onOpenChange={setIsCalendarOpen}>
                <PopoverTrigger asChild>
                  <Button
                    className={cn(
                      'mt-3 w-full h-11 rounded-xl justify-between py-0 hover:text-white text-left font-normal hover:bg-darkGray bg-darkGray',
                      {
                        'ring-[2px] ring-lightBlue': keyInScreener(keyName),
                      },
                      !value && 'text-muted-foreground',
                    )}
                    disabled={isDisabled}
                  >
                    {value ? (
                      dayjs(value).format('YYYY-MM-DD')
                    ) : (
                      <span>{placeholder}</span>
                    )}
                    <FaChevronDown />
                  </Button>
                </PopoverTrigger>
                <PopoverContent className='w-auto p-0 bg-darkGray border-0 rounded-xl'>
                  <Calendar
                    mode='single'
                    selected={value}
                    onSelect={handleSelect}
                    initialFocus
                  />
                </PopoverContent>
              </Popover>
            </div>
          </TooltipTrigger>
          {isDisabled ? (
            <TooltipContent
              side='top'
              align='center'
              className='max-w-[200px] text-center data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade select-none rounded-[14px] bg-navyBlue px-[15px] py-[10px] text-[13px] text-white leading-[17px]'
            >
              {isPremium ? PREMIUM_TOOLTIP_TEXT : ''}
              <TooltipArrow className='fill-navyBlue w-3 h-2' />
            </TooltipContent>
          ) : null}
        </Tooltip>
      </TooltipProvider>
    </div>
  );
};
export default CalendarSelect;
