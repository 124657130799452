import { Typography } from 'src/components/Typography';

interface Props {
  text: string;
}

const FiltersHeading: React.FC<Props> = ({ text }) => {
  return (
    <Typography className='text-white text-[22px] font-semibold'>
      {text}
    </Typography>
  );
};

export default FiltersHeading;
