import React from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';

import FormField from 'src/components/FormField';
import { PROFILE_COMPLETION_INPUT_ERRORS } from 'src/constants/aterlogin';
import { PROFILE_COMPLETION } from 'src/constants/profileCompletion';
import { LocationState } from 'src/hooks/useLocation';
import { BASIC_INFO } from 'src/services/types';

import CitySelect from './(Components)/CitySelect';
import CountrySelect from './(Components)/CountrySelect';
import StateSelect from './(Components)/StateSelect';

type NameFieldsProps = {
  errors: FieldErrors<BASIC_INFO>;
  control: Control<BASIC_INFO, any>;
  location: LocationState;
  setLocation: React.Dispatch<React.SetStateAction<LocationState>>;
};

const AddressField: React.FC<NameFieldsProps> = ({
  errors,
  control,
  location,
  setLocation,
}) => {
  return (
    <div className='grid grid-cols-1 md:grid-cols-3 md:gap-x-7'>
      <div className='md:col-span-2'>
        <Controller
          control={control}
          name='streetAddress'
          rules={{
            required: PROFILE_COMPLETION_INPUT_ERRORS.STREET_ADDRESS.required,
            maxLength: {
              value: 254,
              message: PROFILE_COMPLETION_INPUT_ERRORS.STREET_MAX_LENGTH,
            },
          }}
          render={({ field }) => (
            <FormField
              type='text'
              labelCalssName='text-base'
              title={PROFILE_COMPLETION.STREET_ADDRESS.title}
              placeholder={PROFILE_COMPLETION.STREET_ADDRESS.placeholder}
              {...field}
              errors={errors}
              isRequired
              maxLength={255}
            />
          )}
        />
      </div>

      <Controller
        control={control}
        name='country'
        rules={{
          required: PROFILE_COMPLETION_INPUT_ERRORS.STREET_ADDRESS.required,
        }}
        render={({ field: { onChange, value } }) => (
          <CountrySelect
            errors={errors}
            value={value}
            isRequired
            onChange={onChange}
            countryId={location.countryId}
            setCountryId={(newCountryId) =>
              setLocation((prevLocation) => ({
                ...prevLocation,
                countryId: newCountryId,
                stateId: 0,
                cityList: [],
                cityId: 0,
              }))
            }
            countryList={location.countryList}
            setStateList={(newStateList) =>
              setLocation((prevLocation) => ({
                ...prevLocation,
                stateList: newStateList,
              }))
            }
          />
        )}
      />
      <Controller
        control={control}
        name='state'
        render={({ field: { onChange, value } }) => (
          <StateSelect
            value={value}
            onChange={onChange}
            errors={errors}
            countryId={location.countryId}
            stateList={location.stateList}
            setCityList={(newCityList) =>
              setLocation((prevLocation) => ({
                ...prevLocation,
                cityList: newCityList,
              }))
            }
            setStateId={(newStateId) =>
              setLocation((prevLocation) => ({
                ...prevLocation,
                stateId: newStateId,
              }))
            }
            stateId={location.stateId}
          />
        )}
      />
      <Controller
        control={control}
        name='city'
        render={({ field: { onChange, value } }) => (
          <CitySelect
            cityList={location?.cityList}
            isDisabled={location.stateId === 0}
            onChange={onChange}
            value={value}
            errors={errors}
            cityId={location.cityId}
            setCityId={(newCityId) =>
              setLocation((prevLocation) => ({
                ...prevLocation,
                cityId: newCityId,
              }))
            }
          />
        )}
      />
      <Controller
        control={control}
        name='zipCode'
        rules={{
          required: PROFILE_COMPLETION_INPUT_ERRORS.ZIP_CODE.required,
          maxLength: {
            value: 14,
            message: PROFILE_COMPLETION_INPUT_ERRORS.ZIPCODE_MAX_LENGTH,
          },
        }}
        render={({ field }) => (
          <FormField
            type='text'
            labelCalssName='text-base mb-1'
            title={PROFILE_COMPLETION.ZIP_CODE.title}
            placeholder={PROFILE_COMPLETION.ZIP_CODE.placeholder}
            {...field}
            errors={errors}
            isRequired
            maxLength={15}
          />
        )}
      />
    </div>
  );
};

export default AddressField;
