import { useEffect, useState } from 'react';
import { RiVipCrown2Fill } from 'react-icons/ri';

import BasicCardIcon from 'src/assets/svgs/BasicCardIcon.svg';
import Modal from 'src/components/Modal';
import PricingCard from 'src/components/PricingCard';
import withProtectedRoute from 'src/components/ProtectedRoute';
import { Typography } from 'src/components/Typography';
import { Button } from 'src/components/ui/button';
import { Tabs, TabsList, TabsTrigger } from 'src/components/ui/tabs';
import { NO_DATA_FOUND, PLANS, USER_PLAN_TYPE } from 'src/constants/common';
import { CANCEL_PLAN_MODAL } from 'src/constants/profile';
import {
  PROFILE_COMPLETION,
  TRIAL_SECTION,
} from 'src/constants/profileCompletion';
import useAuthContext from 'src/hooks/useAuth';
import useDashboardContext from 'src/hooks/useDashboardData';
import { UserType } from 'src/providers/Auth/types';
import { getSubscriptionData } from 'src/services/auth';
import {
  cancelSubscription,
  getCurrentSubscription,
} from 'src/services/profile';
import { dataType, SUBSCRIPTION_TYPE } from 'src/services/types';

const Subscription = () => {
  const { auth } = useAuthContext();
  const { isAdmin } = useDashboardContext();
  const { user } = auth ?? {};
  const { setAuth } = useAuthContext();
  const [activeTab, setActiveTab] = useState('monthly');

  const [data, setData] = useState<dataType[]>([]);
  const [confirmCancel, setConfirmCancel] = useState(false);
  const [userSubscriptionType, setUserSubscriptionType] = useState<{
    subscriptionType: string;
    price: string;
  }>({ subscriptionType: SUBSCRIPTION_TYPE.BASIC, price: '0' });

  const getProfile = async () => {
    const response = await getSubscriptionData();
    if (response) {
      setData(response.data as unknown as dataType[]);
    }
  };

  const getUserSubscriptionType = async () => {
    const response = await getCurrentSubscription();
    if (response) {
      setUserSubscriptionType({
        subscriptionType: response.data.subscriptionType,
        price: response.data.amount,
      });
    }
  };

  useEffect(() => {
    getProfile();
    getUserSubscriptionType();
  }, []);

  const cancelHandler = async () => {
    const response = await cancelSubscription(user?.subscription as string);
    if (response) {
      setAuth({
        token: String(auth?.token),
        user: {
          ...(user as UserType),
          isSubscriptionCancel: true,
        },
      });
      if (
        user?.totalSubscriptionsPurchased &&
        user?.totalSubscriptionsPurchased >= 2
      ) {
        setAuth({
          token: String(auth?.token),
          user: {
            ...(user as UserType),
          },
        });
      }
    }
  };

  const handleOpenConfirmCancelDialog = () => setConfirmCancel(true);
  return (
    <div className='min-h-[700px] mb-20'>
      <Modal
        setOpen={setConfirmCancel}
        open={confirmCancel}
        title={CANCEL_PLAN_MODAL.title}
        description={CANCEL_PLAN_MODAL.description}
        handleBtn={cancelHandler}
        btnText={CANCEL_PLAN_MODAL.btnText}
      />
      <div className='flex justify-center my-5'>
        <div className='bg-skyCaptain px-4 py-1 rounded-full border border-white'>
          <Typography
            textGradient
            className='text-lg md:text-xl font-medium md:tracking-[0.15rem]'
          >
            {PLANS}
          </Typography>
        </div>
      </div>
      <div className='bg-skyCaptain rounded-[40px] pt-4 pb-8 min-h-96'>
        <div className='w-full px-5 xl:px-[100px]'>
          <div className='w-full'>
            <div className='bg-nightSky text-white rounded-[18px] pt-5 pb-7'>
              {isAdmin ? (
                <div className='items-center h-96 flex justify-center'>
                  <Typography className='text-md my-2'>
                    {NO_DATA_FOUND}
                  </Typography>
                </div>
              ) : (
                <>
                  {user?.isSubscriptionCancel ? null : (
                    <div className='flex justify-center mb-10'>
                      <div className='bg-borderGrayColor mx-4 px-3 md:px-12 py-1.5 rounded-full border-2 border-skyBlue'>
                        <Typography className='text-lg md:text-xl text-center md:text-start font-medium md:tracking-[0.15rem]'>
                          {PROFILE_COMPLETION.YOU_HAVE_SUBSCRIBE}
                          {userSubscriptionType.subscriptionType}{' '}
                          {userSubscriptionType.price}
                          {['298.08', '571.32'].includes(
                            userSubscriptionType.price,
                          )
                            ? '/Yr'
                            : '/Mo'}
                        </Typography>
                      </div>
                    </div>
                  )}
                  <div className='mx-auto flex w-11/12'>
                    <div className='mx-auto my-4 rounded-full border border-white bg-darkGray px-0.5 py-1.5'>
                      <Tabs
                        defaultValue={activeTab}
                        onValueChange={(value) => setActiveTab(value)}
                        className='md:full z-10 min-w-fit rounded-full'
                      >
                        <TabsList className='h-12 rounded-full bg-darkGray'>
                          <TabsTrigger
                            className='rounded-full px-6 py-3 text-lg font-normal text-lightOrange data-[state=active]:bg-gradient-to-r data-[state=active]:from-lightBlue data-[state=active]:to-lightPurple data-[state=active]:text-white md:px-10'
                            value='monthly'
                            aria-label='trial monthly'
                            aria-expanded={`${activeTab === 'monthly'}`}
                          >
                            {TRIAL_SECTION.monthly}
                          </TabsTrigger>
                          <TabsTrigger
                            className='rounded-full px-6 py-3 text-lg font-normal text-lightOrange data-[state=active]:bg-gradient-to-r data-[state=active]:from-lightBlue data-[state=active]:to-lightPurple data-[state=active]:text-white md:px-10'
                            value='yearly'
                            aria-label='trial annual'
                            aria-expanded={`${activeTab === 'yearly'}`}
                          >
                            {TRIAL_SECTION.annual}
                          </TabsTrigger>
                        </TabsList>
                      </Tabs>
                    </div>
                  </div>
                  <div>
                    <div className='pt-5 w-full sm:w-2/3 px-4 mx-auto'>
                      <div className='space-y-8 md:grid md:grid-cols-2 sm:gap-6 lg:gap-16 md:space-y-0'>
                        <PricingCard
                          cardImage={BasicCardIcon}
                          activeTab={activeTab}
                          cardTitle={data[1]?.name || ''}
                          price={
                            (activeTab === 'monthly'
                              ? data[1]?.price[1]?.unit_amount_decimal
                              : data[1]?.price[0]?.unit_amount_decimal) || 0
                          }
                          cardList={PROFILE_COMPLETION.BASIC_CARD}
                          cardDesc={PROFILE_COMPLETION.CARD_ONE_DESC}
                          priceValue={
                            (activeTab === 'monthly'
                              ? data[1]?.price[1]?.id
                              : data[1]?.price[0]?.id) || ''
                          }
                          isSubscription={
                            activeTab === 'monthly'
                              ? userSubscriptionType.subscriptionType ===
                                  USER_PLAN_TYPE.BASIC &&
                                !user?.isSubscriptionCancel &&
                                user?.planId === data[1]?.price[1]?.id
                              : userSubscriptionType.subscriptionType ===
                                  USER_PLAN_TYPE.BASIC &&
                                !user?.isSubscriptionCancel &&
                                user?.planId === data[1]?.price[0]?.id
                          }
                          isLoading={data.length === 0}
                          totalSubscription={user?.totalSubscriptionsPurchased}
                          isPay={
                            userSubscriptionType.subscriptionType ===
                            USER_PLAN_TYPE.BASIC
                          }
                        />
                        <PricingCard
                          cardImage={<RiVipCrown2Fill size={20} />}
                          cardTitle={data[0]?.name || ''}
                          activeTab={activeTab}
                          price={
                            (activeTab === 'yearly'
                              ? data[0]?.price[0]?.unit_amount_decimal
                              : data[0]?.price[1]?.unit_amount_decimal) || 0
                          }
                          cardList={PROFILE_COMPLETION.PREMIUM_CARD}
                          cardDesc={PROFILE_COMPLETION.CARD_TWO_DESC}
                          priceValue={
                            activeTab === 'yearly'
                              ? data[0]?.price[0]?.id
                              : data[0]?.price[1]?.id
                          }
                          isSubscription={
                            activeTab === 'yearly'
                              ? userSubscriptionType.subscriptionType ===
                                  USER_PLAN_TYPE.PREMIUM &&
                                !user?.isSubscriptionCancel &&
                                user?.planId === data[0]?.price[0]?.id
                              : userSubscriptionType.subscriptionType ===
                                  USER_PLAN_TYPE.PREMIUM &&
                                !user?.isSubscriptionCancel &&
                                user?.planId === data[0]?.price[1]?.id
                          }
                          isLoading={data.length === 0}
                          totalSubscription={user?.totalSubscriptionsPurchased}
                          isPay={
                            userSubscriptionType.subscriptionType ===
                            USER_PLAN_TYPE.PREMIUM
                          }
                        />
                      </div>

                      <div className='w-full flex justify-end'>
                        {user?.isSubscriptionCancel ? (
                          <Typography className='text-base mt-3 text-gray-500'>
                            {PROFILE_COMPLETION.SUBSCRIPTION_CANCELLED} trial
                            will be end at{' '}
                            {new Date(user.trialEndDate).toLocaleDateString()}
                          </Typography>
                        ) : (
                          <Button
                            variant='link'
                            className='underline text-base !p-0'
                            onClick={handleOpenConfirmCancelDialog}
                          >
                            {PROFILE_COMPLETION.CANCEL_PLAN}
                          </Button>
                        )}
                      </div>

                      <Typography className='text-base mb-2 text-center w-full'>
                        {PROFILE_COMPLETION.STEP_THREE_TEXT}
                      </Typography>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default withProtectedRoute(Subscription);
