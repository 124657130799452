import React, { useEffect } from 'react';

import { DropDownListType, IScreenerType } from 'src/@types/dashboardTypes';
import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'src/components/ui/tooltip';
import {
  DASHBOARD_INFO_TEXT,
  RISKYIELD_OPTIONS,
} from 'src/constants/dashboard';
import { cn } from 'src/lib/utils';

import LabelText from '../LabelText';

interface IRiskYieldItem {
  label?: string;
  optionOne?: { title: string; value: string };
  optionTwo?: { title: string; value: string };
  keyName: string;
  infoText?: string;
  hasInfoIcon?: boolean;
  onChange: (name: string, value: string | number | DropDownListType) => void;
  screener: IScreenerType | undefined;
}

const RiskYieldComponent: React.FC<IRiskYieldItem> = ({
  label,
  optionOne,
  optionTwo,
  screener,
  keyName,
  onChange,
  infoText = '',
}) => {
  const options = [
    {
      title: optionOne?.title,
      value: RISKYIELD_OPTIONS.YIELD_BY_DAY,
      tooltipText: DASHBOARD_INFO_TEXT.YIELD_BY_DAY,
    },
    {
      title: optionTwo?.title,
      value: RISKYIELD_OPTIONS.YIELD_BY_CONTRACT,
      tooltipText: DASHBOARD_INFO_TEXT.YIELD_BY_CONTRACT,
    },
  ];
  useEffect(() => {
    if (!screener?.filter?.riskYield?.value) {
      onChange(keyName, {
        name: optionOne?.title,
        value: RISKYIELD_OPTIONS.YIELD_BY_DAY,
      });
    }
  }, []);

  const handleClick = (title: string, value: string) => {
    onChange(keyName, {
      name: title,
      value: value,
    });
  };
  return (
    <div className='mt-3 md:mt-0'>
      <LabelText label={label ?? ''} infoText={infoText} />
      <div className='flex w-full gap-2 mt-3'>
        {options.map((option) => (
          <TooltipProvider key={option.value}>
            <Tooltip>
              <TooltipTrigger asChild>
                <div
                  onClick={() =>
                    handleClick(option?.title || '', option?.value)
                  }
                  className={cn(
                    'bg-darkGray px-2 h-11 w-1/2 text-center rounded-full border text-[13px] font-medium flex items-center justify-center cursor-pointer',
                    {
                      '!bg-white text-black':
                        screener?.filter?.riskYield?.value === option.value,
                    },
                  )}
                >
                  {option.title}
                </div>
              </TooltipTrigger>
              <TooltipContent
                side='top'
                align='center'
                className='max-w-[200px] text-center data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade select-none rounded-[14px] bg-navyBlue px-[15px] py-[10px] text-[13px] text-white leading-[17px]'
              >
                {option.tooltipText}
                <TooltipArrow className='fill-navyBlue w-3 h-2' />
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        ))}
      </div>
    </div>
  );
};

export default RiskYieldComponent;
