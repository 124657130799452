import React, { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';

import FormField from 'src/components/FormField';
import Loader from 'src/components/Loader';
import withPublicRoute from 'src/components/PublicRoute';
import { Typography } from 'src/components/Typography';
import { Button } from 'src/components/ui/button';
import { LOGIN, SIGNUP } from 'src/constants/auth';
import { ROUTES, SUPPORT_EMAIL_ADDRESS } from 'src/constants/common';
import { COMMON_ERROR, INPUT_ERRORS } from 'src/constants/feedback';
import useAuthContext from 'src/hooks/useAuth';
import { signup } from 'src/services/auth';
import { ISignUpFields } from 'src/services/types';
import { EmailRegex } from 'src/utils/common';

const SignUp = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm<ISignUpFields>({
    mode: 'all',
    defaultValues: {
      email: '',
      password: '',
      role: '',
      confirmPassword: '',
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const { setEmailForOtp } = useAuthContext();
  const navigate = useNavigate();
  const watchPassword = watch('password');
  const handleSignUp: SubmitHandler<ISignUpFields> = async (data) => {
    setIsLoading(true);
    try {
      const payload = {
        email: data.email,
        password: data.password,
        role: 'user',
      };
      const response = await signup(payload);
      if (response) {
        setEmailForOtp(data.email);
        reset();
        navigate(ROUTES.OTP);
      }
    } catch (error) {
      toast.error(COMMON_ERROR);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className='bg-nightSky md:px-10 md:py-6 mt-20 mb-40 lg:px-20 p-6 lg:py-8 flex flex-col mx-auto w-11/12 md:w-7/12 lg:w-5/12 rounded-xl'>
      <Typography
        variant='subheading'
        className='mx-auto my-4 text-center capitalize'
      >
        {SIGNUP.TITLE}
      </Typography>
      <Typography variant='p' className='mx-auto capitalize'>
        {SIGNUP.ALREADY_HAVE_AN_ACCOUNT}{' '}
        <Link to={ROUTES.LOGIN}>
          <span className='text-skyBlue underline'> {SIGNUP.LOGIN}</span>
        </Link>
      </Typography>
      <Controller
        control={control}
        name='email'
        rules={{
          required: INPUT_ERRORS.EMAIL.required,
          pattern: {
            value: EmailRegex,
            message: INPUT_ERRORS.EMAIL.pattern,
          },
        }}
        render={({ field: { onChange, value, name } }) => (
          <FormField
            type='text'
            title={SIGNUP.EMAIL.title}
            placeholder={SIGNUP.EMAIL.placeholder}
            name={name}
            value={value}
            onChange={onChange}
            errors={errors}
          />
        )}
      />
      <Controller
        control={control}
        name='password'
        rules={{
          required: INPUT_ERRORS.PASSWORD.required,
        }}
        render={({ field: { onChange, value, name } }) => (
          <FormField
            title={SIGNUP.PASSWORD.title}
            placeholder={SIGNUP.PASSWORD.placeholder}
            name={name}
            type='password'
            value={value}
            onChange={onChange}
            errors={errors}
          />
        )}
      />
      <Controller
        control={control}
        name='confirmPassword'
        rules={{
          required: INPUT_ERRORS.CONFIRM_PASSWORD.required,
          validate: (value) =>
            value === watchPassword || INPUT_ERRORS.CONFIRM_PASSWORD.match,
        }}
        render={({ field: { onChange, value, name } }) => (
          <FormField
            title={SIGNUP.CONFIRM_REMEMBER_ME.title}
            placeholder={SIGNUP.CONFIRM_REMEMBER_ME.placeholder}
            name={name}
            type='password'
            value={value}
            onChange={onChange}
            errors={errors}
          />
        )}
      />
      <Button
        variant='gradient'
        className='my-4'
        onClick={handleSubmit(handleSignUp)}
      >
        {isLoading ? <Loader loading={isLoading} /> : SIGNUP.SIGNUP}
      </Button>
      <Typography className='text-center text-sm'>
        {LOGIN.SEND_EMAIL_TEXT}
        <a
          href={`mailto:${SUPPORT_EMAIL_ADDRESS}`}
          className=' font-semibold hover:text-skyBlue'
        >
          {SUPPORT_EMAIL_ADDRESS}.
        </a>
      </Typography>
    </div>
  );
};

export default withPublicRoute(SignUp);
