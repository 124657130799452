import React, { useState } from 'react';

import { getAuthToken, getAuthUser } from 'src/utils/common';

import { AuthContext } from './context';
import { loggedInUserType } from './types';

type PROPS = {
  children:
    | null
    | boolean
    | undefined
    | React.ReactChild
    | React.ReactPortal
    | React.ReactFragment;
};

const AuthProvider: React.FC<PROPS> = ({ children }) => {
  const [auth, setAuth] = React.useState<loggedInUserType | null>(null);
  const [emailForOtp, setEmailForOtp] = useState<string | null>('');

  const setUser = () => {
    const token = getAuthToken();
    const user = getAuthUser();
    setAuth({ token: String(token), user: user });
  };

  const contextValue = {
    auth,
    setAuth,
    emailForOtp,
    setEmailForOtp,
    setUser,
  };
  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;

export { AuthContext };
