import { OTP_TYPE } from 'src/constants/auth';
import { CARD_TYPE } from 'src/constants/profile';
import { UserType } from 'src/providers/Auth/types';

export type ResponseType<T> = {
  statusCode: number;
  data: T;
  message: string;
  status: boolean;
};
export interface IAgreement {
  _id: string;
  description: string;
  user: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export type LoginRequest = {
  email: string;
  password: string;
};

export type LoginResponse = {
  statusCode: number;
  message: string;
  data: UserType;
  token: string;
};

export type OtpRequest = {
  email: string;
  otpCode: string;
};

export type ResendOtpRequest = {
  email: string;
  otpType: OTP_TYPE;
};

export type OtpResponse = {
  statusCode: number;
  data: {
    _id: string;
    email: string;
    password: string;
    isEmailVerified: boolean;
    role: string;
    createdAt: string;
    updatedAt: string;
    __v: number;
  };
  message: string;
  status: boolean;
};

export type SignUpRequest = {
  email: string;
  password: string;
};

export type SignupResponse = {
  statusCode: number;
  data: {
    user: {
      email: string;
      password: string;
      isEmailVerified: boolean;
      role: string;
      _id: string;
      createdAt: string;
      updatedAt: string;
      __v: number;
    };
    otp: string;
  };
  message: string;
  status: boolean;
};

export interface ISignUpFields {
  email: string;
  password: string;
  confirmPassword: string;
  role?: string;
}

export type forgotPasswordRequest = {
  email: string;
};

export type forgotPasswordResponse = {
  statusCode: number;
  data: {
    code: string;
    otpType: string;
    user: string;
    _id: string;
    createdAt: string;
    updatedAt: string;
    __v: number;
  };
  message: string;
  status: boolean;
};

export type resetPasswordRequest = {
  email: string;
  password: string;
};

export type resetPasswordResponse = {
  statusCode: number;
  data: {
    _id: string;
    email: string;
    password: string;
    isEmailVerified: boolean;
    role: string;
    createdAt: string;
    updatedAt: string;
    __v: number;
    customerId: string;
    trialEndDate: string;
  };
  message: string;
  status: boolean;
};

export type CardInfo = {
  cardType: string;
  last4: string;
  expMonth: number;
  expYear: number;
};

export type CurrentSubscription = {
  nextPaymentDate: string;
  paymentMethod: string;
  amount: string;
  interval: string;
  subscriptionType: string;
};

export enum SUBSCRIPTION_TYPE {
  BASIC = 'Basic',
  PREMIUM = 'Premium',
}

export type PaymentHistory = {
  amount: string;
  date: string;
  status: string;
  cardType: CARD_TYPE;
  downloadUrl: string;
  last4: string;
}[];
export interface BASIC_INFO {
  avatar: File | null | string;
  firstName: string;
  middleName: string;
  lastName: string;
  streetAddress: string;
  currentlyEmployed: boolean;
  country: string;
  state: string;
  zipCode: string;
  nameOfEmployer: string;
  employerJobTitle: string;
  city: string;
  userType: string;
}

export type ReviewType = {
  comment: string;
  rating: number;
};
export type ContactFieldsType = {
  title: string;
  message: string;
};

export type ReviewResponse = {
  statusCode: number;
  data: {
    _id: string;
    rating: number;
    comment: string;
    user: string;
    createdAt: string;
    updatedAt: string;
    __v: number;
  };
  message: string;
  status: boolean;
};

export type UpdateProfileRequest = {
  firstName: string;
  lastName: string;
  middleName: string;
  country: string;
  city: string;
  state: string;
  zipCode: string;
  streetAddress: string;
  currentlyEmployed?: boolean;
  nameOfEmployer?: string;
  employerJobTitle?: string;
};

export type uploadImageRequest = {
  image: File;
};

export type CREATE_PROFILE_PAYLOAD = {
  avatar?: File | null | string;
  firstName: string;
  lastName: string;
  middleName: string;
  country: string;
  city: string;
  state: string;
  zipCode: string;
  streetAddress: string;
  currentlyEmployed: boolean;
  nameOfEmployer: string;
  employerJobTitle: string;
  userType: string;
};
export type CREATE_PROFILE_PAYLOAD_RESPONSE = {
  statusCode: number;
  data: {
    _id: string;
    avatar: string;
    firstName: string;
    lastName: string;
    middleName: string;
    country: string;
    city: string;
    state: string;
    zipCode: string;
    streetAddress: string;
    currentlyEmployed: boolean;
    nameOfEmployer: string;
    employerJobTitle: string;
    user: string;
    userType: string;
    createdAt: string;
    updatedAt: string;
    __v: number;
  };
  message: string;
  status: boolean;
};
export type AggrementType = {
  agreementDate: string;
  agreementList: { name: string; value: string }[];
};
export type AggrementResponse = {
  statusCode: number;
  data: {
    _id: string;
    agreementDate: string;
    user: string;
    createdAt: string;
    updatedAt: string;
    __v: number;
  };
  message: string;
  status: boolean;
};

export type LineItems = {
  price: string;
  quantity: number;
};

export type createSubscriptionType = {
  lineItems: LineItems[];
  isPay: boolean;
};

export type createSubscriptionTypeResponse = {
  statusCode: number;
  data: {
    url: string;
    _id: string;
    createdAt: string;
    updatedAt: string;
    __v: number;
  };
  message: string;
  status: boolean;
};

export type Price = {
  unit_amount_decimal: number;
  id: string;
};

export type dataType = {
  name: string;
  price: Array<Price>;
  default_price: string;
};
export type subscriptionDataType = {
  data: {
    name: string;
    price: {
      unit_amount_decimal: number;
    };
    default_price: string;
  };
};
export enum USER_TYPE {
  INDVIDUAL = 'individual',
  ORGANIZATION = 'organization',
}

export enum SORT {
  PINNED = 'pinned',
  UNPINNED = 'unpinned',
  ALL = 'all',
}

export type Tickers = {
  name: string;
  value: string;
  description: string;
};

export type Industries = {
  name: string;
  value: string;
};
