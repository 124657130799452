import { AiOutlineUser } from 'react-icons/ai';
import { FaDiscord, FaFacebook, FaInstagram, FaYoutube } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { FiHome } from 'react-icons/fi';

export const HEADER_BUTTONS = {
  LOGIN: 'Login',
  START_TRIAL: 'Start Trial',
};
export const SUPPORT_EMAIL_ADDRESS = 'support@boilingoptions.com';

export const SOCIAL_LINKS = [
  { route: '/', label: 'Discord', icon: FaDiscord },
  { route: '/', label: 'Twitter', icon: FaXTwitter },
  { route: '/', label: 'Youtube', icon: FaYoutube },
  { route: '/', label: 'Instagram', icon: FaInstagram },
  { route: '/', label: 'Facebook', icon: FaFacebook },
];

export const FOOTER_CONTENT = {
  description:
    'Options involve a high degree of risk and are not suitable for all investors. BoilingOptions is not an investment advisor. The calculations, information, and opinions on this site are for educational purposes only and are not investment advice. Calculations are estimates and do not account for all market conditions and events.',
  disclaimer: {
    sections: [
      {
        title: 'Disclosures & Footnotes',
        points: [
          '<b>Streamed Data:</b> Includes Stock, Expiration, Strike, Premium, Days Remaining.',
          '<b>Calculated Data:</b> Includes Breakeven, Yield, Update, First Published Premium, First Published At, History, and Probability. Probability is a calculated value based on the Binomial Model and Black-Scholes Model. Data updates approximately every 15 minutes for both Professional and Non-Professional users.',
        ],
      },
      {
        title: 'Market Data and Quotes',
        points: [
          'Prices and market values are real-time based on Cboe One Real-Time Quote, NASDAQ, and consolidated market quotes unless otherwise indicated. However, the algorithmic calculations result in a short delay before the data is published on the Dashboard. The source of the data is a third party.',
          'Quotes from the Toronto Stock Exchange and TSX Venture Exchange are delayed for professional users. Non-professional users may see a mix of real-time and delayed quotes. The source of the data is a third party.',
          'Historical PNL data (including PNL, Below Strike, and Week) is updated after the market closes each day and may take approximately 4 hours to complete the process.',
          'If there is no quote, Day Change and/or Price Change values will show as N/A or it may not show at all.',
        ],
      },
      {
        title: 'General Disclaimers',
        points: [
          '<b>Real-Time Gain/Loss Calculation:</b> Provided for informational purposes only, this is an estimate of your unrealized daily gains or losses. It does not include all necessary adjustments for computing your tax gains or losses or for reporting these gains or losses on your tax return. These estimates are not binding on the IRS.',
          '<b>Value Reporting:</b> The value reported may not reflect the current price.',
          '<b>Options Profit Calculator:</b> Assumes the position is opened on one contract per security published simultaneously. Profitability is based on the initial premium. Contracts are held until expiration, with shares sold at the closing price if assigned. This is just an estimate; please conduct your due diligence.',
          '<b>Options Optimizer:</b> This tool is designed to provide estimates. Users are advised to perform their own due diligence.',
          '<b>Probability Data:</b> The probability of the contract is streamed by a third party and may change by the time the data is published.',
        ],
      },
      {
        title: 'Liability',
        points: [
          'As stipulated in your agreement for the receipt and use of market data, BoilingOptions (1) reserves all rights to the market data provided; (2) does not guarantee the accuracy or completeness of the data; and (3) shall not be liable for any loss due either to their negligence or to any cause beyond their reasonable control.',
        ],
      },
      {
        title: 'Important Notice',
        points: [
          'All data and tools provided by BoilingOptions are intended for informational purposes only and should not be construed as financial advice. Users should independently verify all information and consider their individual financial situations before making any investment decisions.',
          'BoilingOptions shall not be liable for any errors or delays in the content, or for any actions taken in reliance thereon.',
        ],
      },
    ],
  },
  copyright: '© 2024 Galileo Alpha Sigma LLC | Privacy | Terms',
};

export const ROUTES = {
  HOME: '/',
  LOGIN: '/login',
  SIGNUP: '/signup',
  OTP: '/otp',
  FORGOT_PASSWORD: '/forgot-password',
  SET_NEW_PASSWORD: '/new-password',
  WRITE_A_REVIEW: '/write-review',
  DASHBOARD: '/dashboard',
  PROFILE_COMPLETION: '/profile-completion',
  PROFILE: '/profile',
  SUBSCRIPTION: '/subscription',
  CONTACT: '/contact',
  FAQ: '/faq',
  OPTIONS_PROFIT_CALCULATOR: '/options-profit-calculator',
  OPTIONS_OPTIMIZER: '/options-optimizer',
  TUTORIALS: '/tutorials',
};
export const FOOTER_LINKS = [
  { route: ROUTES.FAQ, label: 'FAQ' },
  // { route: '/', label: 'About' },
  { route: ROUTES.CONTACT, label: 'Contact' },
  { route: ROUTES.TUTORIALS, label: 'Tutorials' },
];
export const FOOTER_SECOND_LINKS = [
  { route: ROUTES.WRITE_A_REVIEW, label: 'Write a Review' },
];
export const MENU_ITEMS = [
  { title: 'Home', routeName: '/dashboard', icon: FiHome },
  { title: 'Profile', routeName: ROUTES.PROFILE, icon: AiOutlineUser },
];
export const HEADER_TEXT = {
  LOGOUT: 'Logout',
  FREE: 'Free',
  USER: 'User',
  ADMIN: 'Admin',
};
export enum USER_PLAN_TYPE {
  BASIC = 'Basic',
  PREMIUM = 'Premium',
}
export enum USER_ROLE {
  USER = 'user',
  ADMIN = 'admin',
}
export const PLANS = 'Plans';
export const NO_DATA_FOUND = 'No Data Available';
export const FOOTER_ITEMS = [
  {
    route: ROUTES.OPTIONS_PROFIT_CALCULATOR,
    label: 'Options Profit Calculator',
  },
  { route: ROUTES.OPTIONS_OPTIMIZER, label: 'Options Optimizer' },
  { route: ROUTES.SUBSCRIPTION, label: 'Upgrade' },
];
export const WELCOME_MODAL = {
  title: 'Welcome',
  description: [
    {
      text: 'Your Subscription has been created, but there is one last steps:',
      type: 'heading',
    },
    {
      text: 'Your Subscription gives you access to live data which is regulated by the Boiling Options and various exchanges. Similar to when you open a trading account, we are legally required to ask some questions to ensure that you are a non-professional, individual investor and that you will not share access to the data.',
      type: 'paragraph',
    },
    {
      text: 'Individuals and Non-Professionals',
      type: 'heading',
    },
    {
      text: 'Click here if you are an individual user who is not a finance professional.',
      type: 'paragraph',
    },
    {
      text: 'Organizations and Investment Professionals',
      type: 'heading',
    },
    {
      text: 'Click here if you represent a corporation or organization, or are an investment professional such as an investment advisor, or if you are registered with the SEC, NFA, or FINRA.',
      type: 'paragraph',
    },
  ],
  btnText: 'Proceed',
};

export const DUMMY_TOOLTIP_TEXT = `Lorem Ipsum is simply dummy text of the printing and
typesetting industry. Lorem Ipsum has been the industry's
standard dummy text ever since the 1500s.`;

export const PREMIUM_TOOLTIP_TEXT =
  'You can use this field in premium version only.';
export const YIELD_TEXT = 'Values are multiple of 1000';
export const TABLE_HISTORTY_TOOLTIP = {
  Date: 'Date',
  Qty: 'Qty',
  noData: 'No Data',
};
export const PROFIT_PROBABILITY_VALUE = {
  no: 1345.804,
  yes: 3930.265,
  grandTotal: 2584.46,
};
