import toast from 'react-hot-toast';
import axios from 'axios';
import { saveAs } from 'file-saver';

import {
  AllScreenerListTypeResponse,
  DeleteFilterResponseType,
  IFilterSectionPayload,
  IFilterSectionResponse,
  ScreenerListType,
  ScreenerResponseType,
} from 'src/@types/dashboardTypes';
import { API } from 'src/api/ApiInstance';
import { COMMON_ERROR, COMMON_SUCCESS } from 'src/constants/feedback';
import { REST_SUB_URL, REST_URL } from 'src/constants/url';
import { IActualStocks } from 'src/pages/Dashboard/components/Table/makeData';

import { Industries, Tickers } from './types';

export const getFiltersData = async () => {
  const response = await API.Get<ScreenerResponseType>(
    REST_SUB_URL.GET_SCREENER_FILTERS_OPTIONS,
  );
  return response?.data;
};
export const saveFilter = async (payload: IFilterSectionPayload) => {
  const response = await API.Post<
    IFilterSectionPayload,
    IFilterSectionResponse
  >(REST_SUB_URL.SAVE_FILTERS, {
    ...payload,
  });
  if (response.status) {
    toast.success(response?.data?.message || COMMON_SUCCESS);
    return response;
  }
  toast.error(response?.message || COMMON_ERROR);
  return response;
};
export const getAllFiltersDataByUser = async () => {
  const response = await API.Get<AllScreenerListTypeResponse>(
    REST_SUB_URL.SAVE_FILTERS,
  );
  return response?.data;
};
export const deleteFilter = async (filterId: string) => {
  const response = await API.Delete<DeleteFilterResponseType>(
    `${REST_SUB_URL.SAVE_FILTERS}/${filterId}`,
  );
  if (response.status) {
    toast.success(response?.data?.message || COMMON_SUCCESS);
    return true;
  }
  toast.error(response?.message || COMMON_ERROR);
  return false;
};
export const updateFilter = async (
  filterId: string | number,
  payload: IFilterSectionPayload,
) => {
  const response = await API.Put<IFilterSectionResponse, IFilterSectionPayload>(
    `${REST_SUB_URL.SAVE_FILTERS}/${filterId}`,
    { ...payload },
  );
  if (response.status) {
    toast.success(response?.data?.message || COMMON_SUCCESS);
    return response;
  }
  toast.error(response?.message || COMMON_ERROR);
  return response;
};

export const getStocksData = async () => {
  const response = await API.Get<{
    data: IActualStocks[];
    total_pages: number;
  }>(REST_SUB_URL.GET_STOCKS);
  return response?.data;
};

export const pinStock = async (id: number) => {
  const response = await API.Get(`${REST_SUB_URL.PINNED_STOCK}/${id}`);
  return response?.data;
};

export const unpinStock = async (id: number) => {
  const response = await API.Get(`${REST_SUB_URL.UNPINNED_STOCK}/${id}`);
  return response?.data;
};

export const getAllLatestSymbols = async ({ query = '', page = 0 }) => {
  const response = await API.Get<{
    data: {
      industries: Industries[];
      tickers: Tickers[];
      pagination: { totalPages: number };
    };
  }>(`${REST_SUB_URL.LATEST_SYMBOLS}?searchQuery=${query}&page=${page}`);
  return response?.data?.data;
};

export const tradeStock = async (id: number, quantity: number) => {
  const response = await API.Post(`${REST_SUB_URL.TRADE_STOCK}/${id}`, {
    quantity,
  });
  return response?.data;
};

export const exportData = async (
  filters: ScreenerListType,
  sort: string,
  isLive: boolean,
) => {
  try {
    const headers = await API.getHeaders();
    const response = await axios.get(`${REST_URL}${REST_SUB_URL.EXPORT_DATA}`, {
      params: {
        filters: JSON.stringify({
          filters,
        }),
        sort,
        isLive,
      },
      responseType: 'blob', // Important
      headers: {
        ...headers,
      },
    });

    const blob = new Blob([response.data], { type: 'text/csv' });
    saveAs(blob, 'completed.csv');
  } catch (error) {
    toast.error('File Upload Failed');
  }
};
