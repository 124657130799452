/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useWebSocket, { ReadyState } from 'react-use-websocket';

import { ProfitProbility } from 'src/@types/dashboardTypes';
// import Loader from 'src/components/Loader';
import withProtectedRoute from 'src/components/ProtectedRoute';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'src/components/ui/accordion';
import { ROUTES } from 'src/constants/common';
import {
  DASHBOARD_TEXT,
  FiltersKeyName,
  RISKYIELD_OPTIONS,
  TABLE_HEADING_TABS,
  TableFilterType,
} from 'src/constants/dashboard';
import { SUBSCRIPTION_MESSAGES } from 'src/constants/subscription';
import useAuthContext from 'src/hooks/useAuth';
import useDashboardContext from 'src/hooks/useDashboardData';
import { SORT, SUBSCRIPTION_TYPE } from 'src/services/types';
import { checkTokenValidity } from 'src/utils/common';

import FilterSection from './components/FilterSection';
import Table from './components/Table';
import { IActualStocks } from './components/Table/makeData';
import TableHeadingTabs from './components/tableHeadingTabs';

const Dashboard = () => {
  const navigate = useNavigate();
  const [_showText, setShowText] = useState('');
  const [lastUpdateTime, setLastUpdateTime] = useState(new Date());
  const [activeTab] = useState(SUBSCRIPTION_TYPE.BASIC);
  const [pageSize, setPageSize] = useState<number>(10);
  const [selectedTableTab, setSelectedTableTab] = useState(
    TABLE_HEADING_TABS.LIVE_FLOW.key,
  );
  const [sort, setSort] = useState(SORT.ALL);
  const [liveFlowstocks, setLiveFlowStocks] = useState<IActualStocks[]>([]);
  const [liveFlowcurrentPage, setLiveFlowCurrentPage] = useState<number>(1);
  const [liveFlowtotalPages, setLiveFlowTotalPages] = useState<
    number | undefined
  >();
  const [alert, setAlert] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [historicalStocks, setHistoricalStocks] = useState<IActualStocks[]>([]);
  const [historicalCurrentPage, setHistoricalCurrentPage] = useState<number>(1);
  const [historicalTotalPae, setHistoricalTotalPages] = useState<
    number | undefined
  >();
  const { auth } = useAuthContext();
  const { user } = auth ?? {};
  const { screener, setProfitProbability, setCalculatorError } =
    useDashboardContext();

  const getCardData = () => {
    if (user?.subscriptionType === SUBSCRIPTION_TYPE.BASIC) {
      setShowText(SUBSCRIPTION_MESSAGES.BASIC_PLAN);
    } else if (user?.subscriptionType === SUBSCRIPTION_TYPE.PREMIUM) {
      setShowText(SUBSCRIPTION_MESSAGES.PREMIUM_PLAN);
    } else {
      setShowText('');
    }
  };

  const isBasic = useMemo(
    () => user?.subscriptionType === SUBSCRIPTION_TYPE.BASIC,
    [user],
  );

  useEffect(() => {
    getCardData();
  }, []);

  const didUnmount = useRef(false);

  const { sendJsonMessage, readyState, lastJsonMessage } = useWebSocket(
    `${process.env.REACT_APP_WEBSOCKET_BASE_URL}`,
    {
      share: false,
      shouldReconnect: () => {
        return didUnmount.current === false;
      },
      onClose: () => console.log('socket disconnected'),
      onOpen: () => console.log('socket connected'),
      reconnectAttempts: 10,
      reconnectInterval: 3000,
    },
  );

  useEffect(() => {
    return () => {
      didUnmount.current = true;
    };
  }, []);

  useEffect(() => {
    if (readyState === ReadyState.OPEN && user) {
      setIsLoading(true);
      sendJsonMessage({
        action: 'register',
        userId: user?._id,
      });
      sendJsonMessage({
        action: 'getStocks',
        userId: user?._id,
      });
    }
  }, [readyState, user?._id]);

  // Run when a new WebSocket message is received (lastJsonMessage)
  useEffect(() => {
    if (lastJsonMessage !== null) {
      setIsLoading(false);
      setLastUpdateTime(new Date());
      if (
        ['stocks.updated', 'stocks.init', 'paginated.data'].includes(
          (lastJsonMessage as { type: string })?.type,
        ) &&
        selectedTableTab === TABLE_HEADING_TABS.LIVE_FLOW.key
      ) {
        setLiveFlowStocks((lastJsonMessage as { data: IActualStocks[] })?.data);
        setLiveFlowTotalPages(
          (lastJsonMessage as { totalPages: number })?.totalPages,
        );
        setLiveFlowCurrentPage((lastJsonMessage as { page: number })?.page);
        setPageSize((lastJsonMessage as { limit: number }).limit);
        setCalculatorError(
          (lastJsonMessage as { calculatorError: string })?.calculatorError,
        );
        setAlert((lastJsonMessage as { alert: any })?.alert);
      }
    }

    if (
      'historical.data' === (lastJsonMessage as { type: string })?.type &&
      selectedTableTab === TABLE_HEADING_TABS.HISTORICAL_FLOW.key
    ) {
      setHistoricalStocks((lastJsonMessage as { data: IActualStocks[] })?.data);
      setHistoricalTotalPages(
        (lastJsonMessage as { totalPages: number })?.totalPages,
      );
      setHistoricalCurrentPage((lastJsonMessage as { page: number })?.page);
      setPageSize((lastJsonMessage as { limit: number }).limit);
      setProfitProbability(
        (lastJsonMessage as { profitProbability: ProfitProbility })
          ?.profitProbability,
      );
      setAlert((lastJsonMessage as { alert: any })?.alert);
    }

    if (
      (lastJsonMessage as { type: string })?.type === 'invoice.paid' &&
      (lastJsonMessage as { data: { amount_paid: number } })?.data
        .amount_paid === 0
    ) {
      setShowText(SUBSCRIPTION_MESSAGES.YOUR_TRIAL);
    }
    if (
      (lastJsonMessage as { type: string })?.type === 'invoice.paid' &&
      (lastJsonMessage as { data: { amount_paid: number } })?.data.amount_paid >
        0
    ) {
      if (
        (lastJsonMessage as { data: { amount_paid: number } })?.data
          .amount_paid === 500
      ) {
        setShowText(SUBSCRIPTION_MESSAGES.BASIC_PLAN);
      } else {
        setShowText(SUBSCRIPTION_MESSAGES.PREMIUM_PLAN);
      }
    }
    if (
      (lastJsonMessage as { type: string })?.type === 'invoice.payment_failed'
    ) {
      setShowText(SUBSCRIPTION_MESSAGES.PAYMENT_FAILED);
    }
    if (
      (lastJsonMessage as { type: string })?.type === 'subscription.updated'
    ) {
      const amount = (
        lastJsonMessage as {
          data: { items: { data: { price: { unit_amount: number } }[] } };
        }
      )?.data?.items?.data?.[0]?.price?.unit_amount;

      if (amount === 500) {
        setShowText(SUBSCRIPTION_MESSAGES.BASIC_PLAN);
      } else {
        setShowText(SUBSCRIPTION_MESSAGES.PREMIUM_PLAN);
      }
    }
  }, [lastJsonMessage]);

  const handleTabChange = (item: string) => {
    setIsLoading(true);
    if (item === TABLE_HEADING_TABS.HISTORICAL_FLOW.key) {
      sendJsonMessage({
        action: 'requested_historical_data',
        userId: user?._id,
        page: 1,
        sort: 'all',
        filters: {
          filter: {
            [FiltersKeyName.RISKYIELD]: {
              name: DASHBOARD_TEXT.YIELD_BY_DAY,
              value: RISKYIELD_OPTIONS.YIELD_BY_DAY,
            },
            [FiltersKeyName.PROFIT_PROBABILITY_SHORT]: {
              start: 0,
              end: 100,
            },
          },
        },
        limit: Number(pageSize),
      });
    } else {
      sendJsonMessage({
        action: 'getStocks',
        userId: user?._id,
        page: 1,
        sort: 'all',
        filters: {
          filter: {
            [FiltersKeyName.RISKYIELD]: {
              name: DASHBOARD_TEXT.YIELD_BY_DAY,
              value: RISKYIELD_OPTIONS.YIELD_BY_DAY,
            },
            [FiltersKeyName.PROFIT_PROBABILITY_SHORT]: {
              start: 0,
              end: 100,
            },
          },
        },
        limit: Number(pageSize),
      });
    }
    setSelectedTableTab(item as TableFilterType);
  };

  useEffect(() => {
    const token = checkTokenValidity();
    if (!token) {
      navigate(ROUTES.LOGIN);
    }
  }, []);

  const handleResetFilters = () => {
    setIsLoading(true);
    if (selectedTableTab === TABLE_HEADING_TABS.HISTORICAL_FLOW.key) {
      sendJsonMessage({
        action: 'requested_historical_data',
        userId: user?._id,
        page: 1,
        sort: 'all',
        filters: {
          calculator: {},
          filter: {
            [FiltersKeyName.RISKYIELD]: {
              name: DASHBOARD_TEXT.YIELD_BY_DAY,
              value: RISKYIELD_OPTIONS.YIELD_BY_DAY,
            },
            [FiltersKeyName.PROFIT_PROBABILITY_SHORT]: {
              start: 0,
              end: 100,
            },
          },
          sorter: {},
        },
        limit: Number(pageSize),
      });
    } else {
      sendJsonMessage({
        action: 'request_paginated_data',
        userId: user?._id,
        page: 1,
        sort: 'all',
        filters: {
          calculator: {},
          filter: {
            [FiltersKeyName.RISKYIELD]: {
              name: DASHBOARD_TEXT.YIELD_BY_DAY,
              value: RISKYIELD_OPTIONS.YIELD_BY_DAY,
            },
            [FiltersKeyName.PROFIT_PROBABILITY_SHORT]: {
              start: 0,
              end: 100,
            },
          },
          sorter: {},
        },
        limit: Number(pageSize),
      });
    }
  };

  const handleApplyFilters = () => {
    setIsLoading(true);
    if (selectedTableTab === TABLE_HEADING_TABS.HISTORICAL_FLOW.key) {
      sendJsonMessage({
        action: 'requested_historical_data',
        userId: user?._id,
        page: historicalCurrentPage,
        sort: 'all',
        filters: screener,
        limit: Number(pageSize),
      });
    } else {
      sendJsonMessage({
        action: 'request_paginated_data',
        userId: user?._id,
        page: liveFlowcurrentPage,
        sort: 'all',
        filters: screener,
        limit: Number(pageSize),
      });
    }
  };

  const handleSelectValueChange = (value: string) => {
    let sortValue;

    if (value === 'first') {
      sortValue = SORT.PINNED;
    } else if (value === 'last') {
      sortValue = SORT.UNPINNED;
    } else {
      sortValue = SORT.ALL;
    }
    setIsLoading(true);
    setSort(sortValue);
    if (selectedTableTab === TABLE_HEADING_TABS.LIVE_FLOW.key) {
      sendJsonMessage({
        action: 'request_paginated_data',
        userId: user?._id,
        page: liveFlowcurrentPage,
        sort: sortValue,
        filters: screener,
        limit: Number(pageSize),
      });
    } else {
      sendJsonMessage({
        action: 'requested_historical_data',
        userId: user?._id,
        page: historicalCurrentPage,
        sort: sortValue,
        filters: screener,
        limit: Number(pageSize),
      });
    }
  };

  return (
    <>
      <div className='w-full bg-bobleBlack py-5 rounded-[18px]'>
        <div className='w-11/12 lg:w-10/12 mx-auto'>
          <Accordion type='single' defaultValue='item-1' collapsible>
            <AccordionItem value='item-1' className='border-none'>
              <div className='w-full flex justify-end'>
                <AccordionTrigger className='bg-darkGray rounded-md size-7 flex items-center justify-center' />
              </div>
              <AccordionContent>
                <FilterSection
                  activeTab={activeTab}
                  resetFilters={handleResetFilters}
                  applyFilters={handleApplyFilters}
                  selectedTableTab={selectedTableTab}
                  isLoading={isLoading}
                />
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </div>
      </div>
      <div>
        <TableHeadingTabs
          onTabChange={handleTabChange}
          selectedTableTab={selectedTableTab}
          isBasic={isBasic}
        />
        <div className='bg-bobleBlack rounded-3xl mb-20 py-6'>
          <Table
            selectedTableTab={selectedTableTab}
            setPageSize={setPageSize}
            pageSize={pageSize}
            sort={sort}
            alert={alert}
            data={
              selectedTableTab === TABLE_HEADING_TABS.LIVE_FLOW.key
                ? liveFlowstocks || []
                : historicalStocks || []
            }
            currentPage={
              selectedTableTab === TABLE_HEADING_TABS.LIVE_FLOW.key
                ? liveFlowcurrentPage
                : historicalCurrentPage
            }
            sendJsonMessage={sendJsonMessage}
            lastJsonMessage={lastJsonMessage}
            totalPages={
              selectedTableTab === TABLE_HEADING_TABS.LIVE_FLOW.key
                ? liveFlowtotalPages
                : historicalTotalPae
            }
            setCurrentPage={
              selectedTableTab === TABLE_HEADING_TABS.LIVE_FLOW.key
                ? setLiveFlowCurrentPage
                : setHistoricalCurrentPage
            }
            handleSelectChange={(value) => handleSelectValueChange(value)}
            handleChangePage={(page, limit) => {
              setIsLoading(true);
              if (selectedTableTab === TABLE_HEADING_TABS.HISTORICAL_FLOW.key) {
                sendJsonMessage({
                  action: 'requested_historical_data',
                  userId: user?._id,
                  page,
                  sort: 'all',
                  filters: screener,
                  limit,
                });
              } else {
                sendJsonMessage({
                  action: 'request_paginated_data',
                  userId: user?._id,
                  page,
                  sort: 'all',
                  filters: screener,
                  limit,
                });
              }
            }}
            isLoading={isLoading}
            lastUpdateTime={lastUpdateTime}
          />
        </div>
      </div>
    </>
  );
};

export default withProtectedRoute(Dashboard);
