import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { IoIosStar } from 'react-icons/io';

import { ErrorMessage as HookFormErrorMessage } from '@hookform/error-message';

import withProtectedRoute from 'src/components/ProtectedRoute';
import { Typography } from 'src/components/Typography';
import { Button } from 'src/components/ui/button';
import { Textarea } from 'src/components/ui/textArea';
import { INPUT_ERRORS, REVIEW } from 'src/constants/feedback';
import { cn } from 'src/lib/utils';
import { createReview } from 'src/services/review';
import { ReviewType } from 'src/services/types';

const WriteAReview = () => {
  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<ReviewType>({
    mode: 'all',
    defaultValues: {
      rating: 1,
      comment: '',
    },
  });
  const review = watch('comment');

  const handleReview: SubmitHandler<ReviewType> = async (data) => {
    const payload = {
      rating: data.rating,
      comment: data.comment,
    };
    const response = await createReview(payload);
    if (response) {
      reset();
    }
  };
  return (
    <div className='bg-nightSky md:px-10 md:py-6 mt-20 mb-40 lg:px-20 p-6 lg:py-8 flex flex-col mx-auto w-11/12 md:w-7/12 lg:w-5/12 rounded-[18px]'>
      <Typography variant='subheading' className=' self-center'>
        {REVIEW.TITLE}
      </Typography>
      <Typography className='text-[22px] font-semibold'>
        {REVIEW.SUB_HEADING}
      </Typography>
      <div className='flex flex-row mt-[18px]'>
        <Controller
          control={control}
          name='rating'
          render={({ field: { onChange, value } }) => (
            <div>
              {Array.from({ length: 5 }, (_, index) => (
                <button
                  className='rounded-[14px] bg-darkGray p-2 mr-[14px]'
                  key={index}
                  onClick={() => onChange(index + 1)}
                >
                  <IoIosStar
                    size={21}
                    className={cn({
                      'fill-starYellow': index < value,
                      'fill-fadedYellow': index >= value,
                    })}
                  />
                </button>
              ))}
            </div>
          )}
        />
      </div>
      <HookFormErrorMessage
        errors={errors}
        name={'rating'}
        render={({ message }) => (
          <p className='text-red_color mt-1 md:mt-2'>{message}</p>
        )}
      />
      <Typography className='text-[12px] mt-[10px]'>
        {REVIEW.CLICK_TO_RATE}
      </Typography>
      <Typography className='text-base mt-5 mb-4'>
        {REVIEW.YOUR_REVIEW}
      </Typography>
      <Controller
        control={control}
        name='comment'
        rules={{
          required: INPUT_ERRORS.REVIEW.required,
        }}
        render={({ field: { onChange, value } }) => (
          <Textarea
            placeholder={REVIEW.PLACEHOLDER}
            className='text-white resize-none focus:outline-none focus:ring-2 focus:ring-blue-500'
            maxLength={150}
            value={value}
            onChange={onChange}
          />
        )}
      />
      <HookFormErrorMessage
        errors={errors}
        name={'comment'}
        render={({ message }) => (
          <p className='text-red_color mt-1 md:mt-2'>{message}</p>
        )}
      />
      <div className='text-right text-white'>{review.length} - 150</div>
      <Button
        variant='gradient'
        onClick={handleSubmit(handleReview)}
        className='my-4 text-lg'
      >
        {REVIEW.BUTTON_TEXT}
      </Button>
    </div>
  );
};

export default withProtectedRoute(WriteAReview);
