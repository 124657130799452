import * as React from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';

import * as SliderPrimitive from '@radix-ui/react-slider';

import { cn } from 'src/lib/utils';

type SliderProps = {
  className?: string;
  min: number;
  max: number;
  minStepsBetweenThumbs: number;
  step: number;
  formatLabel?: (value: number) => string;
  value?: number[] | readonly number[];
  onValueChange?: (values: number[]) => void;
  startWithZero?: boolean;
};

const Slider = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Root>,
  SliderProps & React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root>
>(
  (
    {
      className,
      value,
      min,
      max,
      step,
      formatLabel,
      onValueChange,
      startWithZero,
      defaultValue,
      ...props
    },
    ref,
  ) => {
    const initialValue = Array.isArray(value) ? value : [min, max];
    const [localValues, setLocalValues] = React.useState(initialValue);

    const handleValueChange = (newValues: number[]) => {
      if (startWithZero) {
        if (newValues[1] !== undefined) {
          setLocalValues([0, newValues[1]]);
        }
      } else {
        setLocalValues(newValues);
      }
      if (onValueChange) {
        onValueChange(newValues);
      }
    };
    const renderContent = (index: number) => {
      if (index === 0) {
        if (startWithZero) {
          return;
        }
        return <MdKeyboardArrowRight size={14} fill='white' />;
      }
      return <MdKeyboardArrowLeft size={14} fill='white' />;
    };
    return (
      <SliderPrimitive.Root
        ref={ref}
        className={cn(
          'relative flex w-full touch-none select-none items-center',
          className,
        )}
        min={min}
        max={max}
        step={step}
        value={localValues}
        onValueChange={handleValueChange}
        {...props}
      >
        <SliderPrimitive.Track className='relative h-2 w-full grow overflow-hidden rounded-full bg-white'>
          <SliderPrimitive.Range className='absolute h-full bg-skyBlue' />
        </SliderPrimitive.Track>
        {localValues.map((value, index) => (
          <React.Fragment key={index}>
            <div
              className='absolute text-center text-white'
              style={{
                left: `calc(${((value - min) / (max - min)) * 100}% + 0px)`,
                top: '15px',
              }}
            >
              <span className='text-sm'>
                {formatLabel ? formatLabel(value) : value}
              </span>
            </div>
            <SliderPrimitive.Thumb className='block h-4 w-4 rounded-full bg-skyBlue bg-background shadow transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50'>
              <span className='flex items-center justify-center h-full w-full '>
                {renderContent(index)}
              </span>
            </SliderPrimitive.Thumb>
          </React.Fragment>
        ))}
        {localValues[localValues.length - 1] !== max &&
          (localValues?.[localValues.length - 1] ?? 0) <
            max - (localValues?.[0] ?? 0 > 200 ? 120 : 0) && (
            <div
              className='absolute text-center text-white'
              style={{
                right: '7px',
                top: '15px',
                transform: 'translateX(50%)',
              }}
            >
              <span className='text-sm'>
                {formatLabel ? formatLabel(max) : max}
              </span>
            </div>
          )}
      </SliderPrimitive.Root>
    );
  },
);
Slider.displayName = SliderPrimitive.Root.displayName;

export { Slider };
