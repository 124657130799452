import React, { useEffect, useState } from 'react';

import UserImg from 'src/assets/images/user.png';
import cardImage from 'src/assets/svgs/card.svg';
import visaCardImage from 'src/assets/svgs/visa-card.svg';
import { Typography } from 'src/components/Typography';
import { CARD_TYPE, PROFILE } from 'src/constants/profile';
import useAuthContext from 'src/hooks/useAuth';
import { getCardInfo } from 'src/services/profile';
import { CardInfo } from 'src/services/types';

const LinkedCard = () => {
  const [cardInfo, setCardInfo] = useState<CardInfo | null>(null);
  const { auth } = useAuthContext();
  const { user } = auth ?? {};

  useEffect(() => {
    getCardData();
  }, []);

  const getCardData = async () => {
    const response = await getCardInfo();
    setCardInfo(response?.data as CardInfo);
  };
  return (
    <div className='bg-nightSky w-11/12 mx-auto p-3 md:p-6 m-4 rounded-2xl'>
      <div className='flex justify-between'>
        <Typography variant='subheading' className='text-lg md:text-2xl'>
          {PROFILE.linkedCard}
        </Typography>
      </div>
      <div className='lg:w-1/2 md:grid grid-cols-2 mt-5 lg:mt-0 items-center space-y-5 md:space-y-0 text-white'>
        <Typography className='text-sm'>{PROFILE.linkedCardText}</Typography>
        <div className='border border-borderColor px-5 pt-3 pb-1 rounded-2xl bg-bobleBlack'>
          <div className='flex items-center gap-1 my-2'>
            <img
              src={user?.avatar || UserImg}
              className='size-8 rounded-full'
            />
            <Typography className='text-base'>
              {cardInfo ? `${user?.firstName} ${user?.lastName}` : 'John Doe'}
            </Typography>
          </div>
          <div className='flex gap-1 items-start '>
            {cardInfo?.cardType === CARD_TYPE.VISA ? (
              <img alt='visa' src={visaCardImage} className='sm:w-14' />
            ) : null}
            {cardInfo?.cardType === CARD_TYPE.MASTER ? (
              <img alt='card' src={cardImage} className='min-w-14' />
            ) : null}
            <div>
              <Typography className='text-xs'>
                {`*******${cardInfo?.last4 || '1234'} | Expires ${
                  cardInfo?.expMonth || '09'
                }/${cardInfo?.expYear || '2024'}`}
              </Typography>
              <Typography className='text-xs text-gray-400'>
                {PROFILE.defaultPaymentMethod}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LinkedCard;
