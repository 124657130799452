/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react';
import { PieChart } from 'react-minimal-pie-chart';

import Divider from 'src/components/Divider';
import { Typography } from 'src/components/Typography';
import { Skeleton } from 'src/components/ui/skeleton';
import { DASHBOARD_TEXT } from 'src/constants/dashboard';
import useDashboardContext from 'src/hooks/useDashboardData';
import { formatNumber } from 'src/utils/common';

import FiltersHeading from '../FiltersHeading';

import { pieChartustomStyles } from './CustomStyle';

interface Props {
  isLoading: boolean;
}

const ProfitProbability: React.FC<Props> = ({ isLoading }) => {
  const { profitProbability } = useDashboardContext();

  const chartData = useMemo(() => {
    if (profitProbability) {
      return [
        {
          title: 'yes',
          value: profitProbability?.yesPercentage,
          color: '#B279FF',
          key: 1,
        },
        {
          title: 'no',
          value: profitProbability?.noPercentage,
          color: '#FFB800',
          key: 2,
        },
      ];
    }
    return [
      {
        title: 'yes',
        value: 0,
        color: '#B279FF',
        key: 1,
      },
      {
        title: 'no',
        value: 0,
        color: '#FFB800',
        key: 2,
      },
    ];
  }, [profitProbability]);
  return (
    <div>
      <div className='py-5'>
        <FiltersHeading text={DASHBOARD_TEXT.PROFIT_PROBABILITY} />
      </div>
      <Divider className='border-b-2' />
      <div className='pt-5 pb-1 grid lg:grid-cols-2 gap-x-8 gap-y-8 lg:gap-y-0'>
        <div className=' bg-darkGray rounded-[10px] flex py-5 items-center'>
          <div className='flex w-full  mx-3 xl:w-2/3 xl:mx-auto gap-8'>
            <div className='w-1/2 pb-1'>
              <Typography className='block mb-2 font-normal text-sm sm:text-base text-lightGray'>
                {DASHBOARD_TEXT.BELOWSTRIKE}
              </Typography>
              <div className='space-y-4'>
                {profitProbability?.totalYesPnl === 0 ? null : (
                  <Typography className='block font-medium text-sm sm:text-base'>
                    {DASHBOARD_TEXT.YES}
                  </Typography>
                )}
                {profitProbability?.totalCountNo === 0 ? null : (
                  <Typography className='block font-medium text-sm sm:text-base'>
                    {DASHBOARD_TEXT.NO}
                  </Typography>
                )}
                <Typography className='block font-medium text-sm sm:text-base'>
                  {DASHBOARD_TEXT.GRAND_TOTAL}
                </Typography>
              </div>
            </div>
            <div className='bg-borderColor w-[2px]'></div>
            <div className='w-1/2 pb-1'>
              <Typography className='block mb-2 text-sm sm:text-base font-normal text-lightGray'>
                {DASHBOARD_TEXT.VALUE_SUM_OF}
              </Typography>
              <div className='space-y-4'>
                {profitProbability?.totalYesPnl === 0 ? null : isLoading ? (
                  <Skeleton className='h-6 w-16 bg-borderGrayColor' />
                ) : (
                  <Typography className='block font-medium text-sm sm:text-base'>
                    {profitProbability?.totalYesPnl
                      ? formatNumber(profitProbability?.totalYesPnl)
                      : 0}
                  </Typography>
                )}
                {profitProbability?.totalNoPnl === 0 ? null : isLoading ? (
                  <Skeleton className='h-6 w-16 bg-borderGrayColor' />
                ) : (
                  <Typography className='block font-medium text-sm sm:text-base'>
                    {profitProbability?.totalNoPnl
                      ? formatNumber(
                          Math.abs(
                            parseFloat(
                              parseFloat(
                                profitProbability?.totalNoPnl.toString(),
                              ).toFixed(2),
                            ),
                          ),
                        )
                      : 0}
                  </Typography>
                )}
                {isLoading ? (
                  <Skeleton className='h-6 w-16 bg-borderGrayColor' />
                ) : (
                  <Typography className='block font-medium text-sm sm:text-base'>
                    {profitProbability?.totalNoPnl ||
                    profitProbability?.totalYesPnl
                      ? formatNumber(
                          profitProbability?.totalYesPnl -
                            Math.abs(
                              parseFloat(
                                parseFloat(
                                  profitProbability?.totalNoPnl.toString(),
                                ).toFixed(2),
                              ),
                            ),
                        )
                      : 0}
                  </Typography>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='bg-darkGray rounded-[10px] p-3 '>
          <div className='w-full gap-8 sm:grid grid-cols-5 h-full'>
            <div className='flex justify-center items-center col-span-2 relative mb-5 sm:mb-0'>
              {isLoading ? (
                <Skeleton className='h-6 w-16 bg-borderGrayColor absolute top-25' />
              ) : (
                <Typography className='absolute top-25'>
                  {profitProbability?.grandTotal === 0 ? '00.00%' : '100.00%'}
                </Typography>
              )}
              {isLoading ? (
                <Skeleton className='w-44 h-44 bg-borderGrayColor rounded-full' />
              ) : profitProbability?.grandTotal === 0 ? null : (
                <PieChart
                  paddingAngle={7}
                  labelStyle={pieChartustomStyles}
                  labelPosition={76}
                  startAngle={-10}
                  lineWidth={45}
                  className='size-44'
                  label={({ dataEntry }) => `${dataEntry.value.toFixed(2)}%`}
                  data={chartData}
                />
              )}
            </div>
            <div className='flex flex-col justify-center items-start space-y-2 col-span-3'>
              <Typography className='text-sm flex items-center justify-center gap-1.5'>
                <div className='size-[10px] bg-lightPurple rounded-full' />
                {DASHBOARD_TEXT.BELOWSTRIKE_YES}
              </Typography>
              <Typography className='text-sm  flex items-center justify-center gap-1.5'>
                <div className='size-[10px] bg-starYellow rounded-full' />
                {DASHBOARD_TEXT.BELOWSTRIKE_NO}
              </Typography>
              <Typography className='text-xs flex items-center justify-center gap-1.5'>
                {DASHBOARD_TEXT.CHART_SECTION_TEXT}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfitProbability;
