import React from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';

import FormField from 'src/components/FormField';
import { PROFILE_COMPLETION_INPUT_ERRORS } from 'src/constants/aterlogin';
import { PROFILE_COMPLETION } from 'src/constants/profileCompletion';
import { BASIC_INFO } from 'src/services/types';
import { isAlpha } from 'src/utils/common';
type NameFiledsProps = {
  errors: FieldErrors<BASIC_INFO>;
  control: Control<BASIC_INFO, any>;
};

const NameFileds: React.FC<NameFiledsProps> = ({ errors, control }) => {
  return (
    <div className='grid grid-cols-1 md:grid-cols-3 md:gap-7'>
      <Controller
        control={control}
        name='firstName'
        rules={{
          required: PROFILE_COMPLETION_INPUT_ERRORS.FIRST_NAME.required,
          validate: {
            isAlpha: (value) =>
              isAlpha(value) ||
              PROFILE_COMPLETION_INPUT_ERRORS.NAME_TYPE.acceptFormat,
          },
          maxLength: {
            value: 24,
            message: PROFILE_COMPLETION_INPUT_ERRORS.NAME_MAX_LENGTH,
          },
        }}
        render={({ field: { onChange, value, name } }) => (
          <FormField
            type='text'
            labelCalssName='text-base'
            title={PROFILE_COMPLETION.FIRST_NAME.title}
            placeholder={PROFILE_COMPLETION.FIRST_NAME.placeholder}
            name={name}
            value={value}
            onChange={onChange}
            maxLength={25}
            errors={errors}
            isRequired
          />
        )}
      />
      <Controller
        control={control}
        name='middleName'
        rules={{
          validate: {
            isAlphaIfNotEmpty: (value) => {
              if (value.trim() === '') {
                return true;
              }
              return (
                isAlpha(value) ||
                PROFILE_COMPLETION_INPUT_ERRORS.NAME_TYPE.acceptFormat
              );
            },
          },
          maxLength: {
            value: 24,
            message: PROFILE_COMPLETION_INPUT_ERRORS.NAME_MAX_LENGTH,
          },
        }}
        render={({ field: { onChange, value, name } }) => (
          <FormField
            type='text'
            labelCalssName='text-base mb-3'
            title={PROFILE_COMPLETION.MIDDLE_NAME.title}
            placeholder={PROFILE_COMPLETION.MIDDLE_NAME.placeholder}
            name={name}
            value={value}
            onChange={onChange}
            errors={errors}
          />
        )}
      />
      <Controller
        control={control}
        name='lastName'
        rules={{
          required: PROFILE_COMPLETION_INPUT_ERRORS.LAST_NAME.required,
          validate: {
            isAlphaIfNotEmpty: (value) => {
              if (value.trim() === '') {
                return true;
              }
              return (
                isAlpha(value) ||
                PROFILE_COMPLETION_INPUT_ERRORS.NAME_TYPE.acceptFormat
              );
            },
          },
          maxLength: {
            value: 24,
            message: PROFILE_COMPLETION_INPUT_ERRORS.NAME_MAX_LENGTH,
          },
        }}
        render={({ field: { onChange, value, name } }) => (
          <FormField
            type='text'
            labelCalssName='text-base'
            title={PROFILE_COMPLETION.LAST_NAME.title}
            placeholder={PROFILE_COMPLETION.LAST_NAME.placeholder}
            name={name}
            value={value}
            onChange={onChange}
            errors={errors}
            isRequired
          />
        )}
      />
    </div>
  );
};

export default NameFileds;
