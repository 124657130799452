import React, { useEffect, useState } from 'react';
import { LiaCalendarDaySolid } from 'react-icons/lia';

import { DropDownListType } from 'src/@types/dashboardTypes';
import { Input } from 'src/components/ui/input';
import { cn } from 'src/lib/utils';

import LabelText from '../LabelText';

interface Props {
  label: string;
  keyName: string;
  infoText?: string;
  value?: { min: string; max: string };
  onChange: (name: string, value: string | number | DropDownListType) => void;
}

const DaysRemaining: React.FC<Props> = ({
  label,
  onChange,
  keyName,
  value,
  infoText = '',
}) => {
  const [min, setMin] = useState('');
  const [max, setMax] = useState('');
  const [isFocusedMinInput, setIsFocusedMinInput] = useState(false);
  const [isFocusedMaxInput, setIsFocusedMaxInput] = useState(false);

  useEffect(() => {
    setMin(value?.min ? value.min.toString() : '');
    setMax(value?.max ? value.max.toString() : '');
  }, [value]);

  const handleMinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(keyName, { min: e.target.value, max } as DropDownListType);
  };

  const handleMaxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(keyName, { max: e.target.value, min } as DropDownListType);
  };

  return (
    <div>
      <LabelText label={label} infoText={infoText} />
      <div className='flex flex-row mt-3'>
        <Input
          name={'min'}
          placeholder={'Min.'}
          value={min}
          onFocus={() => setIsFocusedMinInput(true)}
          onBlur={() => setIsFocusedMinInput(false)}
          onChange={handleMinChange}
          type={'number'}
          containerStyles={cn('w-full h-11 mr-[9px]', {
            '!ring-2 !ring-skyBlue': value?.min && !isFocusedMinInput,
          })}
          icon={<LiaCalendarDaySolid size={19} fill='white' />}
        />
        <Input
          name={'max'}
          placeholder={'Max.'}
          value={max}
          onFocus={() => setIsFocusedMaxInput(true)}
          onBlur={() => setIsFocusedMaxInput(false)}
          min={0}
          onChange={handleMaxChange}
          type={'number'}
          containerStyles={cn('w-full h-11 mr-[9px] focus:!border-none', {
            '!ring-2 !ring-skyBlue': value?.max && !isFocusedMaxInput,
          })}
          icon={<LiaCalendarDaySolid size={19} fill='white' />}
        />
      </div>
    </div>
  );
};

export default DaysRemaining;
