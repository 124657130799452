import dayjs from 'dayjs';

import { UserType } from 'src/providers/Auth/types';

import { JSONParse } from './jsonparse';

export const isUndefined = (value: any) => value === undefined;
export const isDefined = (value: any) => value !== undefined;
export const isNull = (value: any) => value === null;

export const EmailRegex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/;
export const isAlpha = (value: string) => /^[A-Za-z\s]+$/.test(value);
export const isNumeric = (value: string) => /^[0-9]+$/.test(value);

export const setTokenToLS = (token: string) => {
  if (typeof localStorage !== 'undefined') {
    localStorage.setItem('token', JSON.stringify(token));
  }
};
export const setUserToLS = (user: UserType) => {
  if (typeof localStorage !== 'undefined') {
    localStorage.setItem('user', JSON.stringify(user));
  }
};

export const setRememberMeToSS = (isRememberMe: boolean) => {
  if (typeof sessionStorage !== 'undefined') {
    sessionStorage.setItem('isRememberMe', JSON.stringify(isRememberMe));
  }
};
export const setUserToSS = (user: UserType) => {
  if (typeof localStorage !== 'undefined') {
    sessionStorage.setItem('user', JSON.stringify(user));
  }
};

export const getRememberMeFromSS = () => {
  if (typeof sessionStorage !== 'undefined') {
    return JSONParse(sessionStorage.getItem('isRememberMe') as string);
  }
  return false;
};

export const setRememberMeToLS = (isRememberMe: boolean) => {
  if (typeof localStorage !== 'undefined') {
    localStorage.setItem('isRememberMe', JSON.stringify(isRememberMe));
  }
};

export const setEmailLS = (email: string) => {
  if (typeof localStorage !== 'undefined') {
    localStorage.setItem('email', JSON.stringify(email));
  }
};

export const getRememberMeFromLS = () => {
  if (typeof localStorage !== 'undefined') {
    return JSONParse(localStorage.getItem('isRememberMe') as string);
  }
  return false;
};

export const getEmailFromLS = () => {
  if (typeof localStorage !== 'undefined') {
    return JSONParse(localStorage.getItem('email') as string);
  }
  return '';
};

export const getTokenFromLS = () => {
  if (typeof localStorage !== 'undefined') {
    return JSONParse(localStorage.getItem('token') as string);
  }
  return null;
};

export const setTokenToSS = (token: string) => {
  if (typeof sessionStorage !== 'undefined') {
    sessionStorage.setItem('token', JSON.stringify(token));
  }
};
export const getUserFromLS = () => {
  if (typeof localStorage !== 'undefined') {
    return JSONParse(localStorage.getItem('user') as string);
  }
  return null;
};
export const getUserFromSS = () => {
  if (typeof sessionStorage !== 'undefined') {
    return JSONParse(sessionStorage.getItem('user') as string);
  }
  return null;
};
export const getAuthUser = (): UserType => {
  const user = getUserFromSS() || getUserFromLS();
  return user as UserType;
};

export const getTokenFromSS = () => {
  if (typeof sessionStorage !== 'undefined') {
    return JSONParse(sessionStorage.getItem('token') as string);
  }
  return null;
};

export const checkTokenValidity = () => {
  const token = getTokenFromLS() || getTokenFromSS();
  if (!token) {
    return false;
  }
  return true;
};

export const maskEmailAddress = (email: string) => {
  if (email) {
    return email.replace(
      /^(.)(.*)(.@.*)$/,
      (_, a, b, c) => a + b.replace(/./g, '*') + c,
    );
  }
  return '';
};

export const getAuthToken = () => {
  const token = getTokenFromLS() || getTokenFromSS();
  return token || '';
};

export const getRememberMe = () => {
  const isRememberMe = getRememberMeFromLS() || getRememberMeFromSS();
  return isRememberMe;
};

export const getEmail = () => {
  const email = getEmailFromLS();
  return email || '';
};

export const removeAuthToken = () => {
  localStorage.removeItem('token');
  sessionStorage.removeItem('token');
  localStorage.removeItem('isRememberMe');
  sessionStorage.removeItem('isRememberMe');
  localStorage.removeItem('user');
  sessionStorage.removeItem('user');
};

export const getFormattedDate = (date: string) => {
  const _date = dayjs(date);
  const formattedDate = _date.format('YYYY-MM-DD');
  return formattedDate;
};
export const truncateText = (text: string) =>
  text.length > 13 ? `${text.slice(0, 13)}...` : text;

export const formatNumber = (number: number) => {
  const absNumber = Math.abs(number);

  if (absNumber >= 1e24) {
    return `${Math.floor(number / 1e24)} Y`;
  } else if (absNumber >= 1e21) {
    return `${Math.floor(number / 1e21)} Z`;
  } else if (absNumber >= 1e18) {
    return `${Math.floor(number / 1e18)} E`;
  } else if (absNumber >= 1e15) {
    return `${Math.floor(number / 1e15)} P`;
  } else if (absNumber >= 1e12) {
    return `${Math.floor(number / 1e12)} T`;
  } else if (absNumber >= 1e9) {
    return `${Math.floor(number / 1e9)} B`;
  } else if (absNumber >= 1e6) {
    return `${Math.floor(number / 1e6)} M`;
  } else if (absNumber >= 1e3) {
    return `${Math.floor(number / 1e3)} K`;
  }
  return number.toString();
};
