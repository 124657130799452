import * as React from 'react';

import { cn } from 'src/lib/utils';

interface Props {
  icon?: React.ReactNode;
  containerStyles?: string | undefined;
  notEditable?: boolean;
}

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> & Props;

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    { className, containerStyles, disabled, type, notEditable, icon, ...props },
    ref,
  ) => {
    return (
      <div
        className={cn(
          ' flex realtive flex-row items-center h-10 w-full rounded-xl border-none border-slate-200 bg-darkGray ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-lightGray focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 dark:border-slate-800 dark:bg-darkGray dark:ring-offset-slate-950 dark:placeholder:text-lightGray dark:focus-visible:ring-slate-300 border border-input text-sm ring-offset-background focus-within:ring-1 focus-within:ring-ring focus-within:ring-offset-2',
          {
            'opacity-50': disabled && !notEditable,
          },
          containerStyles,
        )}
      >
        {icon ? <div className='p-1'>{icon}</div> : null}
        <input
          type={type}
          disabled={disabled}
          className={cn(
            'flex h-full w-full text-white text-md rounded-xl px-3 py-2 dark:bg-darkGray bg-darkGray placeholder:text-lightGray focus:outline-none focus:ring-0 file:bg-transparent disabled:opacity-50 dark:ring-offset-slate-950 items-center pl-3 text-sm',
            {
              '!opacity-100': notEditable,
            },
            className,
          )}
          ref={ref}
          {...props}
        />
      </div>
    );
  },
);
Input.displayName = 'Input';

export { Input };
