import React from 'react';

import { Button } from 'src/components/ui/button';
import {
  DASHBOARD_TEXT,
  SAVE_UPDATE_FILTER_MODAL,
} from 'src/constants/dashboard';
import useDashboardContext from 'src/hooks/useDashboardData';

import FiltersHeading from '../FiltersHeading';
import SaveAndEditFilterDialog from '../saveAndEditFilterDialog';

type ScreenerTopbarProps = {
  handleOpenSaveFilterDialog: () => void;
  openSave: boolean;
  setOpenSave: React.Dispatch<React.SetStateAction<boolean>>;
};

const ScreenerTopbar: React.FC<ScreenerTopbarProps> = ({
  handleOpenSaveFilterDialog,
  openSave,
  setOpenSave,
}) => {
  const {
    screener,
    handleSaveFilter,
    removeFilter,
    setScreenerName,
    screenerName,
  } = useDashboardContext();
  return (
    <>
      <SaveAndEditFilterDialog
        open={openSave}
        title={SAVE_UPDATE_FILTER_MODAL.save.title}
        btnText={SAVE_UPDATE_FILTER_MODAL.save.title}
        setOpen={setOpenSave}
        handleBtn={handleSaveFilter}
        data={screener}
        removeFilter={removeFilter}
        onChange={setScreenerName}
        screenerName={screenerName}
      />
      <div className='py-5 flex justify-between items-center'>
        <FiltersHeading text={DASHBOARD_TEXT.TITLE} />
        <Button
          className='py-0 h-7 text-base px-0 text-white rounded-lg bg-transparent hover:bg-transparent'
          onClick={handleOpenSaveFilterDialog}
        >
          {DASHBOARD_TEXT.SAVE_FILTER}
        </Button>
      </div>
    </>
  );
};

export default ScreenerTopbar;
