import React from 'react';
import { RxCross2 } from 'react-icons/rx';

import { IScreenerType } from 'src/@types/dashboardTypes';
import Modal from 'src/components/Modal';
import { Typography } from 'src/components/Typography';
import { Input } from 'src/components/ui/input';
import { Label } from 'src/components/ui/label';
import {
  FILTER_LABEL_ENUM,
  SAVE_UPDATE_FILTER_MODAL,
} from 'src/constants/dashboard';
import { cn } from 'src/lib/utils';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  btnText: string;
  handleBtn: () => void;
  data: IScreenerType | undefined;
  removeFilter: (name: string) => void;
  onChange: (name: string) => void;
  screenerName: string;
}
interface ValueObject {
  callPutRatioValue?: number;
  priceToEarningsRatioValue?: number;
  operator?: { name: string };
  [key: string]: any;
}
const SaveAndEditFilterDialog: React.FC<Props> = ({
  open,
  setOpen,
  title,
  btnText,
  handleBtn,
  data,
  removeFilter,
  onChange,
  screenerName,
}) => {
  const convertedFilters = data?.filter
    ? Object.entries(data.filter).map(([key, value]) => {
        return { key, value };
      })
    : [];
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };
  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title={title}
      btnText={btnText}
      handleBtn={handleBtn}
      buttonDisabled={!convertedFilters?.length || !screenerName}
    >
      <div className='max-h-96'>
        <div>
          <Label
            className={cn(
              'text-white text-xs xl:text-base font-light flex items-center gap-2 xl:gap-4',
            )}
          >
            {SAVE_UPDATE_FILTER_MODAL.label}
          </Label>
          <Input
            placeholder={SAVE_UPDATE_FILTER_MODAL.placeholder}
            containerStyles={cn(
              'w-full mt-4 h-11 ring-offset-0 focus:!ring-0 focus:!ring-offset-0 focus:!border-none',
            )}
            maxLength={50}
            onChange={handleNameChange}
            value={screenerName}
          />
        </div>
        <Typography className='text-base font-light text-white mt-6'>
          {convertedFilters?.length
            ? SAVE_UPDATE_FILTER_MODAL.secondLabel
            : SAVE_UPDATE_FILTER_MODAL.thirdLabel}
        </Typography>
        <div
          className={`flex p-2 flex-wrap overflow-y-auto max-h-44  ${
            convertedFilters?.length ? null : 'hidden'
          }`}
        >
          {convertedFilters?.length
            ? convertedFilters?.map(({ key, value }) => {
                let displayValue = '';
                if (value instanceof Date) {
                  displayValue = value.toDateString();
                } else if (
                  typeof value === 'object' &&
                  'min' in value &&
                  'max' in value
                ) {
                  displayValue = `Min: ${value.min}, Max: ${value.max}`;
                } else if (
                  typeof value === 'object' &&
                  'value' in value &&
                  'name' in value
                ) {
                  displayValue = `${value.name}`;
                } else if (
                  Array.isArray(value) &&
                  value.every((item) => 'symbol' in item)
                ) {
                  displayValue = value.map((v) => `${v.symbol}`).join(', ');
                } else if (
                  Array.isArray(value) &&
                  value.every((item) => 'name' in item)
                ) {
                  displayValue = value
                    .map((v: { name: string }) => `${v.name}`)
                    .join(', ');
                } else if (
                  typeof value === 'object' &&
                  'start' in value &&
                  'end' in value
                ) {
                  displayValue = `Start: ${value.start}, End: ${value.end}`;
                } else if (typeof value === 'object') {
                  const valueObj = value as ValueObject;
                  if ('callPutRatioValue' in valueObj) {
                    displayValue =
                      'operator' in valueObj
                        ? `Operator: ${valueObj.operator?.name}, Value: ${valueObj.callPutRatioValue}`
                        : `Value: ${valueObj.callPutRatioValue}`;
                  } else if ('priceToEarningsRatioValue' in valueObj) {
                    displayValue =
                      'operator' in valueObj
                        ? `Operator: ${valueObj.operator?.name}, Value: ${valueObj.priceToEarningsRatioValue}`
                        : `Value: ${valueObj.priceToEarningsRatioValue}`;
                  }
                } else {
                  displayValue = value.toString();
                }
                return (
                  <div
                    className='min-h-8 text-white text-sm rounded-[15px] inline-flex items-center py-2 px-3 bg-darkGray mr-[10px] mt-[10px]'
                    key={`${
                      FILTER_LABEL_ENUM[key as keyof typeof FILTER_LABEL_ENUM]
                    }`}
                  >
                    <Typography className='text-[14px] font-medium'>
                      {FILTER_LABEL_ENUM[key as keyof typeof FILTER_LABEL_ENUM]}
                    </Typography>{' '}
                    |{' '}
                    <Typography className='text-[11px] font-normal'>
                      {displayValue}
                    </Typography>
                    <button
                      type='button'
                      className='ml-[6px]'
                      onClick={() => removeFilter?.(key)}
                    >
                      <RxCross2 fill='white' />
                    </button>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </Modal>
  );
};

export default SaveAndEditFilterDialog;
