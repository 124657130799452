import React, { useState } from 'react';

import Divider from 'src/components/Divider';
import { Button } from 'src/components/ui/button';
import {
  DASHBOARD_TEXT,
  FiltersKeyName,
  RISKYIELD_OPTIONS,
  TableFilterType,
} from 'src/constants/dashboard';
import useDashboardContext from 'src/hooks/useDashboardData';
import { SUBSCRIPTION_TYPE } from 'src/services/types';

import Calculator from '../Calculator';
import HistoricalFilter from '../HistoricalFilter';
import ProfitProbability from '../ProfitProbability';
import Screener from '../Screener';
import SelectPlanModal from '../SelectPlanModal';

interface Props {
  activeTab: string;
  applyFilters: () => void;
  resetFilters: () => void;
  selectedTableTab: TableFilterType;
  isLoading: boolean;
}

const FilterSection: React.FC<Props> = ({
  activeTab,
  applyFilters,
  resetFilters,
  selectedTableTab,
  isLoading,
}) => {
  const [open, setOpen] = useState(false);
  const { setScreener, setSelectedValueScreener } = useDashboardContext();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleResetFilter = () => {
    resetFilters();
    setScreener({
      filter: {
        [FiltersKeyName.RISKYIELD]: {
          name: DASHBOARD_TEXT.YIELD_BY_DAY,
          value: RISKYIELD_OPTIONS.YIELD_BY_DAY,
        },
        [FiltersKeyName.PROFIT_PROBABILITY_SHORT]: {
          start: 0,
          end: 100,
        },
      },
      calculator: {},
    });
    setSelectedValueScreener('');
  };

  return (
    <div className='bg-nightSky text-white mt-5 rounded-[18px] px-4 pb-7'>
      {activeTab === SUBSCRIPTION_TYPE.BASIC ? (
        <>
          <Screener handleOpen={handleOpen} />
          {selectedTableTab === TableFilterType.historicalFlow ? (
            <HistoricalFilter handleOpen={handleOpen} />
          ) : null}
          {selectedTableTab === TableFilterType.historicalFlow ? (
            <ProfitProbability isLoading={isLoading} />
          ) : (
            <Calculator handleOpen={handleOpen} />
          )}
        </>
      ) : null}
      <Divider className='border-b-2 my-5' />
      <div className='flex justify-center sm:justify-end'>
        <div className='flex flex-col sm:flex-row gap-4'>
          <Button
            variant='primary'
            className='hover:bg-lightBlue md:p-3 lg:py-[22px] lg:px-10 text-base font-medium'
            onClick={handleResetFilter}
          >
            {DASHBOARD_TEXT.RESET_FILTER}
          </Button>
          <Button
            variant='gradient'
            className='md:p-3 lg:py-[22px] lg:px-10 text-base font-medium'
            onClick={applyFilters}
          >
            {DASHBOARD_TEXT.APPLY_FILTER}
          </Button>
        </div>
        <SelectPlanModal open={open} setOpen={setOpen} />
      </div>
    </div>
  );
};

export default FilterSection;
