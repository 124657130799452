import React from 'react';
import { LiaInfoCircleSolid } from 'react-icons/lia';

import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipPortal,
  TooltipProvider,
  TooltipTrigger,
} from './ui/tooltip';

interface Props {
  tooltipText: string;
}

const InfoIconTooltip: React.FC<Props> = ({ tooltipText }) => {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <span>
            <LiaInfoCircleSolid
              size={'small'}
              className='inline-flex h-[20px] w-[20px] items-center justify-center outline-none'
            />
          </span>
        </TooltipTrigger>
        <TooltipPortal>
          <TooltipContent
            sideOffset={5}
            className='max-w-[300px] overflow-hidden data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade select-none rounded-[14px] bg-navyBlue px-[15px] py-[10px] text-[13px] text-white leading-[17px] '
          >
            {tooltipText}
            <TooltipArrow className='fill-navyBlue w-3 h-2' />
          </TooltipContent>
        </TooltipPortal>
      </Tooltip>
    </TooltipProvider>
  );
};

export default InfoIconTooltip;
