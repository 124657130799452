import React, { useEffect, useState } from 'react';
import { LiaDollarSignSolid } from 'react-icons/lia';

import { Tooltip } from '@radix-ui/react-tooltip';

import { DropDownListType } from 'src/@types/dashboardTypes';
import { Input } from 'src/components/ui/input';
import {
  TooltipArrow,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'src/components/ui/tooltip';
import { PREMIUM_TOOLTIP_TEXT } from 'src/constants/common';
import { cn } from 'src/lib/utils';

import LabelText from '../LabelText';
interface Props {
  label: string;
  keyName: string;
  infoText?: string;
  isDisabled?: boolean;
  isPremium?: boolean;
  handleOpen?: () => void;
  value?: { start: number; end: number };
  onChange: (name: string, value: string | number | DropDownListType) => void;
}

const PNL: React.FC<Props> = ({
  label,
  onChange,
  keyName,
  value,
  handleOpen,
  infoText = '',
  isPremium = false,
  isDisabled = false,
}) => {
  const handleClickPremiumFields = () => {
    if (isPremium && isDisabled && handleOpen) {
      handleOpen();
    }
  };

  const [min, setMin] = useState('');
  const [max, setMax] = useState('');
  const [isFocusedMinInput, setIsFocusedMinInput] = useState(false);
  const [isFocusedMaxInput, setIsFocusedMaxInput] = useState(false);

  useEffect(() => {
    setMin(value?.start ? value.start.toString() : '');
    setMax(value?.end ? value.end.toString() : '');
  }, [value]);

  const handleMinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(keyName, {
      start: Number(e.target.value),
      end: Number(max),
    } as DropDownListType);
  };

  const handleMaxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(keyName, {
      end: Number(e.target.value),
      start: Number(min),
    } as DropDownListType);
  };

  return (
    <div onClick={handleClickPremiumFields}>
      <LabelText
        label={label}
        infoText={infoText}
        isPremium={isPremium}
        isDisabled={isDisabled}
      />
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <div className='flex flex-row mt-3'>
              <Input
                name={'min'}
                placeholder={'Min.'}
                value={String(min)}
                onFocus={() => setIsFocusedMinInput(true)}
                onBlur={() => setIsFocusedMinInput(false)}
                onChange={handleMinChange}
                type={'number'}
                containerStyles={cn('w-full h-11 mr-[9px]', {
                  '!ring-2 !ring-skyBlue': value?.start && !isFocusedMinInput,
                })}
                icon={<LiaDollarSignSolid size={19} fill='white' />}
              />
              <Input
                name={'max'}
                placeholder={'Max.'}
                value={String(max)}
                onFocus={() => setIsFocusedMaxInput(true)}
                onBlur={() => setIsFocusedMaxInput(false)}
                min={0}
                onChange={handleMaxChange}
                type={'number'}
                containerStyles={cn('w-full h-11 mr-[9px] focus:!border-none', {
                  '!ring-2 !ring-skyBlue': value?.end && !isFocusedMaxInput,
                })}
                icon={<LiaDollarSignSolid size={19} fill='white' />}
              />
            </div>
          </TooltipTrigger>
          {isDisabled ? (
            <TooltipContent
              side='top'
              align='center'
              className='max-w-[200px] text-center data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade select-none rounded-[14px] bg-navyBlue px-[15px] py-[10px] text-[13px] text-white leading-[17px]'
            >
              {isPremium ? PREMIUM_TOOLTIP_TEXT : ''}
              <TooltipArrow className='fill-navyBlue w-3 h-2' />
            </TooltipContent>
          ) : null}
        </Tooltip>
      </TooltipProvider>
    </div>
  );
};

export default PNL;
