/* eslint-disable no-nested-ternary */
import React, { ReactNode, useState } from 'react';
import { PiDotOutlineFill } from 'react-icons/pi';

import Loader from 'src/components/Loader';
import { PROFILE_COMPLETION } from 'src/constants/profileCompletion';
import { cn } from 'src/lib/utils';
import { createSubscription } from 'src/services/auth';

import { Button } from './ui/button';
import { Skeleton } from './ui/skeleton';
import { Typography } from './Typography';

interface ICard {
  cardImage?: string | ReactNode;
  activeCard?: string;
  handleActiveCard?: (activeTab: string) => void;
  cardTitle: string;
  price: number;
  cardList: string[];
  cardDesc: string;
  isSubscription?: boolean;
  priceValue?: string;
  isLoading?: boolean;
  totalSubscription?: number;
  activeTab: string;
  isPay?: boolean;
}

const PricingCard: React.FC<ICard> = ({
  cardImage,
  activeCard,
  handleActiveCard,
  cardDesc,
  cardTitle,
  cardList,
  price,
  priceValue,
  isSubscription = false,
  isLoading = false,
  totalSubscription = 0,
  activeTab,
  isPay = false,
}) => {
  const [loading, setLoading] = useState(false);

  const goToPayment = async () => {
    setLoading(true);
    const payload = {
      lineItems: [
        {
          price: priceValue ?? '',
          quantity: 1,
        },
      ],
      isPay: isPay || false,
    };
    const response = await createSubscription(payload);
    if (response.status) {
      window.location.href = response?.data?.data.url || '';
    }
    setLoading(false);
  };

  return (
    <div
      className={cn(
        'py-4 w-full px-2 border border-skyCaptain flex flex-col justify-between mx-auto bg-skyCaptain text-white rounded-[20px] shadow',
        {
          'border-gray-100':
            (activeCard === cardTitle || isSubscription) && !isLoading,
        },
      )}
      onClick={() => handleActiveCard?.(cardTitle)}
    >
      <div>
        <div className='flex items-center justify-center gap-3'>
          {isLoading ? (
            <Skeleton className='w-32 h-7 bg-borderGrayColor rounded-[20px]' />
          ) : (
            <>
              {typeof cardImage === 'string' ? (
                <img src={cardImage} alt={cardTitle} />
              ) : (
                cardImage
              )}

              <h3 className='text-xl font-semibold text-center'>{cardTitle}</h3>
            </>
          )}
        </div>
        <div className='flex justify-center my-2 text-skyBlue'>
          {isLoading ? (
            <Skeleton className='w-40 h-6 bg-borderGrayColor rounded-[20px]' />
          ) : (
            <>
              <span className='text-2xl font-bold'>
                ${(price / 100).toFixed(2)}
              </span>
              {activeTab === 'monthly' ? (
                <span className='text-2xl font-bold'>/mo</span>
              ) : (
                <span className='text-2xl font-bold'>/yr</span>
              )}
            </>
          )}
        </div>
        <div className='text-center'>
          <Typography className='text-sm text-white w-3/4 mb-2'>
            {isLoading ? (
              <Skeleton className='w-full h-5 bg-borderGrayColor rounded-[20px]' />
            ) : (
              cardDesc
            )}
          </Typography>
        </div>
        <div className='mx-2'>
          <ul role='list' className='mb-8 space-y-3 text-left'>
            {isLoading ? (
              <Skeleton className='w-full h-36 bg-borderGrayColor rounded-[20px]' />
            ) : (
              cardList.map((item: string) => (
                <li key={item} className='flex text-sm'>
                  <PiDotOutlineFill className='min-w-4 mt-1' />
                  {item}
                </li>
              ))
            )}
          </ul>
        </div>
      </div>
      <div className='mx-3'>
        <Button
          variant='gradient'
          onClick={goToPayment}
          disabled={isSubscription || isLoading || loading}
          className='flex py-1 md:py-3 lg:py-6 text-base font-medium w-full'
        >
          {loading ? (
            <Loader />
          ) : isSubscription ? (
            PROFILE_COMPLETION.BTN_SUBSCRIBED
          ) : totalSubscription >= 2 || isPay ? (
            PROFILE_COMPLETION.PAYMENT
          ) : (
            PROFILE_COMPLETION.BTN_TRIAL
          )}
        </Button>
      </div>
    </div>
  );
};

export default PricingCard;
