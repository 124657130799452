import toast from 'react-hot-toast';

import { API } from 'src/api/ApiInstance';
import { COMMON_ERROR, COMMON_SUCCESS } from 'src/constants/feedback';
import { REST_SUB_URL } from 'src/constants/url';

import { ReviewResponse, ReviewType } from './types';

export const createReview = async (payload: ReviewType) => {
  const response = await API.Post<ReviewType, ReviewResponse>(
    REST_SUB_URL.CREATE_REVIEW,
    {
      ...payload,
    },
  );
  if (response.status) {
    toast.success(response?.data?.message || COMMON_SUCCESS);
    return true;
  }
  toast.error(response?.message || COMMON_ERROR);
  return false;
};
