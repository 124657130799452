import React from 'react';
import { Link } from 'react-router-dom';

import TutorialThumbnail from 'src/assets/images/coming_soon.jpg';
import { Typography } from 'src/components/Typography';
import { ROUTES } from 'src/constants/common';
import { TUTORIALS, TUTORIALS_TEXT_ARRAY } from 'src/constants/static';
import { cn } from 'src/lib/utils';

const SubHeading = ({ text }: { text: string }) => (
  <Typography className='mb-2 mt-4 block text-2xl font-medium'>
    {text}
  </Typography>
);
const UnderlineHeading = ({
  text,
  className,
}: {
  text: string;
  className?: string;
}) => (
  <Typography
    className={cn('my-2 text-xl font-medium text-lightBlue', className)}
  >
    {text}
  </Typography>
);
const SimpleText = ({ text }: { text: string }) => (
  <Typography className='text-[15px]'>{text}</Typography>
);

const Tutorials = () => {
  return (
    <div className='mx-auto w-5/6 py-5 text-white 2xl:w-10/12'>
      <Typography variant='subheading'>{TUTORIALS.TITLE}</Typography>
      <SubHeading text={TUTORIALS.SUB_HEADING} />
      <SimpleText text={TUTORIALS.SUB_HEADING_TEXT} />
      <div className='grid grid-cols-1 items-center justify-center lg:grid-cols-2 xl:grid-cols-4 xl:gap-5'>
        {TUTORIALS_TEXT_ARRAY.map(
          (item: {
            id?: number;
            heading: string;
            link: string;
            videoLink: any;
          }) => (
            <div className='mt-2' key={item.id}>
              <div className='h-20'>
                <UnderlineHeading text={item.heading} />
              </div>
              <video
                src={item.videoLink}
                className='h-52 w-11/12 rounded-2xl object-cover'
                poster={!item.videoLink ? TutorialThumbnail : ''}
                controls
              />
            </div>
          ),
        )}
      </div>

      <Typography className='mt-4 block text-base'>
        {TUTORIALS.STILL_HAVE_QUETION}{' '}
        <Link to={ROUTES.FAQ} className='text-lightBlue hover:underline'>
          {TUTORIALS.FAQ}
        </Link>{' '}
        or{' '}
        <Link className='text-lightBlue hover:underline' to={ROUTES.CONTACT}>
          {TUTORIALS.CONTACT}!
        </Link>
      </Typography>

      <SubHeading text={TUTORIALS.DAILY_TIPS} />
      <SimpleText text={TUTORIALS.DAILY_TIPS_TEXT} />
    </div>
  );
};

export default Tutorials;
