import React from 'react';

import { ContentItem, ListItem } from 'src/@types';
import { Typography } from 'src/components/Typography';
import { OPTIONS_OPTIMIZER } from 'src/constants/static';
import { cn } from 'src/lib/utils';

const OptionsOptimizer = () => {
  return (
    <div className='mx-auto w-5/6 pb-16 pt-10 text-white 2xl:w-10/12'>
      <Typography variant='subheading' className='mb-3'>
        {OPTIONS_OPTIMIZER.TITLE}
      </Typography>
      {OPTIONS_OPTIMIZER.CONTENT.map((content: ContentItem) => {
        if (content.type === 'paragraph') {
          return (
            <Typography key={content.title} className='mt-3 text-base'>
              {content.value as string}
            </Typography>
          );
        }
        if (content.type === 'list') {
          return (
            <div key={content.title} className='mt-3'>
              <Typography className='mt-5 block font-medium'>
                {content.title}
              </Typography>
              <ul
                className={cn('my-2 ml-5 list-disc', {
                  'list-disc': content.listStyle === 'disc',
                  'list-decimal': content.listStyle === 'decimal',
                })}
              >
                {typeof content.value === 'object'
                  ? content.value.map((item: ListItem) => (
                      <li key={item.simpleText} className='mt-2'>
                        <strong className='mr-1'>{item.boldText}</strong>
                        <span className='text-base'>{item.simpleText}</span>
                      </li>
                    ))
                  : null}
              </ul>
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};

export default OptionsOptimizer;
