import React, { useState } from 'react';
import { FaRegEdit } from 'react-icons/fa';

import { Typography } from 'src/components/Typography';
import { Button } from 'src/components/ui/button';
import { PROFILE } from 'src/constants/profile';
import useAuthContext from 'src/hooks/useAuth';
import { UserType } from 'src/providers/Auth/types';
import { updateProfilePicture } from 'src/services/profile';
import { uploadImageRequest } from 'src/services/types';

import UploadImage from '../../ProfileCompletion/UploadImage';

interface EditProfileImageProps {
  toggleEditImage: () => void;
}

const EditProfileImage: React.FC<EditProfileImageProps> = ({
  toggleEditImage,
}) => {
  const [image, setImage] = useState<File | null>(null);
  const { auth, setAuth } = useAuthContext();
  const { user } = auth ?? {};

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.files?.[0]) {
      const file = event?.target?.files?.[0];
      setImage(file);
    }
  };

  const uploadHandler = async () => {
    const response = await updateProfilePicture({
      image,
    } as uploadImageRequest);
    if (response.status) {
      setAuth({
        token: String(auth?.token),
        user: {
          ...(user as UserType),
          avatar: response?.data?.data?.avatar as string,
        },
      });
      toggleEditImage();
    }
  };

  return (
    <div className='bg-nightSky w-11/12 mx-auto p-3 md:p-6 m-4 rounded-2xl'>
      <div className='flex justify-between'>
        <Typography variant='subheading' className='text-lg md:text-2xl'>
          {PROFILE.profilePicture}
        </Typography>
        <div className='flex gap-4'>
          <Button
            variant={'primary'}
            className='rounded-full p-3 sm:p-5 font-medium text-sm md:text-base'
            onClick={toggleEditImage}
          >
            <FaRegEdit className='mr-2 sm:mr-4' size={19} />
            {PROFILE.CANCEL}
          </Button>
          <Button
            variant={'primary'}
            className='rounded-full p-3 sm:p-5 font-medium text-sm md:text-base'
            onClick={uploadHandler}
          >
            <FaRegEdit className='mr-2 sm:mr-4' size={19} />
            {PROFILE.UPDATE}
          </Button>
        </div>
      </div>
      <div className='flex items-center gap-2 my-1 text-white'>
        <UploadImage
          handleFileChange={handleFileChange}
          selectedFile={image}
          className='size-16'
        />
      </div>
    </div>
  );
};

export default EditProfileImage;
