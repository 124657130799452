import React, { useCallback, useMemo, useState } from 'react';
import toast from 'react-hot-toast';

import {
  AllScreenerListType,
  DropDownListType,
  IScreenerType,
  ProfitProbility,
} from 'src/@types/dashboardTypes';
import { USER_ROLE } from 'src/constants/common';
import {
  DASHBOARD_TEXT,
  FIELDS_NAME,
  FiltersKeyName,
  RISKYIELD_OPTIONS,
} from 'src/constants/dashboard';
import { COMMON_ERROR } from 'src/constants/feedback';
import useAuthContext from 'src/hooks/useAuth';
import {
  getAllFiltersDataByUser,
  getFiltersData,
  saveFilter,
  updateFilter,
} from 'src/services/Dashboard';
import { getCurrentSubscription } from 'src/services/profile';
import { SUBSCRIPTION_TYPE } from 'src/services/types';

import { DashboardContext } from './context';

type PROPS = {
  children:
    | null
    | boolean
    | undefined
    | React.ReactChild
    | React.ReactPortal
    | React.ReactFragment;
};

type FilterState = {
  name: string;
  options: DropDownListType[];
};

const DashboardProvider: React.FC<PROPS> = ({ children }) => {
  const [countryList, setCountryList] = React.useState<DropDownListType[]>();
  const [sectorList, setSectorList] = React.useState<DropDownListType[]>();
  const { auth } = useAuthContext();
  const [marketCapList, setMarketCapList] =
    React.useState<DropDownListType[]>();
  const [industoryList, setIndustryList] = React.useState<DropDownListType[]>();
  const [selectedValueScreener, setSelectedValueScreener] = React.useState('');
  const [userSubscriptionTypeLaoding, setUserSubscriptionTypeLoading] =
    React.useState<boolean | null>(null);
  const [profitProbability, setProfitProbability] =
    React.useState<ProfitProbility | null>(null);
  const [userSubscriptionType, setUserSubscriptionType] =
    useState<SUBSCRIPTION_TYPE | null>(null);
  const [calculatorError, setCalculatorError] = useState<string>('');

  const [screenerList, setScreenerList] = React.useState<AllScreenerListType[]>(
    [],
  );

  const isPremiumOrAdmin = useMemo(() => {
    return (
      userSubscriptionType === SUBSCRIPTION_TYPE.PREMIUM ||
      auth?.user?.role === USER_ROLE.ADMIN
    );
  }, [auth?.user, userSubscriptionType]);
  const isAdmin = useMemo(() => {
    return auth?.user?.role === USER_ROLE.ADMIN;
  }, [auth]);

  const [screener, setScreener] = React.useState<IScreenerType>({
    filter: {
      [FiltersKeyName.RISKYIELD]: {
        name: DASHBOARD_TEXT.YIELD_BY_DAY,
        value: RISKYIELD_OPTIONS.YIELD_BY_DAY,
      },
      [FiltersKeyName.PROFIT_PROBABILITY_SHORT]: {
        start: 0,
        end: 100,
      },
    },
    calculator: {},
    sorter: {},
  });
  const [screenerName, setScreenerName] = useState<string>('');
  const areCalculatorFieldsValid = (calculator: { [x: string]: string }) => {
    const calculatorFields = [
      FiltersKeyName.AMOUNT,
      FiltersKeyName.MIN_SECURITY,
      FiltersKeyName.CONTRACT_PER_SECURITY,
      FiltersKeyName.MAX_WEIGHTAGE_PER_SECURITY,
      FiltersKeyName.CONTRACT_PER_SECURITY,
    ];
    const filledFields = calculatorFields.filter(
      (field: string) => calculator[field],
    );
    return (
      filledFields.length === 0 ||
      filledFields.length === calculatorFields.length
    );
  };

  const handleFilterChange = (
    name: string,
    value: string | DropDownListType | number | Date | any,
  ) => {
    if (
      name === FiltersKeyName.AMOUNT ||
      name === FiltersKeyName.MIN_SECURITY ||
      name === FiltersKeyName.CONTRACT_PER_SECURITY ||
      name === FiltersKeyName.MAX_WEIGHTAGE_PER_SECURITY ||
      name === FiltersKeyName.CONTRACT_TO_PICK
    ) {
      setScreener((prev: IScreenerType) => {
        const calculator = { ...(prev.calculator || {}) };
        if (value) {
          calculator[name] = value;
        } else {
          switch (name) {
            case FiltersKeyName.AMOUNT:
              delete calculator[FiltersKeyName.AMOUNT];
              delete calculator[FiltersKeyName.MIN_SECURITY];
              delete calculator[FiltersKeyName.CONTRACT_PER_SECURITY];
              delete calculator[FiltersKeyName.MAX_WEIGHTAGE_PER_SECURITY];
              delete calculator[FiltersKeyName.CONTRACT_TO_PICK];
              break;
            case FiltersKeyName.CONTRACT_TO_PICK:
              delete calculator[FiltersKeyName.CONTRACT_TO_PICK];
              delete calculator[FiltersKeyName.MIN_SECURITY];
              delete calculator[FiltersKeyName.CONTRACT_PER_SECURITY];
              delete calculator[FiltersKeyName.MAX_WEIGHTAGE_PER_SECURITY];
              break;
            case FiltersKeyName.MIN_SECURITY:
              delete calculator[FiltersKeyName.MIN_SECURITY];
              delete calculator[FiltersKeyName.CONTRACT_PER_SECURITY];
              delete calculator[FiltersKeyName.MAX_WEIGHTAGE_PER_SECURITY];
              break;
            case FiltersKeyName.CONTRACT_PER_SECURITY:
              delete calculator[FiltersKeyName.CONTRACT_PER_SECURITY];
              delete calculator[FiltersKeyName.MAX_WEIGHTAGE_PER_SECURITY];
              break;
            case FiltersKeyName.MAX_WEIGHTAGE_PER_SECURITY:
              delete calculator[FiltersKeyName.MAX_WEIGHTAGE_PER_SECURITY];
              break;
            default:
              break;
          }
        }
        return { ...prev, calculator };
      });
    } else {
      setScreener((prev: IScreenerType) => {
        const filter: { [key: string]: any } = { ...(prev.filter || {}) };
        if (value) {
          filter[name] = value;
        } else {
          delete filter[name];
        }
        return { ...prev, filter };
      });
    }
  };
  const handleSaveFilter = async () => {
    const response = await saveFilter({ screener, name: screenerName });
    if (response.status) {
      const data = response?.data?.data;
      setScreenerName('');
      setScreener(data?.screener as IScreenerType);
      if (data) {
        setScreenerList((prevList) => [...prevList, data]);
      }
      setSelectedValueScreener(data?._id as string);
    }
  };
  const removeFilter = (name: string) => {
    setScreener((prev: IScreenerType) => {
      const newState = { ...prev };
      if (
        name === FiltersKeyName.AMOUNT ||
        name === FiltersKeyName.MIN_SECURITY ||
        name === FiltersKeyName.CONTRACT_PER_SECURITY ||
        name === FiltersKeyName.MAX_WEIGHTAGE_PER_SECURITY ||
        name === FiltersKeyName.CONTRACT_PER_SECURITY
      ) {
        const newCalculator = { ...(newState.calculator || {}) };
        delete newCalculator[name as keyof typeof newCalculator];
        newState.calculator = newCalculator;
      } else {
        const newFilter = { ...(newState.filter || {}) };
        delete newFilter[name as keyof typeof newFilter];
        newState.filter = newFilter;
      }
      return newState;
    });
  };
  const fetchFiltersData = async () => {
    const response = await getFiltersData();
    if (response?.data && response.data[0]?.filters) {
      const [{ filters }] = response.data;
      setCountryList(
        filters
          .filter((state: FilterState) => state.name === FIELDS_NAME.COUNTRY)
          .map((state: FilterState) => state.options)
          .flat(),
      );
      setSectorList(
        filters
          .filter((state: FilterState) => state.name === FIELDS_NAME.SECTOR)
          .map((state: FilterState) => state.options)
          .flat(),
      );
      setIndustryList(
        filters
          .filter((state: FilterState) => state.name === FIELDS_NAME.INDUSTRY)
          .map((state: FilterState) => state.options)
          .flat(),
      );
      setMarketCapList(
        filters
          .filter((state: FilterState) => state.name === FIELDS_NAME.MARKET_CAP)
          .map((state: FilterState) => state.options)
          .flat(),
      );
    }
  };
  const fetchAllFiltersDataByUser = async () => {
    const response = await getAllFiltersDataByUser();
    if (response) {
      setScreenerList(response?.data);
    }
  };
  const handleUpdateFilter = async (id: string | number) => {
    const response = await updateFilter(id, { screener, name: screenerName });
    if (response.status) {
      const data = response.data?.data ?? null;
      if (data) {
        const screenerListClone: AllScreenerListType[] =
          structuredClone(screenerList);
        const listIndex = screenerListClone.findIndex(
          (element) => element._id === id,
        );
        if (listIndex !== -1) {
          screenerListClone[listIndex] = data;
        }
        setScreenerList(screenerListClone);
      }
    }
  };
  const keyInScreener = useCallback(
    (keyName: string) => {
      if (keyName) {
        const isKeyPresent =
          keyName in (screener?.calculator || {}) ||
          keyName in (screener?.filter || {});
        return isKeyPresent;
      }
      return false;
    },
    [screener?.calculator, screener?.filter],
  );
  const getCardData = async () => {
    try {
      setUserSubscriptionTypeLoading(true);
      const response = await getCurrentSubscription();
      if (response) {
        setUserSubscriptionType(
          response?.data?.subscriptionType as SUBSCRIPTION_TYPE,
        );
      }
    } catch (error) {
      toast.error(COMMON_ERROR);
    } finally {
      setUserSubscriptionTypeLoading(false);
    }
  };

  const contextValue = {
    countryList,
    sectorList,
    marketCapList,
    industoryList,
    fetchFiltersData,
    setScreenerName,
    screener,
    screenerName,
    handleFilterChange,
    handleSaveFilter,
    removeFilter,
    fetchAllFiltersDataByUser,
    screenerList,
    setScreener,
    setScreenerList,
    handleUpdateFilter,
    keyInScreener,
    selectedValueScreener,
    setSelectedValueScreener,
    userSubscriptionType,
    getCardData,
    areCalculatorFieldsValid,
    isPremiumOrAdmin,
    isAdmin,
    userSubscriptionTypeLaoding,
    setProfitProbability,
    profitProbability,
    setCalculatorError,
    calculatorError,
  };
  return (
    <DashboardContext.Provider value={contextValue}>
      {children}
    </DashboardContext.Provider>
  );
};

export default DashboardProvider;

export { DashboardContext };
