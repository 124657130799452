import React, { useEffect, useState } from 'react';
import { Stepper } from 'react-form-stepper';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

import withProtectedRoute from 'src/components/ProtectedRoute';
import { Typography } from 'src/components/Typography';
import { Button } from 'src/components/ui/button';
import { ROUTES } from 'src/constants/common';
import {
  PROFILE_COMPLETION,
  PROFILE_STEPS,
  STYLE_CONFIG,
} from 'src/constants/profileCompletion';
import useSubmitProfile from 'src/hooks/useProfileCompletion';
import { BASIC_INFO } from 'src/services/types';

import TabOne from './StepOne';
import StepThree from './StepThree';
import StepTwo from './StepTwo';

const ProfileCompletion = () => {
  const [searchParams] = useSearchParams();
  const steps = Number(searchParams.get('step'));
  const [step, setStep] = React.useState(steps || 1);
  const navigate = useNavigate();
  const [accepTerms, setAcceptTerms] = React.useState(false);
  const [acceptAllTerms, setAcceptAllTerms] = useState<string[]>([]);
  const {
    control,
    setValue,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm<BASIC_INFO>({
    mode: 'all',
    defaultValues: {
      avatar: null,
      firstName: '',
      lastName: '',
      streetAddress: '',
      currentlyEmployed: true,
      middleName: '',
      country: '0',
      state: '0',
      zipCode: '',
      nameOfEmployer: '',
      employerJobTitle: '',
      city: '0',
      userType: '',
    },
  });

  useEffect(() => {
    const stepParam = searchParams.get('step');
    if (stepParam) {
      setStep(parseInt(stepParam));
    }
  }, [searchParams]);

  useEffect(() => {
    fetchAgreements();
  }, []);

  const handleAcceptTerms = () => setAcceptTerms(!accepTerms);

  const incrementStep = () => setStep((prevStep) => prevStep + 1);

  const {
    fetchAgreements,
    handleNext,
    isLoading,
    agreementList,
    location,
    setLocation,
    currentUseData,
    fullLocation,
    buttonLoading,
  } = useSubmitProfile(setValue, reset, incrementStep, step, handleSubmit);

  const handleBack = () => {
    setStep((prevStep) => prevStep - 1);
    navigate(`${ROUTES.PROFILE_COMPLETION}?step=${step - 1}`);
  };
  return (
    <div className='min-h-[700px] mb-20'>
      <div className='flex justify-center my-5'>
        <div
          onClick={() => setStep(1)}
          className='bg-skyCaptain px-3 py-1 rounded-full border border-white'
        >
          <Typography
            textGradient
            className='text-lg md:text-xl font-medium md:tracking-[0.15rem]'
          >
            {PROFILE_COMPLETION.profileCompletion}
          </Typography>
        </div>
      </div>
      <div className='bg-skyCaptain rounded-[40px] pt-4 pb-8 min-h-96'>
        <div className='w-full px-5 xl:px-[100px]'>
          <div className='w-full '>
            <div className='bg-nightSky text-white rounded-[18px] pt-5 pb-7'>
              <div className='mb-5 min-h-20 md:w-2/3 mx-auto flex items-center justify-center'>
                <Stepper
                  steps={PROFILE_STEPS}
                  activeStep={step - 1}
                  styleConfig={STYLE_CONFIG}
                  className='stepper w-full'
                  stepClassName={'stepper__step'}
                />
              </div>
              {step === 1 ? (
                <TabOne
                  errors={errors}
                  control={control}
                  setValue={setValue}
                  location={location}
                  setLocation={setLocation}
                />
              ) : null}
              {step === 2 ? (
                <StepTwo
                  accepTerms={accepTerms}
                  handleAcceptTerms={handleAcceptTerms}
                  agreementList={agreementList}
                  isLoading={isLoading}
                  setAcceptAllTerms={setAcceptAllTerms}
                  acceptAllTerms={acceptAllTerms}
                  setAcceptTerms={setAcceptTerms}
                  currentUseData={currentUseData}
                  fullLocation={fullLocation}
                />
              ) : null}
              {step === 3 && <StepThree />}
            </div>
            {step < 3 ? (
              <div className='mt-8 flex justify-end space-x-3'>
                <Button
                  variant='primary'
                  disabled={step === 1}
                  onClick={handleBack}
                  className='hover:bg-lightBlue py-0 md:py-3 xl:py-5 w-40 text-base font-medium'
                >
                  {PROFILE_COMPLETION.GO_BACK}
                </Button>
                <Button
                  onClick={handleNext}
                  disabled={(step === 2 && !accepTerms) || buttonLoading}
                  variant='gradient'
                  className='flex md:py-3 py-0 xl:py-[21px] w-40 text-base font-medium '
                >
                  {PROFILE_COMPLETION.NEXT}
                </Button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withProtectedRoute(ProfileCompletion);
