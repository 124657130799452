import { Typography } from 'src/components/Typography';
import { Checkbox } from 'src/components/ui/checkbox';
import { PROFILE_COMPLETION } from 'src/constants/profileCompletion';
import { UserType } from 'src/providers/Auth/types';
import { IAgreement } from 'src/services/types';

type AgreementProp = {
  item: IAgreement;
  currentUseData: UserType | undefined;
  fullLocation: string;
  acceptAllTerms: string[];
  handleCheckboxChange: (id: string) => void;
};

const Agreement: React.FC<AgreementProp> = ({
  item,
  currentUseData,
  fullLocation,
  acceptAllTerms,
  handleCheckboxChange,
}) => {
  const sanitisedAgreementTextWithNameAndLocation = item.description
    .replace(
      /\/\*name\*\//g,
      `${currentUseData?.firstName} ${currentUseData?.lastName}`,
    )
    .replace(/\/\*location\*\//g, fullLocation);

  return (
    <div key={item._id} className='mb-5'>
      <div className=' p-3 bg-darkGray rounded-lg m-3 max-h-72 overflow-scroll'>
        <Typography className='text-[13px] text-light'>
          {sanitisedAgreementTextWithNameAndLocation}
        </Typography>
      </div>
      <div className='flex items-center space-x-2 mt-2 mx-4'>
        <Checkbox
          className='ml-1 border-0 bg-lightSkyBlue'
          onCheckedChange={() => handleCheckboxChange(item._id)}
          checked={acceptAllTerms.some((id: string) => id === item._id)}
        />
        <div className='grid gap-1.5 leading-none'>
          <label htmlFor='terms1' className='text-sm text-skyBlue underline'>
            {PROFILE_COMPLETION.ACCEPT_TERM}
          </label>
        </div>
      </div>
    </div>
  );
};
export default Agreement;
