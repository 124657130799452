import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ROUTES } from 'src/constants/common';
import { getAuthToken } from 'src/utils/common';

const withPublicRoute = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
) => {
  const AuthenticatedRoute: React.FC<P> = (props) => {
    const navigate = useNavigate();
    const token = getAuthToken();
    const location = useLocation();
    const { state } = location || {};

    useEffect(() => {
      if (token) {
        navigate(ROUTES.DASHBOARD);
      } else {
        if (window.location.pathname === ROUTES.LOGIN) {
          navigate(ROUTES.LOGIN);
        } else if (window.location.pathname === ROUTES.SIGNUP) {
          navigate(ROUTES.SIGNUP);
        } else if (window.location.pathname === ROUTES.FORGOT_PASSWORD) {
          navigate(ROUTES.FORGOT_PASSWORD);
        } else if (window.location.pathname === ROUTES.SET_NEW_PASSWORD) {
          navigate(ROUTES.SET_NEW_PASSWORD);
        } else if (window.location.pathname === ROUTES.OTP) {
          navigate(ROUTES.OTP, { state });
        } else {
          navigate(ROUTES.LOGIN);
        }
      }
    }, [token]);

    return <WrappedComponent {...props} />;
  };

  return AuthenticatedRoute;
};

export default withPublicRoute;
