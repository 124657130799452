import React, { useState } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormSetValue,
} from 'react-hook-form';

import FormField from 'src/components/FormField';
import { Typography } from 'src/components/Typography';
import { Label } from 'src/components/ui/label';
import { RadioGroup, RadioGroupItem } from 'src/components/ui/radio-group';
import { PROFILE_COMPLETION_INPUT_ERRORS } from 'src/constants/aterlogin';
import { PROFILE_COMPLETION } from 'src/constants/profileCompletion';
import { cn } from 'src/lib/utils';
import { BASIC_INFO } from 'src/services/types';

type NameFieldsProps = {
  errors: FieldErrors<BASIC_INFO>;
  control: Control<any>;
  setValue: UseFormSetValue<any>;
  status?: boolean;
  empName?: string;
  empJobTitle?: string;
};

const EmployedFields: React.FC<NameFieldsProps> = ({
  errors,
  control,
  setValue,
  empJobTitle,
  empName,
  status,
}) => {
  const [employmentStatus, setEmploymentStatus] = useState<string>(
    status ? 'yes' : 'no',
  );
  const handleEmploymentStatusChange = (value: string) => {
    setEmploymentStatus(value);
    if (value === 'no') {
      setValue('currentlyEmployed', false);
      setValue('nameOfEmployer', '');
      setValue('employerJobTitle', '');
    } else {
      setValue('currentlyEmployed', true);
      if (empName && empJobTitle) {
        setValue('nameOfEmployer', empName);
        setValue('employerJobTitle', empJobTitle);
      }
    }
  };
  return (
    <>
      <div>
        <Typography className='text-base'>
          {PROFILE_COMPLETION.ARE_YOU_CURRENTLY_EMPLOYED}
        </Typography>
        <RadioGroup
          value={employmentStatus}
          onValueChange={handleEmploymentStatusChange}
          className='md:flex my-4 gap-4 md:gap-16'
        >
          <div className='flex items-center space-x-2'>
            <RadioGroupItem
              value='yes'
              id='yes'
              className={cn(
                'bg-white focus:bg-skyBlue text-white border-none',
                {
                  'bg-skyBlue': employmentStatus === 'yes',
                },
              )}
            />
            <Label htmlFor='yes' className='text-white'>
              {PROFILE_COMPLETION.YES}
            </Label>
          </div>
          <div className='flex items-center space-x-2'>
            <RadioGroupItem
              value='no'
              id='no'
              className={cn(
                'bg-white focus:bg-skyBlue text-white border-none',
                {
                  'bg-skyBlue': employmentStatus === 'no',
                },
              )}
            />
            <Label htmlFor='no' className='text-white'>
              {PROFILE_COMPLETION.NO}
            </Label>
          </div>
        </RadioGroup>
      </div>
      {employmentStatus === 'yes' ? (
        <div className='grid grid-cols-1 md:grid-cols-3 md:gap-7'>
          <Controller
            control={control}
            name='nameOfEmployer'
            rules={{
              required:
                employmentStatus === 'yes'
                  ? PROFILE_COMPLETION_INPUT_ERRORS.NAME_OF_EMPLOYER.required
                  : false,
            }}
            render={({ field: { onChange, value, name } }) => (
              <FormField
                readOnly={employmentStatus !== 'yes'}
                className={cn({
                  'focus-visible:ring-5 cursor-not-allowed':
                    employmentStatus !== 'yes',
                })}
                type='text'
                labelCalssName='text-base'
                title={PROFILE_COMPLETION.NAME_OF_EMPLOYER.title}
                placeholder={PROFILE_COMPLETION.NAME_OF_EMPLOYER.placeholder}
                name={name}
                value={value}
                onChange={onChange}
                errors={errors}
                isRequired
              />
            )}
          />
          <div className='md:col-span-2'>
            <Controller
              control={control}
              name='employerJobTitle'
              rules={{
                required:
                  employmentStatus === 'yes'
                    ? PROFILE_COMPLETION_INPUT_ERRORS.OCCUPATION_OR_JOB.required
                    : false,
              }}
              render={({ field: { onChange, value, name } }) => (
                <FormField
                  readOnly={employmentStatus !== 'yes'}
                  type='text'
                  className={cn({
                    'focus-visible:ring-5 cursor-not-allowed':
                      employmentStatus !== 'yes',
                  })}
                  labelCalssName='text-base'
                  title={PROFILE_COMPLETION.OCCUPATION_OR_JOB.title}
                  placeholder={PROFILE_COMPLETION.OCCUPATION_OR_JOB.placeholder}
                  name={name}
                  value={value}
                  onChange={onChange}
                  errors={errors}
                  isRequired
                />
              )}
            />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default EmployedFields;
