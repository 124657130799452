import React, { useState } from 'react';
import { AiOutlineMenu, AiOutlineUser } from 'react-icons/ai';
import { FaChevronDown } from 'react-icons/fa';
import { TbLogout2 } from 'react-icons/tb';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import Logo from 'src/assets/svgs/Logo';
import { Button } from 'src/components/ui/button';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from 'src/components/ui/sheet';
import {
  HEADER_BUTTONS,
  HEADER_TEXT,
  MENU_ITEMS,
  ROUTES,
  USER_ROLE,
} from 'src/constants/common';
import useAuthContext from 'src/hooks/useAuth';
import useDashboardContext from 'src/hooks/useDashboardData';
import { cn } from 'src/lib/utils';
import { removeAuthToken } from 'src/utils/common';

import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarTrigger,
} from './ui/menubar';
import Divider from './Divider';
import UserData from './HeaderUserText';

const Header = () => {
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { auth, setAuth } = useAuthContext();
  const {
    setScreener,
    setSelectedValueScreener,
    isAdmin,
    userSubscriptionType,
    userSubscriptionTypeLaoding,
  } = useDashboardContext();
  const { user } = auth ?? {};
  const toogleSidebar = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };
  const handleSheetTrigger = () => {
    setIsNavbarOpen(true);
  };
  const logoutUser = () => {
    removeAuthToken();
    setAuth(null);
    setIsNavbarOpen(false);
    navigate(ROUTES.LOGIN);
    setScreener({ filter: {}, calculator: {}, sorter: {} });
    setSelectedValueScreener('');
  };

  return (
    <nav className='mx-auto w-full bg-blueGray px-2 py-1 md:mt-2 md:w-11/12 md:rounded-full z-10 md:px-8'>
      <div className='mx-auto flex flex-wrap items-center justify-between'>
        <Link to={ROUTES.HOME}>
          <Logo />
        </Link>
        <div className='flex space-x-3 md:order-2 md:space-x-0 rtl:space-x-reverse relative'>
          {auth?.token ? (
            <Menubar className='bg-midnightBlue border-none hidden md:flex'>
              <MenubarMenu>
                <MenubarTrigger className='data-[state=open]:bg-midnightBlue focus:bg-midnightBlue'>
                  <UserData
                    user={user}
                    currentPlan={
                      !isAdmin && user?.subscription
                        ? userSubscriptionType
                        : null
                    }
                    userSubscriptionTypeLaoding={userSubscriptionTypeLaoding}
                  />
                  <FaChevronDown className='text-white ml-3' />
                </MenubarTrigger>
                <MenubarContent
                  className={cn(
                    'bg-darkGray rounded-xl border-0 absolute left-12',
                    {
                      'left-12': !user?.subscription,
                      'left-0':
                        (user?.role === USER_ROLE.ADMIN && !user?.firstName) ||
                        !user?.firstName,
                    },
                  )}
                >
                  <MenubarItem
                    className={cn('text-white rounded-md text-md flex gap-2', {
                      'disabled cursor-not-allowed hover:!bg-darkGray hover:!text-white opacity-70':
                        !user?.subscription && !isAdmin,
                    })}
                    disabled={!user?.subscription && !isAdmin}
                    onClick={() => navigate(ROUTES.PROFILE)}
                  >
                    <AiOutlineUser size={20} />
                    {MENU_ITEMS[1]?.title}
                  </MenubarItem>
                  <MenubarItem
                    className='text-red-500 rounded-md text-md flex gap-2'
                    onClick={logoutUser}
                  >
                    <TbLogout2 size={20} />
                    {HEADER_TEXT.LOGOUT}
                  </MenubarItem>
                </MenubarContent>
              </MenubarMenu>
            </Menubar>
          ) : (
            <div className='flex gap-2 '>
              <Button
                variant='primary'
                className='hover:bg-lightBlue md:p-3 hidden md:flex lg:p-6'
                onClick={() => navigate(ROUTES.LOGIN)}
              >
                {HEADER_BUTTONS.LOGIN}
              </Button>
              <Link to={ROUTES.SIGNUP}>
                <Button
                  variant='gradient'
                  className='hidden md:flex md:p-3 lg:p-6'
                >
                  {HEADER_BUTTONS.START_TRIAL}
                </Button>
              </Link>
            </div>
          )}
        </div>
        <Sheet>
          <SheetTrigger>
            <button
              type='button'
              className={cn(
                'text-gray-500 hover:bg-gradient-to-tl to-skyBlue from-lightPurple/45  dark:text-gray-400  my-1 inline-flex size-10 items-center justify-center rounded-lg p-2 text-sm focus:outline-none md:hidden',
                {
                  'hidden ': !auth,
                },
              )}
              aria-controls='navbar-cta'
              aria-label='Toggle Navigation Menu'
              onClick={handleSheetTrigger}
            >
              <AiOutlineMenu className='size-5' color='white' />
            </button>
          </SheetTrigger>
          {isNavbarOpen && (
            <SheetContent
              side={'left'}
              className='bg-skyCaptain border-0 p-8 text-white'
            >
              <SheetHeader>
                <SheetTitle>
                  <div className='flex'>
                    <UserData
                      user={user}
                      currentPlan={user?.subscriptionType}
                      userSubscriptionTypeLaoding={userSubscriptionTypeLaoding}
                    />
                  </div>
                </SheetTitle>
                <SheetDescription>
                  <Divider className='my-3' />
                  {MENU_ITEMS.map((item) => (
                    <Link to={item.routeName} key={item.title}>
                      <li
                        className={cn(
                          'text-white hover:bg-borderGrayColor py-2 px-2 rounded-md text-md flex gap-2 mt-3',
                          {
                            'bg-borderGrayColor': pathname === item.routeName,
                            'disabled opacity-70 cursor-not-allowed':
                              !user?.subscription &&
                              !isAdmin &&
                              item.title === 'Profile',
                          },
                        )}
                        onClick={toogleSidebar}
                      >
                        <item.icon size={20} />
                        {item.title}
                      </li>
                    </Link>
                  ))}
                  <Divider className='my-3' />
                  <li
                    className='text-red-500 hover:bg-borderGrayColor py-2 px-2 rounded-md text-md flex gap-2 mt-3'
                    onClick={logoutUser}
                  >
                    <TbLogout2 size={20} />
                    {HEADER_TEXT.LOGOUT}
                  </li>
                </SheetDescription>
              </SheetHeader>
            </SheetContent>
          )}
        </Sheet>
      </div>
    </nav>
  );
};

export default Header;
