import toast from 'react-hot-toast';

import { API } from 'src/api/ApiInstance';
import { COMMON_ERROR, COMMON_SUCCESS } from 'src/constants/feedback';
import { REST_SUB_URL } from 'src/constants/url';

import { ContactFieldsType, ReviewResponse } from './types';

export const createContact = async (payload: ContactFieldsType) => {
  const response = await API.Post<ContactFieldsType, ReviewResponse>(
    REST_SUB_URL.CONTACT,
    {
      ...payload,
    },
  );
  if (response.status) {
    toast.success(response?.data?.message || COMMON_SUCCESS);
    return true;
  }
  toast.error(response?.message || COMMON_ERROR);
  return false;
};
