import React from 'react';

import { Typography } from 'src/components/Typography';
import { Skeleton } from 'src/components/ui/skeleton';
import { USER_ROLE } from 'src/constants/common';
import { cn } from 'src/lib/utils';

interface IDetailItemProps {
  title: string;
  value: string | undefined;
  isLoading?: boolean;
}

const DetailItem: React.FC<IDetailItemProps> = ({
  title,
  value = '',
  isLoading = false,
}: IDetailItemProps) => {
  return (
    <div className='flex flex-col gap-[9px]'>
      <Typography className='text-lightGray'>{title}</Typography>
      {isLoading ? (
        <Skeleton className='w-[90%] h-7 bg-borderGrayColor rounded-[20px]' />
      ) : (
        <Typography
          className={cn('text-md font-normal', {
            'bg-greenishWhite text-charmedGreen w-24 flex justify-center items-center rounded-2xl':
              value === USER_ROLE.ADMIN,
            'bg-lightSky text-homeWorld  w-24 flex justify-center items-center rounded-2xl':
              value === USER_ROLE.USER,
          })}
        >
          <div
            className={cn('h-1.5 w-1.5 rounded-full me-2', {
              'bg-charmedGreen ': value === USER_ROLE.ADMIN,
              'bg-homeWorld ': value === USER_ROLE.USER,
            })}
          />
          {value}
        </Typography>
      )}
    </div>
  );
};

export default DetailItem;
