import React, { useEffect, useState } from 'react';

import { DropDownListType } from 'src/@types/dashboardTypes';
import { Input } from 'src/components/ui/input';
import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'src/components/ui/tooltip';
import { PREMIUM_TOOLTIP_TEXT } from 'src/constants/common';
import useDashboardContext from 'src/hooks/useDashboardData';
import { cn } from 'src/lib/utils';

import LabelText from '../LabelText';

interface IInputItem {
  placeholder?: string;
  inputClassName?: string;
  label?: string;
  isPremium?: boolean;
  isDisabled?: boolean;
  handleOpen?: () => void;
  value: string | number | undefined;
  keyName: string;
  onChange: (name: string, value: string | number | DropDownListType) => void;
  type?: string;
  noLabel?: boolean;
  isrequired?: boolean;
  errorMessage?: string;
  infoText?: string;
  maxValue?: number;
  minValue?: number;
  notEditable?: boolean;
  calculatorToolTipText?: string;
  labelClassName?: string;
}

const FilterTextInput: React.FC<IInputItem> = ({
  placeholder = 'Type here...',
  inputClassName,
  label = '',
  isPremium = false,
  isDisabled = false,
  keyName,
  handleOpen,
  value,
  onChange,
  type,
  noLabel,
  isrequired,
  errorMessage,
  infoText = '',
  maxValue,
  minValue,
  notEditable,
  calculatorToolTipText,
  labelClassName,
}) => {
  const [inputValue, setInputValue] = useState('');
  const { keyInScreener } = useDashboardContext();

  useEffect(() => {
    if (value !== undefined) {
      setInputValue(value.toString());
    } else {
      setInputValue('');
    }
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = e.target.value;
    const numericValue = parseFloat(inputValue);
    if (!isNaN(numericValue)) {
      if (minValue !== undefined && numericValue < minValue) {
        inputValue = minValue.toString();
      } else if (maxValue !== undefined && numericValue > maxValue) {
        inputValue = maxValue.toString();
      }
    }
    setInputValue(inputValue);
    onChange(keyName, inputValue);
  };

  const handleClickPremiumFields = () => {
    if (isPremium && handleOpen) {
      handleOpen();
    }
  };

  return (
    <div onClick={handleClickPremiumFields}>
      {!noLabel ? (
        <LabelText
          label={label}
          isDisabled={isDisabled}
          isPremium={isPremium}
          infoText={infoText}
          labelClassName={labelClassName}
        />
      ) : null}
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <div className='relative mt-3'>
              <div
                className={cn('w-auto h-11 z-50 left-0 absolute right-0', {
                  'hidden ': !isDisabled,
                })}
                onClick={handleClickPremiumFields}
              />
              <Input
                value={inputValue}
                placeholder={placeholder}
                containerStyles={cn(
                  'w-full h-11 z-10 ring-offset-0 focus:!ring-0 focus:!ring-offset-0 focus:!border-none',
                  inputClassName,
                )}
                disabled={isDisabled || notEditable}
                onChange={handleChange}
                type={type}
                max={maxValue}
                min={minValue}
                className={cn({
                  'ring-[2px] ring-lightBlue': keyInScreener(keyName),
                })}
                required={isrequired}
                notEditable={notEditable}
              />
              {errorMessage ? (
                <div className='pt-1'>
                  <span className='text-red_color'>{errorMessage}</span>
                </div>
              ) : null}
            </div>
          </TooltipTrigger>
          {isDisabled ? (
            <TooltipContent
              side='top'
              align='center'
              className='max-w-[200px] text-center data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade select-none rounded-[14px] bg-navyBlue px-[15px] py-[10px] text-[13px] text-white leading-[17px]'
            >
              {calculatorToolTipText || PREMIUM_TOOLTIP_TEXT}
              <TooltipArrow className='fill-navyBlue w-3 h-2' />
            </TooltipContent>
          ) : null}
        </Tooltip>
      </TooltipProvider>
    </div>
  );
};

export default FilterTextInput;
