import React, { useEffect, useState } from 'react';
import { LuChevronDown } from 'react-icons/lu';
import Select, { components } from 'react-select';

import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@radix-ui/react-tooltip';

import {
  IMultiSelectInput,
  OptionPropsType,
  OptionType,
} from 'src/@types/dashboardTypes';
import { PREMIUM_TOOLTIP_TEXT } from 'src/constants/common';
import useDashboardContext from 'src/hooks/useDashboardData';
import { cn } from 'src/lib/utils';

import LabelText from '../LabelText';

import { CustomStyles, customStyles } from './customStyle';

export const Option: React.FC<OptionPropsType> = (options: OptionPropsType) => {
  return (
    <div>
      <components.Option {...options}>
        <input
          id='checkb'
          type='checkbox'
          checked={options.isSelected}
          className='h-[13px] w-[13px] mr-2'
        />
        <label htmlFor='checkb'>{options.label}</label>
      </components.Option>
    </div>
  );
};

type FilterOptionFunction = (
  option: { data: OptionType },
  inputValue: string,
) => boolean;

const MultuSelectInput: React.FC<IMultiSelectInput> = ({
  inputClassName,
  label = '',
  isPremium = false,
  isDisabled = false,
  keyName,
  onChange,
  handleOpen,
  value,
  options,
  infoText = '',
}) => {
  const [state, setState] = useState<{
    optionSelected: Array<OptionType> | null | any;
  }>({
    optionSelected: null,
  });
  const { keyInScreener } = useDashboardContext();
  useEffect(() => {
    setState({ optionSelected: value ? value : null });
  }, [value]);

  const handleChange = (selected: Array<OptionType>) => {
    setState({ optionSelected: selected });
    onChange(keyName, selected);
  };
  const filterOption: FilterOptionFunction = (option, inputValue) => {
    if (!inputValue) return true;
    const valueMatch = option.data.value
      .toLowerCase()
      .includes(inputValue.toLowerCase());
    const nameMatch = option.data.name
      ? option.data.name.toLowerCase().includes(inputValue.toLowerCase())
      : false;
    const symbolMatch = option.data.symbol
      ? option.data.symbol.toLowerCase().includes(inputValue.toLowerCase())
      : false;
    const descriptionMatch = option.data.description
      ? option.data.description.toLowerCase().includes(inputValue.toLowerCase())
      : false;

    return valueMatch || symbolMatch || descriptionMatch || nameMatch;
  };
  const handleClickPremiumFields = () => {
    if (isPremium && isDisabled && handleOpen) {
      handleOpen();
    }
  };

  return (
    <div onClick={handleClickPremiumFields}>
      <LabelText
        label={label}
        isDisabled={isDisabled}
        isPremium={isPremium}
        infoText={infoText}
      />
      <div className='relative'>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <div className='relative'>
                <div
                  className={cn('w-auto h-11 z-50 left-0 absolute right-0', {
                    'hidden ': !isDisabled,
                  })}
                />
                <Select
                  isDisabled={isDisabled}
                  defaultValue={null}
                  filterOption={filterOption}
                  getOptionLabel={(option: OptionType) =>
                    option.symbol ? option.symbol : option?.name || ''
                  }
                  getOptionValue={(option: OptionType) => option.value}
                  isMulti
                  placeholder='Select'
                  styles={customStyles as CustomStyles | any}
                  name={keyName}
                  options={options}
                  onChange={handleChange}
                  value={state.optionSelected}
                  components={{ Option }}
                  className={cn(
                    'basic-multi-select w-full mt-4',
                    {
                      'opacity-50': isDisabled,
                      'ring-[2px] ring-lightBlue rounded-xl':
                        state.optionSelected?.length > 0
                          ? keyInScreener(keyName)
                          : false,
                    },
                    inputClassName,
                  )}
                  classNamePrefix='select'
                />
              </div>
            </TooltipTrigger>
            {isDisabled ? (
              <TooltipContent
                side='top'
                align='center'
                className='max-w-[200px] text-center data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade select-none rounded-[14px] bg-navyBlue px-[15px] py-[10px] text-[13px] text-white leading-[17px]'
              >
                {isPremium ? PREMIUM_TOOLTIP_TEXT : ''}
                <TooltipArrow className='fill-navyBlue w-3 h-2' />
              </TooltipContent>
            ) : null}
          </Tooltip>
        </TooltipProvider>
        <LuChevronDown className='h-4 w-4 absolute top-3 right-4' />
      </div>
    </div>
  );
};

export default MultuSelectInput;
