import React from 'react';
import { PiCheckCircle } from 'react-icons/pi';
import { Link } from 'react-router-dom';

import { Typography } from 'src/components/Typography';
import { Button } from 'src/components/ui/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
} from 'src/components/ui/dialog';
import { ROUTES } from 'src/constants/common';
import { DASHBOARD_TEXT } from 'src/constants/dashboard';
import { PROFILE } from 'src/constants/profile';

interface ISelectPlanModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SelectPlanModal: React.FC<ISelectPlanModalProps> = ({
  open,
  setOpen,
}) => {
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className='w-11/12 bg-maypole lg:!w-2/5 text-center'>
        <DialogHeader>
          <DialogDescription className='flex gap-1 lg:gap-3 w-full'>
            <div className='min-w-12 h-12 rounded-full bg-white flex justify-center items-center'>
              <PiCheckCircle size={35} />
            </div>
            <div className='flex flex-col'>
              <Typography className='text-lg text-left md:text-xl font-semibold text-pineGreen'>
                {DASHBOARD_TEXT.SELECT_PLAN}!
              </Typography>
              <Typography className='text-sm block mt-[6px] text-pineGreen '>
                {DASHBOARD_TEXT.PLAN_MODAL_TEXT}
              </Typography>
            </div>
          </DialogDescription>
        </DialogHeader>
        <DialogFooter className='sm:justify-center items-center'>
          <div className='flex gap-3 items-center'>
            <DialogClose asChild>
              <Button
                variant='primary'
                className='bg-maypoledark text-xs md:p-3 w-24 sm:w-28 lg:p-[22px] focus-visible:ring-0 md:w-40 font-medium sm:text-base !ring-offset-0'
                onClick={() => setOpen(false)}
              >
                {PROFILE.CANCEL}
              </Button>
            </DialogClose>
            <Link to={ROUTES.SUBSCRIPTION}>
              <Button
                variant='gradient'
                className='flex md:p-3 text-xs lg:p-[22px] w-24 sm:w-28 font-medium sm:text-base md:w-40'
              >
                {DASHBOARD_TEXT.SELECT_PLAN}
              </Button>
            </Link>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default SelectPlanModal;
