import React, { useMemo, useRef } from 'react';

import Avatar from 'src/assets/images/Avatar.png';
import Upload from 'src/assets/svgs/uploadIcon.svg';
import { PROFILE_COMPLETION } from 'src/constants/profileCompletion';
import useAuthContext from 'src/hooks/useAuth';
import { cn } from 'src/lib/utils';

type UploadImageProps = {
  selectedFile: File | null;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
};

const UploadImage: React.FC<UploadImageProps> = ({
  selectedFile,
  handleFileChange,
  className,
}) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { auth } = useAuthContext();
  const { user } = auth ?? {};

  const imageUrl = useMemo(() => {
    if (selectedFile) {
      return URL.createObjectURL(selectedFile as File);
    } else if (user?.avatar) {
      return user.avatar;
    }
    return Avatar;
  }, [selectedFile, user]);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  return (
    <>
      <img
        src={imageUrl}
        alt='avatar'
        className={cn('mt-2 rounded-full w-28 h-28', className)}
      />
      <input
        type='file'
        ref={fileInputRef}
        onChange={handleFileChange}
        className='hidden'
        accept='.png, .jpg, .jpeg'
      />
      <div
        onClick={handleButtonClick}
        className='!bg-transparent font-medium w-24 flex mt-2 mx-3'
      >
        <img src={Upload} alt='upload' className='mr-1' />
        {PROFILE_COMPLETION.UPLOAD}
      </div>
    </>
  );
};

export default UploadImage;
