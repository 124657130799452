import React from 'react';

interface DividerProps {
  className?: string;
}

const Divider: React.FC<DividerProps> = ({ className }) => {
  return <div className={`border-b border-borderColor ${className}`} />;
};

export default Divider;
