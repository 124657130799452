import React from 'react';
import { FaRegCheckCircle } from 'react-icons/fa';
import { GrCircleInformation } from 'react-icons/gr';
import { PiWarningBold } from 'react-icons/pi';
import { RxCrossCircled } from 'react-icons/rx';

import { SUB_LEVELS } from 'src/@types/dashboardTypes';

import { Skeleton } from './ui/skeleton';

type AlertType = SUB_LEVELS;

type BannerAlertType = {
  text: string;
  loading?: boolean;
  alertType: AlertType;
};

const alertStyles: {
  [key in AlertType]: {
    borderColor: string;
    bgColor: string;
    icon: JSX.Element;
  };
} = {
  NORMAL: {
    borderColor: 'border-green-300 text-green-300',
    bgColor: 'bg-green-500 bg-opacity-10',
    icon: <FaRegCheckCircle className='text-green-300' />,
  },
  SLOWED: {
    borderColor: 'border-yellow-300 text-yellow-300',
    bgColor: 'bg-yellow-500 bg-opacity-10 ',
    icon: <GrCircleInformation className='text-yellow-300' />,
  },
  DELAYED: {
    borderColor: 'border-orange-300 text-orange-300',
    bgColor: 'bg-orange-500 bg-opacity-10 ',
    icon: <PiWarningBold className='text-orange-300' />,
  },
  ALERT: {
    borderColor: 'border-red-300 text-red-300',
    bgColor: 'bg-red-500 bg-opacity-10',
    icon: <RxCrossCircled className='text-red-300' />,
  },
  CRITICAL: {
    borderColor: 'border-red-300 text-red-300',
    bgColor: 'bg-red-500 bg-opacity-10',
    icon: <RxCrossCircled className='text-red-300' />,
  },
};

const BannerAlert: React.FC<BannerAlertType> = ({
  text,
  alertType,
  loading = false,
}) => {
  const { borderColor, bgColor, icon } = alertStyles[alertType];

  if (loading) {
    return (
      <Skeleton className='h-10 w-full py-2 pl-5 pr-10 rounded-md inline-flex items-center bg-borderGrayColor' />
    );
  }

  return (
    <div
      className={`border ${borderColor} ${bgColor} py-2 pl-5 pr-10 rounded-md inline-flex items-center`}
    >
      <div className='mr-3'>{icon}</div>
      <div>{text}</div>
    </div>
  );
};

export default BannerAlert;
