import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import Logo from 'src/assets/svgs/Logo';
import {
  FOOTER_CONTENT,
  FOOTER_ITEMS,
  FOOTER_LINKS,
  FOOTER_SECOND_LINKS,
  ROUTES,
  SOCIAL_LINKS,
} from 'src/constants/common';

import { Typography } from './Typography';

const Footer = () => {
  const { pathname } = useLocation();

  const isDashboard = React.useMemo(
    () => pathname === ROUTES.DASHBOARD,
    [pathname],
  );

  return (
    <footer className='rounded-t-2xl bg-gradient-to-t from-lightBlue/30 to-lightPurple/45 px-4 text-white md:rounded-t-[30px] lg:px-0 z-10'>
      <div className='mx-auto md:w-11/12 p-4 py-6 lg:py-8'>
        <div className='md:flex md:justify-between'>
          <div className='mb-6 md:mb-0 md:w-3/12'>
            <Link
              to='/'
              className='flex items-center space-x-3 rtl:space-x-reverse'
            >
              <Logo />
            </Link>
          </div>
          <div className='grid grid-cols-1 sm:grid-cols-3 gap-8 xl:grid-cols-4 md:gap-6 md:w-9/12'>
            <ul>
              {FOOTER_ITEMS.map((item: { label: string; route: string }) => (
                <li key={item.label} className='mb-2'>
                  <Link to={item.route} className='hover:underline'>
                    {item.label}
                  </Link>
                </li>
              ))}
            </ul>
            <ul>
              {FOOTER_LINKS.map((item: { label: string; route: string }) => (
                <li key={item.label} className='mb-2'>
                  <Link to={item.route} className='hover:underline'>
                    {item.label}
                  </Link>
                </li>
              ))}
            </ul>
            <ul>
              {FOOTER_SECOND_LINKS.map(
                (item: { label: string; route: string }) => (
                  <li key={item.label} className='mb-2'>
                    <Link to={item.route} className='hover:underline'>
                      {item.label}
                    </Link>
                  </li>
                ),
              )}
            </ul>
            <div className='mt-4 hidden h-fit min-w-48 max-w-60 gap-2 rounded-full bg-deepBlue px-6 py-3 sm:mt-0 sm:justify-center md:flex'>
              {SOCIAL_LINKS.map((item) => (
                <Link key={item.label} to={item.route}>
                  <item.icon size={28} color='white' />
                </Link>
              ))}
            </div>
          </div>
        </div>
        <div className='mx-auto mt-4 flex h-fit w-60 justify-between gap-2 rounded-full bg-deepBlue px-6 py-3 sm:mt-0 sm:justify-center md:hidden'>
          {SOCIAL_LINKS.map((item) => (
            <Link key={item.label} to={item.route}>
              <item.icon size={28} color='white' />
            </Link>
          ))}
        </div>
        <hr className='my-6 rounded border-2 border-white/25 sm:mx-auto lg:my-6' />
        <div className='text-center'>
          {isDashboard ? (
            <Typography className='mb-2 text-sm lg:w-11/12 text-left'>
              {FOOTER_CONTENT.disclaimer?.sections?.map((section, idx) => (
                <div key={idx}>
                  <Typography className='mb-2 mx-0 text-lg font-bold'>
                    {section?.title}
                  </Typography>
                  <ul>
                    {section?.points?.map((point, idx) => (
                      <li key={idx} className='list-disc'>
                        <div
                          className='mb-2 text-sm'
                          dangerouslySetInnerHTML={{ __html: point }}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </Typography>
          ) : (
            <Typography className='mb-2 text-sm lg:w-11/12'>
              {FOOTER_CONTENT.description}
            </Typography>
          )}

          <Typography className='text-sm font-semibold'>
            {FOOTER_CONTENT.copyright}
          </Typography>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
