export const REST_URL = process.env.REACT_APP_BASE_URL || '';

export const CONFIG = {
  X_API_KEY: process.env.X_API_KEY,
};

export const REST_SUB_URL = {
  SIGNUP: '/auth/signup',
  CREATE_PROFILE: '/auth/create-profile',
  OTP: '/auth/verify-otp',
  LOGIN: '/auth/login',
  RESEND_OTP: '/auth/resend-otp',
  FORGOT_PASSWORD: '/auth/forgot-password',
  RESET_PASSWORD: '/auth/reset-password',
  GET_PROFILE: '/auth/me',
  UPDATE_PROFILE: '/auth/update-profile',
  UPLOAD_PROFILE_PICTURE: '/auth/profile-picture',
  GET_CARD_INFO: '/subscription/card-info',
  SUBSCRIPTION: '/subscription',
  GET_CURRENT_SUBSCRIPTION: '/subscription/current',
  CANCEL_SUBSCRIPTION: '/subscription/cancel',
  GET_PAYMENT_HISTORY: '/subscription/history',
  CREATE_REVIEW: '/review',
  CONTACT: '/auth/send-contact-email',
  UPDATE_AGGREMENT: '/auth/agreement',
  GET_SCREENER_FILTERS_OPTIONS: '/screener-filter',
  SAVE_FILTERS: '/filter',
  GET_STOCKS: '/stocks/temp?page=1',
  PINNED_STOCK: '/stocks/pin',
  UNPINNED_STOCK: '/stocks/unpin',
  LATEST_SYMBOLS: '/stocks/latest-symbols',
  GET_AGREEMENTS: '/agreement/get-agreements',
  TRADE_STOCK: '/stocks/trade',
  EXPORT_DATA: '/stocks/download', // will be updated
};
