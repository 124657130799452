import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ROUTES, USER_ROLE } from 'src/constants/common';
import useAuthContext from 'src/hooks/useAuth';
import { getAuthToken, getAuthUser } from 'src/utils/common';

const withProtectedRoute = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
) => {
  const AuthenticatedRoute: React.FC<P> = (props) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { auth } = useAuthContext();
    const user = getAuthUser();
    const token = getAuthToken();
    const handleAuthenticatedUserNavigation = () => {
      switch (pathname) {
        case ROUTES.WRITE_A_REVIEW:
          navigate(ROUTES.WRITE_A_REVIEW);
          break;
        case ROUTES.SUBSCRIPTION:
          navigate(ROUTES.SUBSCRIPTION);
          break;
        case ROUTES.DASHBOARD:
          navigate(ROUTES.DASHBOARD);
          break;
        case ROUTES.PROFILE:
          navigate(ROUTES.PROFILE);
          break;
        case ROUTES.CONTACT:
          navigate(ROUTES.CONTACT);
          break;
        default:
          navigate(ROUTES.DASHBOARD);
          break;
      }
    };
    useEffect(() => {
      if (!token) {
        navigate(ROUTES.LOGIN);
      } else {
        if (
          user?.role === USER_ROLE.ADMIN ||
          (user?.isProfileCompleted && user?.isAgreement && user?.subscription)
        ) {
          handleAuthenticatedUserNavigation();
        } else if (!user?.isProfileCompleted) {
          navigate(`${ROUTES.PROFILE_COMPLETION}?step=1`);
        } else if (!user?.isAgreement) {
          navigate(`${ROUTES.PROFILE_COMPLETION}?step=2`);
        } else if (!user?.subscription) {
          navigate(`${ROUTES.PROFILE_COMPLETION}?step=3`);
        }
      }
    }, [token, auth?.user]);

    return <WrappedComponent {...props} />;
  };

  return AuthenticatedRoute;
};

export default withProtectedRoute;
