import React, { useState } from 'react';

import { DropDownListType } from 'src/@types/dashboardTypes';
import { Slider } from 'src/components/ui/slider';
import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'src/components/ui/tooltip';
import { PREMIUM_TOOLTIP_TEXT } from 'src/constants/common';
import { cn } from 'src/lib/utils';

import LabelText from '../LabelText';

interface Props {
  label: string;
  keyName: string;
  value?: { start: number; end: number };
  onChange: (name: string, value: string | number | DropDownListType) => void;
  isPremium?: boolean;
  isDisabled?: boolean;
  infoText?: string;
  handleOpen?: () => void;
}

const ProfitProbabilityLongSlider: React.FC<Props> = ({
  label,
  onChange,
  keyName,
  value,
  isDisabled,
  isPremium,
  infoText = '',
  handleOpen,
}) => {
  const [isBlur, setIsBlur] = useState(true);
  const handleRangeChange = (value: number[]) => {
    onChange(keyName, {
      start: value[0] || 0,
      end: value[1] || 0,
    } as DropDownListType);
  };
  const handleClickPremiumFields = () => {
    if (isPremium && isDisabled && handleOpen) {
      handleOpen();
    }
  };
  return (
    <div className='relative' onClick={handleClickPremiumFields}>
      <LabelText
        label={label}
        isDisabled={isDisabled}
        isPremium={isPremium}
        infoText={infoText}
      />
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <div className='relative w-full mt-7'>
              <Slider
                onBlur={() => setIsBlur(true)}
                onFocus={() => setIsBlur(false)}
                key={isBlur ? `${value?.end}-${value?.start}-` : ''}
                max={100}
                disabled={isDisabled}
                min={0}
                step={1}
                value={value ? [value.start, value.end] : [0, 100]}
                onValueChange={handleRangeChange}
                className={cn('w-full cursor-pointer', {
                  'opacity-70': isDisabled,
                })}
                minStepsBetweenThumbs={1}
              />
            </div>
          </TooltipTrigger>
          {isDisabled ? (
            <TooltipContent
              side='top'
              align='center'
              className='max-w-[200px] text-center data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade select-none rounded-[14px] bg-navyBlue px-[15px] py-[10px] text-[13px] text-white leading-[17px]'
            >
              {PREMIUM_TOOLTIP_TEXT}
              <TooltipArrow className='fill-navyBlue w-3 h-2' />
            </TooltipContent>
          ) : null}
        </Tooltip>
      </TooltipProvider>
    </div>
  );
};

export default ProfitProbabilityLongSlider;
