interface ControlState {
  isFocused: boolean;
}

export interface CustomStyles {
  control: (
    provided: React.CSSProperties,
    state: ControlState,
  ) => React.CSSProperties;
  menu: (provided: React.CSSProperties) => React.CSSProperties;
  option: (
    provided: React.CSSProperties,
    state: ControlState,
  ) => React.CSSProperties;
  multiValue: (provided: React.CSSProperties) => React.CSSProperties;
  indicatorsContainer: () => React.CSSProperties;
  multiValueLabel: (provided: React.CSSProperties) => React.CSSProperties;
  multiValueRemove: (provided: React.CSSProperties) => React.CSSProperties;
  placeholder: (provided: React.CSSProperties) => React.CSSProperties;
}

export const customStyles: CustomStyles = {
  control: (provided, state) => ({
    ...provided,
    'background': '#2E3E4D',
    '[type="text"]': {
      fontFamily: 'Helvetica, sans-serif !important',
      fontSize: 13,
      fontWeight: 900,
      color: 'green',
    },
    'display': 'flex',
    'minHeight': '44px',
    'maxHeight': '80px',
    'maxWidth': '100%',
    'overflowX': 'scroll',
    'flexWrap': 'nowrap',
    'outline': 'none',
    'border': 'none',
    'borderRadius': '12px',
    'borderColor': state.isFocused ? 'transparent' : 'transparent',
    'boxShadow': undefined,
    '&:hover': {
      borderColor: state.isFocused ? 'transparent' : 'transparent',
    },
    'cursor': 'pointer',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  }),
  menu: (provided) => ({
    ...provided,
    background: '#2E3E4D',
    zIndex: 9999,
    borderRadius: '12px',
    padding: '5px 0',
  }),
  option: (provided) => ({
    ...provided,
    'backgroundColor': '#2E3E4D',
    'color': 'white',
    '&:hover': {
      backgroundColor: '#242A34',
      color: 'white',
    },
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: '#242A34',
    borderRadius: '4px',
    color: '#fff',
  }),
  indicatorsContainer: () => ({
    display: 'none',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: '#fff',
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    'color': '#95949A',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#fff',
  }),
};
