import React, { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import FormField from 'src/components/FormField';
import Loader from 'src/components/Loader';
import { Typography } from 'src/components/Typography';
import { Button } from 'src/components/ui/button';
import { SET_NEW_PASSWORD } from 'src/constants/auth';
import { ROUTES } from 'src/constants/common';
import { INPUT_ERRORS } from 'src/constants/feedback';
import { resetPassword } from 'src/services/auth';

interface ISetPasswordFields {
  password: string;
  confirmPassword?: string;
}

const SetNewPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const {
    state: { emailForOtp },
  } = useLocation();

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<ISetPasswordFields>({
    mode: 'all',
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  });
  const watchPassword = watch('password');

  const handleSetPassword: SubmitHandler<ISetPasswordFields> = async (data) => {
    const payload = {
      email: emailForOtp,
      password: data.password,
    };
    setIsLoading(true);
    const response = await resetPassword(payload);
    setIsLoading(false);
    if (!response) return;
    navigate(ROUTES.DASHBOARD);
  };

  return (
    <div className='bg-nightSky md:px-10 md:py-6 mt-20 mb-40 lg:px-20 p-6 lg:py-8 flex flex-col mx-auto w-11/12 md:w-7/12 lg:w-5/12 rounded-xl'>
      <Typography
        variant='subheading'
        className='mx-auto my-4 text-center capitalize'
      >
        {SET_NEW_PASSWORD.TITLE}
      </Typography>
      <Typography variant='p' className='mx-auto capitalize'>
        {SET_NEW_PASSWORD.NEW_TO_BO}{' '}
        <Link to={ROUTES.SIGNUP}>
          <span className='text-skyBlue underline'>
            {SET_NEW_PASSWORD.SIGNUP}
          </span>
        </Link>
      </Typography>
      <Controller
        control={control}
        name='password'
        rules={{
          required: INPUT_ERRORS.PASSWORD.required,
        }}
        render={({ field: { onChange, value, name } }) => (
          <FormField
            title={SET_NEW_PASSWORD.PASSWORD.title}
            placeholder={SET_NEW_PASSWORD.PASSWORD.placeholder}
            name={name}
            type='password'
            value={value}
            onChange={onChange}
            errors={errors}
          />
        )}
      />
      <Controller
        control={control}
        name='confirmPassword'
        rules={{
          required: INPUT_ERRORS.CONFIRM_PASSWORD.required,
          validate: (value) =>
            value === watchPassword || INPUT_ERRORS.CONFIRM_PASSWORD.match,
        }}
        render={({ field: { onChange, value, name } }) => (
          <FormField
            title={SET_NEW_PASSWORD.CONFIRM_REMEMBER_ME.title}
            placeholder={SET_NEW_PASSWORD.CONFIRM_REMEMBER_ME.placeholder}
            name={name}
            type='password'
            value={value || ''}
            onChange={onChange}
            errors={errors}
          />
        )}
      />
      <Button
        variant='gradient'
        className='my-4'
        onClick={handleSubmit(handleSetPassword)}
      >
        {isLoading ? <Loader loading={isLoading} /> : SET_NEW_PASSWORD.SUBMIT}
      </Button>
    </div>
  );
};

export default SetNewPassword;
