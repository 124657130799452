import { OptionProps as ReactSelectOptionProps } from 'react-select';
export interface OptionType {
  value: string;
  symbol?: string;
  description?: string;
  name?: string;
}

export type ColumnOrder = 'asc' | 'desc' | undefined;

export interface IMultiSelectInput {
  inputClassName?: string;
  label?: string;
  isPremium?: boolean;
  isDisabled?: boolean;
  handleOpen?: () => void;
  options: Array<OptionType>;
  keyName: string;
  value?: OptionType;
  infoText?: string;
  onChange: (
    name: string,
    value: string | number | OptionType | Date | OptionType[],
  ) => void;
}
export type OptionPropsType = ReactSelectOptionProps & {
  isSelected: boolean;
  label: string;
};
export type DropDownListType = {
  name?: string;
  value?: string | number;
  description?: string;
  symbol?: string;
  min?: string;
  max?: string;
  start?: number;
  end?: number;
  _id?: string;
  operator?: string;
  priceToEarningsRatioValue?: number;
  callPutRatioValue?: number;
};
export interface IFilterSectionFormFields {
  priceToEarningsRatio: string;
  incInsiderPurcFor: string;
  excInsiderSellFor: string;
  firstPubPrice: string;
  amount: number;
  minOfSecurity: number;
  contractPerSecurity: number;
  maxWeightagePerSecurity: number;
  assetPrice: { min: number; max: number };
}
export interface IScreenerType {
  filter: {
    daysRemaining?: { min: string; max: string };
    riskYield?: { value: string; name: string };
    tickerFiled?: {
      value: string;
      symbol: string;
      description: string;
    };
    assetPrice?: { min: string; max: string };
    expiratonDate?: Date;
    excludeInsiderSellFor?: string;
    includeInsiderPurchFor?: string;
    sector?: { value: string; name: string };
    industry?: { value: string; name: string };
    marketCap?: { value: string; name: string };
    priceToEarningsRatio?: {
      operator: { value: string; name: string };
      priceToEarningsRatioValue: number;
    };
    country?: { value: string; name: string };
    profitProbabilityShort?: { start: number; end: number };
    callPutRatio?: {
      operator: { value: string; name: string };
      callPutRatioValue: number;
    };
    expw?: { value: string; name: string };
    week?: Date;
    pnl?: { start: number; end: number };
    firstPubPrice?: string | number;
    firstPubAt?: Date;
  };
  calculator: {
    amount?: number;
    minOfSecurity?: number;
    contractPerSecurity?: number;
    maxWeightagePerSecurity?: number;
    contractsToPick?: { value: number; name: string };
  };
  sorter: {
    column?: string;
    order?: ColumnOrder;
  };
}
export interface ICalculator {
  amount?: number;
  minOfSecurity?: number;
  contractPerSecurity?: number;
  maxWeightagePerSecurity?: number;
  contractsToPick?: { value: number; name: string };
}
export interface IFilterSectionPayload {
  screener: IScreenerType;
  name: string;
}
export interface IFilterSectionResponse {
  status: boolean;
  message: string;
  statusCode: number;
  data: {
    screener: IScreenerType;
    name: string;
    userId: string;
    _id: string;
    __v: number;
  };
}
type ResponseOptionType = {
  name: string;
  value: string;
  _id: string;
};

type FilterType = {
  name: string;
  description: string;
  options: ResponseOptionType[];
  _id: string;
};

type DataType = {
  _id: string;
  filters: FilterType[];
};

export type ScreenerResponseType = {
  data: DataType[];
  statusCode: number;
  status: boolean;
  message: string;
};
export type ScreenerListType = {
  filter: Record<string, unknown>;
  calculator: Record<string, unknown>;
};

export type AllScreenerListType = {
  screener: ScreenerListType;
  _id: string;
  userId: string;
  name: string;
  __v: number;
};

export type AllScreenerListTypeResponse = {
  data: AllScreenerListType[];
  message: string;
  status: boolean;
  statusCode: number;
};
type FilterTypeForDelete = {
  tickerField: string[];
  assetPrice: {
    max: number;
    min: number;
  };
  industry: string[];
  daysRemaining: {
    max: number;
    min: number;
  };
};

type CalculatorTypeForDelete = {
  amount: number;
  contractsToPick: number;
};

type ScreenerType = {
  filter: FilterTypeForDelete;
  calculator: CalculatorTypeForDelete;
};

type DeleteFilterDataType = {
  screener: ScreenerType;
  _id: string;
  userId: string;
  name: string;
  __v: number;
};

export type DeleteFilterResponseType = {
  statusCode: number;
  data: DeleteFilterDataType;
  message: string;
  status: boolean;
};

export type SendMessage = {
  action: string;
  userId?: string | undefined;
  page?: number | undefined;
  filters?: IScreenerType | undefined;
  sort?: string | undefined;
  limit?: number | undefined;
};

export type PinnedAndUnPinnedApiResponseType = {
  data: {
    id: number;
    user_id: string;
  };
  statusCode: number;
  message: string;
};

export type TradeStockAPIResponseType = {
  data: {
    id: number;
    user_id: string;
  };
  statusCode: number;
  message: string;
};

export type ProfitProbility = {
  grandTotal: number;
  noPercentage: number;
  totalCountNo: number;
  totalCountYes: number;
  yesPercentage: number;
  totalYesPnl: number;
  totalNoPnl: number;
  grandTotalPnl: number;
};

export enum LEVELS {
  UP = 'UP',
  DOWN = 'DOWN',
}

export enum SUB_LEVELS {
  NORMAL = 'NORMAL',
  SLOWED = 'SLOWED',
  DELAYED = 'DELAYED',
  ALERT = 'ALERT',
  CRITICAL = 'CRITICAL',
}

export type Alert = {
  id: number;
  levels: LEVELS;
  message: string;
  serviceName: string;
  updatedAt: string;
  subLevels: SUB_LEVELS;
};
