import React from 'react'; // Import ReactNode type

import {
  AllScreenerListType,
  DropDownListType,
  IScreenerType,
  ProfitProbility,
} from 'src/@types/dashboardTypes';

export const DashboardContext = React.createContext<{
  countryList: DropDownListType[] | undefined;
  sectorList: DropDownListType[] | undefined;
  marketCapList: DropDownListType[] | undefined;
  industoryList: DropDownListType[] | undefined;
  fetchFiltersData: () => void;
  screener: IScreenerType | undefined;
  screenerName: string;
  handleFilterChange: (
    name: string,
    value: string | DropDownListType | number | Date | DropDownListType[],
  ) => void;
  handleSaveFilter: () => void;
  setScreenerName: React.Dispatch<React.SetStateAction<string>>;
  removeFilter: (name: string) => void;
  fetchAllFiltersDataByUser: () => void;
  handleUpdateFilter: (id: string | number) => void;
  screenerList: AllScreenerListType[] | undefined;
  setScreener:
    | React.Dispatch<React.SetStateAction<IScreenerType | undefined>>
    | undefined
    | any;
  setScreenerList:
    | React.Dispatch<React.SetStateAction<AllScreenerListType[]>>
    | undefined;
  keyInScreener: (keyName: string) => boolean;
  selectedValueScreener: DropDownListType | undefined | string;
  setSelectedValueScreener: React.Dispatch<React.SetStateAction<string>>;
  userSubscriptionType: string | null;
  getCardData: () => void;
  areCalculatorFieldsValid: (calculator: any) => boolean;
  isPremiumOrAdmin?: boolean | undefined;
  isAdmin: boolean | null;
  userSubscriptionTypeLaoding: boolean | null;
  setProfitProbability: React.Dispatch<
    React.SetStateAction<ProfitProbility | null>
  >;
  profitProbability: ProfitProbility | null;
  calculatorError: string;
  setCalculatorError: React.Dispatch<React.SetStateAction<string>>;
}>({
  countryList: [],
  sectorList: [],
  marketCapList: [],
  industoryList: [],
  screenerName: '',
  fetchFiltersData: () => {},
  screener: undefined,
  handleFilterChange: () => {},
  handleSaveFilter: () => {},
  setScreenerName: () => {},
  removeFilter: () => {},
  fetchAllFiltersDataByUser: () => {},
  handleUpdateFilter: () => {},
  screenerList: [],
  setScreener: () => {},
  setScreenerList: () => {},
  keyInScreener: () => false,
  selectedValueScreener: undefined,
  setSelectedValueScreener: () => {},
  userSubscriptionType: '',
  getCardData: () => {},
  areCalculatorFieldsValid: () => false,
  isPremiumOrAdmin: undefined,
  isAdmin: null,
  userSubscriptionTypeLaoding: null,
  setProfitProbability: () => {},
  profitProbability: null,
  calculatorError: '',
  setCalculatorError: () => {},
});
