import React, { useEffect, useState } from 'react';

import PaymentTable from 'src/components/PaymentTable';
import { Typography } from 'src/components/Typography';
import { PROFILE } from 'src/constants/profile';
import { getPaymentHistory } from 'src/services/profile';
import { PaymentHistory as PaymentsHistory } from 'src/services/types';

const PaymentHistory = () => {
  const [paymentHistory, setPaymentHistory] = useState<PaymentsHistory | null>(
    null,
  );

  useEffect(() => {
    getHistoryData();
  }, []);

  const getHistoryData = async () => {
    const response = await getPaymentHistory();
    setPaymentHistory(response?.data as PaymentsHistory);
  };

  return (
    <div className='bg-nightSky w-11/12 mx-auto p-6 m-4 rounded-2xl'>
      <div>
        <div className='flex justify-between'>
          <Typography variant='subheading' className='text-lg md:text-2xl'>
            {PROFILE.PAYMENT_HISTORY}
          </Typography>
        </div>
        <PaymentTable
          tableHeadings={PROFILE.TABLE_HEADING}
          data={paymentHistory as PaymentsHistory}
        />
      </div>
    </div>
  );
};

export default PaymentHistory;
