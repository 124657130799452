import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FaRegEdit } from 'react-icons/fa';
import { RiVipCrown2Fill } from 'react-icons/ri';
import { TbTrash } from 'react-icons/tb';

import { DropDownListType } from 'src/@types/dashboardTypes';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'src/components/ui/select';
import { PREMIUM_TOOLTIP_TEXT } from 'src/constants/common';
import {
  DASHBOARD_TEXT,
  DELETE_FILTER_MODAL,
  NO_SCREENERS,
  SAVE_UPDATE_FILTER_MODAL,
} from 'src/constants/dashboard';
import useDashboardContext from 'src/hooks/useDashboardData';
import { cn } from 'src/lib/utils';
import { Operator } from 'src/pages/Dashboard/components/CallPutRatio';
import SaveAndEditFilterDialog from 'src/pages/Dashboard/components/saveAndEditFilterDialog';
import { deleteFilter } from 'src/services/Dashboard';

import { Input } from './ui/input';
import { Label } from './ui/label';
import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from './ui/tooltip';
import InfoIconTooltip from './InfoIconTooltip';
import Modal from './Modal';
type ItemType = Record<string, unknown>;
interface ISelectItem {
  placeholder?: string;
  infoText?: string;
  items: ({ value: string | number; name?: string } | string)[] | any;
  handleSelectedValue?: (value: string) => void;
  inputClassName?: string;
  listItemContainerClassName?: string;
  listItemClassName?: string;
  label?: string;
  isPremium?: boolean;
  isDisabled?: boolean;
  isScreener?: boolean;
  isNumber?: boolean;
  value?:
    | { value: number | string; name: string }
    | string
    | number
    | undefined;
  hasInputFields?: boolean;
  handleOpen?: () => void;
  onChange?: (
    name: string,
    value: string | number | DropDownListType | Date | Operator,
  ) => void;
  keyName?: string;
  hasStringTypeValue?: boolean;
  hasOperator?: boolean;
  minValue?: number;
  maxValue?: number;
  calculatorToolTipText?: string;
  labelClassName?: string;
}

const SelectDropdown: React.FC<ISelectItem> = ({
  placeholder = 'Select',
  infoText = '',
  items = [],
  inputClassName,
  listItemContainerClassName,
  listItemClassName,
  handleSelectedValue,
  label,
  isPremium = false,
  isDisabled = false,
  isScreener = false,
  hasInputFields = false,
  isNumber,
  handleOpen,
  onChange,
  keyName,
  value,
  hasOperator,
  hasStringTypeValue,
  minValue,
  maxValue,
  calculatorToolTipText,
  labelClassName,
}) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [idForDelete, setIdForDelete] = useState<{
    id: number | string;
    name: string;
  }>({
    id: '',
    name: '',
  });
  const [selectedValue, setSelectedValue] = React.useState('');
  const {
    screenerList,
    setScreener,
    screener,
    screenerName,
    selectedValueScreener,
    setSelectedValueScreener,
    removeFilter,
    setScreenerName,
    handleUpdateFilter,
    setScreenerList,
  } = useDashboardContext();
  const [openEdit, setOpenEdit] = useState(false);
  const [typedValue, setTypedValue] = useState<string>('');
  const [selectedFilterId, setSelectedFilterId] = useState<string | number>('');
  const flattenedItems = items.flat();
  useEffect(() => {
    if (value) {
      if (typeof value === 'object') {
        setSelectedValue(String(value?.value));
      } else {
        setSelectedValue(String(value));
      }
    } else {
      setSelectedValue('');
    }
  }, [value]);

  // function for handling Select
  const handleValueChange = (value: string) => {
    if (value === NO_SCREENERS.value) return;
    if (isScreener) {
      setScreener({ filter: {}, calculator: {}, sorter: {} });
      const searchSelectedScreener = screenerList?.find(
        (item) => item._id === value,
      );
      setScreener(searchSelectedScreener?.screener);
      setSelectedValue('');
      setSelectedValueScreener(value);
    } else if (typedValue !== '') {
      setTypedValue('');
    }
    if (onChange && keyName && !isScreener) {
      const findSelectValueObject = flattenedItems.filter(
        (item: DropDownListType) => item.value === Number(value),
      );
      const stringSelectedValue = flattenedItems.filter(
        (item: DropDownListType) => item.value === value,
      );
      if (hasStringTypeValue) {
        onChange(keyName, {
          value: stringSelectedValue[0]?.value,
          name: stringSelectedValue[0]?.name,
        });
      } else {
        onChange(
          keyName,
          hasOperator ? stringSelectedValue[0] : findSelectValueObject[0],
        );
      }
    }
    if (!isScreener) {
      setSelectedValue(value);
    }
    setSelectedValue(value);
    if (handleSelectedValue) {
      handleSelectedValue(value);
    }
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    let numericValue = parseFloat(value);
    if (!isNaN(numericValue)) {
      if (minValue !== undefined && numericValue < minValue) {
        numericValue = minValue;
      } else if (maxValue !== undefined && numericValue > maxValue) {
        numericValue = maxValue;
      }
    }
    const newValue = isNaN(numericValue) ? value : numericValue.toString();
    setTypedValue(newValue);
    setSelectedValue(newValue);
    if (onChange && keyName) {
      onChange(keyName, newValue);
    }
    if (handleSelectedValue) {
      handleSelectedValue(newValue);
    }
  };
  const selectedOptionName = useMemo(() => {
    if (isScreener) {
      const newItem = flattenedItems
        .filter(
          (item: ItemType) => String(item.value) === selectedValueScreener,
        )
        .map((item: ItemType) => item.name)
        .toString();
      return newItem;
    }
    const newItem = flattenedItems
      .filter((item: ItemType) => String(item.value) === selectedValue)
      .map((item: ItemType) => item.name)
      .toString();
    return newItem;
  }, [flattenedItems, selectedValue]);
  const handleClickPremiumFields = () => {
    if (isPremium && isDisabled && handleOpen) {
      handleOpen();
    }
  };
  const handleModalOpen = (itemId: string | number, itemName: string) => {
    setOpenDeleteModal(true);
    setIdForDelete({ id: itemId, name: itemName });
  };
  const handleDeleteFilter = async () => {
    const response = await deleteFilter(idForDelete?.id as string);
    if (!response) return;
    setSelectedValue('');
    if (idForDelete?.id === selectedValueScreener) {
      setSelectedValueScreener('');
      setScreener({ filter: {}, calculator: {} });
    }
    const updatedScreener =
      screenerList?.filter((item) => item._id !== idForDelete?.id) || [];
    if (setScreenerList) {
      setScreenerList(updatedScreener);
    }
  };

  const updatedDescription = DELETE_FILTER_MODAL.description.map((item) => ({
    ...item,
    text: item.text.replace('this', idForDelete?.name as string),
  }));
  const openEditFilter = (val: string | number) => {
    const searchSelectedScreener = screenerList?.find(
      (item) => item._id === val,
    );
    setScreener(searchSelectedScreener?.screener);
    setSelectedFilterId(val);
    setScreenerName(searchSelectedScreener?.name ?? '');
    setOpenEdit(true);
  };
  const keyInScreener = useCallback(() => {
    if (keyName) {
      const isKeyPresent =
        keyName in (screener?.calculator || {}) ||
        keyName in (screener?.filter || {});
      return isKeyPresent;
    }
    return false;
  }, [screener?.calculator, screener?.filter]);
  return (
    <div onClick={handleClickPremiumFields}>
      <SaveAndEditFilterDialog
        setOpen={setOpenEdit}
        title={SAVE_UPDATE_FILTER_MODAL.update.title}
        btnText={SAVE_UPDATE_FILTER_MODAL.update.title}
        open={openEdit}
        handleBtn={() => handleUpdateFilter(selectedFilterId)}
        data={screener}
        screenerName={screenerName}
        removeFilter={removeFilter}
        onChange={setScreenerName}
      />
      <Label
        className={cn(
          'text-white text-xs xl:text-[15px] font-light flex items-center gap-2 xl:gap-4',
          {
            'opacity-70': isDisabled,
          },
          labelClassName,
        )}
      >
        {label}
        {isPremium ? <RiVipCrown2Fill className='size-4' /> : null}
        {infoText ? <InfoIconTooltip tooltipText={infoText} /> : null}
      </Label>
      <TooltipProvider>
        <Select
          onValueChange={handleValueChange}
          value={
            isScreener
              ? selectedValueScreener?.toString()
              : (selectedValue as string | undefined)
          }
        >
          <Tooltip>
            <TooltipTrigger asChild>
              <div className='relative'>
                <div
                  className={cn('w-auto h-11 z-50 left-0 absolute right-0', {
                    'hidden ': !isDisabled,
                  })}
                  onClick={handleClickPremiumFields}
                />
                <SelectTrigger
                  className={cn(
                    'text-white p-4 h-11 rounded-xl w-[180px] ring-offset-0  bg-darkGray border-none focus:ring-2 focus:ring-lightBlue focus:ring-offset-0',
                    {
                      ' mt-4': label,
                      'ring-[2px] ring-lightBlue': keyInScreener(),
                    },
                    inputClassName,
                  )}
                  disabled={isDisabled}
                >
                  <SelectValue placeholder={placeholder} className='text-base'>
                    {typedValue !== ''
                      ? typedValue
                      : selectedOptionName || placeholder}
                  </SelectValue>
                </SelectTrigger>
              </div>
            </TooltipTrigger>
            {isDisabled ? (
              <TooltipContent
                side='top'
                align='center'
                className='max-w-[200px] text-center data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade select-none rounded-[14px] bg-navyBlue px-[15px] py-[10px] text-[13px] text-white leading-[17px]'
              >
                {calculatorToolTipText
                  ? calculatorToolTipText
                  : PREMIUM_TOOLTIP_TEXT}
                <TooltipArrow className='fill-navyBlue w-3 h-2' />
              </TooltipContent>
            ) : null}
          </Tooltip>
          <SelectContent
            className={cn(
              'bg-darkGray border-none text-white rounded-xl relative',
              listItemContainerClassName,
            )}
          >
            {hasInputFields && (
              <Input
                type={isNumber ? 'number' : 'text'}
                containerStyles='w-[90%] mx-auto h-9 rounded-full mt-2.5 mb-1.5 focus-within:ring-3'
                className='border border-borderColor rounded-full bg-skyCaptain '
                placeholder={DASHBOARD_TEXT.TYPE_HERE}
                value={typedValue}
                onChange={handleInputChange}
              />
            )}
            <div className='dropdown-scrollbar max-h-40 overflow-y-auto mt-2'>
              {flattenedItems.map(
                (
                  item: { value: string | number; name?: string } | string,
                  index: number,
                ) => (
                  <div key={index} className='flex items-center'>
                    <SelectItem
                      value={
                        typeof item === 'object' ? item.value.toString() : item
                      }
                      className={cn(
                        'rounded-lg px-2 hover:!text-white focus:!text-white hover:!font-medium',
                        {
                          'hover:!bg-skyCaptain focus:!bg-skyCaptain':
                            !isScreener,
                          'hover:bg-darkGray focus:!bg-darkGray': isScreener,
                        },
                        listItemClassName,
                      )}
                    >
                      {typeof item === 'object' ? item.name : item}
                    </SelectItem>
                    {isScreener &&
                      typeof item === 'object' &&
                      !(item?.value === NO_SCREENERS.value) && (
                        <div className='flex mr-2 gap-1'>
                          <FaRegEdit
                            size={15}
                            onClick={() => openEditFilter(item.value)}
                          />
                          <TbTrash
                            size={15}
                            onClick={() =>
                              handleModalOpen(item.value, item.name as string)
                            }
                          />
                        </div>
                      )}
                  </div>
                ),
              )}
            </div>
          </SelectContent>
        </Select>
      </TooltipProvider>
      <Modal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        title={DELETE_FILTER_MODAL.title}
        description={updatedDescription}
        btnText={DELETE_FILTER_MODAL.btnText}
        handleBtn={handleDeleteFilter}
      />
    </div>
  );
};

export default SelectDropdown;
