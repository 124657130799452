import React from 'react';

import { Typography } from 'src/components/Typography';
import { Button } from 'src/components/ui/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'src/components/ui/dialog';
import { cn } from 'src/lib/utils';
type TabTwoPropType = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  description?: { text: string; type: string }[];
  btnText: string;
  handleBtn: () => void;
  children?: JSX.Element;
  buttonDisabled?: boolean;
};
const Modal: React.FC<TabTwoPropType> = ({
  open,
  setOpen,
  title,
  description,
  btnText,
  handleBtn,
  children,
  buttonDisabled,
}) => {
  const hadleSuccessButton = () => {
    handleBtn();
    setOpen(false);
  };
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className='text-xl font-semibold text-white'>
            {title}
          </DialogTitle>
          {description ? (
            <DialogDescription className='p-4 w-full overflow-scroll text-white noScrollBar h-72 md:h-auto text-center'>
              {description.map((item: { text: string; type: string }) => (
                <Typography
                  className={cn('text-base font-light mb-5', {
                    'text-lg font-semibold mb-2': item.type === 'heading',
                  })}
                  key={item.text}
                >
                  {item.text}
                </Typography>
              ))}
            </DialogDescription>
          ) : null}
        </DialogHeader>
        {children ? children : null}
        <DialogFooter className='sm:justify-center'>
          <div className='flex gap-3 items-center'>
            <DialogClose asChild>
              <Button
                variant='primary'
                className='hover:bg-lightBlue md:p-3 lg:p-[22px] focus-visible:ring-0 sm:w-44 md:w-56 font-medium sm:text-base !ring-offset-0'
              >
                Cancel
              </Button>
            </DialogClose>
            <Button
              onClick={hadleSuccessButton}
              variant='gradient'
              className='flex md:p-3 lg:p-[22px] sm:w-44 font-medium sm:text-base md:w-56'
              disabled={buttonDisabled}
            >
              {btnText}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
