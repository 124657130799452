import React, { useState } from 'react';
import { RiVipCrown2Fill } from 'react-icons/ri';

import { Tabs, TabsList, TabsTrigger } from 'src/components/ui/tabs';
import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
} from 'src/components/ui/tooltip';
import { PREMIUM_TOOLTIP_TEXT } from 'src/constants/common';
import { TABLE_HEADING_TABS } from 'src/constants/dashboard';
import useDashboardContext from 'src/hooks/useDashboardData';

import SelectPlanModal from '../SelectPlanModal';

interface Props {
  onTabChange: (value: string) => void;
  selectedTableTab: string;
  isBasic: boolean;
}

const TableHeadingTabs: React.FC<Props> = ({
  onTabChange,
  selectedTableTab,
  isBasic,
}) => {
  const { isPremiumOrAdmin } = useDashboardContext();
  const [open, setOpen] = useState(false);
  const handleTabSelection = (value: string) => {
    onTabChange(value);
  };

  return (
    <div className='mx-auto flex w-11/12 justify-center'>
      <div className='my-8 rounded-full bg-darkGray py-[6px]'>
        <Tabs
          defaultValue={TABLE_HEADING_TABS.LIVE_FLOW.key}
          className='md:full min-w-fit rounded-full'
          onValueChange={handleTabSelection}
          value={selectedTableTab}
        >
          <TabsList className='h-12 rounded-full bg-darkGray'>
            <TabsTrigger
              className='rounded-full p-3 text-lg font-normal data-[state=active]:font-semibold data-[state=active]:bg-gradient-to-r data-[state=active]:from-lightBlue data-[state=active]:to-lightPurple text-white md:px-10'
              value={TABLE_HEADING_TABS.LIVE_FLOW.key}
            >
              {TABLE_HEADING_TABS.LIVE_FLOW.title}
            </TabsTrigger>
            <Tooltip>
              <TooltipTrigger asChild>
                <div onClick={() => (isBasic ? setOpen(true) : () => {})}>
                  <TabsTrigger
                    className='rounded-full cursor-pointer p-3 text-lg font-normal data-[state=active]:font-semibold data-[state=active]:bg-gradient-to-r data-[state=active]:from-lightBlue data-[state=active]:to-lightPurple text-white md:px-10'
                    value={TABLE_HEADING_TABS.HISTORICAL_FLOW.key}
                    disabled={!isPremiumOrAdmin}
                  >
                    <RiVipCrown2Fill size={20} className='fill-white mr-2' />
                    {TABLE_HEADING_TABS.HISTORICAL_FLOW.title}
                  </TabsTrigger>
                </div>
              </TooltipTrigger>
              {isBasic ? (
                <TooltipContent
                  side='top'
                  align='center'
                  className='max-w-[200px] text-center data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade select-none rounded-[14px] bg-navyBlue px-[15px] py-[10px] text-[13px] text-white leading-[17px]'
                >
                  {PREMIUM_TOOLTIP_TEXT}
                  <TooltipArrow className='fill-navyBlue w-3 h-2' />
                </TooltipContent>
              ) : null}
            </Tooltip>
          </TabsList>
        </Tabs>
      </div>
      <SelectPlanModal open={open} setOpen={setOpen} />
    </div>
  );
};

export default TableHeadingTabs;
