import React from 'react';

import { Typography } from 'src/components/Typography';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'src/components/ui/accordion';
import { FAQ } from 'src/constants/static';

type QuestionType = {
  question: string;
  answere: string;
  listText?: string[];
};

const Faq = () => {
  return (
    <div className='mx-auto w-5/6 pb-16 pt-5 text-white 2xl:w-10/12'>
      <div className='md:w-3/4'>
        <Typography variant='subheading'>{FAQ.TITLE}</Typography>
        {FAQ.SECTIONS.map(
          (item: { TITLE: string; QUESTIONS: QuestionType[] }) => (
            <div className='mt-11' key={item.TITLE}>
              {/* <Typography className='mt-11 text-2xl' noBold>
                {item.TITLE}
              </Typography> */}
              <Accordion type='single' collapsible>
                {item.QUESTIONS.map((quetions: QuestionType) => (
                  <AccordionItem
                    key={quetions.question}
                    value={quetions.question}
                  >
                    <AccordionTrigger>{quetions.question}</AccordionTrigger>
                    <AccordionContent>
                      {quetions.answere}
                      <ul className='mt-2 list-outside list-disc space-y-2 pl-5'>
                        {quetions.listText?.map((pointListItem: string) => (
                          <li key={pointListItem}>{pointListItem}</li>
                        ))}
                      </ul>
                    </AccordionContent>
                  </AccordionItem>
                ))}
              </Accordion>
            </div>
          ),
        )}
      </div>
    </div>
  );
};

export default Faq;
