import React, { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';

import FormField from 'src/components/FormField';
import Loader from 'src/components/Loader';
import withPublicRoute from 'src/components/PublicRoute';
import { Typography } from 'src/components/Typography';
import { Button } from 'src/components/ui/button';
import { Checkbox } from 'src/components/ui/checkbox';
import { LOGIN } from 'src/constants/auth';
import { ROUTES, SUPPORT_EMAIL_ADDRESS } from 'src/constants/common';
import { INPUT_ERRORS } from 'src/constants/feedback';
import useAuthContext from 'src/hooks/useAuth';
import { login } from 'src/services/auth';
import { EmailRegex, getEmail } from 'src/utils/common';

interface ILoginFields {
  email: string;
  password: string;
}

const Login = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isRemember, setIsRemember] = useState(false);
  const { setEmailForOtp } = useAuthContext();
  const { setUser } = useAuthContext();
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<ILoginFields>({
    mode: 'all',
    defaultValues: {
      email: '',
      password: '',
    },
  });

  useEffect(() => {
    const email = getEmail();
    if (email) {
      setValue('email', typeof email === 'string' ? email : '');
    }
  }, []);

  const handleLogin: SubmitHandler<ILoginFields> = async (data) => {
    setIsLoading(true);
    const response: any = await login(data, isRemember);
    setIsLoading(false);
    if (!response) return;
    await setUser();
    if (!response.isEmailVerified) {
      setEmailForOtp(data.email);
      navigate(ROUTES.OTP);
    }
  };

  const toggleRemember = () => {
    setIsRemember(!isRemember);
  };

  return (
    <div className='bg-nightSky md:px-10 md:py-6 mt-20 mb-40 lg:px-20 p-6 lg:py-8 flex flex-col mx-auto w-11/12 md:w-7/12 lg:w-5/12 rounded-[18px]'>
      <Typography
        variant='subheading'
        className='mx-auto my-4 text-center capitalize'
      >
        {LOGIN.TITLE}
      </Typography>
      <Typography variant='p' className='mx-auto capitalize'>
        {LOGIN.NEW_TO_BO}{' '}
        <Link to={ROUTES.SIGNUP}>
          <span className='text-skyBlue underline'>{LOGIN.SIGN_UP}</span>
        </Link>
      </Typography>
      <Controller
        control={control}
        name='email'
        rules={{
          required: INPUT_ERRORS.EMAIL.required,
          pattern: {
            value: EmailRegex,
            message: INPUT_ERRORS.EMAIL.pattern,
          },
        }}
        render={({ field: { onChange, value, name } }) => (
          <FormField
            title={LOGIN.EMAIL.title}
            placeholder={LOGIN.EMAIL.placeholder}
            name={name}
            type='email'
            value={value}
            errors={errors}
            onChange={(e) => onChange(e.target.value?.toLocaleLowerCase())}
          />
        )}
      />
      <Controller
        control={control}
        name='password'
        rules={{
          required: INPUT_ERRORS.PASSWORD.required,
        }}
        render={({ field: { onChange, value, name } }) => (
          <FormField
            title={LOGIN.PASSWORD.title}
            placeholder={LOGIN.PASSWORD.placeholder}
            name={name}
            type='password'
            value={value}
            onChange={onChange}
            errors={errors}
          />
        )}
      />
      <div className='flex items-center justify-between'>
        <label className='flex items-center gap-2' onClick={toggleRemember}>
          <Checkbox className='bg-darkGray data-[state=checked]:bg-darkGray rounded-[4px]' />
          <Typography className='my-2'>{LOGIN.REMEMBER_ME}</Typography>
        </label>
        <Link to={ROUTES.FORGOT_PASSWORD}>
          <Typography className='text-skyBlue underline'>
            {LOGIN.FORGOT_PASSWORD}
          </Typography>
        </Link>
      </div>
      <Button
        variant='gradient'
        className='my-4 text-lg'
        onClick={handleSubmit(handleLogin)}
      >
        {isLoading ? <Loader loading={isLoading} /> : LOGIN.LOG_IN}
      </Button>
      <Typography className='text-center text-sm'>
        {LOGIN.SEND_EMAIL_TEXT}
        <a
          href={`mailto:${SUPPORT_EMAIL_ADDRESS}`}
          className=' font-semibold hover:text-skyBlue'
        >
          {SUPPORT_EMAIL_ADDRESS}.
        </a>
      </Typography>
    </div>
  );
};

export default withPublicRoute(Login);
