import React, { useState } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormSetValue,
} from 'react-hook-form';

import { Typography } from 'src/components/Typography';
import { Label } from 'src/components/ui/label';
import { RadioGroup, RadioGroupItem } from 'src/components/ui/radio-group';
import { PROFILE_COMPLETION } from 'src/constants/profileCompletion';
import { LocationState } from 'src/hooks/useLocation';
import { cn } from 'src/lib/utils';
import { BASIC_INFO, USER_TYPE } from 'src/services/types';

import AddressFiled from './AddressFields';
import EmployedFields from './EmployedFields';
import NameFileds from './NameFileds';
import UploadImage from './UploadImage';
type StepOneProps = {
  errors: FieldErrors<BASIC_INFO>;
  control: Control<BASIC_INFO>;
  setValue: UseFormSetValue<BASIC_INFO>;
  location: LocationState;
  setLocation: React.Dispatch<React.SetStateAction<LocationState>>;
};
const StepOne: React.FC<StepOneProps> = ({
  errors,
  control,
  setValue,
  location,
  setLocation,
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.files?.[0]) {
      const file = event?.target?.files?.[0];
      setValue('avatar', file);
      setSelectedFile(file);
    }
  };
  return (
    <div>
      <Typography className='text-center w-full'>
        {PROFILE_COMPLETION.ENTER_INF}
      </Typography>
      <div className='lg:flex pt-5'>
        <div className='border-b flex flex-col items-center mb-5 pb-5 lg:mb-0 lg:pb-0 lg:border-b-0 lg:border-r border-lightGray lg:min-w-56'>
          <UploadImage
            selectedFile={selectedFile as File}
            handleFileChange={handleFileChange}
          />
        </div>
        <div className='w-full px-5 md:px-14 py-2'>
          <NameFileds errors={errors} control={control} />
          <AddressFiled
            errors={errors}
            control={control}
            location={location}
            setLocation={setLocation}
          />
        </div>
      </div>
      <div className='lg:flex'>
        <div className='lg:min-w-56' />
        <div className='w-full px-5 md:px-14 py-2'>
          <EmployedFields
            errors={errors}
            control={control}
            setValue={setValue}
          />
        </div>
      </div>
      <div className='lg:flex'>
        <div className='lg:min-w-56' />
        <div className='w-full px-5 md:px-14 py-2'>
          <Typography className='text-base'>
            {PROFILE_COMPLETION.USER_RADIO_BUTTON_HEADING}
          </Typography>
          <Controller
            name='userType'
            control={control}
            render={({ field }) => (
              <RadioGroup
                value={field.value}
                onValueChange={field.onChange}
                className='md:flex my-4 gap-4 md:gap-16'
              >
                <div className='flex items-center space-x-2'>
                  <RadioGroupItem
                    value={USER_TYPE.INDVIDUAL}
                    id={USER_TYPE.INDVIDUAL}
                    className={cn(
                      'bg-white focus:bg-skyBlue text-white border-none',
                      {
                        'bg-skyBlue': field.value === USER_TYPE.INDVIDUAL,
                      },
                    )}
                  />
                  <Label htmlFor={USER_TYPE.INDVIDUAL} className='text-white'>
                    {PROFILE_COMPLETION.FIRST_RADIO_BUTTON}
                  </Label>
                </div>
                <div className='flex items-center space-x-2'>
                  <RadioGroupItem
                    value={USER_TYPE.ORGANIZATION}
                    id={USER_TYPE.ORGANIZATION}
                    className={cn(
                      'bg-white focus:bg-skyBlue text-white border-none',
                      {
                        'bg-skyBlue': field.value === USER_TYPE.ORGANIZATION,
                      },
                    )}
                  />
                  <Label
                    htmlFor={USER_TYPE.ORGANIZATION}
                    className='text-white'
                  >
                    {PROFILE_COMPLETION.SECOND_RADIO_BUTTON}
                  </Label>
                </div>
              </RadioGroup>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default StepOne;
