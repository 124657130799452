import { RiVipCrown2Fill } from 'react-icons/ri';

import UserImg from 'src/assets/images/Avatar.png';
import BasicCardIcon from 'src/assets/svgs/BasicCardIcon.svg';
import { HEADER_TEXT, USER_PLAN_TYPE } from 'src/constants/common';
import useDashboardContext from 'src/hooks/useDashboardData';
import { truncateText } from 'src/utils/common';

import { Skeleton } from './ui/skeleton';
import { Typography } from './Typography';

type UserDataProps = {
  user: any;
  currentPlan?: string | null;
  userSubscriptionTypeLaoding: boolean | null;
};

const UserData: React.FC<UserDataProps> = ({
  user,
  currentPlan,
  userSubscriptionTypeLaoding,
}) => {
  const { isAdmin } = useDashboardContext();
  return (
    <>
      <img src={user?.avatar || UserImg} className='size-12 rounded-full ' />
      <div className='text-white text-left ml-2 flex flex-col'>
        <Typography>
          {user?.firstName ? (
            truncateText(`${user?.firstName} ${user?.lastName}`)
          ) : (
            <>{isAdmin ? HEADER_TEXT.ADMIN : HEADER_TEXT.USER}</>
          )}
        </Typography>
        <Typography className='text-sm flex gap-1 items-center'>
          {!userSubscriptionTypeLaoding ? (
            <>
              {currentPlan && currentPlan === USER_PLAN_TYPE.BASIC ? (
                <>
                  <img
                    src={BasicCardIcon}
                    alt='basic'
                    className='size-[14px]'
                  />
                  {USER_PLAN_TYPE.BASIC}
                </>
              ) : null}
              {currentPlan && currentPlan === USER_PLAN_TYPE.PREMIUM ? (
                <>
                  <RiVipCrown2Fill className='size-[16px]' />
                  {USER_PLAN_TYPE.PREMIUM}
                </>
              ) : null}
              {!currentPlan && !isAdmin ? HEADER_TEXT.FREE : null}
              {!currentPlan && isAdmin ? HEADER_TEXT.ADMIN : null}
            </>
          ) : (
            <Skeleton className='h-4 w-16 bg-borderGrayColor' />
          )}
        </Typography>
      </div>
    </>
  );
};
export default UserData;
