import React, { useEffect } from 'react';

import Loader from 'src/components/Loader';
import { cn } from 'src/lib/utils';
import { UserType } from 'src/providers/Auth/types';
import { IAgreement, USER_TYPE } from 'src/services/types';

import Agreement from './Agreement';
import OrgAgreement from './OrgAgreement';

type StepTwoProps = {
  accepTerms: boolean;
  handleAcceptTerms: () => void;
  agreementList: IAgreement[];
  setAcceptAllTerms: React.Dispatch<React.SetStateAction<string[]>>;
  isLoading: boolean;
  acceptAllTerms: string[];
  setAcceptTerms: React.Dispatch<React.SetStateAction<boolean>>;
  currentUseData: UserType | undefined;
  fullLocation: string;
};

const StepTwo: React.FC<StepTwoProps> = ({
  accepTerms,
  handleAcceptTerms,
  agreementList,
  isLoading,
  setAcceptAllTerms,
  acceptAllTerms,
  setAcceptTerms,
  currentUseData,
  fullLocation,
}) => {
  const handleCheckboxChange = (id: string) => {
    setAcceptAllTerms((prev: string[]) => {
      if (prev.includes(id)) {
        return prev.filter((item) => item !== id);
      }
      return [...prev, id];
    });
  };
  useEffect(() => {
    setAcceptTerms(agreementList.length === acceptAllTerms.length);
  }, [acceptAllTerms, agreementList]);

  return (
    <div className='px-5 xl:px-10'>
      <div
        className={cn(
          'bg-skyCaptain max-h-[480px] p-3 w-full overflow-scroll text-white rounded-[14px] h-60 !mt-7 !mb-3',
          {
            'h-auto':
              !isLoading && currentUseData?.userType === USER_TYPE.INDVIDUAL,
          },
        )}
      >
        {isLoading ? (
          <div className='p-5'>
            <div className='flex items-center justify-center h-44'>
              <Loader />
            </div>
          </div>
        ) : (
          <>
            {currentUseData?.userType === USER_TYPE.INDVIDUAL
              ? agreementList.map((item: IAgreement, idx: number) => (
                  <Agreement
                    key={idx?.toString()}
                    {...{
                      item,
                      currentUseData,
                      fullLocation,
                      acceptAllTerms,
                      handleCheckboxChange,
                    }}
                  />
                ))
              : null}
            {currentUseData?.userType === USER_TYPE.ORGANIZATION ? (
              <OrgAgreement
                accepTerms={accepTerms}
                userType={currentUseData?.userType || ''}
                handleAcceptTerms={handleAcceptTerms}
                currentUserData={currentUseData}
              />
            ) : null}
          </>
        )}
      </div>
    </div>
  );
};

export default StepTwo;
