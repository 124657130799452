import React from 'react';
import { RiVipCrown2Fill } from 'react-icons/ri';

import InfoIconTooltip from 'src/components/InfoIconTooltip';
import { Label } from 'src/components/ui/label';
import { cn } from 'src/lib/utils';

type LabelTextProps = {
  label: string;
  isDisabled?: boolean;
  isPremium?: boolean;
  infoText?: string;
  labelClassName?: string;
};

const LabelTextProps: React.FC<LabelTextProps> = ({
  isDisabled,
  label,
  isPremium,
  infoText = '',
  labelClassName,
}) => {
  return (
    <Label
      className={cn(
        'text-white text-xs lg:text-[15px] font-light flex items-center gap-2 xl:gap-4',
        {
          'opacity-70': isDisabled,
        },
        labelClassName,
      )}
    >
      {label}
      <div className='flex gap-1.5 items-center'>
        {isPremium ? <RiVipCrown2Fill className='size-4' /> : null}
        {infoText ? <InfoIconTooltip tooltipText={infoText} /> : null}
      </div>
    </Label>
  );
};

export default LabelTextProps;
