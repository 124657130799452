import React from 'react';

import { DropDownListType } from 'src/@types/dashboardTypes';
import Divider from 'src/components/Divider';
import SelectDropdown from 'src/components/SelectDropdown';
import {
  BELOWSTRIKE_OPTION_VALUES,
  DASHBOARD_INFO_TEXT,
  DASHBOARD_TEXT,
  FiltersKeyName,
} from 'src/constants/dashboard';
import useDashboardContext from 'src/hooks/useDashboardData';

import CalendarSelect from '../CalendarSelect';
import FiltersHeading from '../FiltersHeading';
// import FilterTextInput from '../FilterTextInput';
import PNL from '../PNL';

interface IHistoricalFilter {
  handleOpen: () => void;
}
const HistoricalFilter: React.FC<IHistoricalFilter> = ({ handleOpen }) => {
  const { handleFilterChange, screener, isPremiumOrAdmin } =
    useDashboardContext();
  const onChangeValues = (
    name: string,
    value: string | number | DropDownListType | Date,
  ) => {
    handleFilterChange(name, value);
  };
  return (
    <div className='mb-2'>
      <div className='py-5'>
        <FiltersHeading text={DASHBOARD_TEXT.HISTORICAL_FILTER} />
      </div>
      <Divider className='border02' />
      <div className='pt-5 pb-1 grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-x-3 gap-y-4 lg:gap-x-7'>
        <SelectDropdown
          items={BELOWSTRIKE_OPTION_VALUES}
          inputClassName='w-full'
          label={DASHBOARD_TEXT.EXPW}
          hasStringTypeValue
          isPremium={!isPremiumOrAdmin}
          isDisabled={!isPremiumOrAdmin}
          handleOpen={handleOpen}
          onChange={onChangeValues}
          keyName={FiltersKeyName.EXPW}
          value={screener?.filter?.expw}
          infoText={DASHBOARD_INFO_TEXT.EXPW}
        />
        <PNL
          label={DASHBOARD_TEXT.PNL}
          keyName={FiltersKeyName.PNL}
          onChange={handleFilterChange}
          handleOpen={handleOpen}
          value={screener?.filter?.pnl}
          isPremium={!isPremiumOrAdmin}
          isDisabled={!isPremiumOrAdmin}
          infoText={DASHBOARD_INFO_TEXT.PNL}
        />
        <CalendarSelect
          placeholder={DASHBOARD_TEXT.SELECT}
          value={screener?.filter?.week}
          onChange={onChangeValues}
          keyName={FiltersKeyName.WEEK}
          label={DASHBOARD_TEXT.WEEK}
          isPremium={!isPremiumOrAdmin}
          isDisabled={!isPremiumOrAdmin}
          handleOpen={handleOpen}
          infoText={DASHBOARD_INFO_TEXT.WEEK}
        />
        <CalendarSelect
          placeholder={DASHBOARD_TEXT.SELECT}
          value={screener?.filter?.firstPubAt}
          onChange={onChangeValues}
          keyName={FiltersKeyName.FIRST_PUB_AT}
          label={DASHBOARD_TEXT.FIRST_PUB_AT}
          isPremium={!isPremiumOrAdmin}
          isDisabled={!isPremiumOrAdmin}
          handleOpen={handleOpen}
          infoText={DASHBOARD_INFO_TEXT.FIRST_PUB_AT}
        />
        {/* <FilterTextInput
          inputClassName='w-full'
          label={DASHBOARD_TEXT.FIRST_PUB_PRICE}
          isPremium={!isPremiumOrAdmin}
          isDisabled={!isPremiumOrAdmin}
          onChange={onChangeValues}
          value={screener?.filter?.firstPubPrice}
          handleOpen={handleOpen}
          keyName={FiltersKeyName.FIRST_PUB_PRICE}
          infoText={DASHBOARD_INFO_TEXT.FIRST_PUB_PRICE}
        /> */}
      </div>
    </div>
  );
};

export default HistoricalFilter;
