export const FAQ = {
  TITLE: 'Frequently Asked Questions (FAQ)',
  SECTIONS: [
    {
      TITLE: 'General Questions:',
      QUESTIONS: [
        {
          question: 'What does the dashboard display?',
          answere:
            'The dashboard displays a list of put options sorted by profit probability and yield. The data can be viewed either by day or by contract.',
        },
        {
          question: 'How are the put options sorted?',
          answere:
            'The put options are sorted based on their profit probability and yield, helping you identify the most profitable opportunities.',
        },
        {
          question: 'What does "selling to open a position" mean?',
          answere:
            'Selling to open a position means you sell put options, opening a new position with the expectation that the stock will remain above the strike price. If it does, you keep the premium received from selling the options.',
        },
        {
          question:
            'What happens if the underlying stock stays above the strike price?',
          answere:
            'If the underlying stock remains above the strike price, the put options expire worthless, and you keep the premium received from selling the options.',
        },
        {
          question: 'How is profitability calculated?',
          answere:
            'Profitability is based on the initial premium received from selling the put option. Assuming one contract per security is published simultaneously, the profit is the premium minus any transaction costs.',
        },
        {
          question: 'What if the stock drops below the strike price?',
          answere:
            'If the stock drops below the strike price, you may be obligated to buy the stock at the strike price. The contracts are held until expiration, and if assigned, the shares are sold at the closing price.',
        },
        {
          question: 'Can I choose the data display period?',
          answere:
            'Yes, you can choose to display the data either by day or by contract, depending on your preference.',
        },
        {
          question: 'What filters can I apply to the data?',
          answere:
            'You can apply various filters to the data to customize the list of put options according to your investment criteria and risk tolerance.',
        },
        {
          question: 'How often is the data updated?',
          answere:
            'The data on the dashboard is updated in real-time, though there may be a slight delay in publishing the data on the dashboard, ensuring you have access to the most current information available.',
        },
        {
          question: 'Can I export the data?',
          answere:
            'Yes as a Premium user, you can export the data displayed on the dashboard for further analysis or record-keeping.',
        },
        {
          question: 'How do I access the premium features?',
          answere:
            'The premium features, such as advanced filtering options and historical data analysis, are available to premium users. You can upgrade your subscription to access these features.',
        },
        {
          question:
            'What is the benefit of holding contracts until expiration?',
          answere:
            'Holding contracts until expiration ensures that you fully realize the premium received from selling the options. If the options expire worthless, you keep the entire premium.',
        },
        {
          question: 'What are the risks involved?',
          answere:
            'The main risk is that the stock may drop below the strike price, obligating you to buy the stock at a higher price than its market value. Proper risk management and diversification can help mitigate these risks.',
        },
        {
          question: 'Can I back-test my strategies using this dashboard?',
          answere:
            'Yes, premium users can back-test their strategies using the historical data provided on the dashboard. This allows you to evaluate the potential success of your strategies before applying them in real-time.',
        },
        {
          question:
            'How do I interpret the profit probability and yield metrics?',
          answere: '',
          listText: [
            'Profit Probability: This indicates the likelihood that the put option will expire worthless, allowing you to keep the premium.',
            'Yield: This represents the potential return on investment based on the premium received relative to the risk involved.',
          ],
        },
        {
          question: 'Is there a tutorial available for using the dashboard?',
          answere:
            'Yes, a comprehensive tutorial is available to guide you through the features and functionalities of the dashboard. You can access it from the help section.',
        },
        {
          question: 'How do I contact support if I have questions?',
          answere:
            'You can contact our support team via email or live chat for any questions or assistance. Support details are available in the help section of the dashboard.',
        },
      ],
    },
  ],
};

export const OPTIONS_PROFIT_CALCULATOR = {
  TITLE: 'Options Profit Calculator:',
  CONTENT: [
    {
      type: 'paragraph',
      value:
        'Unlock the full potential of your trading strategies with our premium feature, the Options Profit Calculator. This powerful tool lets you back-test your strategies using industry-standard filters and our proprietary dynamic filters.',
    },
    {
      type: 'list',
      title: 'How It Works:',
      listStyle: 'decimal',
      value: [
        {
          boldText: 'Set Your Filters:',
          simpleText:
            'Choose from a variety of commonly used and proprietary filters to tailor your strategy.',
        },
        {
          boldText: 'Apply Filters:',
          simpleText: 'Click "Apply Filters" to run the Profit Calculator.',
        },
        {
          boldText: 'Analyze Results:',
          simpleText:
            "View your strategy's success rate, including the percentage of options expiring above or below the strike price, and see detailed $ PNL calculations.",
        },
      ],
    },
    {
      type: 'paragraph',
      value:
        'Maximize your returns by leveraging our Options Profit Calculator, exclusively for premium users.',
    },
    {
      type: 'paragraph',
      value:
        'Note: Assuming one contract per security published simultaneously, profitability is based on the initial premium. Contracts are held until expiration, with shares sold at the closing price if assigned',
    },
  ],
};

export const OPTIONS_OPTIMIZER = {
  TITLE: 'Options Optimizer',
  CONTENT: [
    {
      type: 'paragraph',
      value:
        'Harness the power of our Optimal Allocation Wiz tool to calculate the ideal number of option contracts to open for a security. This optimizer utilizes Optimal Portfolio Theory to enhance diversification, reduce risk, and improve overall success.',
    },
    {
      type: 'list',
      title: 'How It Works:',
      listStyle: 'decimal',
      value: [
        {
          boldText: 'Input Investment Amount:',
          simpleText: 'Enter the total amount of money you want to invest.',
        },
        {
          boldText: 'Select Top Contracts:',
          simpleText:
            'Choose the top number of contracts to pick from the displayed list on the dashboard (e.g., top 10, top 30).',
        },
        {
          boldText: 'Set Minimum Securities:',
          simpleText:
            'Specify the minimum number of different stocks to include in your portfolio.',
        },
        {
          boldText: 'Define Minimum Contracts per Security:',
          simpleText:
            'Determine the minimum number of contracts/options to open for each stock selected.',
        },
        {
          boldText: 'Maximize Stock Weightage:',
          simpleText:
            'Choose the maximum weightage for each holding, representing the value of contracts in a stock compared to the entire portfolio.',
        },
        {
          boldText: 'Apply Filters:',
          simpleText:
            'After making your selections, apply the filters to optimize your allocation.',
        },
      ],
    },
    {
      type: 'list',
      title: 'Key Features:',
      listStyle: 'disc',
      value: [
        {
          boldText: 'Versatile Application: ',
          simpleText:
            'The Optimal Allocation Wiz can be applied to any data published on the dashboard, either before or after applying other filters.',
        },
        {
          boldText: 'Dynamic Updates: ',
          simpleText:
            'If dashboard values change, simply reapply the filters to get updated Optimal Allocation Wiz values.',
        },
      ],
    },
    {
      type: 'paragraph',
      value:
        'Optimize your investment strategy with our Options Optimizer and achieve better diversification and reduced risk.',
    },
  ],
};

export const TUTORIALS = {
  TITLE: 'Tutorials',
  SUB_HEADING: 'How to Use Boiling Options:',
  SUB_HEADING_TEXT:
    'Learn how to use Boiling Options effectively, these tutorials walk you through how to use each feature of Boiling Options  ',
  STILL_HAVE_QUETION: 'Still have questions? See our',
  FAQ: 'frequently asked questions',
  CONTACT: 'contact us',
  WEEKLY_VIDEOS: 'Weekly Trade Videos:',
  WEEKLY_VIDEOS_TEXT:
    'Check out our YouTube channel for weekly trade ideas and trade tips. Our host, Steve Ganz, has over 30 years of trading experience. Follow along and learn how to trade options like a pro to make steady income without taking unnecessary risks.',
  WATCH_ON_YOUTUBE: 'Watch on YouTube',
  DAILY_TIPS: 'Daily Tips:',
  DAILY_TIPS_TEXT: 'Coming soon!',
  TIPS_LINK_TEXY:
    'tips page to view all tips and disable or enable daily tips.',
};

export const TUTORIALS_TEXT_ARRAY = [
  {
    heading: 'Selling Put Options',
    link: '/',
    videoLink: '',
    // http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/WeAreGoingOnBullrun.mp4
  },
  {
    heading: 'How does Boiling Options Work',
    link: '/',
    videoLink: '',
  },
  {
    heading: 'Quirks and features of Boiling Options',
    link: '/',
    videoLink: '',
  },
  {
    heading: 'Premium Features',
    link: '/',
    videoLink: '',
  },
];
