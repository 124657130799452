/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from 'react-icons/md';

import { Table } from '@tanstack/react-table';

import { cn } from 'src/lib/utils';

import { IActualStocks } from './makeData';

const PaginationButton = ({
  canChangePage,
  changePage,
  children,
  isActive,
}: {
  canChangePage: boolean;
  changePage: () => void;
  children: React.ReactNode | string;
  isActive?: boolean;
}) => (
  <button
    className={cn(
      'rounded-md flex justify-center bg-white items-center p-1 size-6 sm:size-8 text-sm',
      {
        'text-slate-500': !canChangePage,
        'hover:bg-gray-200 hover:cursor-pointe': canChangePage,
        'bg-skyBlue text-white hover:bg-skyBlue/90': isActive,
      },
      'whitespace-nowrap',
    )}
    onClick={changePage}
    disabled={!canChangePage}
  >
    {typeof children === 'string'
      ? children?.toString().length > 3
        ? `${children.toString().slice(0, 2)}..`
        : children
      : children}
  </button>
);

const Pagination = ({
  table,
  setCurrentPage,
  currentPage,
  totalPages,
  handleChangePage,
  pageSize,
}: {
  table: Table<IActualStocks>;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>> | undefined;
  currentPage: number;
  totalPages: number;
  handleChangePage: (page: number, pageSize: number) => void;
  pageSize: number;
}) => {
  const totalPagesNum = totalPages || table.getPageCount();

  const canNavigatePrevious = currentPage > 1;
  const canNavigateNext = currentPage < totalPagesNum;
  const buttonNumbers = useMemo(() => {
    const buttons: (number | string)[] = [];
    const ellipsis = '...';
    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        buttons.push(i);
      }
    } else {
      if (currentPage <= 3) {
        buttons.push(1, 2, 3, ellipsis, totalPages);
      } else if (currentPage >= totalPages - 2) {
        buttons.push(1, ellipsis, totalPages - 2, totalPages - 1, totalPages);
      } else {
        buttons.push(
          1,
          ellipsis,
          currentPage - 1,
          currentPage,
          currentPage + 1,
          ellipsis,
          totalPages,
        );
      }
    }
    return buttons;
  }, [currentPage, totalPages]);

  const goToFirstPage = () => {
    table.setPageIndex(0);
    if (setCurrentPage) setCurrentPage(1);
    handleChangePage(1, pageSize);
  };

  const goToPreviousPage = () => {
    table.previousPage();
    if (setCurrentPage) setCurrentPage((prev) => Math.max(prev - 1, 1));
    handleChangePage(Math.max(currentPage - 1), pageSize);
  };

  const goToPage = (pageNumber: number) => {
    table.setPageIndex(pageNumber - 1);
    if (setCurrentPage) setCurrentPage(pageNumber);
    handleChangePage(pageNumber, pageSize);
  };

  const goToNextPage = () => {
    table.nextPage();
    if (setCurrentPage)
      setCurrentPage((prev) => Math.min(prev + 1, totalPagesNum));
    handleChangePage(Math.min(currentPage + 1), pageSize);
  };

  const goToLastPage = () => {
    table.setPageIndex(totalPagesNum - 1);
    if (setCurrentPage) setCurrentPage(totalPagesNum);
    handleChangePage(totalPagesNum, pageSize);
  };

  return (
    <div className='px-4 flex justify-end gap-2'>
      <span></span>
      <PaginationButton
        canChangePage={canNavigatePrevious}
        changePage={goToFirstPage}
      >
        <MdKeyboardDoubleArrowLeft size={20} />
      </PaginationButton>
      <PaginationButton
        canChangePage={canNavigatePrevious}
        changePage={goToPreviousPage}
      >
        <MdKeyboardArrowLeft size={20} />
      </PaginationButton>

      {buttonNumbers.map((buttonNumber: number | string, index: number) => (
        <PaginationButton
          key={index}
          canChangePage={
            typeof buttonNumber === 'number' &&
            buttonNumber <= totalPages &&
            buttonNumber >= 1
          }
          changePage={() =>
            typeof buttonNumber === 'number' && goToPage(buttonNumber)
          }
          isActive={buttonNumber === currentPage}
        >
          {buttonNumber}
        </PaginationButton>
      ))}

      <PaginationButton
        canChangePage={canNavigateNext}
        changePage={goToNextPage}
      >
        <MdKeyboardArrowRight size={20} />
      </PaginationButton>
      <PaginationButton
        canChangePage={canNavigateNext}
        changePage={goToLastPage}
      >
        <MdKeyboardDoubleArrowRight size={20} />
      </PaginationButton>
    </div>
  );
};

export default Pagination;
