import React, { useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ReactCodeInput from 'react-verification-code-input';

import Loader from 'src/components/Loader';
import withPublicRoute from 'src/components/PublicRoute';
import { Typography } from 'src/components/Typography';
import { Button } from 'src/components/ui/button';
import { OTP_TYPE, VERIFICATION } from 'src/constants/auth';
import { ROUTES } from 'src/constants/common';
import useAuthContext from 'src/hooks/useAuth';
import { cn } from 'src/lib/utils';
import { otp, resentOTP } from 'src/services/auth';
import { maskEmailAddress } from 'src/utils/common';

const OTP = () => {
  const [code, setCode] = useState<string>('');
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [countdownTime, setCountdownTime] = useState('');
  const location = useLocation();
  const { state } = location || {};
  const { isForgot } = state || {};
  const { emailForOtp, setEmailForOtp } = useAuthContext();

  useEffect(() => {
    if (!emailForOtp) {
      navigate(ROUTES.SIGNUP);
    }
  }, [emailForOtp, navigate]);

  let timeout: null | NodeJS.Timeout = null;
  const duration = 120;
  const otpType = useMemo(() => {
    return isForgot ? OTP_TYPE.RESET_PASSWORD : OTP_TYPE.EMAIL_VERIFICATION;
  }, [isForgot]);

  const timerOnView = () => {
    let timer = duration;
    let minutes;
    let seconds;

    const updateTimer = () => {
      minutes = parseInt(String(timer / 60), 10);
      seconds = parseInt(String(timer % 60), 10);

      minutes = minutes < 10 ? `0${minutes}` : minutes;
      seconds = seconds < 10 ? `0${seconds}` : seconds;

      setCountdownTime(`${minutes}:${seconds}`);

      if (timer > 0) {
        timer--;
        timeout = setTimeout(updateTimer, 1000);
      }
    };

    updateTimer();
  };

  useEffect(() => {
    timerOnView();
    return () => {
      clearTimeout(timeout as NodeJS.Timeout);
    };
  }, []);

  const handleVerifyButton = async () => {
    const payload = {
      email: emailForOtp as string,
      otpCode: code,
      otpType,
    };
    setIsLoading(true);
    const response = await otp(payload);
    setIsLoading(false);
    if (response) {
      if (isForgot) {
        navigate(ROUTES.SET_NEW_PASSWORD, { state: { emailForOtp } });
        return;
      }
      navigate(ROUTES.LOGIN);
      setEmailForOtp('');
    }
  };

  const handleResendOTP = async () => {
    if (countdownTime !== '00:00') return;
    const payload = {
      email: emailForOtp as string,
      otpType,
    };
    const response = await resentOTP(payload);
    if (response) {
      setCountdownTime('120:00');
      timerOnView();
    }
  };

  return (
    <div className='bg-nightSky md:px-6 md:py-6 mt-20 mb-40 xl:px-20 p-6 lg:py-8 flex flex-col mx-auto w-11/12 md:w-7/12 lg:w-5/12 rounded-xl'>
      <Typography
        variant='subheading'
        className='mx-auto my-4 text-center capitalize'
      >
        {VERIFICATION.TITLE}
      </Typography>
      <Typography variant='p' className='mx-auto'>
        {`${VERIFICATION.ENTER_OTP}${maskEmailAddress(emailForOtp || '')}`}
      </Typography>
      <div className='mt-5 mx-auto '>
        <ReactCodeInput
          onChange={(code) => setCode(code)}
          className='!w-full !mx-auto '
        />
        <div className='flex justify-between mt-2'>
          <Typography
            className={cn('text-sm text-skyBlue underline cursor-pointer', {
              'cursor-not-allowed text-darkGray': countdownTime !== '00:00',
            })}
          >
            <span onClick={handleResendOTP}>{VERIFICATION.RESEND_OTP}</span>
          </Typography>
          <Typography className='text-sm'>
            {`${countdownTime} Minutes`}
          </Typography>
        </div>
      </div>
      <Button variant='gradient' className='my-4' onClick={handleVerifyButton}>
        {isLoading ? <Loader /> : VERIFICATION.VERIFY}
      </Button>
      <Typography variant='p' className='mx-auto capitalize'>
        {VERIFICATION.BACK_TO}{' '}
        <Link to={ROUTES.HOME}>
          <span className='text-skyBlue underline'>{VERIFICATION.LOGIN}</span>
        </Link>
      </Typography>
    </div>
  );
};

export default withPublicRoute(OTP);
