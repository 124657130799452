import React, { useEffect, useMemo, useState } from 'react';
import { FaRegEdit } from 'react-icons/fa';

import { Typography } from 'src/components/Typography';
import { Button } from 'src/components/ui/button';
import { PROFILE } from 'src/constants/profile';
import useAuthContext from 'src/hooks/useAuth';
import { truncateText } from 'src/utils/common';

import DetailItem from './DetailItem';
const useScreenSize = () => {
  const [isMdToXl, setIsMdToXl] = useState(false);
  useEffect(() => {
    const checkScreenSize = () => {
      const width = window.innerWidth;
      setIsMdToXl(width >= 768 && width < 1180);
    };
    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);
  return isMdToXl;
};

interface PersonalInfoProps {
  handleEditInfo: () => void;
}
interface PersonalInfo {
  title: string;
  value: string | undefined;
}

const PersonalInfo: React.FC<PersonalInfoProps> = ({ handleEditInfo }) => {
  const { auth } = useAuthContext();
  const { user } = auth ?? {};
  const isMdToXl = useScreenSize();

  const personalInfo: PersonalInfo[] = useMemo(() => {
    return [
      {
        title: PROFILE.FIELDS.FIRST_NAME,
        value: user?.firstName || 'N/A',
      },
      {
        title: PROFILE.FIELDS.MIDDLE_NAME,
        value: user?.middleName || 'N/A',
      },
      {
        title: PROFILE.FIELDS.LAST_NAME,
        value: user?.lastName || 'N/A',
      },
      {
        title: PROFILE.FIELDS.EMAIL,
        value: user?.email || 'N/A',
      },
      {
        title: PROFILE.FIELDS.ROLE,
        value: user?.role || 'N/A',
      },
      {
        title: PROFILE.FIELDS.ADDRESS,
        value: user?.streetAddress || 'N/A',
      },
      {
        title: PROFILE.FIELDS.COUNTRY,
        value: user?.country || 'N/A',
      },
      {
        title: PROFILE.FIELDS.STATE,
        value: user?.state || 'N/A',
      },
      {
        title: PROFILE.FIELDS.CITY,
        value: user?.city || 'N/A',
      },
      {
        title: PROFILE.FIELDS.ZIP_CODE,
        value: user?.zipCode || 'N/A',
      },
    ];
  }, [user]);

  return (
    <div className='bg-nightSky w-11/12 mx-auto p-3 md:p-6 m-4 rounded-2xl'>
      <div className='flex justify-between'>
        <Typography variant='subheading' className='text-lg md:text-2xl'>
          {PROFILE.personalInformation}
        </Typography>
        <Button
          variant={'primary'}
          className='rounded-full p-3 sm:p-5 font-medium text-sm md:text-base'
          onClick={handleEditInfo}
        >
          <FaRegEdit className='mr-2 sm:mr-4' size={19} />
          {PROFILE.EDIT}
        </Button>
      </div>
      <div className='md:grid md:grid-cols-3 md:w-10/12 gap-2 mt-5 md:mt-0 space-y-5 md:space-y-0'>
        {personalInfo?.map((info: PersonalInfo) => (
          <div className='md:h-28' key={info.title}>
            <DetailItem
              title={info.title}
              value={
                typeof info.value === 'string' && isMdToXl
                  ? truncateText(info?.value)
                  : info?.value
              }
              isLoading={user === undefined}
            />
          </div>
        ))}
        {user?.currentlyEmployed ? (
          <>
            <div className='md:h-28'>
              <DetailItem
                title={PROFILE.FIELDS.NAME_OF_EMPLOYER}
                value={user?.nameOfEmployer}
              />
            </div>
            <div className='md:h-28'>
              <DetailItem
                title={PROFILE.FIELDS.OCCUPATION_OR_JOB}
                value={user?.employerJobTitle}
              />
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default PersonalInfo;
