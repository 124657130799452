import React from 'react';

import { Typography } from 'src/components/Typography';
import { Checkbox } from 'src/components/ui/checkbox';
import { PROFILE_COMPLETION } from 'src/constants/profileCompletion';
import { UserType } from 'src/providers/Auth/types';

type OrgAgreementType = {
  accepTerms: boolean;
  userType: string;
  handleAcceptTerms: () => void;
  currentUserData: UserType | undefined;
};
const OrgAgreement: React.FC<OrgAgreementType> = ({
  accepTerms,
  handleAcceptTerms,
  currentUserData,
}) => {
  const sanitizeAgreementText = (text: string) => {
    return text
      .replace(
        /\/\*name\*\//g,
        `${currentUserData?.firstName} ${currentUserData?.lastName}`,
      )
      .replace(/\/\*location\*\//g, currentUserData?.streetAddress || '')
      .replace(/\/\*Title\*\//g, currentUserData?.employerJobTitle || '')
      .replace(/\/\*Company\*\//g, currentUserData?.nameOfEmployer || '');
  };

  return (
    <>
      {PROFILE_COMPLETION.PROFILE_COMPLETIONG_DIALOG_ORG_TEXT.map(
        (item: string) => (
          <Typography className='text-[13px] text-light mb-5' key={item}>
            {sanitizeAgreementText(item)}
          </Typography>
        ),
      )}
      <div className='flex items-center space-x-2 mt-2'>
        <Checkbox
          checked={accepTerms}
          className=' ml-1 border-0 bg-lightSkyBlue '
          onCheckedChange={handleAcceptTerms}
        />
        <div className='grid gap-1.5 leading-none'>
          <label htmlFor='terms1' className='text-sm'>
            {PROFILE_COMPLETION.CONTINUE_AS_PRO}
          </label>
        </div>
      </div>
    </>
  );
};

export default OrgAgreement;
