import { RxDownload } from 'react-icons/rx';

import cardImage from 'src/assets/svgs/card.svg';
import visaCardImage from 'src/assets/svgs/visa-card.svg';
import { CARD_TYPE, PAYMENT_STATUS, PROFILE } from 'src/constants/profile';
import { cn } from 'src/lib/utils';
import { getFormattedDate } from 'src/utils/common';

type TableDataType = {
  amount: string;
  date: string;
  downloadUrl: string;
  last4: string;
  status: string;
  cardType: CARD_TYPE;
};

type TableProps = {
  tableHeadings: { title: string; key: string }[];
  data: TableDataType[];
  haveImage?: boolean;
};

const PaymentTable: React.FC<TableProps> = ({ tableHeadings, data }) => {
  const downloadInvoice = (url: string) => {
    window.open(url, '_blank');
  };
  return (
    <div className='relative overflow-x-auto sm:rounded-lg p-3'>
      <table className='min-w-[700px] md:w-full text-base text-left rtl:text-right border-separate border-spacing-y-3'>
        <thead className='font-semibold'>
          <tr className='border-separate text-primaryBackground h-12'>
            {tableHeadings.map((header, index) => (
              <th
                key={header.title}
                scope='col'
                className={cn('px-6 bg-wintersDay', {
                  'rounded-r-[10px]': index === tableHeadings.length - 1,
                  'rounded-l-[10px]': index === 0,
                })}
              >
                {header.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className='borders'>
          {data?.length ? (
            data?.map((item: TableDataType, index: number) => (
              <tr
                className='text-white bg-primaryBackground'
                key={`${item?.last4}_${index}`}
              >
                <td
                  scope='row'
                  className='flex py-2 items-center px-6 whitespace-nowrap mt-1'
                >
                  {item.cardType === CARD_TYPE.VISA ? (
                    <img
                      className='w-7 h-7'
                      src={visaCardImage}
                      alt={'card-image'}
                    />
                  ) : null}
                  {item.cardType === CARD_TYPE.MASTER ? (
                    <img
                      className='w-7 h-7 rounded-full'
                      src={cardImage}
                      alt={'card-image'}
                    />
                  ) : null}

                  <div className='ps-3'>
                    <div>{`*******${item?.last4}`}</div>
                  </div>
                </td>
                <td className='px-6 py-3'>{`$${item?.amount}`}</td>
                <td className='px-6 py-3'>{getFormattedDate(item?.date)}</td>
                <td className='px-6 py-3'>
                  <div className='flex items-center align-middle justify-between'>
                    <div
                      className={cn(
                        ' flex items-center py-1 capitalize rounded-full w-24 justify-center',
                        {
                          'bg-greenishWhite text-charmedGreen':
                            item.status === PAYMENT_STATUS.PAID,
                          'bg-fallStraw text-therapeuticToucan ':
                            item.status === PAYMENT_STATUS.DRAFT,
                          'bg-darkGray text-white ':
                            item.status === PAYMENT_STATUS.OPEN,
                        },
                      )}
                    >
                      <div
                        className={cn(
                          'h-1.5 w-1.5 rounded-full bg-charmedGreen me-2',
                          {
                            'bg-charmedGreen ':
                              item.status === PAYMENT_STATUS.PAID,
                            'bg-therapeuticToucan ':
                              item.status === PAYMENT_STATUS.DRAFT,
                            'bg-white ': item.status === PAYMENT_STATUS.OPEN,
                          },
                        )}
                      />
                      {item?.status}
                    </div>
                  </div>
                </td>
                <td
                  onClick={() => downloadInvoice(item?.downloadUrl)}
                  className='px-6 cursor-pointer py-3 text-skyBlue flex gap-1 items-center'
                >
                  <RxDownload size={18} />
                  {PROFILE.DOWNLOAD}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={6} className='text-center text-white py-3'>
                {PROFILE.NO_DATA_FOUND}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
export default PaymentTable;
